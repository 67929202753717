import React, { useEffect, useState } from 'react';
import {
  Banner,
  Box,
  Button,
  Flex,
  Heading,
  Loader,
  Select,
  Text,
  TextField,
  Tooltip,
} from '@mediahuis/chameleon-react-legacy';
import {
  ChevronBack,
  ChevronForward,
} from '@mediahuis/chameleon-theme-wl/legacy/icons';
import useHolidayContext from '~/context/hooks/useHolidayContext';
import {
  hasEmptyAddressFields,
  hasEmptyFamilyFields,
  validateAddresseeStepsData,
} from '~/utils';
import { config, ts } from '~/services';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import { addressIsComplete } from '~/pages/CustomerDetails/utils';
import AddressDeliveryOptions from '~/pages/CustomerDetails/components/DialogContent/AddressDeliveryOptions';
import {
  AH_HOLIDAY_SOLUTIONS,
  HOLIDAY_SOLUTIONS,
  HOLIDAY_STEPS,
  salutationSelectOptions,
} from '~/pages/Holiday/data';
import getEmptySolutionData from '~/pages/Holiday/utils/getEmptySolutionData';
import ahAddressFieldsValidations from '~/components/AddressFields/ahAddressFieldsValidations';
import getAddressDeliveryOptionsForSubscription from '~/api/ah/getAddressDeliveryOptionsForSubscription';
import AhAddressFields from '~/components/ah/AhAddressFields';
import isEmpty from 'lodash.isempty';
import getAllowedPostalCodesBySubscription from '~/api/ah/getAllowedPostalCodesBySubscription';

const AhAddressStep = ({
  submissionData,
  setSubmissionData,
  setCurrentStep,
  selectedDeliveryOptionBySubscription,
  setSelectedDeliveryOptionBySubscription,
  setIsDialogShown,
}) => {
  const holidayContext = useHolidayContext();
  const { holidayArrangementOptions, setHolidayState } = holidayContext;
  const { chosenUserSubscription } = useGlobalContext();
  const internalSubscriptionType =
    chosenUserSubscription?.internalSubscriptionType;

  // Here we work with local state as the AddressFields component
  // expects this.
  const [localAddress, setLocalAddress] = useState({
    title: '',
    firstName: '',
    lastName: '',
    countryCode: '',
    postalCode: '',
    city: '',
    street: '',
    houseNumber: '',
    box: '',
    ...submissionData.solutionData,
  });
  const [localErrors, setLocalErrors] = useState({});
  const [
    isLoadingAddressDeliveryOptions,
    setIsLoadingAddressDeliveryOptions,
  ] = useState(false);
  const [
    addressDeliveryOptionsHasFailed,
    setAddressDeliveryOptionsHasFailed,
  ] = useState(false);
  const [addressDeliveryOptions, setAddressDeliveryOptions] = useState(null);
  const [
    allowedPostalCodesBySubscriptionFailed,
    setAllowedPostalCodesBySubscriptionFailed,
  ] = useState(false);
  const [
    isLoadingallowedPostalCodesBySubscription,
    setIsLoadingallowedPostalCodesBySubscription,
  ] = useState(true);
  const [countryError, setCountryError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoadingallowedPostalCodesBySubscription(true);
        const {
          allowedCountryPostalCodes,
        } = await getAllowedPostalCodesBySubscription({
          id: chosenUserSubscription?.id,
        });
        setHolidayState({
          holidayArrangementOptions: {
            ...holidayArrangementOptions,
            allowedCountries: Object.keys(allowedCountryPostalCodes),
            allowedPostalCodesByCountry: allowedCountryPostalCodes,
          },
        });
      } catch {
        setAllowedPostalCodesBySubscriptionFailed(true);
      } finally {
        setIsLoadingallowedPostalCodesBySubscription(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenUserSubscription?.id]);

  useEffect(() => {
    setSubmissionData(prevState => ({
      ...prevState,
      solutionData: {
        ...localAddress,
      },
    }));
  }, [localAddress, setSubmissionData]);

  const validateOnBlur = e => {
    setLocalErrors({
      ...localErrors,
      ...validateAddresseeStepsData({
        [e.target.name]: e.target.value,
      }),
    });
  };

  const clearValidationOnFocus = e => {
    const spreadedErrors = { ...localErrors };
    delete spreadedErrors[e.target.name];
    setLocalErrors(spreadedErrors);
  };

  const onNextStep = () => {
    setCurrentStep(HOLIDAY_STEPS.SUMMARY_STEP);
  };

  const isFamilyAddress =
    submissionData.vacationSolution === HOLIDAY_SOLUTIONS.FAMILY_ADDRESS ||
    submissionData.vacationSolution === AH_HOLIDAY_SOLUTIONS.FAMILY_ADDRESS;

  const isHolidayAddress =
    submissionData.vacationSolution === HOLIDAY_SOLUTIONS.HOLIDAY_ADDRESS ||
    submissionData.vacationSolution === AH_HOLIDAY_SOLUTIONS.HOLIDAY_ADDRESS;

  const validateStep = async () => {
    const addresseeErrors =
      isFamilyAddress &&
      validateAddresseeStepsData({
        firstName: submissionData.solutionData.firstName,
        lastName: submissionData.solutionData.lastName,
        salutation: submissionData.solutionData.title,
      });

    const addressErrors = await ahAddressFieldsValidations({
      fields: localAddress,
      country: localAddress.countryCode,
      // The address is already validated before fetching the delivery options on the APortal
      useAddressService: !config.isAportal,
    });

    const combinedErrors = {
      ...(isFamilyAddress && addresseeErrors),
      ...addressErrors,
    };
    if (Object.keys(combinedErrors).length === 0) {
      onNextStep();
    }
    return setLocalErrors({ ...combinedErrors });
  };

  const handleOnPreviousStep = () => {
    if (isFamilyAddress || isHolidayAddress) {
      if (
        (isFamilyAddress &&
          holidayArrangementOptions?.knownFamilyAddresses?.length > 0) ||
        (isHolidayAddress &&
          holidayArrangementOptions?.knownHolidayAddresses?.length > 0)
      ) {
        setSubmissionData(prevState => ({
          ...prevState,
          solutionData: getEmptySolutionData(false),
        }));
        setCurrentStep(HOLIDAY_STEPS.ADDRESS_CHOICE_STEP);
      } else {
        setCurrentStep(HOLIDAY_STEPS.CHOICE_STEP);
      }
    } else {
      setCurrentStep(HOLIDAY_STEPS.CHOICE_STEP);
    }
  };
  const fetchAddressDeliveryOptions = async () => {
    try {
      setAddressDeliveryOptionsHasFailed(false);
      setAddressDeliveryOptions(null);
      setSelectedDeliveryOptionBySubscription(null);
      const addressErrors = await ahAddressFieldsValidations({
        fields: localAddress,
        country: localAddress.countryCode,
        useAddressService: true,
      });
      setLocalErrors(addressErrors);
      if (isEmpty(addressErrors)) {
        setIsLoadingAddressDeliveryOptions(true);
        const res = await getAddressDeliveryOptionsForSubscription({
          subscriptionId: chosenUserSubscription.id,
          address: {
            countryCode: localAddress.countryCode,
            postalCode: localAddress.postalCode,
            street: localAddress.street,
            houseNumber: localAddress.houseNumber,
          },
        });
        setAddressDeliveryOptions([res]);

        const defaultDeliveryOption = res.deliveryOptions.find(
          deliveryOption => deliveryOption.isDefault,
        );
        setSelectedDeliveryOptionBySubscription({
          [res.subscriptionId]: {
            typeCode: defaultDeliveryOption.type.code,
            typeLabel: defaultDeliveryOption.type.label,
          },
        });
      }
    } catch (error) {
      setAddressDeliveryOptionsHasFailed(true);
    } finally {
      setIsLoadingAddressDeliveryOptions(false);
    }
  };

  useEffect(() => {
    if (
      addressIsComplete({
        addressFields: localAddress,
        errors: localErrors,
      }) &&
      config.isAportal
    ) {
      fetchAddressDeliveryOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localAddress?.countryCode,
    localAddress?.postalCode,
    localAddress?.city,
    localAddress?.street,
    localAddress?.houseNumber,
    localErrors?.countryCode,
    localErrors?.postalCode,
    localErrors?.city,
    localErrors?.street,
    localErrors?.houseNumber,
  ]);

  const heading = (
    <Heading
      level={3}
      mb={6}
      fontFamily="primary"
      color={config.isAportal ? 'colorSecondaryBase' : 'colorPrimaryBase'}
    >
      {ts.t('Holiday.AddressStep.ForwardPaper')}
    </Heading>
  );

  if (isLoadingallowedPostalCodesBySubscription) {
    return (
      <>
        {heading}
        <Flex justifyContent="center">
          <Loader />
        </Flex>
      </>
    );
  }
  if (
    holidayArrangementOptions?.allowedCountries?.length === 0 ||
    allowedPostalCodesBySubscriptionFailed
  ) {
    return (
      <>
        {heading}
        <Banner appearance="error" show closeHidden>
          {ts.t(
            allowedPostalCodesBySubscriptionFailed
              ? 'Holiday.Errors.AllowedPostalCodes'
              : 'Holiday.NoAllowedCountries',
          )}
        </Banner>
        <Flex mt={5} justifyContent="flex-end">
          <Button onClick={handleOnPreviousStep} iconLeft={ChevronBack}>
            {ts.t('Common.Back')}
          </Button>
        </Flex>
      </>
    );
  }
  return (
    <>
      {heading}
      {isFamilyAddress && (
        <Box mb={8}>
          <Select
            id="salutation"
            value={submissionData.solutionData.title}
            onChange={e => {
              const value = e.target.value;
              return setLocalAddress(prevState => ({
                ...prevState,
                title: value,
              }));
            }}
            label={ts.t('Holiday.AddressStep.Salutation')}
            mb={3}
            name="salutation"
            placeholder={ts.t('Holiday.AddressStep.SalutationPlaceholder')}
            onBlur={validateOnBlur}
            onFocus={clearValidationOnFocus}
            message={localErrors.salutation}
            error={!!localErrors.salutation}
          >
            {salutationSelectOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          <TextField
            id="first-name-textfield"
            label={ts.t('Holiday.AddressStep.Firstname')}
            mb={3}
            name="firstName"
            value={submissionData.solutionData.firstName}
            onChange={e => {
              const value = e.target.value;
              return setLocalAddress(prevState => ({
                ...prevState,
                firstName: value,
              }));
            }}
            onBlur={validateOnBlur}
            onFocus={clearValidationOnFocus}
            message={localErrors.firstName}
            error={!!localErrors.firstName}
            maxLength={35}
          />
          <TextField
            id="last-name-textfield"
            label={ts.t('Holiday.AddressStep.LastName')}
            mb={3}
            name="lastName"
            value={submissionData.solutionData.lastName}
            onChange={e => {
              const value = e.target.value;
              return setLocalAddress(prevState => ({
                ...prevState,
                lastName: value,
              }));
            }}
            onBlur={validateOnBlur}
            onFocus={clearValidationOnFocus}
            message={localErrors.lastName}
            error={!!localErrors.lastName}
            maxLength={35}
          />
        </Box>
      )}
      <AhAddressFields
        address={localAddress}
        setAddress={setLocalAddress}
        errors={localErrors}
        setCountryError={setCountryError}
        setErrors={setLocalErrors}
        internalSubscriptionType={internalSubscriptionType}
        selectedDeliveryOptionBySubscription={
          selectedDeliveryOptionBySubscription
        }
        allowedCountries={holidayArrangementOptions?.allowedCountries}
        allowedPostalCodesByCountry={
          holidayArrangementOptions?.allowedPostalCodesByCountry
        }
        setDialogShown={setIsDialogShown}
      />
      <TextField
        id="extra-textfield"
        label={
          <>
            <span style={{ marginRight: '2px' }}>
              {ts.t('Holiday.AddressStep.ExtraAdressLine')}
            </span>
            <Tooltip
              id="extraInfo"
              label={ts.t('Holiday.AddressStep.ExtraAdressLineTooltip')}
              usePortal
            />
          </>
        }
        mb={3}
        name="extra"
        maxLength={60}
        value={submissionData.solutionData.extra}
        onChange={e => {
          const value = e.target.value;
          return setLocalAddress(prevState => ({
            ...prevState,
            extra: value,
          }));
        }}
      />
      {addressDeliveryOptionsHasFailed && (
        <Text size="Paragraph" color="colorRedBase" fontFamily="system">
          {ts.t('Holiday.Errors.DeliveryOptions')}
        </Text>
      )}
      {isLoadingAddressDeliveryOptions && <Loader />}
      {!addressDeliveryOptionsHasFailed &&
        addressDeliveryOptions &&
        !isLoadingAddressDeliveryOptions &&
        addressIsComplete({
          addressFields: localAddress,
          errors: localErrors,
        }) && (
          <AddressDeliveryOptions
            addressDeliveryOptions={addressDeliveryOptions}
            selectedDeliveryOptionBySubscription={
              selectedDeliveryOptionBySubscription
            }
            setSelectedDeliveryOptionBySubscription={
              setSelectedDeliveryOptionBySubscription
            }
          />
        )}
      <Flex mt={5} justifyContent="flex-end">
        <Button mr={3} onClick={handleOnPreviousStep} iconLeft={ChevronBack}>
          {ts.t('Common.Back')}
        </Button>
        <Button
          onClick={validateStep}
          iconRight={ChevronForward}
          disabled={
            !isEmpty(localErrors) ||
            hasEmptyAddressFields(localAddress) ||
            hasEmptyFamilyFields({
              solutionData: submissionData.solutionData,
              isFamilyAddress,
            }) ||
            countryError
          }
        >
          {submissionData?.id ? ts.t('Common.Edit') : ts.t('Common.Next')}
        </Button>
      </Flex>
    </>
  );
};

export default AhAddressStep;
