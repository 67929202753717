const allowedPrefixes = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'NL',
];

const getIsIbanCountryAllowed = (iban = '') => {
  if (
    allowedPrefixes.includes(
      iban
        ?.replace(/ /g, '')
        ?.substring(0, 2)
        ?.toUpperCase(),
    )
  )
    return true;
  return false;
};

export default getIsIbanCountryAllowed;
