/* eslint-disable babel/camelcase */
import apiCall from '~/utils/apiCall';
import { config } from '~/services';

const getAllHolidays = ({ subscriptionId, max_end, min_end, states } = {}) =>
  apiCall(`${config.serviceApiUrl}/VacationArrangements`, {
    withCredentials: true,
    params: {
      'api-version': '2.0',
      subscriptionId,
      max_end,
      min_end,
      states,
    },
  });

export default getAllHolidays;
