import { ts } from '~/services';
import { STRING_REPLACE } from '../enums';
import { arrHoursShortByLanguage } from '../data';

const setHours = ({ strText, arrHours }) => {
  const language = ts.getLanguage().substring(0, 2) || 'nl';
  const shortHour = arrHoursShortByLanguage[language];

  const { OPENHOUR, CLOSEHOUR } = STRING_REPLACE;
  let strReplacedText = strText;

  if (strText) {
    let strOpenHour = '';
    let strCloseHour = '';

    if (arrHours) {
      strOpenHour = arrHours[0].replace('h', shortHour);
      strCloseHour = arrHours[1].replace('h', shortHour);
    }

    strReplacedText = strReplacedText.replace(OPENHOUR, strOpenHour);
    strReplacedText = strReplacedText.replace(CLOSEHOUR, strCloseHour);
  }

  return strReplacedText;
};

export default setHours;
