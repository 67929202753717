import { ts } from '~/services';
import {
  arrHoursShortByLanguage,
  arrWeekdays,
  arrWeekdaysShortByLanguage,
} from '../data';

const getOpeningHours = ({ jsonOpeningHours }) => {
  const language = ts.getLanguage().substring(0, 2) || 'nl';
  let strTxt = '';
  let iDayOfTheWeek = 0;
  const arrOpeningHours = [];
  const shortHour = arrHoursShortByLanguage[language];

  arrWeekdays.forEach(strWeekday => {
    Object.keys(jsonOpeningHours).map(iKey => {
      if (iKey === strWeekday) {
        if (jsonOpeningHours[iKey]) {
          arrOpeningHours.push([
            arrWeekdaysShortByLanguage[language][iDayOfTheWeek],
            jsonOpeningHours[iKey][0],
            jsonOpeningHours[iKey][1],
          ]);
        } else {
          arrOpeningHours.push([
            arrWeekdaysShortByLanguage[language][iDayOfTheWeek],

            '00h00',
            '00h00',
          ]);
        }

        iDayOfTheWeek += 1;
      }
      return iKey;
    });
  });

  let arrBackupOpen = arrOpeningHours[0][1];
  let arrBackupClosure = arrOpeningHours[0][2];
  let strFirstDay = arrOpeningHours[0][0];

  for (let i = 0; i < 7; i += 1) {
    if (
      arrOpeningHours[i][1] !== arrBackupOpen ||
      arrOpeningHours[i][2] !== arrBackupClosure
    ) {
      if (strFirstDay !== arrOpeningHours[i - 1][0]) {
        strTxt += `${strFirstDay} ${ts.t(
          'CustomerServiceWidget.UpToAndIncluding',
        )} ${arrOpeningHours[i - 1][0]} (${arrOpeningHours[i - 1][1].replace(
          'h',
          shortHour,
        )}-${arrOpeningHours[i - 1][2].replace('h', shortHour)})<br />`;
      } else if (
        arrOpeningHours[i - 1][1] !== '00h00' &&
        arrOpeningHours[i - 1][2] !== '00h00'
      ) {
        strTxt += `${arrOpeningHours[i - 1][0]} (${arrOpeningHours[
          i - 1
        ][1].replace('h', shortHour)}-${arrOpeningHours[i - 1][2].replace(
          'h',
          shortHour,
        )})<br />`;
      }

      strFirstDay = arrOpeningHours[i][0];
    }

    arrBackupOpen = arrOpeningHours[i][1];
    arrBackupClosure = arrOpeningHours[i][2];
  }

  strTxt = strTxt.slice(0, -6);

  return strTxt;
};

export default getOpeningHours;
