/* eslint-disable import/max-dependencies */
import React from 'react';

// https://mediahuis.chameleon.be Reset and Fonts import
import '@mediahuis/chameleon-reset';
import '@mediahuis/chameleon-theme-wl/lib-legacy/web/fonts.css';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Flex } from '@mediahuis/chameleon-react-legacy';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

import {
  useResponsive,
  setCurrentBreakpoint,
  getCurrentBreakpoint,
} from '~/utils';
import { config } from '~/services';
import {
  DeliveryComplaintContextProvider,
  GlobalContextProvider,
  HolidayContextProvider,
  SubscriptionContextProvider,
} from '~/context';
import Contact from '~/pages/Contact';
import SmartWebform from '~/pages/SmartWebform';
import { BRANDS, ENTITIES, PAGES } from './constants';
import { GlobalStyle } from './styles';
import Footer from './components/Footer';
import { ContentLoader, Navigation } from './components';
import Authentication from './components/Authentication';
import Home from './pages/Home';
import AuthCallback from './pages/Auth';
import Other from './pages/Other';
import Holiday from './pages/Holiday';
import ReadDigital from './pages/ReadDigital';
import Subscription from './pages/Subscription';
import DeliveryComplaint from './pages/DeliveryComplaint';
import DeliverersWanted from './pages/DeliverersWanted';
import Faq from './pages/Faq';
import ScrollToTop from './components/ScrollToTop';
import Didomi from './components/Didomi/Didomi';
import Maintenance from './components/Maintenance';
import {
  FEATURE_FLAG_MAINTENANCE_MODE_BRAND,
  FEATURE_FLAG_MAINTENANCE_MODE_ENTITY,
  FEATURE_FLAG_CONTACT_FORM_MHLI,
  getFlagSmartWebform,
} from './constants/featureSwitch';

const ResponsiveWrapper = props => {
  const newBreakpoint = useResponsive();
  const currentBreakpoint = getCurrentBreakpoint();
  if (currentBreakpoint !== newBreakpoint) {
    setCurrentBreakpoint(newBreakpoint);
  }

  return props.children;
};

const App = () => {
  const isMaintenanceModeBrand = useFlag(FEATURE_FLAG_MAINTENANCE_MODE_BRAND);
  const isMaintenanceModeEntity = useFlag(FEATURE_FLAG_MAINTENANCE_MODE_ENTITY);
  const isMaintenanceMode = isMaintenanceModeEntity || isMaintenanceModeBrand;
  const showSmartWebformPage = useFlag(
    getFlagSmartWebform({
      isEntity: true,
      env: ENTITIES.MHBE,
    }),
  );
  const showContactPage =
    useFlag(FEATURE_FLAG_CONTACT_FORM_MHLI) && config.brand === BRANDS.DL;

  const { flagsReady } = useFlagsStatus();

  if (!flagsReady) {
    return <ContentLoader height="300px" />;
  }

  const showReadDigitalPage = ![BRANDS.CO, BRANDS.TC].includes(config.brand);
  const showdeliveryComplaintPage = ![ENTITIES.MHLU].includes(config.entity);

  return isMaintenanceMode ? (
    <Maintenance />
  ) : (
    <Router>
      <ScrollToTop />
      <GlobalStyle />
      <GlobalContextProvider>
        <ResponsiveWrapper>
          <Didomi />
          <Authentication>
            <Navigation />
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              height="full"
              bgColor="whiteBase"
            >
              <Switch>
                {config.brand === 'dl' && (
                  <Route
                    path={`/${PAGES.deliverersWanted}`}
                    component={DeliverersWanted}
                  />
                )}
                {config.brand === 'dl' && (
                  <Route path={`/${PAGES.other}`} component={Other} />
                )}
                {showReadDigitalPage && (
                  <Route
                    path={`/${PAGES.readDigital}`}
                    component={ReadDigital}
                  />
                )}
                {showdeliveryComplaintPage && (
                  <Route path={`/${PAGES.deliveryComplaint}`}>
                    <DeliveryComplaintContextProvider>
                      <DeliveryComplaint />
                    </DeliveryComplaintContextProvider>
                  </Route>
                )}
                <Route path={`/${PAGES.subscription}`}>
                  <SubscriptionContextProvider>
                    <Subscription />
                  </SubscriptionContextProvider>
                </Route>
                {config.brand !== BRANDS.LT && (
                  <Route path={`/${PAGES.holiday}`}>
                    <HolidayContextProvider>
                      <Holiday />
                    </HolidayContextProvider>
                  </Route>
                )}
                <Route path={`/${PAGES.faq}`} component={Faq} />
                <Route path="/auth/oauthcallback" component={AuthCallback} />
                {showContactPage && (
                  <Route path={`/${PAGES.contact}`} component={Contact} />
                )}
                {showSmartWebformPage && (
                  <Route
                    path={`/${PAGES.smartWebform}`}
                    component={SmartWebform}
                  />
                )}
                <Route path="/" component={Home} />
              </Switch>
              <Footer />
            </Flex>
          </Authentication>
        </ResponsiveWrapper>
      </GlobalContextProvider>
    </Router>
  );
};

export default App;
