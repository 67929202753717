import { getDeviceType, getDeviceBrand } from '~/utils';

const getDeviceMapping = () => {
  const deviceBrand = getDeviceBrand();
  const deviceType = getDeviceType();

  return `${deviceType} ${deviceBrand}`;
};

export default getDeviceMapping;
