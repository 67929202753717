import React, { useEffect, useState } from 'react';

import { validateDates } from '~/utils';
import isEmpty from 'lodash.isempty';
import { AH_HOLIDAY_SOLUTIONS, HOLIDAY_STEPS } from '~/pages/Holiday/data';

import BaseChoiceStep from '~/pages/Holiday/components/DialogContent/Steps/BaseChoiceStep';
import getWebformStartDate from '../utils/getWebformStartDate';
import getWebformEndDate from '../utils/getWebformEndDate';

const AhWebformChoiceStep = ({
  submissionData,
  setSubmissionData,
  setCurrentStep,
  isDialogShown,
}) => {
  const [errors, setErrors] = useState({});
  const [disabledEndDays, setDisabledEndDays] = useState();

  const getStartDateDisabledDays = () => {
    const startDate = getWebformStartDate();
    return [{ before: startDate }];
  };

  const getEndDateDisabledDays = day => {
    const startDate = getWebformStartDate(day);
    return [{ before: getWebformEndDate(startDate) }];
  };

  const onStartDateChange = (value, meta) => {
    const fields = [{ id: 'startDate', value, meta }];
    const endDate = getWebformEndDate(value);
    setDisabledEndDays([{ before: endDate }]);
    if (meta.formattedDateIsValid) {
      setSubmissionData(prevState => ({
        ...prevState,
        start: value,
        end: endDate,
      }));
    }
    setErrors(validateDates({ errors, fields }));
  };

  const onEndDateChange = (value, meta) => {
    setSubmissionData(prevState => ({
      ...prevState,
      end: value,
    }));

    const fields = [{ id: 'endDate', value, meta }];
    setErrors(validateDates({ errors, fields }));
  };

  useEffect(() => {
    setDisabledEndDays(getEndDateDisabledDays());
  }, []);

  const handleNextStep = () => {
    const fields = [
      { id: 'startDate', value: submissionData.start },
      { id: 'endDate', value: submissionData.end },
    ];

    const datesErrors = validateDates({ errors, fields });
    setErrors(datesErrors);

    if (!isEmpty(datesErrors)) {
      return;
    }

    switch (submissionData.vacationSolution) {
      case AH_HOLIDAY_SOLUTIONS.CONTINUE_DIGITAL:
      case AH_HOLIDAY_SOLUTIONS.DISCONTINUE_DIGITAL:
      case AH_HOLIDAY_SOLUTIONS.CHARITY:
        setCurrentStep(HOLIDAY_STEPS.SUMMARY_STEP);
        break;
      case AH_HOLIDAY_SOLUTIONS.FAMILY_ADDRESS:
      case AH_HOLIDAY_SOLUTIONS.HOLIDAY_ADDRESS: {
        setCurrentStep(HOLIDAY_STEPS.ADDRESS_STEP);
        break;
      }
      default:
        break;
    }
  };
  return (
    <BaseChoiceStep
      onEndDateChange={onEndDateChange}
      onStartDateChange={onStartDateChange}
      getEndDateDisabledDays={disabledEndDays}
      getStartDateDisabledDays={getStartDateDisabledDays()}
      isDialogShown={isDialogShown}
      setSubmissionData={setSubmissionData}
      submissionData={submissionData}
      setCurrentStep={setCurrentStep}
      errors={errors}
      setErrors={setErrors}
      isStartDateDisabled={false}
      isAhWebForm
      onNextStep={handleNextStep}
    />
  );
};

export default AhWebformChoiceStep;
