import { config } from '~/services';
import ahApiCall from '~/api/ah/ahApiCall';

const getAddressDeliveryOptionsForSubscription = async ({
  subscriptionId,
  address,
}) => {
  const queryParams = new URLSearchParams(address).toString();
  const res = await ahApiCall(
    `${config.ahServiceApiUrl}/subscriptions/${subscriptionId}/options/delivery/address?${queryParams}`,
  );

  return res;
};

export default getAddressDeliveryOptionsForSubscription;
