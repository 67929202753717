import React from 'react';
import { Header } from '~/components';
import { METADATA, PAGES } from '../../constants';

const Faq = () => (
  <React.Fragment>
    {METADATA[PAGES.faq]}
    <Header heading="Klantenservice" />;
  </React.Fragment>
);

export default Faq;
