import { Select } from '@mediahuis/chameleon-react-legacy';
import React from 'react';
import { sortBySequence } from '~/pages/SmartWebform/utils';
import { clearValidationOnFocus } from '~/utils';

export default function SelectWrapper({
  id,
  label,
  placeholder,
  value,
  handleChange,
  items = [],
  error,
  message,
  errors,
  setErrors,
}) {
  const sortedItems = sortBySequence(items);
  return (
    <Select
      name={id}
      id={id}
      label={label}
      value={value}
      onChange={handleChange}
      mb={4}
      required
      placeholder={placeholder}
      error={error}
      message={message}
      onFocus={e => clearValidationOnFocus({ e, errors, setErrors })}
    >
      {sortedItems.map(item => (
        <option key={item.id} value={item.id}>
          {item.label}
        </option>
      ))}
    </Select>
  );
}
