import React, { useState } from 'react';
import styled from 'styled-components';
import {
  colorBlackBase,
  colorSecondaryBase,
} from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';
import { Paragraph, Flex, Box } from '@mediahuis/chameleon-react-legacy';
import { styles } from './styles/';
import ChatInfo from './ChatInfo';
import PhoneInfo from './PhoneInfo';
import EmailInfo from './EmailInfo';

const FakeLink = styled(Paragraph)`
  color: ${colorBlackBase};
  transition: 0.2s;
`;

const OnHoverFlex = styled(Flex)`
  &:hover ${FakeLink} {
    text-decoration: underline;
    color: ${colorSecondaryBase};
    cursor: pointer;
  }
  width: auto;
`;

const Container = styled.div`
  border: 1px solid #eee;
  background-color: #fff;
  z-index: 999;
  border-top: 0px;

  &:nth-child(1) {
    border-top: 1px solid #eee;
  }

  &:hover {
    cursor: pointer;
  }

  .boldCustomerServiceLink {
    font-weight: bold;
  }

  &:hover .boldCustomerServiceLink {
    text-decoration: underline;
  }
`;

const StatusIcon = styled.div`
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props =>
    props.isOnline ? styles.statusColors.active : styles.statusColors.inactive};
  margin-right: 10px;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const HoverableBoxItems = styled.div`
  position: absolute;
  z-index: 50;
  width: 300px;
  right: ${props => (props.dropdownAlignment === 'right' ? 0 : '')};
`;

const Header = ({
  title,
  isOnline,
  chat,
  email,
  phone,
  openChat,
  dropdownAlignment,
  openPhone,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <OnHoverFlex
      alignItems="flex-end"
      flexDirection="column"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <ContentWrapper>
        <Box>
          <Flex alignItems="center">
            <StatusIcon isOnline={isOnline} />
            <FakeLink>{title}</FakeLink>
          </Flex>
        </Box>
        {isOpen && (
          <HoverableBoxItems dropdownAlignment={dropdownAlignment}>
            {chat.mayShow && (
              <Container onClick={openChat}>
                <Box p={4}>
                  <ChatInfo componentData={chat} />
                </Box>
              </Container>
            )}
            {phone.mayShow && (
              <Container onClick={openPhone}>
                <Box p={4}>
                  <PhoneInfo componentData={phone} />
                </Box>
              </Container>
            )}
            {email.mayShow && (
              <Container onClick={() => window.open(email.link)}>
                <Box p={4}>
                  <EmailInfo componentData={email} />
                </Box>
              </Container>
            )}
          </HoverableBoxItems>
        )}
      </ContentWrapper>
    </OnHoverFlex>
  );
};

export default Header;
