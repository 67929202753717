/* eslint-disable babel/camelcase */
import {
  Flex,
  Heading,
  List,
  ListItem,
  Text,
} from '@mediahuis/chameleon-react-legacy';
import React, { useEffect } from 'react';
import { useGlobalContext } from '~/context';
import StepFourAndFiveIcon from '~/pages/Subscription/components/DialogContent/OnlineCancellation/StepFourAndFiveIcon';
import { getCancellationReasonCategory } from '~/pages/Subscription/utils/onlineCancellation';
import { isMobile } from '~/utils';
import { pushTrackingEvent } from '~/utils/tealium';

export default function FifthStep({
  selectedCancellationReason,
  selectedAlternativeOffer,
}) {
  const { userInfo, chosenUserSubscription } = useGlobalContext();

  useEffect(() => {
    pushTrackingEvent(chosenUserSubscription, {
      cancellation_step: 4,
      cancellation_reason: selectedCancellationReason?.label,
      cancellation_reason_id: selectedCancellationReason?.code,
      cancellation_reason_category: getCancellationReasonCategory(),
      tealium_event: 'view_offer_final_confirmation',
      offer: [selectedAlternativeOffer.trackingData],
    });
  }, []);
  return (
    <>
      <Flex justifyContent="center" mb={7}>
        <StepFourAndFiveIcon
          width={isMobile() ? 143 : 180}
          height={isMobile() ? 130 : 163}
        />
      </Flex>
      <Heading level={3} mb={6}>
        Gelukt {userInfo?.firstName}!
      </Heading>
      <Heading level={4} mb={4} fontFamily="primary">
        We gaan voor jou aan de slag:
      </Heading>
      <List as="ol" mb={6}>
        <ListItem>
          <Text size="Heading5" fontWeight="medium" fontFamily="primary">
            Je ontvangt een bevestiging van je wijziging op{' '}
            {userInfo?.emailAddress}.
          </Text>
        </ListItem>
        <ListItem>
          <Text size="Heading5" fontWeight="medium" fontFamily="primary">
            Je domiciliëring wordt automatisch aangepast aan je nieuw tarief de
            volgende keer dat de betaling wordt aangeboden aan je bank.
          </Text>
        </ListItem>
      </List>
    </>
  );
}
