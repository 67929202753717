// polyfills
import 'react-app-polyfill/ie11';
import 'url-search-params-polyfill';

import React from 'react';
import { createRoot } from 'react-dom/client';

import { FlagProvider } from '@unleash/proxy-client-react';
import { featureSwitchConfig } from './constants/featureSwitch';

import './index.css';
import App from './App';
// eslint-disable-next-line import/no-namespace
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <FlagProvider config={featureSwitchConfig}>
      <App />
    </FlagProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
