import dayjs from 'dayjs';
import { DATE_FORMAT_BE } from '~/constants/date';
import { config } from '~/services';
import ahApiCall from '~/api/ah/ahApiCall';

const mapTochangeSubscriptionDelivery = selectedDeliveryOptionBySubscription => {
  return Object.entries(selectedDeliveryOptionBySubscription).reduce(
    (acc, [subscriptionId, values]) => {
      return {
        ...acc,
        [subscriptionId]: {
          typeCode: values.typeCode,
        },
      };
    },
    {},
  );
};
const postAddress = ({
  startDate,
  customerId,
  addressFields,
  selectedDeliveryOptionBySubscription,
}) => {
  const {
    city,
    postalCode,
    countryCode,
    street,
    houseNumber,
    box,
    extra,
  } = addressFields;
  const body = {
    startDate: dayjs(startDate).format(DATE_FORMAT_BE),
    address: {
      city,
      countryCode,
      houseNumber,
      postalCode,
      street,
      // TODO : remove/refactor this checks after the functional analyse
      ...(countryCode === 'BE' && { busNumber: box }),
      ...((countryCode === 'NL' || countryCode === 'LU') && {
        houseNumberExtension: box,
      }),
      additionalInformation: extra,
    },
    ...(selectedDeliveryOptionBySubscription && {
      changeSubscriptionDelivery: mapTochangeSubscriptionDelivery(
        selectedDeliveryOptionBySubscription,
      ),
    }),
  };

  return ahApiCall(
    `${config.ahServiceApiUrl}/customers/${customerId}/address-changes`,
    {
      method: 'POST',
      data: JSON.stringify(body),
    },
  );
};

export default postAddress;
