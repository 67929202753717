/* eslint-disable babel/camelcase */

import dayjs from 'dayjs';
import { ENVIRONMENTS } from '~/constants';
import { environment } from '~/services/environment';

const SCRIPT_URLS = {
  dev: 'https://tags.tiqcdn.com/utag/corelio/e-loket/dev/utag.js',
  test: 'https://tags.tiqcdn.com/utag/corelio/e-loket/dev/utag.js',
  preview: 'https://tags.tiqcdn.com/utag/corelio/e-loket/qa/utag.js',
  production: 'https://tags.tiqcdn.com/utag/corelio/e-loket/prod/utag.js',
};

export const loadTealium = () => {
  // TODO
  const scriptUrl = SCRIPT_URLS[environment.name];

  if (scriptUrl) {
    window.utag_data = {
      consentsocialmedia: '',
      consentcookies: '',
      consentselectbasicads: '',
      consentcreateadsprofile: '',
      consentselectpersonalizedads: '',
      consentmeasureadperformance: '',
      consentmeasurecontentperformance: '',
      consentmarketresearch: '',
      consentimproveproducts: '',
      consentcreatecontentprofile: '',
      consentselectpersonalizedcontent: '',
      event_label: '',
      event_action: '',
      event_category: '',
      event_value: '',
      event_non_interactive: '',
    };

    const script = document.createElement('script');
    script.src = scriptUrl;
    script.type = 'text/javascript';
    script.async = true;

    const element = document.getElementsByTagName('script')[0];
    element.parentNode.insertBefore(script, element);
  }
};

export const pushTrackingEvent = (subscription = {}, options = {}) => {
  const { id, name, paymentMethod, subscriptionType, startDate } = subscription;
  const now = dayjs(new Date());

  if (environment.name === ENVIRONMENTS.LOCAL) {
    console.log({
      current_subscription_id: id,
      current_subscription_name: name,
      current_subscription_category: subscriptionType,
      current_subscription_payment_method: paymentMethod,
      current_subscription_age: now.diff(startDate, 'month'),
      ...options,
    });
  }

  if (window?.gdprConsents?.uitgebreidAMN2chet) {
    window?.utag?.link({
      current_subscription_id: id,
      current_subscription_name: name,
      current_subscription_category: subscriptionType,
      current_subscription_payment_method: paymentMethod,
      current_subscription_age: now.diff(startDate, 'month'),
      ...options,
    });
  }
};
