import { ENTITIES } from '~/constants';
import { config } from '~/services';

export default function EntitySwitch({ defaultComponent, mhbe, mhlu, mhli }) {
  if (config.entity === ENTITIES.MHBE && mhbe) {
    return mhbe;
  }
  if (config.entity === ENTITIES.MHLI && mhli) {
    return mhli;
  }
  if (config.entity === ENTITIES.MHLU && mhlu) {
    return mhlu;
  }
  return defaultComponent;
}
