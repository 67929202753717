import isEmpty from 'lodash.isempty';
import { useEffect } from 'react';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import { authService } from '~/services';

const Authentication = ({ children }) => {
  const globalState = useGlobalContext();
  const { setGlobalState, userInfo } = globalState;

  useEffect(() => {
    const storedToken = authService.getStoredToken();
    if (isEmpty(userInfo) && storedToken) {
      authService
        .login()
        .then(token => {
          const newUserInfo = token
            ? {
                accountGuid: token.idToken.sub,
                name: token.idToken.family_name,
                firstName: token.idToken.given_name,
              }
            : {};
          setGlobalState({
            userInfo: newUserInfo,
            isFetchingUser: false,
          });
          return newUserInfo;
        })
        .catch(e => {
          setGlobalState({ isFetchingUser: false });
          throw e;
        });
    } else {
      setGlobalState({
        userInfo: {},
        isFetchingUser: false,
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return children;
};

export default Authentication;
