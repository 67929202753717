import React from 'react';
import {
  Heading,
  Flex,
  Button,
  Paragraph,
  DatePicker,
  useMediaQuery,
  Box,
} from '@mediahuis/chameleon-react-legacy';
import {
  ChevronBack,
  ChevronForward,
} from '@mediahuis/chameleon-theme-wl/legacy/icons';
import { config, ts } from '~/services';
import EntitySwitch from '~/components/ah/EntitySwitch';
import AhDisabledSubscriptionInfo from '~/components/ah/AhDisabledSubscriptionInfo';
import {
  SUBSCRIPTION_COMPONENT_TYPES,
  subscriptionHasComponent,
} from '~/utils';

import { useGlobalContext } from '~/context';
import {
  HOLIDAY_SOLUTIONS,
  HOLIDAY_STEPS,
  countries,
  titles,
  AH_HOLIDAY_SOLUTIONS_MAP,
  AH_HOLIDAY_SOLUTIONS,
} from '../../../data';

import getLanguage from '../../../language';
import { DisabledSubscriptionInfo } from '../../../../../components';
import SameShipToSolutionRadioBtns from '../../ah/Webform/components/SameSolutionRadioButtons';

const BaseSummaryStep = ({
  submissionData,
  setCurrentStep,
  submitData,
  isLoading,
  selectedDeliveryOptionBySubscription,
  isWebform,
  setSameShipToSolutionRadioBtnValue,
  sameShipToSolutionRadioBtnValue,
  sameShipToSolutionRadioBtnErrors,
  setSameShipToSolutionRadioBtnErrors,
}) => {
  const globalContext = useGlobalContext();
  const { chosenUserSubscription } = globalContext;

  const hasDigitalComponent = subscriptionHasComponent(
    chosenUserSubscription,
    SUBSCRIPTION_COMPONENT_TYPES.DIGITAL,
  );

  const language = getLanguage(
    chosenUserSubscription?.internalSubscriptionType,
  );

  const isSolutionWithAddress = [
    HOLIDAY_SOLUTIONS.FAMILY_ADDRESS,
    HOLIDAY_SOLUTIONS.HOLIDAY_ADDRESS,
    AH_HOLIDAY_SOLUTIONS.FAMILY_ADDRESS,
    AH_HOLIDAY_SOLUTIONS.HOLIDAY_ADDRESS,
  ].includes(submissionData.vacationSolution);

  const isFamilyAddress =
    submissionData.vacationSolution === HOLIDAY_SOLUTIONS.FAMILY_ADDRESS ||
    submissionData.vacationSolution === AH_HOLIDAY_SOLUTIONS.FAMILY_ADDRESS;

  const getBackPath = () => {
    if (isSolutionWithAddress) {
      setCurrentStep(HOLIDAY_STEPS.ADDRESS_STEP);
    } else {
      setCurrentStep(HOLIDAY_STEPS.CHOICE_STEP);
    }
  };
  const formattedVacationSolution =
    submissionData?.vacationSolution?.charAt(0)?.toLowerCase() +
    submissionData?.vacationSolution?.slice(1);

  const getChosenResolutionForDigitalOnly = () => {
    if (hasDigitalComponent) return false;
    if (formattedVacationSolution === AH_HOLIDAY_SOLUTIONS.CONTINUE_DIGITAL)
      return language.continueDigitalPrintOnly;

    if (formattedVacationSolution === AH_HOLIDAY_SOLUTIONS.DISCONTINUE_DIGITAL)
      return language.discontinueDigitalPrintOnly;
    return false;
  };

  const chosenResolution =
    getChosenResolutionForDigitalOnly()?.optionTitle ||
    language[formattedVacationSolution]?.optionTitle ||
    language[AH_HOLIDAY_SOLUTIONS_MAP[submissionData?.vacationSolution]]
      ?.optionTitle ||
    '';

  const color = config.isAportal ? 'colorSecondaryBase' : 'colorPrimaryBase';

  const deliveryOption = Object.values(
    selectedDeliveryOptionBySubscription || {},
  )?.[0];
  return (
    <>
      <Heading level={5} mb={3} mt={5} fontFamily="primary" color={color}>
        {ts.t('Holiday.SummaryStep.YourInfo')}
      </Heading>
      <EntitySwitch
        defaultComponent={<DisabledSubscriptionInfo />}
        mhlu={<AhDisabledSubscriptionInfo />}
      />
      <Heading level={5} mb={3} mt={6} fontFamily="primary" color={color}>
        {ts.t('Holiday.SummaryStep.ChosenPeriod')}
      </Heading>
      <Flex
        justifyContent="space-between"
        flexDirection={useMediaQuery(
          {
            md: 'row',
          },
          'column',
        )}
        pb={6}
        width="full"
      >
        <Box
          width={useMediaQuery(
            {
              md: '48%',
            },
            '100%',
          )}
          mb={useMediaQuery(
            {
              md: 0,
            },
            5,
          )}
        >
          <DatePicker
            pickerInputOnly
            id="start-vacation-period"
            data-testid="start-vacation-period"
            label={ts.t('Holiday.SummaryStep.StartDate')}
            value={submissionData.start}
            disabled={true}
          />
        </Box>
        <Box
          width={useMediaQuery(
            {
              md: '48%',
            },
            '100%',
          )}
        >
          <DatePicker
            pickerInputOnly
            id="end-vacation-period"
            data-testid="end-vacation-period"
            label={ts.t('Holiday.SummaryStep.EndDate')}
            disabled={true}
            value={submissionData.end}
          />
        </Box>
      </Flex>
      <Heading level={5} mb={3} fontFamily="primary" color={color}>
        {ts.t('Holiday.SummaryStep.ChosenResolution')}
      </Heading>
      <Paragraph mb={3}>{chosenResolution}.</Paragraph>
      {isSolutionWithAddress && (
        <>
          <Heading level={5} mb={3} mt={5} fontFamily="primary" color={color}>
            {ts.t('Holiday.SummaryStep.ChosenAddress')}
          </Heading>
          <Paragraph mb={3}>
            {isFamilyAddress && (
              <>
                {titles[submissionData.solutionData?.title]}{' '}
                {submissionData.solutionData?.firstName}{' '}
                {submissionData.solutionData?.lastName}
                <br />
              </>
            )}
            {submissionData.solutionData?.street}{' '}
            {submissionData.solutionData?.houseNumber}
            {submissionData.solutionData?.box &&
              `/${submissionData.solutionData.box}`}{' '}
            <br />
            {submissionData.solutionData?.postalCode}{' '}
            {submissionData.solutionData?.city?.charAt(0) +
              submissionData.solutionData?.city?.slice(1)?.toLowerCase()}
            ,{' '}
            {countries[submissionData.solutionData?.countryCode] ||
              submissionData.solutionData?.country}{' '}
            <br />
            {submissionData.solutionData?.extra}
            <br />
          </Paragraph>
        </>
      )}
      {selectedDeliveryOptionBySubscription && (
        <>
          <Heading level={5} mb={3} fontFamily="primary" color={color}>
            {ts.t('Holiday.SummaryStep.ChosenDeliveryMethod')}
          </Heading>
          <Paragraph mb={3}>
            {deliveryOption?.typeLabel || deliveryOption?.typeCode}
          </Paragraph>
        </>
      )}
      {isWebform && (
        <SameShipToSolutionRadioBtns
          sameShipToSolutionRadioBtnValue={sameShipToSolutionRadioBtnValue}
          setSameShipToSolutionRadioBtnValue={
            setSameShipToSolutionRadioBtnValue
          }
          errors={sameShipToSolutionRadioBtnErrors}
          setErrors={setSameShipToSolutionRadioBtnErrors}
        />
      )}
      <Flex mt={10} justifyContent="flex-end">
        <Button
          mr={3}
          onClick={getBackPath}
          iconLeft={ChevronBack}
          disabled={isLoading}
        >
          {ts.t('Common.Back')}
        </Button>
        <Button
          onClick={submitData}
          loading={isLoading}
          iconRight={ChevronForward}
        >
          {ts.t('Common.Confirm')}
        </Button>
      </Flex>
    </>
  );
};

export default BaseSummaryStep;
