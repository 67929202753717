import React, { useEffect, useState } from 'react';
import { Dialog, HelloCustomer, USECASES } from '~/components';
import { useBanner, useGlobalContext, useHolidayContext } from '~/context';
import { mapToDaysOfWeek, scrollTopWithTimeout } from '~/utils';
import { config } from '~/services';
import { BANNER_TYPES, BRANDS } from '~/constants';
import { HOLIDAY_STEPS } from '../data';
import DialogContent from './DialogContent';
import PlannedHolidays from './PlannedHolidays';
import PastHolidays from './PastHolidays';
import getHolidayOptions from '../utils/getHolidayOptions';
import getLanguage from '../language';

const HolidayContent = () => {
  const holidayContext = useHolidayContext();
  const globalContext = useGlobalContext();
  const { showBanner } = useBanner();

  const { setHolidayState, holidayArrangementOptions } = holidayContext;
  const { chosenUserSubscription, userInfo } = globalContext;
  const language = getLanguage(
    chosenUserSubscription?.internalSubscriptionType,
  );
  const [dialogType, setDialogType] = useState('');
  const [isDialogShown, setIsDialogShown] = useState(false);
  const [chosenHoliday, setChosenHoliday] = useState(null);
  const [currentStep, setCurrentStep] = useState(HOLIDAY_STEPS.CHOICE_STEP);
  const [helloCustomerShown, setHelloCustomerShown] = useState(false);

  const fetchHolidayOptions = async () => {
    try {
      const holidayOptions = await getHolidayOptions({
        subscriptionId: chosenUserSubscription.id,
      });
      setHolidayState({
        daysOfWeek: mapToDaysOfWeek(holidayOptions.allowedDays),
        holidayArrangementOptions: holidayOptions,
      });
      return holidayOptions;
    } catch {
      return showBanner({
        message: language.messages.noVacationError,
        type: BANNER_TYPES.ERROR,
      });
    }
  };

  const fetchData = async () => {
    await fetchHolidayOptions();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenUserSubscription]);

  const handleDialog = ({ type }) => {
    setDialogType(type);
    setIsDialogShown(true);
  };

  return (
    <React.Fragment>
      {holidayArrangementOptions && (
        <>
          <PlannedHolidays
            onAction={handleDialog}
            setChosenHoliday={setChosenHoliday}
          />
          <PastHolidays
            onAction={handleDialog}
            setChosenHoliday={setChosenHoliday}
          />
        </>
      )}
      <Dialog
        show={isDialogShown}
        onClose={() => {
          setIsDialogShown(false);
          setCurrentStep(HOLIDAY_STEPS.CHOICE_STEP);
          scrollTopWithTimeout();
          setHelloCustomerShown(true);
        }}
        closeOnBackdropClick={false}
      >
        <DialogContent
          dialogType={dialogType}
          isDialogShown={isDialogShown}
          setIsDialogShown={setIsDialogShown}
          chosenHoliday={chosenHoliday}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      </Dialog>
      {config.brand !== BRANDS.DL && helloCustomerShown && (
        <HelloCustomer
          show={helloCustomerShown}
          chosenUserSubscription={chosenUserSubscription}
          userInfo={userInfo}
          useCase={USECASES.HOLIDAY}
        />
      )}
    </React.Fragment>
  );
};

export default HolidayContent;
