/* eslint-disable */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Heading } from '@mediahuis/chameleon-react-legacy';
import {
  fontFamilyBrandPrimary,
  colorWhiteBase,
  fontFamilySystem,
} from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';
import CloseIcon from './img/close.svg';
import ExpandIcon from './img/expand.svg';
import MinimizeIcon from './img/minimize.svg';
import UserAvatar from './img/user-avatar.svg';
import BrandAvatar from './img/avatar.svg';
import { styles } from './styles/';
import { ts } from '~/services';
import { useHistory } from 'react-router-dom';

// TODO: Refactor to styled components
const ChatbotExternalWrapper = styled.div`
  font-size: 16px;
  font-family: ${fontFamilySystem};
  width: 100%;
  max-width: ${props => (props.isExpanded ? '50vw' : '350px')};
  box-shadow: 0px 15px 30px -10px #bbb;
  border-radius: 5px;
  margin: 0 auto;
  bottom: 0;
  right: 0;
  margin: 0 20px;
  position: fixed;
  z-index: 10;
  a {
    color: white;
    text-decoration: underline;
  }

  @media all and (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    margin: 0;
  }

  .enter-input {
    text-align: right;
    margin-top: 16px;
  }

  a.chatbot__send2 {
    border-radius: 4px;
    padding: 4px 6px;
    background-color: ${styles.chatbotBgColor};
    color: ${colorWhiteBase};
    margin-left: 4px;
    text-decoration: none;
    font-family: ${fontFamilyBrandPrimary};
    font-weight: bold;
  }

  .enter-input label {
    color: #aaa;
  }

  .chatbot__input.input {
    margin-bottom: 0px;
  }

  .chatbot__header h2 {
    font-family: ${fontFamilyBrandPrimary};
    color: ${colorWhiteBase};
    text-align: center;
    font-size: 20px;
  }

  .chatbot__content {
    padding: 0 20px;
    font-size: 15px;
    color: #635f5f;
    padding-bottom: 20px;
    box-shadow: inset 0px -20px 30px -30px grey;
    background-color: white;
  }

  .chatbot__input {
    margin-top: 16px;
    display: block;
    box-sizing: border-box;
    width: 100%;
    -webkit-appearance: none;
    background-color: #fff;
    display: block;
    margin-bottom: 0px;
    border: 1px solid #a8a1a2;
    color: #635f5f;
    border-radius: 5px;
    padding: 10px;
    height: auto;
    overflow-y: auto;
    min-height: 60px;
    font-size: 15px;
    max-height: 180px;
    outline: 0;
    resize: none;
    font-family: ${fontFamilySystem};
  }

  .chatbot__wrapper {
    position: relative;
  }

  .chatbot__icon {
    width: 12px;
    fill: #fff;
    margin-left: 2px;
  }

  .chatbot__box {
    min-height: ${props => (props.isExpanded ? '40vh' : '80px')};
    max-height: ${props => (props.isExpanded ? '50vh' : '30vh')};
    display: inline-block;
    width: 100%;
    overflow-y: auto;
    margin: 10px 0px 5px;
    box-sizing: border-box;
  }

  .chatbot__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: ${styles.chatbotBgColor};
    padding: 0;
    box-sizing: border-box;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 4px 8px;
    font-weight: bold;
  }

  .chatbot__header h2 {
    margin: 0;
  }

  .chatbot__controls {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }

  .chatbot__controls--minimize {
    background-image: url('${MinimizeIcon}');
    outline: none;
  }

  .chatbot__controls--expand {
    background-image: url('${ExpandIcon}');
    outline: none;
  }

  .chatbot__controls--close {
    background-image: url('${CloseIcon}');
  }

  .chatbot.expand {
    width: 995px;
    margin: 0 auto;
    position: relative;
    z-index: 0;
  }

  .expand .chatbot__content {
    height: 100%;
    height: 100vh;
    box-shadow: none;
    margin-bottom: 30px;
  }

  form {
    margin: 0 0 1.42857rem;
  }

  & .cAgentLine,
  & .cUserLine {
    background-color: ${styles.chatbotBgColor};
    font-size: 15px;
    box-sizing: border-box;
    color: ${colorWhiteBase};
    border-radius: 5px;
    margin-bottom: 5px;
    float: right;
    position: relative;
    margin-left: 30px;
    clear: both;
    float: right;
    position: relative;
    line-height: 15px;
    padding: 7px 7px 5px;
    box-sizing: border-box;
  }

  .cUserLine {
    float: left;
    background-color: #a8a1a2;
  }

  & .cAgentLine::before,
  & .cUserLine::before {
    position: absolute;
    left: -25px;
    top: calc(50% - 10px);
    background-image: url('${BrandAvatar}');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    content: ' ';
    height: 20px;
    background-color: ${styles.chatbotBgColor};
    border-radius: 50%;
    background-size: 50%;
    width: 20px;
  }

  & .cUserLine::before {
    background-image: url('${UserAvatar}');
    background-color: #000;
  }
`;

const Chatbot = ({
  setIsChatbotActive,
  setIsChatWindowOpen,
  isScriptLoading,
}) => {
  const [inputState, setInputState] = useState('');
  const searchParams = new URLSearchParams(window.location.search);
  const enterToggle = searchParams.get('enter-toggle');

  const [isExpanded, setIsExpanded] = useState(false);
  const [isTogglerChecked, setIsTogglerChecked] = useState(
    enterToggle ? enterToggle : false,
  );
  const { replace, location } = useHistory();

  useEffect(() => {
    if (!isScriptLoading) {
      // Fire Initialize function from chatbot script.
      Application.Initialize();

      // Fire Unitialize function from chatbot script on beforeunload.
      window.addEventListener('beforeunload', Application.Unitialize);

      return () =>
        // Remove event listener from DOM after "beforeunload"
        window.removeEventListener('beforeunload', Application.Unitialize);
    }
  }, [isScriptLoading]);

  return (
    !isScriptLoading && (
      <ChatbotExternalWrapper
        className="chatbot expand"
        isExpanded={isExpanded}
      >
        <div className="chatbot__header">
          {isExpanded ? (
            <button
              className="chatbot__controls chatbot__controls--minimize"
              onClick={() => setIsExpanded(false)}
            ></button>
          ) : (
            <button
              className="chatbot__controls chatbot__controls--expand"
              title={ts.t('ChatBot.Enlarge')}
              onClick={() => setIsExpanded(true)}
            ></button>
          )}
          <Heading level={4} color="colorWhiteBase" fontFamily="primary">
            {ts.t('ChatBot.ChatWithUs')}
          </Heading>
          <button
            className="chatbot__controls chatbot__controls--close"
            title={ts.t('ChatBot.Close')}
            onClick={() => {
              setIsChatbotActive(false);
              setIsChatWindowOpen(false);
              replace({ ...location, hash: null });
            }}
          ></button>
        </div>
        <div className="chatbot__content">
          <div id="iChatConversation">
            <div id="iChatLinesAndTyping" className="chatbot__box">
              <div
                id="iChatLines"
                title={ts.t('ChatBot.ConversationTitle')}
              ></div>
              <div id="iChatTyping" style={{ display: 'none' }}></div>
            </div>
            <div id="iChatInput">
              <form acceptCharset="utf-8" autoComplete="off">
                <div className="chatbot__wrapper">
                  {isTogglerChecked ? (
                    <input
                      id="iChatInputField"
                      className="cUntouched chatbot__input input"
                      type="text"
                      autoComplete="off"
                      value={inputState}
                      onChange={e => setInputState(e.target.value)}
                      onFocus={e => {
                        if (e.currentTarget.className.includes('cUntouched')) {
                          e.currentTarget.className = e.currentTarget.className
                            .replace('cUntouched', '')
                            .substring(1);
                        }
                      }}
                      onKeyPress={e => {
                        if (e.charCode === 13) {
                          e.preventDefault();
                          setInputState('');
                        }
                        if (gWebClient) {
                          return gWebClient.ProcessKey(e, e.currentTarget);
                        }
                        return e;
                      }}
                      placeholder={ts.t('ChatBot.Input.Placeholder')}
                      title={ts.t('ChatBot.Input.Title')}
                      alt={ts.t('ChatBot.Input.Alt')}
                      maxLength="255"
                    />
                  ) : (
                    <textarea
                      id="iChatInputLargeField"
                      placeholder={ts.t('ChatBot.Textarea.Placeholder')}
                      title={ts.t('ChatBot.Textarea.Title')}
                      className="chatbot__input textarea"
                      value={inputState}
                      onChange={e => setInputState(e.target.value)}
                      onKeyPress={e => {
                        if (gWebClient) {
                          return gWebClient.ProcessKey(
                            e,
                            e.currentTarget,
                            true,
                          );
                        }
                        return e;
                      }}
                    ></textarea>
                  )}
                </div>
                <div className="cClear"></div>
                <div className="enter-input">
                  <label htmlFor="enter-toggle">
                    {ts.t('ChatBot.EnterToSend')}
                  </label>
                  <input
                    type="checkbox"
                    name="enter-toggle"
                    id="enter-toggle"
                    className="enter-toggle"
                    checked={isTogglerChecked}
                    onChange={() => setIsTogglerChecked(!isTogglerChecked)}
                  />
                  <a
                    href=""
                    onClick={e => {
                      e.preventDefault();
                      setInputState('');
                      if (gWebClient) {
                        gWebClient.SubmitUserMessage(null);
                      }
                      return false;
                    }}
                    className="chatbot__send2"
                    title={ts.t('ChatBot.Send')}
                  >
                    {ts.t('ChatBot.Send')}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ChatbotExternalWrapper>
    )
  );
};

export default Chatbot;
