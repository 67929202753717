import React, { useState } from 'react';

const SubscriptionContext = React.createContext();

const SubscriptionContextProvider = ({ children }) => {
  const [subscriptionState, setState] = useState({
    outstandingPayments: [],
  });

  const setSubscriptionState = data =>
    setState(state => ({ ...state, ...data }));

  return (
    <SubscriptionContext.Provider
      value={{ ...subscriptionState, setSubscriptionState }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export { SubscriptionContext, SubscriptionContextProvider };
