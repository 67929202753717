import { config } from '~/services';

export const ssoConfig = {
  ssoEnabled: true,
  local: {
    clientId: 'gwp5s9a0wo57k5av38d6xbhk2a723qpj',
    baseApiUrl: 'https://sp-sso-test.wort.lu',
    logoutUri: `https://sp-sso-test.wort.lu/logoutAll?redirectUri=${config.eloketUrl}`,
  },
  dev: {
    clientId: 'ty2b2drs5jhxt4zt2mqm52ckvgfwf129',
    baseApiUrl: 'https://sp-sso-dev.wort.lu',
    logoutUri: `https://sp-sso-dev.wort.lu/logoutAll?redirectUri=${config.eloketUrl}`,
  },
  test: {
    clientId: 'x478fcrd5dfefd5h6hqi8ilu5ido1xu2',
    baseApiUrl: 'https://sp-sso-test.wort.lu',
    logoutUri: `https://sp-sso-test.wort.lu/logoutAll?redirectUri=${config.eloketUrl}`,
  },
  preview: {
    clientId: 'o2w6utylfn3d218nxga3k31zgq4ejfsp',
    baseApiUrl: 'https://sp-sso-test.wort.lu',
    logoutUri: `https://sp-sso-test.wort.lu/logoutAll?redirectUri=${config.eloketUrl}`,
  },
  production: {
    clientId: 't552j2hg477p36cd136lsy6isw6r960a',
    baseApiUrl: 'https://user.wort.lu',
    logoutUri: `https://user.wort.lu/logoutAll?redirectUri=${config.eloketUrl}`,
  },
  scope: 'openid',
};
