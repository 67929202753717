import styled from 'styled-components';
import {
  colorPrimaryBase,
  space6,
} from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';

const Link = styled.a`
  text-transform: uppercase;
  cursor: pointer;
  margin-right: ${space6};
  &:hover {
    color: ${colorPrimaryBase};
  }
  &:last-child {
    margin-right: 0;
  }
`;

export default Link;
