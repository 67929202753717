import { ts } from '~/services';

export const QUESTION_CATEGORY = 1;
export const CATEGORIES = [
  { value: QUESTION_CATEGORY, label: 'Vraag' },
  { value: 2, label: 'Opmerking' },
  { value: 3, label: 'Compliment' },
  { value: 4, label: 'Klacht' },
];

export const DE_LIMBURGER_DIGITAL_SUB_CATEGORY = 3;
export const DELIVERY_SUB_CATEGORY = 1;

export const SUB_CATEGORIES = [
  {
    value: DELIVERY_SUB_CATEGORY,
    label: 'Bezorging',
    categoryId: '68200956-1c93-ed11-aad1-0022489fd8e0',
    tag: 'bezorging',
  },
  { value: 2, label: 'De Limburger webshop', tag: 'webshop' },
  {
    value: DE_LIMBURGER_DIGITAL_SUB_CATEGORY,
    label: 'De Limburger digitaal',
    tag: 'de-limburger-digitaal',
    categoryId: '69a4f9b5-d377-ed11-81ab-0022489fd914',
  },
  { value: 4, label: 'Facturering' },
  { value: 5, label: 'Lezersvoordeel' },
  {
    value: 6,
    label: 'Abonneren',
    tag: 'abonneren',
    categoryId: '5b8c8abf-8a9d-ed11-aad1-0022489fde6b',
  },
  {
    value: 7,
    label: 'Vakantie',
    tag: 'vakantie',
    categoryId: 'f81b4a77-99ab-ed11-aad0-0022489fdbeb',
  },
  { value: 8, label: 'Familieberichten' },
  { value: 9, label: 'Advertenties' },
  { value: 10, label: 'Redactie' },
  { value: 11, label: 'Anders' },
];

export const DL_SITE_PRODUCT = 0;
export const PRODUCTS = [
  { value: DL_SITE_PRODUCT, label: 'De Limburger online (website)' },
  { value: 1, label: 'Nieuwsapp' },
  { value: 2, label: 'Krantenapp' },
  { value: 3, label: 'Anders' },
];
export const UNKNOWN_DEVICE = 0;
export const MOBILE = 1;
export const TABLET = 2;
export const DESKTOP = 3;

export const DEVICE_TYPES = [
  { value: 0, label: 'Weet ik niet' },
  { value: 1, label: 'Smartphone' },
  { value: 2, label: 'Tablet' },
  { value: DESKTOP, label: 'Laptop/Desktop' },
  { value: 4, label: 'Anders' },
];

export const OTHER_OS = 6;
export const UNKNOWN_OS = 0;

export const OPERATING_SYSTEMS = [
  { value: 0, label: 'Weet ik niet' },
  { value: 1, label: 'Android' },
  { value: 2, label: 'iOS' },
  { value: 3, label: 'Windows' },
  { value: 4, label: 'MacOS' },
  { value: 5, label: 'Linux' },
  { value: OTHER_OS, label: 'Anders' },
];

export const genderOptions = [
  { label: ts.t('Common.Gender.M'), value: 'M' },
  { label: ts.t('Common.Gender.F'), value: 'F' },
  { label: ts.t('Common.Gender.O'), value: 'O' },
];
