import React from 'react';
import {
  Paper,
  Flex,
  Box,
  Icon,
  Heading,
  useMediaQuery,
} from '@mediahuis/chameleon-react-legacy';
import styled from 'styled-components';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import ChatInfo from './ChatInfo';
import PhoneInfo from './PhoneInfo';
import EmailInfo from './EmailInfo';

const Container = styled(Paper)`
  width: 320px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 767px) {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &:first-child {
    border-top: 1px solid #eee;
  }

  &:hover {
    cursor: pointer;
  }

  .boldCustomerServiceLink {
    font-weight: bold;
  }

  .boldCustomerText {
    font-weight: bold;
  }

  &:hover .boldCustomerServiceLink {
    text-decoration: underline;
  }
  .chat-btn {
    border: none;
    text-align: left;
    background-color: white;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .phone-email-link {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 767px) {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Footer = ({
  chat,
  email,
  phone,
  title,
  openChat,
  isChatbot,
  phoneSettings,
}) => {
  const flexDirection = useMediaQuery({ md: 'row' }, 'column');
  return (
    <Box>
      <Box mb={4}>
        {title && (
          <Heading level={4} fontWeight="regular">
            {title}
          </Heading>
        )}
      </Box>
      <Flex alignContent="center" flexDirection={flexDirection}>
        {chat.mayShow && isChatbot && (
          <Container>
            <button type="button" className="chat-btn" onClick={openChat}>
              <Box p={4} width="full">
                <Flex alignItems="center" justifyContent="space-between">
                  <ChatInfo componentData={chat} />
                  <Icon as={ChevronForward} />
                </Flex>
              </Box>
            </button>
          </Container>
        )}
        {phone.mayShow && (
          <Container>
            <a
              className="phone-email-link"
              href={!phoneSettings?.closed ? phone.link : ' '}
            >
              <Box p={4} width="full">
                <Flex alignItems="center" justifyContent="space-between">
                  <PhoneInfo componentData={phone} />
                  <Icon as={ChevronForward} />
                </Flex>
              </Box>
            </a>
          </Container>
        )}
        {email.mayShow && (
          <Container>
            <a className="phone-email-link" href={email.link}>
              <Box p={4} width="full">
                <Flex alignItems="center" justifyContent="space-between">
                  <EmailInfo componentData={email} />
                  <Icon as={ChevronForward} />
                </Flex>
              </Box>
            </a>
          </Container>
        )}
      </Flex>
    </Box>
  );
};

export default Footer;
