import { config } from '~/services';
import { apiCall } from '~/utils';

const postSmartWebform = async ({ body }) => {
  await apiCall(`${config.serviceApiUrl}/smartwebform?api-version=2`, {
    method: 'POST',
    data: JSON.stringify(body),
    withCredentials: true,
  });
};

export default postSmartWebform;
