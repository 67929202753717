import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Loader,
  Paragraph,
  Select,
  Switch,
  TextField,
  Textarea,
  useMediaQuery,
} from '@mediahuis/chameleon-react-legacy';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import isEmpty from 'lodash.isempty';
import React, { useEffect, useState } from 'react';
import { Header, Wrapper } from '~/components';
import addressFieldsValidations from '~/components/AddressFields/addressFieldsValidations';
import PersonalInformation from '~/pages/Contact/components/PersonalInformation';
import {
  DELIVERY_SUB_CATEGORY,
  DESKTOP,
  DEVICE_TYPES,
  DE_LIMBURGER_DIGITAL_SUB_CATEGORY,
  DL_SITE_PRODUCT,
  OPERATING_SYSTEMS,
  OTHER_OS,
  PRODUCTS,
  QUESTION_CATEGORY,
  SUB_CATEGORIES,
  UNKNOWN_OS,
} from '~/pages/Contact/constants';
import {
  filterArticlesByTag,
  getAllFaqArticles,
  handleCheckBoxChange,
  handleSelectChange,
  mapToContactFormBody,
  validateContactFormFields,
} from '~/pages/Contact/utils';
import { ts } from '~/services';
import { clearValidationOnFocus } from '~/utils';
import { useBanner, useGlobalContext } from '~/context';
import postContactForm from '~/api/postContactForm';
import FaqArticles from '~/pages/Contact/components/FaqArticles';
import CategoryAndSubCategory from '~/pages/Contact/components/CategoryAndSubCategory';
import { BANNER_TYPES } from '~/constants';

export default function Contact() {
  const {
    faqCategories,
    isLoadingFaqCategories,
    userInfo,
  } = useGlobalContext();
  const { showBanner } = useBanner();

  const defaultState = {
    category: QUESTION_CATEGORY,
    subCategory: DELIVERY_SUB_CATEGORY,
    product: DL_SITE_PRODUCT,
    description: '',
    currentDevice: true,
    deviceType: DESKTOP,
    os: UNKNOWN_OS,
  };
  const defaultPersonalInformation = {
    gender: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    countryCode: 'NL',
    postalCode: '',
    city: '',
    street: '',
    houseNumber: '',
    box: '',
  };
  const [state, setState] = useState(defaultState);
  const [personalInformation, setPersonalInformation] = useState(
    defaultPersonalInformation,
  );
  const { gender, firstName, lastName, email, phone } = personalInformation;
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const faqArticles = getAllFaqArticles(faqCategories);
  const filteredArticles = filterArticlesByTag({
    articles: faqArticles,
    tag: SUB_CATEGORIES.find(x => x.value === state.subCategory)?.tag,
  });
  const showArticles =
    filteredArticles.length > 0 && state.category === QUESTION_CATEGORY;

  useEffect(() => {
    if (!isEmpty(userInfo)) {
      setPersonalInformation(prevState => ({
        ...prevState,
        gender: userInfo.gender?.charAt(0) ?? '',
        firstName: userInfo.firstName ?? '',
        lastName: userInfo.name ?? '',
        email: userInfo.emailAddress ?? '',
        phone: userInfo.phoneNumber ?? '',
        countryCode: userInfo.countryCode ?? 'NL',
        postalCode: userInfo.zipCode ?? '',
        city: userInfo.city ?? '',
        street: userInfo.street ?? '',
        houseNumber: userInfo.houseNumber ?? '',
        box: userInfo.box ?? '',
      }));
    }
  }, [userInfo]);

  // eslint-disable-next-line consistent-return
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const contactFormFieldsErrors = validateContactFormFields({
        firstName,
        lastName,
        email,
        gender,
        phone,
        description: state.description,
      });

      const addressErrors = await addressFieldsValidations(
        personalInformation,
        personalInformation.countryCode,
      );

      const combinedErrors = {
        ...contactFormFieldsErrors,
        ...addressErrors,
      };
      if (Object.keys(combinedErrors).length === 0) {
        const body = mapToContactFormBody({ state, personalInformation });
        await postContactForm({ body });
        showBanner({
          type: BANNER_TYPES.SUCCESS,
          message: ts.t('Contact.From.SuccessMessage', {
            richContent: true,
          }),
          closeHidden: true,
        });
        window.scrollTo(0, 0);
        setState(defaultState);
        setSubmitted(true);
        setPersonalInformation(defaultPersonalInformation);
      }
      return setErrors(combinedErrors);
    } catch (error) {
      showBanner({
        type: BANNER_TYPES.ERROR,
        message: ts.t('Contact.From.ErrorMessage'),
      });
      window.scrollTo(0, 0);
    } finally {
      setIsLoading(false);
    }
  };

  const flexDirection = useMediaQuery({ md: 'row' }, 'column');
  const deviceTypeMarginRight = useMediaQuery({ md: 5 }, 0);

  const categoryId = SUB_CATEGORIES.find(x => x.value === state.subCategory)
    .categoryId;

  return (
    <div>
      <Header heading={ts.t('Contact.Heading')} />
      {!submitted && (
        <Wrapper>
          <Paragraph mb={9}>
            {ts.t('Contact.Text', { richContent: true })}
          </Paragraph>
        </Wrapper>
      )}
      {!submitted && (
        <Box bgColor="grey10" py={9}>
          <Wrapper>
            <Box maxWidth="800px" mx="auto" p={7} bgColor="whiteBase">
              <Heading level={3} mb={4}>
                {ts.t('Contact.CanWeHelp')}
              </Heading>
              <CategoryAndSubCategory state={state} setState={setState} />
              {isLoadingFaqCategories && state.category === QUESTION_CATEGORY && (
                <Flex justifyContent="center" p={6}>
                  <Loader />
                </Flex>
              )}
              {showArticles && (
                <FaqArticles
                  articles={filteredArticles}
                  href={`/?categoryId=${categoryId}`}
                />
              )}
              {state.subCategory === DE_LIMBURGER_DIGITAL_SUB_CATEGORY && (
                <>
                  <Switch
                    id="currentDevice"
                    label={ts.t('Contact.Form.CurrentDeviceLabel')}
                    mb={4}
                    onChange={e => handleCheckBoxChange({ e, setState })}
                    checked={state.currentDevice}
                  />
                  <Select
                    label={ts.t('Contact.Form.ProductLabel')}
                    id="product"
                    value={state.product}
                    onChange={e => handleSelectChange({ e, setState })}
                    mb={4}
                    required
                  >
                    {PRODUCTS.map(product => (
                      <option key={product.value} value={product.value}>
                        {product.label}
                      </option>
                    ))}
                  </Select>

                  {!state.currentDevice && (
                    <>
                      <Flex flexDirection={flexDirection}>
                        <Box width="100%">
                          <Select
                            label={ts.t('Contact.Form.DeviceType')}
                            id="deviceType"
                            value={state?.deviceType}
                            onChange={e => handleSelectChange({ e, setState })}
                            mb={4}
                            mr={deviceTypeMarginRight}
                            required
                          >
                            {DEVICE_TYPES.map(deviceType => (
                              <option
                                key={deviceType.value}
                                value={deviceType.value}
                              >
                                {deviceType.label}
                              </option>
                            ))}
                          </Select>
                        </Box>
                        <Box width="100%">
                          <Select
                            label={ts.t('Contact.Form.OS')}
                            id="os"
                            value={state?.os}
                            onChange={e => handleSelectChange({ e, setState })}
                            mb={4}
                            required
                          >
                            {OPERATING_SYSTEMS.map(os => (
                              <option key={os.value} value={os.value}>
                                {os.label}
                              </option>
                            ))}
                          </Select>
                        </Box>
                      </Flex>

                      {state?.os === OTHER_OS && (
                        <TextField
                          id="otherOS"
                          label={ts.t('Contact.Form.OtherOs')}
                          name="otherOS"
                          value={state.otherOS}
                          onChange={e => {
                            const value = e.target.value;
                            return setState(prevState => ({
                              ...prevState,
                              otherOS: value,
                            }));
                          }}
                          message={errors?.otherOS}
                          error={!!errors?.otherOS}
                          width="100%"
                          mb={4}
                        />
                      )}
                      {state.os !== UNKNOWN_OS && (
                        <TextField
                          id="osVersion"
                          label={ts.t(
                            state?.os === OTHER_OS
                              ? 'Contact.Form.OtherOsVersion'
                              : 'Contact.Form.OsVersion',
                            {
                              values: {
                                os: OPERATING_SYSTEMS.find(
                                  x => x.value === state.os,
                                ).label,
                              },
                            },
                          )}
                          name="osVersion"
                          value={state?.osVersion}
                          onChange={e => {
                            const value = e.target.value;
                            return setState(prevState => ({
                              ...prevState,
                              osVersion: value,
                            }));
                          }}
                          message={errors?.osVersion}
                          error={!!errors?.osVersion}
                          width="100%"
                          mb={4}
                        />
                      )}
                      <TextField
                        id="browser"
                        label={ts.t('Contact.Form.Browser')}
                        name="browser"
                        value={state?.browser}
                        onChange={e => {
                          const value = e.target.value;
                          return setState(prevState => ({
                            ...prevState,
                            browser: value,
                          }));
                        }}
                        message={errors?.browser}
                        error={!!errors?.browser}
                        width="100%"
                        mb={4}
                      />
                    </>
                  )}
                </>
              )}
              <Box mb={9}>
                <Textarea
                  label={ts.t('Contact.Form.DescriptionLabel')}
                  id="description"
                  name="description"
                  value={state.description}
                  message={
                    errors.description
                      ? errors.description
                      : ts.t('Contact.Form.DescriptionMessage')
                  }
                  maxLength={10_000}
                  required
                  onChange={e => {
                    const value = e.target.value;
                    setState(prevState => ({
                      ...prevState,
                      description: value,
                    }));
                  }}
                  rows={6}
                  error={!!errors.description}
                  onBlur={e =>
                    setErrors({
                      ...errors,
                      ...validateContactFormFields({
                        [e.target.name]: e.target.value,
                      }),
                    })
                  }
                  onFocus={e =>
                    clearValidationOnFocus({ e, errors, setErrors })
                  }
                />
              </Box>
              <PersonalInformation
                personalInformation={personalInformation}
                setPersonalInformation={setPersonalInformation}
                setErrors={setErrors}
                errors={errors}
              />
              <Divider color="grey40" my={8} />
              <Flex justifyContent="center">
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  iconRight={ChevronForward}
                  loading={isLoading}
                  disabled={!isEmpty(errors)}
                >
                  {ts.t('Contact.Form.SubmitButton')}
                </Button>
              </Flex>
            </Box>
          </Wrapper>
        </Box>
      )}
    </div>
  );
}
