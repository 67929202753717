import React, { useEffect, useState } from 'react';
import SelfHelp from '~/components/SelfHelp';
import { Header, Wrapper } from '~/components';
import { seoService, ts } from '~/services';
import { getAllFaqArticles } from '~/pages/Contact/utils';
import { useGlobalContext } from '~/context';
import TestLinkWithUserChoices from '~/components/Didomi/TestLinkWithUserChoices';
import { SERVICE_MESSAGE_PAGES } from '~/constants/serviceMessage';
import ServiceMessage from '../../components/ServiceMessage';
import { METADATA, PAGES } from '../../constants';

const Home = () => {
  const { faqCategories } = useGlobalContext();
  const [seoScript, setSeoScript] = useState(null);

  useEffect(() => {
    if (faqCategories?.length > 0) {
      const faqArticles = getAllFaqArticles(faqCategories);
      setSeoScript(seoService.getFaqStructuredData(faqArticles));
    } else {
      setSeoScript(null);
    }
  }, [faqCategories]);
  return (
    <>
      {METADATA[PAGES.home]}
      <Header heading={ts.t('Home.Heading')} />
      <Wrapper>
        <ServiceMessage page={SERVICE_MESSAGE_PAGES.home} />
        <SelfHelp />
        {seoScript}
        <TestLinkWithUserChoices />
      </Wrapper>
    </>
  );
};

export default Home;
