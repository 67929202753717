import React from 'react';
import ContentLoader from '~/components/ContentLoader';
import Header from '~/components/Header';

export default function HeaderWithContentLoader({ title, mb = 0, level = 0 }) {
  return (
    <>
      <Header heading={title} mb={mb} level={level} />
      <ContentLoader height="500px" />
    </>
  );
}
