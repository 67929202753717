import React from 'react';
import {
  Box,
  TextField,
  Flex,
  useMediaQuery,
} from '@mediahuis/chameleon-react-legacy';
import { COUNTRIES } from '~/constants';
import PostalCodeAutocomplete from './PostalCodeAutocomplete';
import StreetAutocomplete from './StreetAutocomplete';
import useCity from './hooks/useCity';
import useStreets from './hooks/useStreets';
import noop from './noop';
import HouseNumberTextField from './HouseNumberTextField';
import language from './language';

const BelgianAddressFields = ({
  errors = {},
  handleAddressChange = noop,
  address,
  clearFields = noop,
  validateOnBlur = noop,
  clearError = noop,
  allowedPostalCodesByCountry,
  setErrors,
}) => {
  // Get all cities according to postalCode and country.
  const { matchedCities, isLoading: isLoadingCities } = useCity({
    postalCode: address.postalCode,
    countryCode: COUNTRIES.BE,
    allowedPostalCodesByCountry,
    setErrors,
  });
  const { matchedStreets, isLoading: isLoadingStreets } = useStreets({
    streetName: address.street,
    postalCode: address.postalCode,
    countryCode: COUNTRIES.BE,
    setErrors,
  });

  const resetFields = ({ value }) => {
    if (
      matchedStreets &&
      value !== address.postalCode &&
      address.postalCode !== ''
    ) {
      // emptying array by removing index 0
      // so all street suggestions get removed
      matchedStreets.splice(0, 1);
      clearFields();
    }
  };

  return (
    <React.Fragment>
      <Flex
        pb={4}
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
      >
        <Box pb={4} mr={3} width={useMediaQuery({ xs: '100%', md: '50%' })}>
          <PostalCodeAutocomplete
            onChange={selection => {
              if (selection && selection.PostalCode && selection.Name) {
                handleAddressChange({
                  postalCode: selection.PostalCode,
                  city: selection.Name,
                });
              }
            }}
            itemToString={item => (item ? item.PostalCode : '')}
            onInputValueChange={value => {
              resetFields({ value });
              handleAddressChange({
                postalCode: value,
              });
            }}
            postalCode={address.postalCode}
            label={language.postalCode}
            items={matchedCities}
            errors={errors}
            validateOnBlur={validateOnBlur}
            clearError={clearError}
            autoComplete="new-postalCode"
            isLoading={isLoadingCities}
          />
        </Box>
        <Box width={useMediaQuery({ xs: '100%', md: '50%' })}>
          <TextField
            label={language.city}
            data-testid="input-city"
            id="city"
            disabled
            value={address.city}
            autoComplete="new-city"
          />
        </Box>
      </Flex>

      <Box>
        <Flex>
          <Box pb={4} flex={3}>
            <StreetAutocomplete
              onChange={selection => {
                if (selection && selection.Name) {
                  handleAddressChange({
                    street: selection.Name,
                    bpostStreetName: selection?.BpostStreetName,
                    houseNumber: '',
                  });
                }
              }}
              itemToString={cStreet => (cStreet ? cStreet.Name : '')}
              onInputValueChange={value => {
                handleAddressChange({
                  street: value,
                  houseNumber: '',
                });
              }}
              disabled={!address.postalCode}
              label={language.street}
              street={address.street}
              streets={matchedStreets}
              errors={errors}
              validateOnBlur={validateOnBlur}
              clearError={clearError}
              autoComplete="new-street"
              isLoading={isLoadingStreets}
            />
          </Box>
        </Flex>
        <Box pb={4} flex={2}>
          <Flex>
            <Box mr={3} flex={1}>
              <HouseNumberTextField
                validateOnBlur={validateOnBlur}
                clearError={clearError}
                houseNumber={address.houseNumber}
                errors={errors}
                disabled={!address.street}
                onChange={handleAddressChange}
                maxLength={5}
              />
            </Box>
            <Box flex={1}>
              <TextField
                label={language.box}
                data-testid="input-box"
                id="box"
                onChange={event => {
                  if (event.target.value?.length > 6) return;
                  handleAddressChange({
                    box: event.target.value,
                  });
                }}
                value={address.box}
                optionalLabel={language.optional}
                disabled={!address.street}
                autoComplete="new-box"
                maxlength={6}
              />
            </Box>
          </Flex>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default BelgianAddressFields;
