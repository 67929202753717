import { Flex, Text } from '@mediahuis/chameleon-react-legacy';
import isEmpty from 'lodash.isempty';
import WideListItemAction from '~/components/WideListItemAction';
import { DIALOG_TYPES } from '~/pages/CustomerDetails/constants';
import { cleanDate } from '~/pages/Holiday/utils/cleanHolidayData';
import { getAddressField } from '~/pages/Subscription/ah/utils/fields';
import { ts } from '~/services';
import {
  SUBSCRIPTION_COMPONENT_TYPES,
  subscriptionHasComponent,
} from '~/utils';
import removeLeadingZeroes from '~/utils/removeLeadingZeroes';

export const mapImpactedSubscriptions = (affectedSubscriptions = []) => {
  if (!affectedSubscriptions.length) {
    return null;
  }
  return (
    <Flex flexDirection="column">
      {affectedSubscriptions.map((affectedSubscription, index) => {
        const isLast = affectedSubscriptions.length - 1 === index;
        return (
          <Flex
            key={affectedSubscription.subscriptionId}
            flexDirection="column"
            mb={isLast ? 0 : 5}
          >
            <Text size="Caption1" color="colorGrey90">
              {affectedSubscription.name}{' '}
              {removeLeadingZeroes(affectedSubscription.subscriptionId)}
            </Text>
            {affectedSubscription.delivery && (
              <Text
                size="Caption1"
                color="colorGrey90"
                key={affectedSubscription.subscriptionId}
              >
                {ts.t('CustomerDetails.DeliveryType')}:{' '}
                {affectedSubscription.delivery?.type?.label ||
                  affectedSubscription.delivery?.type?.code}
              </Text>
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};
export const mapAddressChanges = ({
  addressChanges = [],
  handleClick,
  setSelectedAddressChange,
  isReadOnlyMode,
  hasTheRightToChangeAddress,
}) => {
  return addressChanges
    .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
    .map(addressChange => {
      return {
        startDate: cleanDate(addressChange.startDate),
        address: getAddressField(addressChange?.addresses?.nonStandard?.[0]),
        impactedSubscriptions: mapImpactedSubscriptions(
          addressChange.affectedSubscriptions,
        ),
        cancel:
          hasTheRightToChangeAddress &&
          addressChange.canDelete &&
          !isReadOnlyMode ? (
            <WideListItemAction
              label={ts.t('Common.Cancel')}
              handleClick={() => {
                setSelectedAddressChange(addressChange);
                handleClick({ type: DIALOG_TYPES.CANCEL_ADDRESS_CHANGE });
              }}
            />
          ) : (
            ' '
          ),
      };
    });
};

export const addressIsComplete = ({ addressFields, errors }) => {
  const { city, countryCode, houseNumber, postalCode, street } = addressFields;

  const hasNoErrors = isEmpty(errors);

  return (
    !!(city && countryCode && houseNumber && postalCode && street) &&
    hasNoErrors
  );
};

export const shouldFetchAddressDeliveryOptions = ({
  userSubscriptions,
  addressFields,
  errors,
}) => {
  const subscriptionHasPrintComponent = userSubscriptions.some(subscription =>
    subscriptionHasComponent(subscription, SUBSCRIPTION_COMPONENT_TYPES.PRINT),
  );

  return (
    subscriptionHasPrintComponent &&
    addressIsComplete({ addressFields, errors })
  );
};
