import React, { useEffect, useState } from 'react';
import { Banner, Flex, Loader } from '@mediahuis/chameleon-react-legacy';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import {
  Header,
  HeaderWithContentLoader,
  SubscriptionSelect,
  Wrapper,
} from '~/components';
import LoginWall from '~/components/LoginWall';
import { ts } from '~/services';
import EntitySwitch from '~/components/ah/EntitySwitch';
import OutstandingPaymentBanners from '~/components/OutstandingPaymentBanners';
import AhSubscriptionSelect from '~/components/ah/AhSubscriptionSelect';
import { SERVICE_MESSAGE_PAGES } from '~/constants/serviceMessage';
import { useSubscriptionContext } from '~/context';
import SubscriptionContent from './SubscriptionContent';
import NotFound from './NotFound';
import ServiceMessage from '../../components/ServiceMessage';
import { METADATA, PAGES } from '../../constants';
import AhSubscriptionContent from './ah/AhSubscriptionContent';

const Subscription = () => {
  const globalContext = useGlobalContext();
  const { chosenUserSubscription, userInfo, isFetchingUser } = globalContext;
  const [
    refreshAddressChangesHasFailed,
    setRefreshAddressChangesHasFailed,
  ] = useState(false);

  useEffect(() => {
    if (chosenUserSubscription) {
      window.MH = window.MH || {};
      window.MH.subscriptionId = chosenUserSubscription.id;
    }
  }, [chosenUserSubscription]);

  const { areOutstandingPaymentBannersVisible } = useSubscriptionContext();
  const isLoggedIn = userInfo.accountGuid;

  if (isFetchingUser && !isLoggedIn) {
    return (
      <HeaderWithContentLoader
        title={ts.t('Subscription.Heading')}
        mb={5}
        level={3}
      />
    );
  }

  return (
    <React.Fragment>
      {METADATA[PAGES.subscription]}
      <Header heading={ts.t('Subscription.Heading')} mb={5} level={3} />
      <Wrapper>
        {refreshAddressChangesHasFailed && (
          <Banner
            mb={5}
            appearance="error"
            show={refreshAddressChangesHasFailed}
            onClose={() => setRefreshAddressChangesHasFailed(false)}
          >
            {ts.t('Subscriptions.Errors.RefreshAddressChanges')}
          </Banner>
        )}
        <ServiceMessage mb={5} page={SERVICE_MESSAGE_PAGES.subscription} />
        {isLoggedIn ? (
          <React.Fragment>
            {areOutstandingPaymentBannersVisible && (
              <OutstandingPaymentBanners />
            )}
            <EntitySwitch
              defaultComponent={<SubscriptionSelect />}
              mhlu={<AhSubscriptionSelect />}
            />
            {chosenUserSubscription === null && (
              <Flex height="500px" justifyContent="center" alignItems="center">
                <Loader />
              </Flex>
            )}
            {chosenUserSubscription !== null &&
              (!chosenUserSubscription ? (
                <NotFound />
              ) : (
                <EntitySwitch
                  defaultComponent={<SubscriptionContent />}
                  mhlu={
                    <AhSubscriptionContent
                      setRefreshAddressChangesHasFailed={
                        setRefreshAddressChangesHasFailed
                      }
                    />
                  }
                />
              ))}
          </React.Fragment>
        ) : (
          <LoginWall variant="inline" />
        )}
      </Wrapper>
    </React.Fragment>
  );
};

export default Subscription;
