import { config, ts } from '~/services';
import Comment from '../components/Comment';
import TimeSelection from '../components/TimeSelection';
import { TYPES } from '../constants';

const deliveryComplaintTypes = [
  {
    value: TYPES.NOT_RECEIVED,
    label: ts.t('DeliveryComplaint.Types.NotReceived'),
  },
  {
    value: TYPES.ANNEX_NOT_RECEIVED,
    label: ts.t('DeliveryComplaint.Types.AnnexNotReceived'),
    extra: config.brand !== 'dl' ? <Comment /> : '',
  },
  {
    value: TYPES.TOO_LATE,
    label: ts.t('DeliveryComplaint.Types.TooLate'),
    extra: <TimeSelection />,
  },
  {
    value: TYPES.DAMAGED,
    label: ts.t('DeliveryComplaint.Types.Damaged'),
    extra: config.brand !== 'dl' ? <Comment /> : '',
  },
  {
    value: TYPES.WRONG_TITLE_OR_EDITION,
    label: ts.t('DeliveryComplaint.Types.WrongEdition'),
    extra: config.brand !== 'dl' ? <Comment /> : '',
  },
  {
    value: TYPES.WRONG_MAILBOX,
    label: ts.t('DeliveryComplaint.Types.WrongMailbox'),
    extra: <Comment required={true} />,
  },
];

export default deliveryComplaintTypes;
