import { Box, Select, Text } from '@mediahuis/chameleon-react-legacy';
import React from 'react';
import { ts } from '~/services';

export default function AddressDeliveryOptions({
  addressDeliveryOptions,
  selectedDeliveryOptionBySubscription,
  setSelectedDeliveryOptionBySubscription,
}) {
  return addressDeliveryOptions.map(
    ({ subscriptionId, subscriptionName, deliveryOptions }, index) => {
      const handleChange = e => {
        const typeCode = e?.target?.value;
        const deliveryOption = deliveryOptions.find(
          x => x.type.code === typeCode,
        );
        setSelectedDeliveryOptionBySubscription(prevState => ({
          ...prevState,
          [subscriptionId]: {
            typeCode,
            subscriptionId,
            typeLabel:
              deliveryOption?.type?.label || deliveryOption?.type?.code,
          },
        }));
      };
      const addressDeliveryOptionsLength = addressDeliveryOptions?.length;
      const isLast = addressDeliveryOptionsLength - 1 === index;
      const hasMultipleSubscriptions = addressDeliveryOptionsLength > 1;
      return (
        <Box key={subscriptionId} mb={isLast ? 5 : 7}>
          {hasMultipleSubscriptions && (
            <Text
              color="colorGrey80"
              size="Heading6"
              fontWeight="bold"
              fontFamily="system"
              mb={2}
            >
              {subscriptionName} ({subscriptionId})
            </Text>
          )}
          <Select
            label={ts.t('Subscription.AddressDialog.DeliveryMethod')}
            id={`delivery-options-${subscriptionId}`}
            value={
              selectedDeliveryOptionBySubscription?.[subscriptionId]?.typeCode
            }
            onChange={handleChange}
            data-testid="delivery-method-select"
          >
            {deliveryOptions.map(deliveryOption => {
              return (
                <option
                  key={`${subscriptionId}-${deliveryOption.type.code}`}
                  value={deliveryOption.type.code}
                >
                  {deliveryOption?.type?.label || deliveryOption?.type?.code}
                </option>
              );
            })}
          </Select>
        </Box>
      );
    },
  );
}
