import React from 'react';
import { Button, useMediaQuery } from '@mediahuis/chameleon-react-legacy';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import { Header, Wrapper } from '~/components';
import { ts } from '~/services';
import { METADATA, PAGES } from '../../constants';

const DeliverersWanted = () => (
  <React.Fragment>
    {METADATA[PAGES.deliverersWanted]}
    <Header heading={ts.t('DeliverersWanted.Heading')} />
    <Wrapper>
      {ts.t('DeliverersWanted.Info', { richContent: true })}
      <Button
        iconRight={ChevronForward}
        width={useMediaQuery({ sm: 'auto' }, 'full')}
        as="a"
        href="https://www.bezorgdekrant.nl"
        target="_blank"
      >
        {ts.t('DeliverersWanted.Button.Text')}
      </Button>
    </Wrapper>
  </React.Fragment>
);

export default DeliverersWanted;
