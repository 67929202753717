import { TextField } from '@mediahuis/chameleon-react-legacy';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import { ts } from '~/services';

const DisabledSubscriptionInfo = () => {
  const { chosenUserSubscription } = useGlobalContext();

  const subscriptionTextFieldValue =
    chosenUserSubscription?.receiver?.companyName ||
    `${
      chosenUserSubscription?.receiver?.firstName
        ? chosenUserSubscription?.receiver?.firstName
        : ''
    } ${
      chosenUserSubscription?.receiver?.lastName
        ? chosenUserSubscription?.receiver?.lastName
        : ''
    }`;

  return (
    <TextField
      id="textfield-your-subscription"
      label={ts.t('Subscription.SubscriptionSelectLabel')}
      placeholder=""
      disabled
      value={`${
        subscriptionTextFieldValue ? `${subscriptionTextFieldValue} - ` : ''
      }${chosenUserSubscription?.description} - ${chosenUserSubscription?.id}`}
    />
  );
};

export default DisabledSubscriptionInfo;
