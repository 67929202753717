import { HOLIDAY_SOLUTIONS } from './index';
import getLanguage from '../language';

const holidayResolutionOptions = (
  holidayArrangementOptions,
  internalSubscriptionType,
) => {
  const language = getLanguage(internalSubscriptionType);

  return [
    {
      resolution: holidayArrangementOptions.resolutions.ContinueDigital,
      value: HOLIDAY_SOLUTIONS.CONTINUE_DIGITAL,
      language: language.continueDigital,
    },
    {
      resolution: holidayArrangementOptions.resolutions.DiscontinueDigital,
      value: HOLIDAY_SOLUTIONS.DISCONTINUE_DIGITAL,
      language: language.discontinueDigital,
    },
    {
      resolution: holidayArrangementOptions.resolutions.DiscontinuePrint,
      value: HOLIDAY_SOLUTIONS.DISCONTINUE_PRINT,
      language: language.discontinuePrint,
    },
    {
      resolution: holidayArrangementOptions.resolutions.HolidayAddress,
      value: HOLIDAY_SOLUTIONS.HOLIDAY_ADDRESS,
      language: language.holidayAddress,
    },
    {
      resolution: holidayArrangementOptions.resolutions.FamilyAddress,
      value: HOLIDAY_SOLUTIONS.FAMILY_ADDRESS,
      language: language.familyAddress,
    },
    {
      resolution: holidayArrangementOptions.resolutions.Charity,
      value: HOLIDAY_SOLUTIONS.CHARITY,
      language: language.charity,
    },
  ];
};

export default holidayResolutionOptions;
