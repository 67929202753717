import {
  Box,
  Button,
  Flex,
  Icon,
  Paper,
} from '@mediahuis/chameleon-react-legacy';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import FeaturesList from './FeaturesList';
import MultibrandBanner from './MultibrandBanner';
import MultibrandBannerInline from './MultibrandBannerInline';

const RESPONSIVE_GRID_SCREEN_SIZE = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
};

const AlternativeOfferCard = ({
  features,
  highlight = false,
  highlightColor,
  highlightColorText,
  multibrandProps,
  price,
  title,
  buttonText,
  onClick,
  screenSize,
  hideMultiBrandBanner = false,
}) => {
  const isMobile = screenSize === RESPONSIVE_GRID_SCREEN_SIZE.xs;
  return (
    <Flex flexDirection="column" height="100%">
      <Box
        borderRadius={3}
        style={{
          border: highlight
            ? `2px solid var(--${highlightColor})`
            : '2px solid var(--color-grey-100)',
        }}
        height="100%"
      >
        <Paper
          alignItems="stretch"
          borderRadius={3}
          display="flex"
          elevation={2}
          hoverable
          justifyContent="space-between"
          onClick={isMobile ? onClick : undefined}
          flexDirection={isMobile ? 'row' : 'column'}
          height="100%"
        >
          <Flex
            flexGrow={1}
            flexDirection="column"
            justifyContent="space-between"
            style={{ borderBottomLeftRadius: 'inherit' }}
          >
            <Flex flexGrow={1}>
              <Flex
                flexDirection="column"
                flexGrow={1}
                p={4}
                style={{ gap: '0.5rem' }}
                justifyContent="space-between"
              >
                <Flex justifyContent="space-between">
                  {title}
                  <Flex flexDirection="column" px={4}>
                    <Box width="70px">{price}</Box>
                  </Flex>
                </Flex>
                <FeaturesList items={features} lineThroughUnavailable />
              </Flex>
            </Flex>

            {multibrandProps &&
              (isMobile ? (
                <MultibrandBannerInline
                  {...multibrandProps}
                  style={{
                    borderRadius: 'inherit',
                    visibility: hideMultiBrandBanner ? 'hidden' : undefined,
                  }}
                />
              ) : (
                <MultibrandBanner
                  {...multibrandProps}
                  style={{
                    borderRadius: '8px',
                    marginLeft: '15px',
                    marginRight: '15px',
                    visibility: hideMultiBrandBanner ? 'hidden' : undefined,
                  }}
                />
              ))}
          </Flex>
          {isMobile && (
            <Flex
              flexDirection="column"
              justifyContent="center"
              px={2}
              style={{
                backgroundColor: highlight
                  ? `var(--${highlightColor})`
                  : 'var(--color-grey-100)',
                borderBottomRightRadius: '6px',
                borderTopRightRadius: '6px',
                color: highlight
                  ? `var(--${highlightColorText})`
                  : 'var(--button-primary-color)',
              }}
            >
              <Icon as={ChevronForward} />
            </Flex>
          )}
          {!isMobile && (
            <Button
              appearance={highlight ? 'secondary' : 'primary'}
              style={{
                backgroundColor: highlight
                  ? 'var(--color-primary-base)'
                  : undefined,
                color: highlight ? 'var(--button-primary-color)' : undefined,
              }}
              onClick={onClick}
              m={5}
            >
              {buttonText}
            </Button>
          )}
        </Paper>
      </Box>
    </Flex>
  );
};

export default AlternativeOfferCard;
