import { useEffect, useState } from 'react';
import language from '~/components/AddressFields/language';
import { environment } from '~/services/environment';

let abortController = new AbortController();
export default ({ houseNumber, postalCode, setErrors }) => {
  const [matchedStreets, setMatchedStreets] = useState('');

  // eslint-disable-next-line consistent-return
  const getStreets = async () => {
    try {
      abortController.abort();
      abortController = new AbortController();
      setMatchedStreets('');
      const res = await fetch(
        `https://${environment.apiPrefix}addressservice.mediahuis.be/Address/Resolve`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            HouseNumber: houseNumber,
            PostalCode: postalCode,
            CountryCode: 'NL',
            LanguageCode: 'NL',
          }),
          signal: abortController.signal,
        },
      );
      const data = await res.json();
      if (data.Address === null) {
        setErrors(prevState => ({
          ...prevState,
          houseNumber: language.postalCodeHouseNumberComboNotFound,
        }));
        return setMatchedStreets('');
      }
      return setMatchedStreets(data.Address.Street.Name);
    } catch (error) {
      setMatchedStreets('');
    }
  };
  useEffect(() => {
    if (houseNumber) {
      getStreets();
    } else {
      setMatchedStreets('');
    }
  }, [houseNumber, postalCode]);

  return matchedStreets;
};
