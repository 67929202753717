import React from 'react';
import snarkdown from 'snarkdown';
import styled from 'styled-components';
import {
  fontWeightBold,
  fontFamilySystem,
} from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';

const MarkdownContainer = styled.div`
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    all: revert;
  }
  white-space: pre-line;
  strong {
    font-weight: ${fontWeightBold};
  }
  img {
    height: 16px;
    margin: 0;
    vertical-align: text-bottom;
  }
  a {
    text-decoration: underline;
  }
  s {
    display: inline-block;
    max-width: fit-content;
    position: relative;
    text-decoration: none;
  }
  s:after {
    border-top: 2px solid red;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: calc(50% - 1px);
    width: 100%;
    transform: rotateZ(-4deg);
  }
  word-wrap: break-word;
  padding: '8px';
  font-family: ${fontFamilySystem};
  ul {
    margin-top: 8px;
    margin-left: 18px;
  }
  li {
    list-style-type: disc;
    margin-bottom: 8px;
  }
  ${props => ({ ...props.styling })};
`;

const MarkdownRender = ({ styling = {}, source = '' }) => {
  return (
    <MarkdownContainer
      styling={styling}
      dangerouslySetInnerHTML={{
        __html: snarkdown(source),
      }}
    />
  );
};

export default MarkdownRender;
