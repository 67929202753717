import { ts } from '~/services';

const validateAddresseeStepsData = ({ firstName, lastName, salutation }) => {
  let errors = {};

  if (firstName != null) {
    if (firstName.length === 0) {
      errors = {
        ...errors,
        firstName: ts.t('AddressFields.FirstNameRequired'),
      };
    }
  }
  if (lastName != null) {
    if (lastName.length === 0) {
      errors = {
        ...errors,
        lastName: ts.t('AddressFields.LastNameNameRequired'),
      };
    }
  }

  if (salutation != null) {
    if (salutation.length === 0) {
      errors = {
        ...errors,
        salutation: ts.t('AddressFields.SalutationRequired'),
      };
    }
  }

  return errors;
};

export default validateAddresseeStepsData;
