import { STATUSES, CHAT, EMAIL } from '../enums';
import isOpen from './isOpen';

const getStatus = ({
  strType,
  todaysopeninghours,
  isClosed,
  isFullClosed,
  openinghours,
  dToday,
  widgetData,
}) => {
  const { CLOSED, HOLIDAY, OPEN, OPENSOON, RESTDAY, CHATBOT } = STATUSES;
  const jsonChatbot = widgetData.jsonSettings.settings.chatbot;
  const bActiveOpeningHours =
    openinghours.activeopeninghours === 'activeopeninghours';
  let strStatus = CLOSED;

  // Closed manually?
  if (!isClosed && !isFullClosed) {
    // Opening hours available?
    if (todaysopeninghours) {
      if (
        isOpen({
          arrOpenHours: todaysopeninghours,
          strTypeCheck: HOLIDAY,
          dToday,
          widgetData,
        })
      ) {
        strStatus = HOLIDAY;
      } else if (
        isOpen({
          arrOpenHours: todaysopeninghours,
          strTypeCheck: OPEN,
          dToday,
          widgetData,
        })
      ) {
        strStatus = OPEN;
      } else if (
        isOpen({
          arrOpenHours: todaysopeninghours,
          strTypeCheck: OPENSOON,
          dToday,
          widgetData,
        })
      ) {
        strStatus = OPENSOON;
      }
    } else {
      // No opening hours = RESTING DAY!
      strStatus = RESTDAY;
    }

    // Type specific settings
    switch (strType) {
      case CHAT:
        if (strStatus !== OPEN && jsonChatbot.active) {
          strStatus = CHATBOT;
        }

        break;
      case EMAIL:
        if (bActiveOpeningHours) {
          strStatus = OPEN;
        } else if (strStatus === OPEN) {
          strStatus = CLOSED;
        } else if (strStatus === CLOSED || strStatus === OPENSOON) {
          strStatus = OPEN;
        }
      default:
        break;
    }
  }

  return strStatus;
};

export default getStatus;
