import React from 'react';
import { Choice, Box } from '@mediahuis/chameleon-react-legacy';
import useHolidayContext from '~/context/hooks/useHolidayContext';
import ahHolidayResolutionOptions from '~/pages/Holiday/data/ahHolidayResolutionOptions';
import { ENTITIES } from '~/constants';
import holidayResolutionOptions from '~/pages/Holiday/data/holidayResolutionOptions';
import { config } from '~/services';
import { useGlobalContext } from '~/context';
import {
  SUBSCRIPTION_COMPONENT_TYPES,
  subscriptionHasComponent,
} from '~/utils';

const HolidayResolutions = ({ chosenResolution, setSubmissionData }) => {
  const holidayState = useHolidayContext();
  const { holidayArrangementOptions } = holidayState;
  const globalContext = useGlobalContext();
  const { chosenUserSubscription } = globalContext;

  const hasDigitalComponent = subscriptionHasComponent(
    chosenUserSubscription,
    SUBSCRIPTION_COMPONENT_TYPES.DIGITAL,
  );

  const resolutions =
    config.entity === ENTITIES.MHLU
      ? ahHolidayResolutionOptions(
          holidayArrangementOptions,
          chosenUserSubscription?.internalSubscriptionType,
          hasDigitalComponent,
        )
      : holidayResolutionOptions(
          holidayArrangementOptions,
          chosenUserSubscription?.internalSubscriptionType,
        );

  return resolutions.map(option => {
    if (option.resolution) {
      return (
        <Box pb={2} key={option.value}>
          <Choice
            color={config.isAportal ? 'colorSecondaryBase' : 'colorPrimaryBase'}
            value={option.value}
            data-testid={option.value}
            name={option.value}
            id={option.value}
            title={option.language.optionTitle}
            message={config.isAportal ? null : option.language.optionBody}
            checked={chosenResolution === option.value}
            onChange={({ target }) =>
              setSubmissionData(prevState => ({
                ...prevState,
                vacationSolution: target.value,
              }))
            }
          />
        </Box>
      );
    }
    return null;
  });
};

export default HolidayResolutions;
