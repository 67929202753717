import { arrWeekdays } from '../data';

const getTodaysOpeningHours = ({ openingHours, dToday }) => {
  if (openingHours) {
    // Add 7 and do modulo 7 to avoid -1 index on sunday
    // ie. sunday => (0 - 1 + 7) % 7 => 6 (without the +7 % 7 this would result in -1
    // ie. monday => (1 - 1 + 7) % 7 => 0
    // ie. wednesday => (3 - 1 + 7) % 7 => 2
    const strWeekday = arrWeekdays[(dToday.getDay() - 1 + 7) % 7];
    let arrOpeningHours = [];

    Object.keys(openingHours).map(strWeekdayKey => {
      if (strWeekdayKey === strWeekday) {
        arrOpeningHours = openingHours[strWeekdayKey];
      }
      return strWeekdayKey;
    });

    return arrOpeningHours;
  }
  return false;
};

export default getTodaysOpeningHours;
