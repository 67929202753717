import { config } from '~/services';
import { apiCall } from '~/utils';

const getNewsArticles = () => {
  return apiCall(`${config.serviceApiUrl}/kiosk/news-articles?api-version=2`, {
    withCredentials: true,
  });
};

export default getNewsArticles;
