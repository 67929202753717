import { Box, Fieldset, Flex, Radio } from '@mediahuis/chameleon-react-legacy';
import React from 'react';
import { ts } from '~/services';

const items = [
  { label: ts.t('SmartWebform.Yes'), value: true },
  { label: ts.t('SmartWebform.No'), value: false },
];

export default function CurrentDeviceChoice({
  state,
  setState,
  errors,
  setErrors,
}) {
  return (
    <Fieldset label={ts.t('SmartWebform.CurrentDeviceChoiceLabel')}>
      <Flex mb={6}>
        {items.map(item => {
          return (
            <Box key={item.label} mr={6}>
              <Radio
                name="currentDevice"
                id={item.label}
                value={item.value}
                label={item.label}
                checked={item.value === state.currentDevice}
                onChange={e => {
                  const value = e.target.value;
                  setState(prevState => ({
                    ...prevState,
                    currentDevice: value === 'true',
                  }));
                  const currentErrors = { ...errors };
                  delete currentErrors.browser;
                  delete currentErrors.deviceBrandAndModel;
                  delete currentErrors.os;
                  setErrors(currentErrors);
                }}
              />
            </Box>
          );
        })}
      </Flex>
    </Fieldset>
  );
}
