import useGlobalContext from './useGlobalContext';

const useBanner = () => {
  const { bannerData, setGlobalState } = useGlobalContext();

  const showBanner = data => {
    setGlobalState({ bannerData: { mayShow: true, ...data } });
  };

  const hideBanner = () => {
    setGlobalState({
      bannerData: { ...bannerData, mayShow: false, closeHidden: false },
    });
  };

  return { ...bannerData, showBanner, hideBanner };
};

export default useBanner;
