/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Header,
  Wrapper,
  SubscriptionSelect,
  HeaderWithContentLoader,
} from '~/components';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import LoginWall from '~/components/LoginWall';
import { Flex, Loader } from '@mediahuis/chameleon-react-legacy';
import { PAGES, METADATA } from '~/constants';
import { ts } from '~/services';
import { SERVICE_MESSAGE_PAGES } from '~/constants/serviceMessage';
import ServiceMessage from '../../components/ServiceMessage';
import DeliveryComplaintContent from './components/DeliveryComplaintContent';
import NotAvailable from './components/NotAvailable';
import useBanner from '../../context/hooks/useBanner';

const DeliveryComplaint = () => {
  const { hideBanner } = useBanner();

  const [loginShown, setLoginShown] = useState(false);

  const {
    chosenUserSubscription,
    userInfo,
    isFetchingUser,
  } = useGlobalContext();

  useEffect(() => {
    hideBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenUserSubscription]);

  const isLoggedIn = userInfo.accountGuid;
  const handleLoginClose = () => {
    setLoginShown(false);
  };

  if (isFetchingUser && !isLoggedIn) {
    return (
      <HeaderWithContentLoader title={ts.t('DeliveryComplaint.Heading')} />
    );
  }

  return (
    <React.Fragment>
      {METADATA[PAGES.deliveryComplaint]}
      <Header heading={ts.t('DeliveryComplaint.Heading')} />
      <Wrapper>
        <ServiceMessage mb={5} page={SERVICE_MESSAGE_PAGES.deliveryComplaint} />
        <SubscriptionSelect />
        {isLoggedIn ? (
          chosenUserSubscription === null && isLoggedIn ? (
            <Flex height="500px" justifyContent="center" alignItems="center">
              <Loader />
            </Flex>
          ) : chosenUserSubscription !== null &&
            !chosenUserSubscription?.modules?.deliveryComplaint ? (
            <NotAvailable chosenUserSubscription={chosenUserSubscription} />
          ) : (
            <DeliveryComplaintContent setLoginShown={setLoginShown} />
          )
        ) : (
          <DeliveryComplaintContent setLoginShown={setLoginShown} />
        )}
        {loginShown && <LoginWall variant="modal" onClose={handleLoginClose} />}
      </Wrapper>
    </React.Fragment>
  );
};

export default DeliveryComplaint;
