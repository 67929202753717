import React from 'react';
import { ts } from '~/services';

const DidomiPreferences = () => {
  const handleOnClick = () => {
    window.Didomi && window.Didomi.preferences.show();
  };

  return (
    <span
      style={{ cursor: 'pointer' }}
      onClick={handleOnClick}
      role="link"
      tabIndex="0"
      data-testid="button-manage-gdpr-preferences"
    >
      {ts.t('Privacy.ManagePrivacyPreferences')}
    </span>
  );
};

export default DidomiPreferences;
