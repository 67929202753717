import {
  Box,
  Button,
  Flex,
  Heading,
  Paper,
} from '@mediahuis/chameleon-react-legacy';
import { space9 } from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import styled from 'styled-components';
import { ts } from '~/services';
import { getBrandLogoUrl } from '~/utils';

const BaseLoginWall = ({ handleClick }) => {
  const StyledBox = styled(Box)`
    margin-left: 16px;
    display: inline-block;
    vertical-align: top;
    line-height: 150%;
    margin-top: 2px;
  `;

  return (
    <Paper p={6}>
      <Box m="auto" style={{ maxWidth: '512px' }}>
        <Flex alignItems="center" mb={6}>
          <img
            src={getBrandLogoUrl()}
            alt={ts.t('Constants.BRAND_FULL_NAME')}
            height={space9}
            width={space9}
          />
          <StyledBox>
            <Heading level={3} fontFamily="secondary">
              {ts.t('LoginWall.Title')}
            </Heading>
          </StyledBox>
        </Flex>
        <Button width="full" iconRight={ChevronForward} onClick={handleClick}>
          {ts.t('LoginWall.Button')}
        </Button>
      </Box>
    </Paper>
  );
};
export default BaseLoginWall;
