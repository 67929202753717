import React from 'react';
import { Flex, useMediaQuery } from '@mediahuis/chameleon-react-legacy';
import { links } from '~/components/Navigation/data';
import { BRANDS } from '~/constants';
import { config } from '~/services';
import SelfHelpLink from './Link';

const SelfHelp = () => {
  const flexDirection = useMediaQuery({ md: 'row' }, 'column');
  const selfHelpLinks = links.filter(link => link.url !== '/');

  const linkWidth = useMediaQuery(
    {
      md:
        config.brand === BRANDS.DL
          ? '31%'
          : `${100 / selfHelpLinks.length - 1}%`,
    },
    'full',
  );
  return (
    <Flex
      flexWrap="wrap"
      alignContent="center"
      justifyContent="space-between"
      flexDirection={flexDirection}
    >
      {selfHelpLinks.map(({ url, icon, value, external }) => (
        <SelfHelpLink
          key={url}
          href={url}
          name={icon}
          width={linkWidth}
          external={external}
        >
          {value}
        </SelfHelpLink>
      ))}
    </Flex>
  );
};

export default SelfHelp;
