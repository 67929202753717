import { parsePhoneNumber } from 'react-phone-number-input';

export const formatPhoneNumbers = (contactPointsPhone = []) => {
  return contactPointsPhone
    .filter(phoneNumber => phoneNumber.phone)
    .map(
      ({ countryCallingPrefix, phone }) => `(${countryCallingPrefix}) ${phone}`,
    )
    .join(', ');
};

export const mapToContactPointsPhone = phoneNumbers => {
  return Object.entries(phoneNumbers).map(([key, value]) => {
    if (!value) {
      return {
        countryCallingPrefix: null,
        phone: null,
        purpose: key,
      };
    }
    const { nationalNumber, countryCallingCode } = parsePhoneNumber(value);
    return {
      countryCallingPrefix: `+${countryCallingCode}`,
      phone: nationalNumber,
      purpose: key,
    };
  });
};

export const mapPhoneNumbers = phoneNumbers => {
  return Object.entries(phoneNumbers).reduce((acc, [key, values]) => {
    return {
      ...acc,
      [key]: values?.phone,
    };
  }, {});
};
