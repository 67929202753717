import ahApiCall from '~/api/ah/ahApiCall';
import { config } from '~/services';

const cancelAddressChange = async ({ customerId, addressChangeId }) => {
  await ahApiCall(
    `${config.ahServiceApiUrl}/customers/${customerId}/address-changes/${addressChangeId}/cancel`,
    {
      method: 'POST',
    },
  );
};

export default cancelAddressChange;
