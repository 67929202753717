import dayjs from 'dayjs';

export default ({ startDate, endDate }) => {
  let isValid = false;
  const now = dayjs();

  if (!startDate && !endDate) {
    // no dates available - always show
    isValid = true;
  } else if (!startDate && endDate) {
    // only endDate - check if valid
    if (now.isBefore(endDate)) {
      isValid = true;
    }
  } else if (startDate && !endDate) {
    // only startDate - check if valid
    if (now.isAfter(startDate)) isValid = true;
  } else if (now.isAfter(startDate) && now.isBefore(endDate)) {
    // check both dates
    isValid = true;
  }
  return isValid;
};
