import { config } from '~/services';
import ahApiCall from '~/api/ah/ahApiCall';

const getTemporaryDeliveryChangesOptionsStartDates = async ({
  subscriptionId,
  temporaryDeliveryChangeId,
}) => {
  const url = temporaryDeliveryChangeId
    ? `${config.ahServiceApiUrl}/subscriptions/${subscriptionId}/temporary-delivery-changes/${temporaryDeliveryChangeId}/options/start-dates`
    : `${config.ahServiceApiUrl}/subscriptions/${subscriptionId}/temporary-delivery-changes/options/start-dates`;

  const res = await ahApiCall(url);

  return res;
};

export default getTemporaryDeliveryChangesOptionsStartDates;
