import React, { useState } from 'react';
import { Text } from '@mediahuis/chameleon-react-legacy';

import { BANNER_TYPES } from '~/constants/messages';
import useBanner from '~/context/hooks/useBanner';
import { ts } from '~/services';
import BaseSummaryStep from '~/pages/Holiday/components/DialogContent/Steps/BaseSummaryStep';
import defaultError from '~/utils/defaultError';
import { useGlobalContext } from '~/context';
import postHolidayWebformCase from '~/api/ah/postHolidayWebformCase';
import { HOLIDAY_STEPS } from '~/pages/Holiday/data';
import getAhHolidaySolutionBody from '~/pages/Holiday/utils/ah/getAhHolidaySolutionBody';

const AhWebformSummaryStep = ({
  submissionData,
  setSubmissionData,
  setCurrentStep,
  setIsDialogShown,
  selectedDeliveryOptionBySubscription,
  resetSubmissionData,
}) => {
  const { chosenUserSubscription } = useGlobalContext();
  const { showBanner } = useBanner();
  const [isLoading, setIsLoading] = useState(false);
  const [
    sameShipToSolutionRadioBtnValue,
    setSameShipToSolutionRadioBtnValue,
  ] = useState();
  const [
    sameShipToSolutionRadioBtnErrors,
    setSameShipToSolutionRadioBtnErrors,
  ] = useState(false);

  const submitData = async () => {
    try {
      const isSameShipToSolution = sameShipToSolutionRadioBtnValue === 'Yes';

      if (!sameShipToSolutionRadioBtnValue) {
        setSameShipToSolutionRadioBtnErrors(true);
      } else {
        setIsLoading(true);

        const formattedSolutionBody = getAhHolidaySolutionBody({
          submissionData,
        });

        const shipToCustomerId = chosenUserSubscription.components.find(
          component => component.type.toLowerCase() === 'print',
        )?.shipToCustomerId;

        const body = {
          ...formattedSolutionBody,
          isSameShipToSolution,
          customerId: shipToCustomerId,
          subscriptionBrand: chosenUserSubscription?.mainBrand?.code,
        };

        await postHolidayWebformCase({
          subscriptionId: submissionData?.subscriptionId,
          body,
        });

        setIsDialogShown(false);
        setCurrentStep(HOLIDAY_STEPS.CHOICE_STEP);
        resetSubmissionData();

        showBanner({
          type: BANNER_TYPES.SUCCESS,
          message: <Text>{ts.t('Holiday.SummaryStep.SuccessMessage')}</Text>,
        });
      }
    } catch (err) {
      showBanner({
        message: defaultError,
        type: BANNER_TYPES.ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BaseSummaryStep
      submissionData={submissionData}
      setCurrentStep={setCurrentStep}
      setSubmissionData={setSubmissionData}
      setSameShipToSolutionRadioBtnValue={setSameShipToSolutionRadioBtnValue}
      sameShipToSolutionRadioBtnErrors={sameShipToSolutionRadioBtnErrors}
      sameShipToSolutionRadioBtnValue={sameShipToSolutionRadioBtnValue}
      setSameShipToSolutionRadioBtnErrors={setSameShipToSolutionRadioBtnErrors}
      submitData={submitData}
      isLoading={isLoading}
      selectedDeliveryOptionBySubscription={
        selectedDeliveryOptionBySubscription
      }
      isWebform
    />
  );
};

export default AhWebformSummaryStep;
