import dayjs from 'dayjs';
import { DATE_FORMAT_BE } from '~/constants/date';
import { apiCall } from '~/utils';
import { config } from '~/services';

const postDeliveryComplaint = ({
  chosenOption,
  chosenUserSubscription,
  deliveryComplaintState,
  onError,
  onSuccess,
}) =>
  apiCall(`${config.serviceApiUrl}/DeliveryComplaint`, {
    method: 'POST',
    withCredentials: true,
    params: { 'api-version': '2.0' },
    data: JSON.stringify({
      subscriptionId: chosenUserSubscription.id,
      deliveryDate: dayjs(deliveryComplaintState.deliveryDate).format(
        DATE_FORMAT_BE,
      ),
      complaintType: deliveryComplaintState.chosenComplaint.complaint,
      solution: chosenOption,
      comment: deliveryComplaintState.comment,
    }),
  })
    .then(onSuccess)
    .catch(onError);

export default postDeliveryComplaint;
