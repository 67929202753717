/* eslint-disable babel/camelcase */
export const arrWeekdays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const days = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const arrWeekdaysShortByLanguage = {
  nl: ['ma', 'di', 'wo', 'do', 'vr', 'za', 'zo'],
  de: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
  pt: [
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta-feira',
    'Sábado',
    'Domingo',
  ],
  en: ['mon', 'tues', 'wed', 'thurs', 'fri', 'sat', 'sun'],
};

export const arrHoursShortByLanguage = {
  nl: 'u',
  de: '.',
  pt: 'h',
  en: 'h',
};

export const jsonMainSettings = {
  autoUpdate: {
    active: true,
    interval: 5,
  },
};

export const jsonTitles = {
  gva: {
    chat: {
      chat_link: 'https://livechat.gva.be',
      subscriptionchat_link: 'https://livechat-aboshop.gva.be',
    },
  },
  hbvl: {
    chat: {
      chat_link: 'https://livechat.hbvl.be',
      subscriptionchat_link: 'https://livechat-aboshop.hbvl.be',
    },
  },
  nieuwsblad: {
    chat: {
      chat_link: 'https://livechat.nieuwsblad.be',
      subscriptionchat_link: 'https://livechat-aboshop.nieuwsblad.be',
    },
  },
  standaard: {
    chat: {
      chat_link: 'https://livechat.standaard.be',
      subscriptionchat_link: 'https://livechat-aboshop.standaard.be',
    },
  },
  limburger: {
    chat: {
      chat_link: 'https://livechat.limburger.nl',
      subscriptionchat_link: 'https://livechat-aboshop.limburger.nl',
    },
  },
};
