import { Box, Heading } from '@mediahuis/chameleon-react-legacy';
import React from 'react';
import DragAndDrop from '~/components/DragAndDrop';
import { ts } from '~/services';

export default function StudentUploadDialog({ files, setFiles }) {
  const maxSize = 15 * 1024 * 1024;
  const maxFiles = 2;
  const errorLabels = {
    'file-invalid-type': ts.t('StudentUploadDialog.Errors.FileInvalidType'),
    'file-too-large': ts.t('StudentUploadDialog.Errors.FileTooLarge', {
      values: {
        maxSize: maxSize / 1024 / 1024,
      },
    }),
    'same-file': ts.t('StudentUploadDialog.Errors.SameFile'),
    'too-many-files': ts.t('StudentUploadDialog.Errors.TooManyFiles', {
      values: {
        maxFiles,
      },
    }),
  };
  return (
    <Box>
      <Heading level={1} mb={5} color="primaryBase">
        {ts.t('StudentUploadDialog.Title')}
      </Heading>
      <Heading level={5} color="colorGrey60" fontWeight="regular" mb={4}>
        {ts.t('StudentUploadDialog.Subtitle')}
      </Heading>
      <DragAndDrop
        accept={{
          'image/jpeg': ['.jpeg', '.jpg'],
          'image/png': ['.png'],
          'image/gif': ['.gif'],
          'application/pdf': ['.pdf'],
        }}
        maxSize={maxSize}
        maxFiles={maxFiles}
        files={files}
        setFiles={setFiles}
        errorLabels={errorLabels}
        text={ts.t('DragAndDrop.Text')}
        subText={ts.t('DragAndDrop.SubText')}
      />
    </Box>
  );
}
