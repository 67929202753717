import { AH_SUBSCRIPTION_STATUS_CODES } from '~/constants';
import { config } from '~/services';

const sortedStates = [
  AH_SUBSCRIPTION_STATUS_CODES.ACTIVE,
  AH_SUBSCRIPTION_STATUS_CODES.FUTURE,
  AH_SUBSCRIPTION_STATUS_CODES.CANCELLED,
];

const getSortedUserSubscriptions = userSubscriptionData =>
  userSubscriptionData.sort((x, y) => {
    const xStateIndex = sortedStates.indexOf(x.lifeCycle);
    const yStateIndex = sortedStates.indexOf(y.lifeCycle);
    // Check if states are different
    if (xStateIndex < yStateIndex) {
      return -1; // X state has priority over Y state, put X before Y
    }
    if (yStateIndex < xStateIndex) {
      return 1; // Y state has priority over X state, put Y before X
    }

    // States are equal, check if brand is different
    const xIsCurrentBrand = x.mainBrand.code === config.brand;
    const yIsCurrentBrand = y.mainBrand.code === config.brand;
    if (xIsCurrentBrand && !yIsCurrentBrand) {
      return -1; // X is from the current brand, Y is not, put X before Y
    }
    if (!xIsCurrentBrand && yIsCurrentBrand) {
      return 1; // Y is from the current brand, X is not, put Y before X
    }
    return x.label < y.label ? -1 : 1; // Status and brand are equal, sort by label shown in dropdown
  });

export default getSortedUserSubscriptions;
