import React from 'react';
import { Heading, Flex, Box } from '@mediahuis/chameleon-react-legacy';
import { isDesktop, useResponsive } from '~/utils';
import OverviewLink from '../OverviewLink';
import Wrapper from '../Wrapper';
import Banner from '../Banner';

const Header = ({ heading }) => {
  useResponsive();
  return (
    <React.Fragment>
      <Box my={7} style={{ position: 'relative' }}>
        <Wrapper>
          <Flex alignItems="center" justifyContent="center" space>
            {isDesktop() && <OverviewLink />}
            <Heading level={3}>{heading.toUpperCase()}</Heading>
          </Flex>
        </Wrapper>
      </Box>
      <Wrapper>
        <Banner mb={5} />
      </Wrapper>
    </React.Fragment>
  );
};

export default Header;
