/* eslint-disable babel/camelcase */
import React from 'react';
import { Caption, Divider, Flex } from '@mediahuis/chameleon-react-legacy';
import { config } from '~/services';
import { useGlobalContext } from '~/context';
import { pushTrackingEvent } from '~/utils/tealium';
import { getCancellationReasonCategory } from '~/pages/Subscription/utils/onlineCancellation';

export default function ArticlesList({
  articles = [],
  selectedCancellationReason,
}) {
  const { chosenUserSubscription } = useGlobalContext();

  const AMOUNT_OF_ARTICLES_TO_SHOW = 3;
  const articlesToShow = articles?.slice(0, AMOUNT_OF_ARTICLES_TO_SHOW);

  return (
    <ul>
      {articlesToShow?.map((article, index) => {
        const href = `https://${
          config.siteUrl
        }/cnt/${article?.id?.toLowerCase()}`;
        const imageSrc = `https://static.${config.siteUrl}${article?.image}`;

        const isLast = index === articlesToShow?.length - 1;

        return (
          <li key={article?.id}>
            <a
              href={href}
              onClick={() => {
                pushTrackingEvent(chosenUserSubscription, {
                  tealium_event: 'click_premium_article',
                  cancellation_step: 2,
                  cancellation_reason: selectedCancellationReason?.label,
                  cancellation_reason_id: selectedCancellationReason?.code,
                  cancellation_reason_category: getCancellationReasonCategory(),
                  article_title: article?.title,
                  article_id: article?.id,
                  article_url: href,
                });
              }}
              target="_blank"
              rel="noreferrer"
            >
              <Flex justifyContent="space-between" py={3}>
                <Caption fontFamily="primary" mr={3}>
                  {article?.title}
                </Caption>
                <img
                  src={imageSrc}
                  alt={article?.caption ?? article?.title}
                  height={40}
                  width={40}
                />
              </Flex>
              {!isLast && <Divider />}
            </a>
          </li>
        );
      })}
    </ul>
  );
}
