import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  DatePicker,
  Flex,
  Heading,
  TextField,
  Tooltip,
} from '@mediahuis/chameleon-react-legacy';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import isEmpty from 'lodash.isempty';
import dayjs from 'dayjs';
import addressFieldsValidations from '~/components/AddressFields/addressFieldsValidations';
import AddressFields from '~/components/AddressFields';
import useBanner from '~/context/hooks/useBanner';
import { BANNER_TYPES } from '~/constants/messages';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import { ContentLoader } from '~/components';
import { DIALOG_WIDTH } from '~/constants/style';
import { validateDates } from '~/utils';
import EntitySwitch from '~/components/ah/EntitySwitch';
import AhAddressFields from '~/components/ah/AhAddressFields';
import { ts } from '~/services';
import getAddressOptions from '../../utils/getAddressOptions';
import postAddress from '../../utils/postAddress';
import handleBannerMessage from '../../../../utils/handleBannerMessage';
import { DATE_FORMAT_READABLE } from '../../../../constants';

const AddressDialog = ({
  setDialogShown,
  setNewPayerAddress,
  setNewReceiverAddress,
  isInvoice,
}) => {
  const { showBanner } = useBanner();
  const { chosenUserSubscription } = useGlobalContext();

  const [address, setAddress] = useState({});
  const [addressFields, setAddressFields] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingOnSubmit, setIsLoadingOnSubmit] = useState(false);

  const setAddressState = data => {
    setAddress(prevState => ({
      ...prevState,
      ...data,
    }));
  };

  const getAddressOptionsData = async () => {
    try {
      const data = await getAddressOptions({
        subscriptionId: chosenUserSubscription.id,
      });
      setAddressState({
        options: data,
        date: new Date(data.minimumDate),
      });
      return data;
    } catch (err) {
      handleBannerMessage({ err, showBanner });
      setDialogShown(false);
      return null;
    }
  };

  const updateDialog = () => {
    const newAddress = isInvoice
      ? chosenUserSubscription.payer
      : chosenUserSubscription.receiver;
    setAddressState({ ...newAddress });
    setAddressFields(newAddress.address);
  };

  /**
   * Make sure to fetch the addressOptions for the chosen user and update the dialog data accordingly when a new subscription or address has been chosen
   */
  useEffect(() => {
    setIsLoading(true);
    const fetchAddressOptions = async () => {
      await getAddressOptionsData();
      updateDialog();
      setIsLoading(false);
    };
    fetchAddressOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenUserSubscription, isInvoice]);

  const requestOrigin = 'addresschange';

  const onSubmit = async () => {
    setIsLoadingOnSubmit(true);
    const addressErrors = await addressFieldsValidations(
      addressFields,
      addressFields.countryCode,
      chosenUserSubscription,
      requestOrigin,
    );
    setErrors(addressErrors);
    if (isEmpty(addressErrors)) {
      try {
        const updatedAddress = await postAddress({
          address,
          addressFields,
          chosenUserSubscription,
          isInvoice,
        });
        setIsLoadingOnSubmit(false);
        setDialogShown(false);

        if (isInvoice) {
          setNewPayerAddress(updatedAddress);
        } else {
          setNewReceiverAddress(updatedAddress);
        }

        const addressText = `${addressFields.street} ${
          addressFields.houseNumber
        }${addressFields.box ? `/${addressFields.box}` : ' '} - ${
          addressFields.postalCode
        } ${addressFields.city}`;
        showBanner({
          type: BANNER_TYPES.SUCCESS,
          message: isInvoice
            ? ts.t('Subscription.AddressDialog.SuccessInvoice', {
                richContent: true,
                values: { addressText },
              })
            : ts.t('Subscription.AddressDialog.Success', {
                richContent: true,
                values: {
                  addressText,
                  date: dayjs(updatedAddress.dateFrom).format(
                    DATE_FORMAT_READABLE,
                  ),
                },
              }),
        });
      } catch (err) {
        handleBannerMessage({ err, showBanner });
        setDialogShown(false);
        setIsLoadingOnSubmit(false);
      }
    } else {
      setIsLoadingOnSubmit(false);
    }
  };

  if (isLoading) {
    return (
      <Box width={DIALOG_WIDTH} maxWidth="full">
        <ContentLoader />
      </Box>
    );
  }
  const addressFieldsProps = {
    'data-testid': 'addressFields',
    allowedCountries: address.options.allowedCountries,
    setAddress: setAddressFields,
    setErrors,
    address: addressFields,
    errors,
    internalSubscriptionType: chosenUserSubscription?.internalSubscriptionType,
  };

  return (
    <Box width={DIALOG_WIDTH} maxWidth="full">
      <React.Fragment>
        <Heading level={4} mb={5} color="primaryBase">
          {isInvoice
            ? ts.t('Subscription.EditInvoiceAddress')
            : ts.t('Subscription.EditDeliveryAddress')}
        </Heading>
        <EntitySwitch
          defaultComponent={<AddressFields {...addressFieldsProps} />}
          mhlu={
            <AhAddressFields
              {...addressFieldsProps}
              isChangingAddress
              setDialogShown={setDialogShown}
            />
          }
        />
        <TextField
          id="extra-textfield"
          label={
            <React.Fragment>
              <span style={{ marginRight: '2px' }}>
                {ts.t('Subscription.AddressDialog.ExtraAddressField.Label')}
              </span>
              <Tooltip
                id="extraInfo"
                label={ts.t(
                  'Subscription.AddressDialog.ExtraAddressField.Tooltip',
                )}
                usePortal
              />
            </React.Fragment>
          }
          mb={3}
          name="extra"
          maxLength={60}
          value={addressFields.extra}
          onChange={e => {
            const value = e.target.value;
            return setAddressFields(prevState => ({
              ...prevState,
              extra: value,
            }));
          }}
        />
        {!isInvoice && (
          <DatePicker
            pickerInputOnly
            mb={5}
            id="date"
            data-testid="date"
            label={ts.t('Subscription.AddressDialog.Date')}
            onChange={(value, meta) => {
              setAddressState({ date: value });
              const fields = [{ id: 'date', value, meta }];
              setErrors(validateDates({ errors, fields }));
            }}
            error={errors.date}
            message={errors.date}
            value={address.date}
            disabledDays={[
              {
                before: new Date(address.options.minimumDate),
                after: new Date(address.options.maximumDate),
              },
            ]}
          />
        )}
        <Flex justifyContent="flex-end">
          <Button
            mt={5}
            onClick={onSubmit}
            data-testid="submit"
            iconRight={ChevronForward}
            disabled={!isEmpty(errors)}
            loading={isLoadingOnSubmit}
          >
            {ts.t('Common.Confirm')}
          </Button>
        </Flex>
      </React.Fragment>
    </Box>
  );
};
export default AddressDialog;
