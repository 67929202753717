/* eslint-disable no-lonely-if */
const setHolidayArray = ({ response, id, holidays, setHolidayState }) => {
  if (id) {
    const index = holidays.findIndex(x => x.id === id);
    const holidayArray = [...holidays];
    holidayArray[index] = response;
    setHolidayState({ holidays: holidayArray });
  } else {
    if (holidays) {
      setHolidayState({ holidays: [...holidays, response] });
    } else {
      setHolidayState({ holidays: [response] });
    }
  }
};

export default setHolidayArray;
