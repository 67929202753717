const setBold = (strText, isLink = true) => {
  let strConcatenateText = '';

  if (strText) {
    const arrTextArr = strText.split('~');

    if (arrTextArr.length > 0) {
      arrTextArr.forEach((arrText, iKey) => {
        if (iKey % 2 === 1) {
          if (isLink)
            strConcatenateText += `<span class="boldCustomerServiceLink">${arrText}</span>`;
          else
            strConcatenateText += `<span class="boldCustomerText">${arrText}</span>`;
        } else {
          strConcatenateText += arrText;
        }
      });
    }
  }

  return strConcatenateText;
};

export default setBold;
