import React from 'react';
import styled from 'styled-components';
import {
  colorPrimaryBase,
  colorTextPrimaryBase,
  fontFamilySystem,
  colorPrimary20,
} from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';

const Item = styled.li`
  padding: 8px 16px;
  border-radius: 0px 50px 50px 0px;
  font-family: ${fontFamilySystem};
  font-size: 14px;
  &:hover {
    background-color: ${colorPrimary20};
    cursor: pointer;
  }
  ${props =>
    props.active &&
    `
    color: ${colorTextPrimaryBase};
    background-color: ${colorPrimaryBase};
    &:hover {
      background-color: ${colorPrimaryBase};
    }
  `}
`;

const ListItem = ({ children, onClick, id, active }) => (
  <Item onClick={onClick} role="presentation" id={id} active={active}>
    {children}
  </Item>
);
export default ListItem;
