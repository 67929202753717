import React, { useState } from 'react';
import useBanner from '~/context/hooks/useBanner';
import { BANNER_TYPES } from '~/constants/messages';
import useHolidayContext from '~/context/hooks/useHolidayContext';
import { ts } from '~/services';
import BaseCancelDialog from '~/pages/Holiday/components/DialogContent/BaseCancelDialog';
import getHolidayArrangementRules from '../../utils/getHolidayArrangementRules';
import handleBannerMessage from '../../../../utils/handleBannerMessage';

const CancelDialog = ({ isDialogShown, setIsDialogShown, chosenHoliday }) => {
  const holidayContext = useHolidayContext();
  const { holidays, setHolidayState } = holidayContext;
  const [submissionData, setSubmissionData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { showBanner } = useBanner();

  const onCancel = () => {
    setIsLoading(true);
    getHolidayArrangementRules({
      id: submissionData?.id,
      method: 'DELETE',
    })
      .then(() => {
        const currentHolidays = [...holidays];
        const newHolidays = currentHolidays.filter(
          holiday => holiday.id !== submissionData?.id,
        );
        setHolidayState({ holidays: newHolidays });
        return null;
      })
      .then(() => {
        setIsDialogShown(false);
        setIsLoading(false);
        return showBanner({
          type: BANNER_TYPES.SUCCESS,
          message: ts.t('Holiday.CancelDialog.SuccessMessage', {
            richContent: true,
          }),
        });
      })
      .catch(err => {
        handleBannerMessage({ err, showBanner });
        setIsLoading(false);
        return setIsDialogShown(false);
      });
  };

  return (
    <BaseCancelDialog
      isDialogShown={isDialogShown}
      chosenHoliday={chosenHoliday}
      isLoading={isLoading}
      onCancel={onCancel}
      submissionData={submissionData}
      setSubmissionData={setSubmissionData}
    />
  );
};

export default CancelDialog;
