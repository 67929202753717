import { AH_HOLIDAY_SOLUTIONS } from '~/pages/Holiday/data';

export function getVacationSolution(types) {
  if (types.includes(AH_HOLIDAY_SOLUTIONS.CONTINUE_DIGITAL)) {
    return AH_HOLIDAY_SOLUTIONS.CONTINUE_DIGITAL;
  }
  if (types.includes(AH_HOLIDAY_SOLUTIONS.DISCONTINUE_DIGITAL)) {
    return AH_HOLIDAY_SOLUTIONS.DISCONTINUE_DIGITAL;
  }
  if (types.includes(AH_HOLIDAY_SOLUTIONS.HOLIDAY_ADDRESS)) {
    return AH_HOLIDAY_SOLUTIONS.HOLIDAY_ADDRESS;
  }
  if (types.includes(AH_HOLIDAY_SOLUTIONS.FAMILY_ADDRESS)) {
    return AH_HOLIDAY_SOLUTIONS.FAMILY_ADDRESS;
  }
  if (types.includes(AH_HOLIDAY_SOLUTIONS.CHARITY)) {
    return AH_HOLIDAY_SOLUTIONS.CHARITY;
  }
  return '';
}
