import React, { useState } from 'react';
import useBanner from '~/context/hooks/useBanner';
import { BANNER_TYPES } from '~/constants/messages';
import useHolidayContext from '~/context/hooks/useHolidayContext';
import { ts } from '~/services';
import BaseCancelDialog from '~/pages/Holiday/components/DialogContent/BaseCancelDialog';
import cancelTemporaryDeliveryChange from '~/api/ah/cancelTemporaryDeliveryChange';
import { useGlobalContext } from '~/context';
import getTemporaryDeliveryChanges from '~/api/ah/getTemporaryDeliveryChanges';
import { COULD_NOT_UPDATE_IN_DYNAMICS_ERROR } from '~/constants/aportal';

const AhCancelDialog = ({
  isDialogShown,
  setIsDialogShown,
  chosenHoliday,
  setUpdateHolidayOverviewHasFailed,
}) => {
  const holidayContext = useHolidayContext();
  const globalState = useGlobalContext();
  const { chosenUserSubscription } = globalState;

  const { setHolidayState } = holidayContext;
  const [submissionData, setSubmissionData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { showBanner } = useBanner();

  const onCancel = async () => {
    try {
      try {
        setIsLoading(true);
        await cancelTemporaryDeliveryChange({
          subscriptionId: chosenUserSubscription.id,
          temporaryDeliveryChangeId: submissionData.id,
        });
        showBanner({
          type: BANNER_TYPES.SUCCESS,
          message: ts.t('Holiday.CancelDialog.SuccessMessage', {
            richContent: true,
          }),
        });
      } catch (error) {
        if (error.detail === COULD_NOT_UPDATE_IN_DYNAMICS_ERROR) {
          showBanner({
            message: ts.t('Common.UpdateInDynamicsFailed', {
              richContent: true,
            }),
            type: BANNER_TYPES.WARNING,
          });
        } else {
          throw new Error(error.detail || error.message);
        }
      }

      try {
        const allHolidays = await getTemporaryDeliveryChanges({
          subscriptionId: chosenUserSubscription.id,
        });
        setHolidayState({
          holidays: allHolidays,
        });
      } catch {
        setUpdateHolidayOverviewHasFailed(true);
      }
    } catch (error) {
      showBanner({
        message: ts.t('Holiday.Errors.Cancel'),
        type: BANNER_TYPES.ERROR,
      });
    } finally {
      setIsLoading(false);
      setIsDialogShown(false);
    }
  };
  return (
    <BaseCancelDialog
      isDialogShown={isDialogShown}
      chosenHoliday={chosenHoliday}
      isLoading={isLoading}
      onCancel={onCancel}
      submissionData={submissionData}
      setSubmissionData={setSubmissionData}
    />
  );
};

export default AhCancelDialog;
