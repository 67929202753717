import React from 'react';
import {
  Heading,
  Box,
  Flex,
  useMediaQuery,
} from '@mediahuis/chameleon-react-legacy';
import { space7 } from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';
import { ICON_FILL } from '~/components/SelfHelp/Icon';
import { config, ts } from '~/services';
import Delivery from '../../../svg/Delivery';
import getNotAvailableCopy from '../utils/getNotAvailableCopy';

const NotAvailable = ({ chosenUserSubscription = [] }) => {
  const copy = getNotAvailableCopy({ chosenUserSubscription });
  return (
    <Flex
      alignItems="center"
      flexDirection={useMediaQuery({ md: 'row' }, 'column')}
    >
      <Box
        width={useMediaQuery({ sm: '50%', lg: '75%' }, '75%')}
        mr={useMediaQuery({ md: space7 }, 0)}
        mb={useMediaQuery({ md: 0 }, space7)}
        style={{ fill: ICON_FILL[config.brand] }}
      >
        <Delivery />
      </Box>
      <Box width="100%">
        <Heading level={3} fontFamily="primary" mb={3}>
          {ts.t('DeliveryComplaint.NotAvailable')}
        </Heading>
        {copy}
      </Box>
    </Flex>
  );
};

export default NotAvailable;
