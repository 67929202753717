import ahApiCall from '~/api/ah/ahApiCall';
import { config } from '~/services';

const cancelTemporaryDeliveryChange = async ({
  subscriptionId,
  temporaryDeliveryChangeId,
}) => {
  await ahApiCall(
    `${config.ahServiceApiUrl}/subscriptions/${subscriptionId}/temporary-delivery-changes/${temporaryDeliveryChangeId}/cancel`,
    {
      method: 'POST',
    },
  );
};

export default cancelTemporaryDeliveryChange;
