import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Icon,
  Box,
  WideList,
  WideListItem,
  Flex,
  Text,
} from '@mediahuis/chameleon-react-legacy';
import {
  ChevronBack,
  ChevronForward,
  Close,
  Menu as Burger,
} from '@mediahuis/chameleon-theme-wl/legacy/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Image from '~/components/Image';
import { authService, config } from '~/services';
import { HEADER_HEIGHT } from '~/styles';
import { goToBrandUrl, getBrandLogoUrl } from '~/utils';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import { links } from './data/index';

const WideListItemWithBorder = styled(WideListItem)`
  border-bottom: 1px solid #f5f5f5;
`;

const WideListNoShadow = styled(WideList)`
  box-shadow: none;
`;

const Menu = styled(Box)`
  position: fixed;
  top: ${HEADER_HEIGHT};
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  transition: transform 0.2s ease;
  transform: ${props => (props.open ? '0' : 'translateX(100vw)')};
`;

const WideListBottom = styled(WideListNoShadow)`
  position: fixed;
  width: 100vw;
  bottom: 0;
`;

const MobileNavigation = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { userInfo } = useGlobalContext();
  const [open, setOpen] = useState(false);
  const brandLogoUrl = getBrandLogoUrl();

  const toggleMenu = isOpen => {
    setOpen(isOpen);
    /* use mobile-nav class to toggle scroll block of body */
    if (isOpen) {
      document.body.classList.add('mobile-nav');
    } else {
      document.body.classList.remove('mobile-nav');
    }
  };

  return (
    <React.Fragment>
      <Flex alignItems="center" justifyContent="space-between">
        <Link
          onClick={() => {
            toggleMenu(false);
            history.push('/');
          }}
        >
          {pathname !== '/' ? (
            <Icon as={ChevronBack} size="medium" />
          ) : (
            <Box width="24px" />
          )}
        </Link>
        <Image
          height={10}
          alt={config.siteName}
          src={brandLogoUrl}
          onClick={goToBrandUrl}
        />
        <Icon
          as={open ? Close : Burger}
          size="medium"
          onClick={() => toggleMenu(!open)}
        />
      </Flex>
      <Menu open={open} bgColor="whiteBase">
        {(userInfo.firstName || userInfo.name) && (
          <Box p={5} my={2}>
            <Text
              fontFamily="system"
              fontSize
              fontWeight="bold"
              color="colorTextPrimary"
            >
              {userInfo.firstName} {userInfo.name}
            </Text>
          </Box>
        )}
        <WideListNoShadow>
          {links
            .filter(link => !link.selfHelpOnly)
            .map(link => (
              <Link
                key={link.value}
                onClick={() => {
                  if (link.external) {
                    window.location.href = link.url;
                  } else {
                    history.push(link.url);
                  }
                }}
              >
                <WideListItemWithBorder
                  title={link.value}
                  clickable
                  iconRight={ChevronForward}
                  onClick={() => toggleMenu(false)}
                />
              </Link>
            ))}
        </WideListNoShadow>
        {userInfo?.accountGuid && (
          <WideListBottom>
            <WideListItem
              title="Afmelden"
              clickable
              iconRight={ChevronForward}
              onClick={authService.logout}
            />
          </WideListBottom>
        )}
      </Menu>
    </React.Fragment>
  );
};

export default MobileNavigation;
