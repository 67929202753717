import React from 'react';
import styled from 'styled-components';
import { Box as ChBox } from '@mediahuis/chameleon-react-legacy/';

const Box = styled(ChBox)`
  width: 100%;
  max-width: 1000px;
  position: relative;
  && {
    margin: 0 auto;
  }
`;

const Wrapper = ({ children, id }) => (
  <Box id={id} px={5}>
    {children}
  </Box>
);

export default Wrapper;
