import { ts } from '~/services';
import { PAYMENT_STATUSES } from './payment';
import defaultError from '../utils/defaultError';

export const BANNER_TYPES = {
  INFO: 'info',
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export const MOLLIE_ERRORS = Object.freeze({
  CARDHOLDER: ts.t('Messages.MollieErrors.CARDHOLDER'),
});

export const MOLLIE_BANNER_TYPES = Object.freeze({
  [PAYMENT_STATUSES.PAID]: BANNER_TYPES.SUCCESS,
  [PAYMENT_STATUSES.FAILED]: BANNER_TYPES.ERROR,
  [PAYMENT_STATUSES.EXPIRED]: BANNER_TYPES.INFO,
  [PAYMENT_STATUSES.CANCELED]: BANNER_TYPES.INFO,
});

export const MOLLIE_BANNER_MESSAGES = Object.freeze({
  [PAYMENT_STATUSES.PAID]: ts.t('Messages.MollieBannerMessages.Paid'),
  [PAYMENT_STATUSES.FAILED]: ts.t('Messages.MollieBannerMessages.Failed'),
  [PAYMENT_STATUSES.EXPIRED]: ts.t('Messages.MollieBannerMessages.Expired'),
  [PAYMENT_STATUSES.CANCELED]: ts.t('Messages.MollieBannerMessages.Canceled'),
});

export const MESSAGES = {
  WRONG_SUBSCRIPTION: ts.t('Messages.WrongSubscription'),
};

export const ERRORS = {
  EMPTYREQBODY: defaultError,
  ENDBEFORESTART: ts.t('Messages.Errors.ENDBEFORESTART'),
  ERRPARSINGENDDATE: ts.t('Messages.Errors.ERRPARSINGENDDATE'),
  ERRPARSINGSTARTDATE: ts.t('Messages.Errors.ERRPARSINGSTARTDATE'),
  NOACPOSSIBLE: ts.t('Messages.Errors.NOACPOSSIBLE'),
  NODCPOSSIBLE: ts.t('Messages.Errors.NODCPOSSIBLE'),
  NODELIVDATES: ts.t('Messages.Errors.NODELIVDATES'),
  NOMATCHINGCUSTID: ts.t('Messages.Errors.NOMATCHINGCUSTID'),
  NOTALLOWEDCOUNTRY: ts.t('Messages.Errors.NOTALLOWEDCOUNTRY'),
  NOTEXISTINGSUB: ts.t('Messages.Errors.NOTEXISTINGSUB'),
  NOTPOSSIBLEINVACDATE: ts.t('Messages.Errors.NOTPOSSIBLEINVACDATE'),
  NOVACPOSSIBLE: ts.t('Messages.Errors.NOVACPOSSIBLE'),
  NOVALIDCOMPTYPE: ts.t('Messages.Errors.NOVALIDCOMPTYPE'),
  NOVALIDDELIVDATE: ts.t('Messages.Errors.NOVALIDDELIVDATE'),
  NOVALIDDELIVTIME: ts.t('Messages.Errors.NOVALIDDELIVTIME'),
  NOVALIDRESOLFORCOMPTYPE: ts.t('Messages.Errors.NOVALIDRESOLFORCOMPTYPE'),
  NOVALIDVACEND: ts.t('Messages.Errors.NOVALIDVACEND'),
  NOVALIDVACSOL: ts.t('Messages.Errors.NOVALIDVACSOL'),
  NOVALIDVACSTART: ts.t('Messages.Errors.NOVALIDVACSTART'),
  REQADDR: ts.t('Messages.Errors.REQADDR'),
  REQCITY: ts.t('Messages.Errors.REQCITY'),
  REQCOMPTYPE: ts.t('Messages.Errors.REQCOMPTYPE'),
  REQCOUNTRY: ts.t('Messages.Errors.REQCOUNTRY'),
  REQCUSTID: ts.t('Messages.Errors.REQCUSTID'),
  REQDELIVACDATE: ts.t('Messages.Errors.REQDELIVACDATE'),
  REQDELIVDATE: ts.t('Messages.Errors.REQDELIVDATE'),
  REQENDDATE: ts.t('Messages.Errors.REQENDDATE'),
  REQHOUSENR: ts.t('Messages.Errors.REQHOUSENR'),
  REQPOSTALCODE: ts.t('Messages.Errors.REQPOSTALCODE'),
  REQSOL: ts.t('Messages.Errors.REQSOL'),
  REQSTARTDATE: ts.t('Messages.Errors.REQSTARTDATE'),
  REQSTREET: ts.t('Messages.Errors.REQSTREET'),
  REQSUBID: ts.t('Messages.Errors.REQSUBID'),
  TOOLATEAC: ts.t('Messages.Errors.TOOLATEAC'),
  TOOLONGVAC: ts.t('Messages.Errors.TOOLONGVAC'),
  TOOSHORTVAC: ts.t('Messages.Errors.TOOSHORTVAC'),
  TOOSOONAC: ts.t('Messages.Errors.TOOSOONAC'),
  TOOSOONVAC: ts.t('Messages.Errors.TOOSOONVAC'),
  NOTCONFIRMEDVAC: ts.t('Messages.Errors.NOTCONFIRMEDVAC'),
  NOVACLNKFOUND: ts.t('Messages.Errors.NOVACLNKFOUND'),
  NOVACCASEFOUND: ts.t('Messages.Errors.NOVACCASEFOUND'),
  VACCANNOTMODIF: ts.t('Messages.Errors.VACCANNOTMODIF'),
  NOVALIDVACPARAMS: defaultError,
  InvalidIban: ts.t('Messages.Errors.InvalidIban'),
  NOT_VALID_DATE: ts.t('Messages.Errors.NOT_VALID_DATE'),
  SELECTED_DATE_NOT_ALLOWED: ts.t('Messages.Errors.SELECTED_DATE_NOT_ALLOWED'),
  NO_END_DATES: ts.t('Messages.Errors.NO_END_DATES'),
  FETCHING_POSTALCODES: ts.t('Messages.Errors.POSTAL_CODE'),
  FETCHING_STREETS: ts.t('Messages.Errors.STREET'),
  FETCHING_HOUSENUMBERS: ts.t('Messages.Errors.HOUSENUMBERS'),
};
