import styled from 'styled-components';
import { Box as ChameleonBox } from '@mediahuis/chameleon-react-legacy';

const Box = styled(ChameleonBox)`
  width: 100%;
  ${props =>
    props.maxWidth &&
    `
    max-width: ${props.maxWidth};
    `}
`;

export default Box;
