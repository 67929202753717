import { Flex, Paragraph, Radio } from '@mediahuis/chameleon-react-legacy';
import {
  colorBlue10,
  colorBlue60,
  colorRed10,
  colorRed30,
} from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';
import styled from 'styled-components';
import { ts } from '~/services';

const RadioButtonsWrapper = styled.div`
  background: ${({ errors }) => (errors ? colorRed10 : colorBlue10)};
  padding-top: 5px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 25px;
  border-radius: 2px;
`;

const SameShipToSolutionRadioBtns = ({
  setSameShipToSolutionRadioBtnValue,
  sameShipToSolutionRadioBtnValue,
  errors,
  setErrors,
}) => {
  const textColor = errors ? '' : colorBlue60;

  return (
    <>
      <RadioButtonsWrapper errors={errors}>
        <Paragraph mt={5} mb={3} style={{ color: textColor }}>
          {ts.t('Holiday.SummaryStep.SameShipToSolutionRadioBtns.Label')}
        </Paragraph>
        <Flex mt={5} style={{ gap: 25 }}>
          <Radio
            name="buggles"
            id="label-yes"
            value="Yes"
            label={ts.t('Holiday.SummaryStep.SameShipToSolutionRadioBtns.Yes')}
            checked={sameShipToSolutionRadioBtnValue === 'Yes'}
            onChange={e => {
              setErrors(false);
              setSameShipToSolutionRadioBtnValue(e?.target?.value);
            }}
          />
          <Radio
            name="buggles"
            id="label-no"
            value="No"
            label={ts.t('Holiday.SummaryStep.SameShipToSolutionRadioBtns.No')}
            checked={sameShipToSolutionRadioBtnValue === 'No'}
            onChange={e => {
              setErrors(false);
              setSameShipToSolutionRadioBtnValue(e?.target?.value);
            }}
          />
        </Flex>
      </RadioButtonsWrapper>
      {errors && (
        <Paragraph mt={3} style={{ color: colorRed30, fontSize: 12 }}>
          {ts.t('Holiday.SummaryStep.SameShipToSolutionRadioBtns.ErrorMessage')}
        </Paragraph>
      )}
    </>
  );
};

export default SameShipToSolutionRadioBtns;
