import { useMediaQuery } from '@mediahuis/chameleon-react-legacy';

let currentBreakpoint;
const SIZES = { desktop: 'desktop', mobile: 'mobile' };

class ResponsiveHelper {
  sizeMap = {
    xs: SIZES.mobile,
    sm: SIZES.mobile,
    md: SIZES.desktop,
    lg: SIZES.desktop,
    xl: SIZES.desktop,
  };
  constructor() {
    currentBreakpoint = 'xs';
  }

  getCurrentBreakpoint = () => {
    return currentBreakpoint;
  };
  setCurrentBreakpoint = breakpoint => {
    currentBreakpoint = breakpoint;
  };
  getValue = values => {
    return values[this.currentSize];
  };
  get currentSize() {
    return this.sizeMap[currentBreakpoint];
  }
  get isMobile() {
    return this.currentSize === SIZES.mobile;
  }
  get isDesktop() {
    return this.currentSize === SIZES.desktop;
  }
}

const helper = Object.freeze(new ResponsiveHelper());
export const useResponsive = () =>
  useMediaQuery(['xs', 'sm', 'md', 'lg', 'xl'], 'xs');
export const setCurrentBreakpoint = helper.setCurrentBreakpoint;
export const getCurrentBreakpoint = helper.getCurrentBreakpoint;
export const isMobile = () => helper.isMobile;
export const isDesktop = () => helper.isDesktop;
export const getResponsiveValue = helper.getValue;
export const getDynamicResponsiveValue = (values, defaultValue = undefined) => {
  return values[currentBreakpoint] || defaultValue;
};
export default helper;
