import {
  Box,
  Button,
  Caption,
  Flex,
  Heading,
  Text,
} from '@mediahuis/chameleon-react-legacy';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import React, { useState } from 'react';
import cancelAddressChange from '~/api/ah/cancelAddressChange';
import getAddressChanges from '~/api/ah/getAddressChanges';
import getFirstUpcomingAddressChange from '~/components/ah/AhSubscriptionSelect/util/getFirstUpcomingAddressChange';
import { BANNER_TYPES } from '~/constants';
import { useBanner, useGlobalContext } from '~/context';
import { cleanDate } from '~/pages/Holiday/utils/cleanHolidayData';
import { getAddressField } from '~/pages/Subscription/ah/utils/fields';
import { ts } from '~/services';

export default function CancelAddressChangeDialog({
  selectedAddressChangeToCancel,
  setDialogShown,
  setNewReceiverAddress,
  setNewPayerAddress,
  setRefreshAddressChangesHasFailed,
  isPayer,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { showBanner } = useBanner();
  const { chosenUserSubscription } = useGlobalContext();

  const onCancel = async () => {
    try {
      setIsLoading(true);
      const customerId = isPayer
        ? chosenUserSubscription.payer.id
        : chosenUserSubscription.receiver.id;
      await cancelAddressChange({
        customerId,
        addressChangeId: selectedAddressChangeToCancel.addressChangeId,
      });

      showBanner({
        type: BANNER_TYPES.SUCCESS,
        message: ts.t('Subscription.CancelAddressChangeDialog.SuccessMessage'),
      });
      try {
        const data = await getAddressChanges({
          customerId,
        });
        if (isPayer) {
          setNewPayerAddress(getFirstUpcomingAddressChange(data));
        } else {
          setNewReceiverAddress(getFirstUpcomingAddressChange(data));
        }
      } catch {
        setRefreshAddressChangesHasFailed(true);
      }
    } catch (error) {
      showBanner({
        message: ts.t('Subscription.CancelAddressChangeDialog.Errors.Cancel'),
        type: BANNER_TYPES.ERROR,
      });
    } finally {
      setIsLoading(false);
      setDialogShown(false);
    }
  };
  return (
    <>
      <Heading level={3} mb={5} fontFamily="primary" color="colorPrimaryBase">
        {ts.t('Subscription.CancelAddressChangeDialog.Heading')}
      </Heading>
      <Box mb={7}>
        <Text fontFamily="primary" size="Heading4" fontWeight="medium">
          {ts.t('Subscription.CancelAddressChangeDialog.SubHeading')}
        </Text>
      </Box>
      <Flex pb={7}>
        <Box mr={7}>
          <Box pb={4}>
            <Text fontFamily="primary" size="caption1">
              {ts.t('Subscription.CancelAddressChangeDialog.StartDate')}
            </Text>
          </Box>
          {selectedAddressChangeToCancel && (
            <Caption color="colorGrey60">
              {cleanDate(selectedAddressChangeToCancel.startDate)}
            </Caption>
          )}
        </Box>
        <Box mr={7}>
          <Box pb={4}>
            <Text fontFamily="primary" size="caption1">
              {ts.t('Subscription.CancelAddressChangeDialog.Address')}
            </Text>
          </Box>
          {selectedAddressChangeToCancel && (
            <Caption color="colorGrey60">
              {getAddressField(
                selectedAddressChangeToCancel?.addresses?.nonStandard?.[0],
              )}
            </Caption>
          )}
        </Box>
      </Flex>
      <Flex justifyContent="flex-end">
        <Button
          onClick={onCancel}
          loading={isLoading}
          iconRight={ChevronForward}
        >
          {ts.t('Common.Confirm')}
        </Button>
      </Flex>
    </>
  );
}
