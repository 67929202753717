const sortHolidays = (data, direction) => {
  return data?.sort?.((a, b) => {
    if (direction === 'desc') {
      return a.startDate - b.startDate;
    }
    return b.startDate - a.startDate;
  });
};

export default sortHolidays;
