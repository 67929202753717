/* eslint-disable babel/camelcase */
import {
  Box,
  Button,
  Flex,
  Heading,
  LinkText,
  List,
  ListItem,
  Text,
} from '@mediahuis/chameleon-react-legacy';
import React, { useEffect } from 'react';
import { useGlobalContext } from '~/context';
import StepFourAndFiveIcon from '~/pages/Subscription/components/DialogContent/OnlineCancellation/StepFourAndFiveIcon';
import {
  CANCELLATION_STEPS,
  FLOW_TYPES,
} from '~/pages/Subscription/constants/onlineCancellation';
import { getCancellationReasonCategory } from '~/pages/Subscription/utils/onlineCancellation';
import { isMobile } from '~/utils';
import { pushTrackingEvent } from '~/utils/tealium';

export default function OverviewOfSelectedAlternativeOffer({
  selectedAlternativeOffer,
  handleSubmit,
  isSubmitting,
  error,
  selectedCancellationReason,
}) {
  const {
    confirmationTitle,
    confirmationItems,
    trackingData,
  } = selectedAlternativeOffer;
  const { chosenUserSubscription } = useGlobalContext();
  const defaultTrackingParams = {
    cancellation_step: 4,
    cancellation_reason: selectedCancellationReason?.label,
    cancellation_reason_id: selectedCancellationReason?.code,
    cancellation_reason_category: getCancellationReasonCategory(),
  };
  useEffect(() => {
    pushTrackingEvent(chosenUserSubscription, {
      ...defaultTrackingParams,
      tealium_event: 'view_confirm_offer_screen',
      offer: [trackingData],
    });
  }, []);

  return (
    <Flex flexDirection="column">
      <Flex justifyContent="center" mb={7}>
        <StepFourAndFiveIcon
          width={isMobile() ? 143 : 180}
          height={isMobile() ? 130 : 163}
        />
      </Flex>
      <Heading level={3} mb={6} fontFamily="primary">
        Fijn dat je bij ons wil blijven.
      </Heading>
      {error}
      <Heading level={4} mb={5}>
        {confirmationTitle}
      </Heading>
      <List mb={6}>
        {confirmationItems.map(confirmationItem => {
          return (
            <ListItem key={confirmationItem}>
              <Text size="Heading5" fontWeight="medium" fontFamily="primary">
                {confirmationItem}
              </Text>
            </ListItem>
          );
        })}
      </List>
      <Box mb={2}>
        <Text size="Caption1" fontFamily="primary">
          *Je domiciliëring wordt automatisch aangepast aan dit nieuwe bedrag de
          volgende keer dat de betaling wordt aangeboden aan je bank.
        </Text>
      </Box>
      {selectedAlternativeOffer.highlight ? (
        <>
          <Box mb={2}>
            <Text size="Caption1" fontFamily="primary">
              Dit aanbod is niet cumuleerbaar met andere aanbiedingen.
            </Text>
          </Box>
          <Box mb={6}>
            <Text size="Caption1" fontFamily="primary">
              Je kan slechts eenmaal intekenen op deze korting.
            </Text>
          </Box>{' '}
        </>
      ) : (
        <Box mb={6}>
          <Text size="Caption1" fontFamily="primary">
            Van zodra de volgende betaling in orde is, wordt je abonnement
            effectief aangepast. Tot dan behoud je je huidige abonnement.{' '}
          </Text>
        </Box>
      )}
      <Button
        appearance="primary"
        onClick={() => {
          handleSubmit(
            CANCELLATION_STEPS.FIFTH_STEP,
            FLOW_TYPES.CONVINCED_TO_STAY,
          );
          pushTrackingEvent(chosenUserSubscription, {
            ...defaultTrackingParams,
            tealium_event: 'click_confirm_offer',
            offer: [trackingData],
          });
        }}
        loading={isSubmitting}
      >
        Bevestigen
      </Button>
      <Flex justifyContent="center" mt={6}>
        <LinkText
          href="https://www.mediahuis.be/nl/voorwaarden/abonnementsvoorwaarden/"
          branded
          target="_blank"
        >
          Abonnementsvoorwaarden
        </LinkText>
      </Flex>
    </Flex>
  );
}
