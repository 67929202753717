/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import useHolidayContext from '~/context/hooks/useHolidayContext';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import { HOLIDAY_STEPS, UPDATE_POSSIBILITIES } from '~/pages/Holiday/data';
import getEmptySolutionData from '~/pages/Holiday/utils/getEmptySolutionData';
import AhChoiceStep from '~/pages/Holiday/components/ah/AhChoiceStep';
import getStartOrEndDate from '~/pages/Holiday/utils/ah/getStartOrEndDate';
import getTemporaryDeliveryChangesOptionsStartDates from '~/api/ah/getTemporaryDeliveryChangesOptionsStartDates';
import getTemporaryDeliveryChangesOptionsEndDates from '~/api/ah/getTemporaryDeliveryChangesOptionsEndDates';
import AhSummaryStep from '~/pages/Holiday/components/ah/AhSummaryStep';
import AhAddressStep from '~/pages/Holiday/components/ah/AhAddressStep';
import AhAddressChoiceStep from '~/pages/Holiday/components/ah/AhAddressChoiceStep';
import { mapHolidayToSolutionData } from '~/pages/Holiday/utils/ah/mapHolidayData';
import { BANNER_TYPES } from '~/constants';
import { useBanner } from '~/context';
import { Flex, Loader } from '@mediahuis/chameleon-react-legacy';
import { getVacationSolution } from '~/pages/Holiday/utils/ah/getVacationSolution';
import { ts } from '~/services';
import getTemporaryDeliveryChangesOptionsTypes from '~/api/ah/getTemporaryDeliveryChangesOptionsType';

const AhHolidayDialog = ({
  isDialogShown,
  setIsDialogShown,
  chosenHoliday,
  currentStep,
  setCurrentStep,
  setUpdateHolidayOverviewHasFailed,
}) => {
  const globalState = useGlobalContext();
  const holidayState = useHolidayContext();
  const { holidays, setHolidayState } = holidayState;
  const { chosenUserSubscription } = globalState;
  const defaultState = {
    id: '',
    start: undefined,
    end: undefined,
    solutionData: getEmptySolutionData(false),
    subscriptionId: chosenUserSubscription.id,
    state: '',
    vacationSolution: null,
  };
  const [submissionData, setSubmissionData] = useState(defaultState);
  const [isLoadingStartAndEndDates, setIsLoadingStartAndEndDates] = useState(
    false,
  );
  const [
    isLoadingUpdateStartAndEndDates,
    setIsLoadingUpdateStartAndEndDates,
  ] = useState(false);
  const [
    isLoadingHolidaysOptionsTypes,
    setIsLoadingHolidaysOptionsTypes,
  ] = useState(true);

  const [
    selectedDeliveryOptionBySubscription,
    setSelectedDeliveryOptionBySubscription,
  ] = useState(null);
  const [errors, setErrors] = useState({});
  const { showBanner } = useBanner();

  const subscriptionId = chosenUserSubscription?.id;

  const getStartAndEndDates = async () => {
    if (!isDialogShown) {
      return;
    }
    setIsLoadingStartAndEndDates(true);
    const startDatesReponse = await getTemporaryDeliveryChangesOptionsStartDates(
      {
        subscriptionId,
      },
    );

    const defaultStartDate = getStartOrEndDate(startDatesReponse.dates);
    const endDatesResponse = await getTemporaryDeliveryChangesOptionsEndDates({
      subscriptionId,
      startDate: defaultStartDate,
    });
    setHolidayState({
      startDates: startDatesReponse.dates,
      endDates: endDatesResponse.dates,
    });
    setSubmissionData(prevState => ({
      ...prevState,
      start: new Date(getStartOrEndDate(startDatesReponse.dates)),
    }));
  };

  const getUpdateStartAndEndDates = async selectedHoliday => {
    if (!isDialogShown) {
      return;
    }
    setIsLoadingUpdateStartAndEndDates(true);
    const holidayIsPartiallyUpdateable =
      selectedHoliday.updatePossibility === UPDATE_POSSIBILITIES.PARTIALLY;

    let startDatesReponse = {};
    if (!holidayIsPartiallyUpdateable) {
      startDatesReponse = await getTemporaryDeliveryChangesOptionsStartDates({
        subscriptionId: chosenUserSubscription.id,
        temporaryDeliveryChangeId: selectedHoliday.id,
      });
    }

    const endDatesResponse = await getTemporaryDeliveryChangesOptionsEndDates({
      subscriptionId: chosenUserSubscription.id,
      ...(!holidayIsPartiallyUpdateable && {
        startDate: selectedHoliday.startDate,
      }),
      temporaryDeliveryChangeId: selectedHoliday.id,
    });

    setHolidayState({
      startDates: startDatesReponse.dates,
      endDates: endDatesResponse.dates,
    });

    const shouldClearStartDate =
      !startDatesReponse.dates?.[selectedHoliday.startDate]?.selectable &&
      !holidayIsPartiallyUpdateable;
    const shouldClearEndDate = !endDatesResponse.dates?.[
      selectedHoliday.endDate
    ]?.selectable;

    setSubmissionData(prevState => ({
      ...prevState,
      start: shouldClearStartDate ? undefined : prevState.start,
      end: shouldClearEndDate ? undefined : prevState.end,
    }));
  };

  const fetchHolidayOptionsTypesAndDates = async () => {
    try {
      let resolutions = [];
      if (isDialogShown) {
        setIsLoadingHolidaysOptionsTypes(true);
        const { types } = await getTemporaryDeliveryChangesOptionsTypes({
          subscriptionId: chosenUserSubscription?.id,
        });
        setHolidayState({
          holidayArrangementOptions: {
            resolutions: types,
          },
        });
        resolutions = types;
      }

      if (chosenHoliday !== null) {
        const holiday = holidays[chosenHoliday];
        setSubmissionData({
          ...defaultState,
          ...holiday,
          vacationSolution: holiday.type.code,
          start: new Date(holiday.startDate),
          end: new Date(holiday.endDate),
          solutionData: mapHolidayToSolutionData(holiday),
        });
        await getUpdateStartAndEndDates(holiday);
      } else {
        setSubmissionData({
          ...defaultState,
          vacationSolution: getVacationSolution(resolutions),
        });
        await getStartAndEndDates();
      }
    } catch (error) {
      showBanner({
        message: ts.t('Holiday.Errors.OptionsTypesAndDates'),
        type: BANNER_TYPES.ERROR,
      });
      setIsDialogShown(false);
    } finally {
      setIsLoadingHolidaysOptionsTypes(false);
      setIsLoadingStartAndEndDates(false);
      setIsLoadingUpdateStartAndEndDates(false);
    }
  };
  useEffect(() => {
    fetchHolidayOptionsTypesAndDates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenHoliday, isDialogShown]);

  if (
    isLoadingStartAndEndDates ||
    isLoadingUpdateStartAndEndDates ||
    isLoadingHolidaysOptionsTypes
  ) {
    return (
      <Flex justifyContent="center">
        <Loader />
      </Flex>
    );
  }
  return (
    <React.Fragment>
      {currentStep === HOLIDAY_STEPS.CHOICE_STEP && (
        <AhChoiceStep
          submissionData={submissionData}
          setSubmissionData={setSubmissionData}
          setCurrentStep={setCurrentStep}
          isDialogShown={isDialogShown}
          chosenHoliday={chosenHoliday}
          isLoadingUpdateStartAndEndDates={isLoadingUpdateStartAndEndDates}
          errors={errors}
          setErrors={setErrors}
        />
      )}
      {currentStep === HOLIDAY_STEPS.ADDRESS_CHOICE_STEP && (
        <AhAddressChoiceStep
          submissionData={submissionData}
          setSubmissionData={setSubmissionData}
          setCurrentStep={setCurrentStep}
        />
      )}
      {currentStep === HOLIDAY_STEPS.ADDRESS_STEP && (
        <AhAddressStep
          submissionData={submissionData}
          setSubmissionData={setSubmissionData}
          setCurrentStep={setCurrentStep}
          selectedDeliveryOptionBySubscription={
            selectedDeliveryOptionBySubscription
          }
          setSelectedDeliveryOptionBySubscription={
            setSelectedDeliveryOptionBySubscription
          }
          setIsDialogShown={setIsDialogShown}
        />
      )}
      {currentStep === HOLIDAY_STEPS.SUMMARY_STEP && (
        <AhSummaryStep
          submissionData={submissionData}
          setCurrentStep={setCurrentStep}
          setIsDialogShown={setIsDialogShown}
          selectedDeliveryOptionBySubscription={
            selectedDeliveryOptionBySubscription
          }
          setUpdateHolidayOverviewHasFailed={setUpdateHolidayOverviewHasFailed}
        />
      )}
    </React.Fragment>
  );
};

export default AhHolidayDialog;
