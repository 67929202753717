import {
  DESKTOP,
  DE_LIMBURGER_DIGITAL_SUB_CATEGORY,
  MOBILE,
  OTHER_OS,
  TABLET,
  UNKNOWN_DEVICE,
  UNKNOWN_OS,
} from '~/pages/Contact/constants';
import { ts } from '~/services';
import {
  osName,
  osVersion as operatingSystemVersion,
  browserName,
  fullBrowserVersion,
  isMobileOnly,
  isDesktop,
  isTablet,
} from 'react-device-detect';

export const handleSelectChange = ({ e, setState }) => {
  const value = Number(e.target.value);
  const id = e.target.id;
  return setState(prevState => ({ ...prevState, [id]: value }));
};

export const handleCheckBoxChange = ({ e, setState }) => {
  const isChecked = e.target.checked;
  const id = e.target.id;
  return setState(prevState => ({ ...prevState, [id]: isChecked }));
};

export const validateContactFormFields = ({
  firstName,
  lastName,
  email,
  gender,
  phone,
  description,
}) => {
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  let errors = {};
  if (firstName != null && !firstName?.length) {
    errors = {
      ...errors,
      firstName: ts.t('Contact.fields.FirstNameRequired'),
    };
  }

  if (lastName != null && !lastName?.length) {
    errors = {
      ...errors,
      lastName: ts.t('Contact.fields.LastNameNameRequired'),
    };
  }
  if (email != null && !email?.length) {
    errors = {
      ...errors,
      email: ts.t('Contact.fields.EmailRequired'),
    };
  }
  if (email && !emailRegex.test(email)) {
    errors = {
      ...errors,
      email: ts.t('Contact.fields.EmailIncorrect'),
    };
  }
  if (gender != null && !gender?.length) {
    errors = {
      ...errors,
      gender: ts.t('Contact.fields.GenderRequired'),
    };
  }
  if (phone != null && !phone?.length) {
    errors = {
      ...errors,
      phone: ts.t('Contact.fields.PhoneNumberRequired'),
    };
  }
  if (description != null && !description?.length) {
    errors = {
      ...errors,
      description: ts.t('Contact.fields.DescriptionRequired'),
    };
  }

  return errors;
};

export const getAllFaqArticles = faqCategories => {
  return faqCategories
    .map(faqCategory => [
      ...faqCategory.knowledgeArticles,
      ...faqCategory.subCategories.map(
        subCategory => subCategory.knowledgeArticles,
      ),
    ])
    .flat(Infinity);
};
export const filterArticlesByTag = ({ articles, tag }) =>
  articles.filter(article => article.tags.includes(tag));

export const getCurrentDeviceType = () => {
  if (isMobileOnly) {
    return MOBILE;
  }
  if (isDesktop) {
    return DESKTOP;
  }
  if (isTablet) {
    return TABLET;
  }
  return UNKNOWN_DEVICE;
};
export const mapToContactFormBody = ({ state, personalInformation }) => {
  const {
    category,
    subCategory,
    description,
    product,
    currentDevice,
    deviceType,
    os,
    osVersion,
    browser,
    otherOS,
  } = state;
  const { houseNumber, box, ...restPersonalInformation } = personalInformation;

  const hasToPostTechnicalInformation =
    state.subCategory === DE_LIMBURGER_DIGITAL_SUB_CATEGORY;
  return {
    category,
    subCategory,
    body: description,
    personal: {
      ...restPersonalInformation,
      houseNumber: `${houseNumber}${box}`,
    },
    ...(hasToPostTechnicalInformation && {
      product,
      technical: {
        currentDevice,
        ...(currentDevice
          ? {
              currentDeviceOperatingSystem: `${osName} ${operatingSystemVersion}`,
              currentDeviceBrowser: `${browserName} ${fullBrowserVersion}`,
              currentDeviceType: getCurrentDeviceType(),
            }
          : {
              deviceType,
              operatingSystem: os,
              operatingSystemVersion: os === UNKNOWN_OS ? null : osVersion,
              otherOperatingSystem: os === OTHER_OS ? otherOS : null,
              browser,
            }),
      },
    }),
  };
};
