import dayjs from 'dayjs';

const getEndDateValue = ({
  value,
  holidayArrangementOptions,
  daysOfWeek,
  endDate,
}) => {
  // If the start date makes the exceeds the min or max limit
  // of what is possible for the endDate, reset the end date to
  // start date plus minDuration.
  if (
    dayjs(value) >
    dayjs(endDate).subtract(holidayArrangementOptions.minDuration, 'day')
  ) {
    const minEndDate = dayjs(value)
      .add(holidayArrangementOptions.minDuration, 'day')
      .toDate();

    let updatedEndDate = null;
    // Check if new minEndDate is a allowed day.
    if (daysOfWeek.includes(dayjs(minEndDate).day())) {
      for (let index = 0; index < 7; index++) {
        // If minEndDate is not an allowed date, search for the next allowed date
        // and use that value.
        if (!daysOfWeek.includes(index)) {
          updatedEndDate = dayjs(minEndDate)
            .day(index)
            .toDate();
          break;
        }
      }
    }

    return updatedEndDate || minEndDate;
  }
  return endDate;
};

export default getEndDateValue;
