import { SUBSCRIPTION_LIFE_CYCLES } from '~/pages/Holiday/data';
import getLanguage from '../../language';

const getAhNotAvailabelCopy = ({ chosenUserSubscription }) => {
  const language = getLanguage();

  const {
    NotAvailableActive,
    NotAvailableWaiting,
    NotAvailableSuspended,
    NoActiveSubscription,
  } = language;
  const { FUTURE, CANCELLED, ACTIVE } = SUBSCRIPTION_LIFE_CYCLES;

  const subscriptionState = chosenUserSubscription?.lifeCycle;

  if (subscriptionState === ACTIVE) {
    return <NotAvailableActive />;
  }
  if (subscriptionState === FUTURE) {
    return <NotAvailableWaiting />;
  }
  if (subscriptionState === CANCELLED) {
    return <NotAvailableSuspended />;
  }
  return <NoActiveSubscription />;
};

export default getAhNotAvailabelCopy;
