import React, { useState } from 'react';
import { Text } from '@mediahuis/chameleon-react-legacy';

import { BANNER_TYPES } from '~/constants/messages';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import useHolidayContext from '~/context/hooks/useHolidayContext';
import useBanner from '~/context/hooks/useBanner';
import { ts } from '~/services';
import BaseSummaryStep from '~/pages/Holiday/components/DialogContent/Steps/BaseSummaryStep';
import getHolidayArrangementRules from '../../../utils/getHolidayArrangementRules';
import getHolidaySolutionBody from '../../../utils/getHolidaySolutionBody';
import { HOLIDAY_STEPS } from '../../../data';
import setHolidayArray from '../../../utils/setHolidayArray';
import handleBannerMessage from '../../../../../utils/handleBannerMessage';

const SummaryStep = ({ submissionData, setCurrentStep, setIsDialogShown }) => {
  const globalState = useGlobalContext();
  const { chosenUserSubscription } = globalState;
  const holidayState = useHolidayContext();
  const { showBanner } = useBanner();
  const { setHolidayState, holidays } = holidayState;
  const [isLoading, setIsLoading] = useState(false);

  const submitData = () => {
    setIsLoading(true);
    getHolidayArrangementRules({
      id: submissionData?.id,
      method: submissionData?.id ? 'PUT' : 'POST',
      body: getHolidaySolutionBody({
        submissionData,
        chosenUserSubscription,
      }),
    })
      .then(response => {
        setHolidayArray({
          response,
          id: submissionData?.id,
          holidays,
          setHolidayState,
        });
        return response;
      })
      .then(() => {
        setIsDialogShown(false);
        setCurrentStep(HOLIDAY_STEPS.CHOICE_STEP);
        setIsLoading(false);
        return showBanner({
          type: BANNER_TYPES.SUCCESS,
          message: <Text>{ts.t('Holiday.SummaryStep.SuccessMessage')}</Text>,
        });
      })
      .catch(err => {
        handleBannerMessage({ err, showBanner });
        setIsDialogShown(false);
        setCurrentStep(HOLIDAY_STEPS.CHOICE_STEP);
        return setIsLoading(false);
      });
  };

  return (
    <BaseSummaryStep
      submissionData={submissionData}
      setCurrentStep={setCurrentStep}
      submitData={submitData}
      isLoading={isLoading}
    />
  );
};

export default SummaryStep;
