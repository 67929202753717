import { config } from '~/services';
import ahApiCall from '~/api/ah/ahApiCall';

const getAdressChangesOptionsStartDate = async ({ customerId }) => {
  const res = await ahApiCall(
    `${config.ahServiceApiUrl}/customers/${customerId}/address-changes/options/start-dates`,
  );

  return res;
};

export default getAdressChangesOptionsStartDate;
