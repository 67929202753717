export const getUserName = (userSubscription = {}) => {
  const { payer = {}, receiver = {} } = userSubscription;

  if (receiver.companyName) return receiver.companyName;
  if (receiver.firstName && receiver.lastName)
    return `${receiver.firstName} ${receiver.lastName}`;
  if (receiver.firstName) return receiver.firstName;
  if (receiver.lastName) return receiver.lastName;
  if (payer.firstName && payer.lastName)
    return `${payer.firstName} ${payer.lastName}`;
  if (payer.firstName) return payer.firstName;
  if (payer.lastName) return payer.lastName;

  return '';
};
