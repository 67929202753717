import { Box, Logo, Text } from '@mediahuis/chameleon-react-legacy';

import MultibrandLogos from './MultibrandLogos';

const multibrandBlue = '#000C7E';
const sortedBrands = ['nb', 'ds', 'gva', 'hbvl', 'dg'];

const MultibrandBanner = ({ activeBrand, text, ...boxProps }) => {
  return (
    <Box
      {...boxProps}
      bgColor="colorGrey10"
      display="flex"
      flexDirection="column"
      mb={6}
      mr={6}
      pb={8}
      position="relative"
      pt={4}
      style={{
        borderBottomRightRadius: '0.5rem',
        borderTopRightRadius: '0.5rem',
        color: multibrandBlue,
        paddingLeft: '10%',
        paddingRight: '10%',
        ...boxProps.style,
      }}
    >
      <Box
        style={{
          boxSizing: 'content-box',
          marginInline: 'auto',
          maxInlineSize: '20rem',
          alignItems: 'center',
          display: 'flex',
          gap: '0.5rem',
        }}
      >
        <Logo height={4} name="subscriptions.plus-icon" width={4} />
        <Text fontFamily="system" fontWeight="bold">
          {text}
        </Text>
      </Box>

      <MultibrandLogos
        alignItems="center"
        alignSelf="center"
        brands={sortedBrands.filter(brand => brand !== activeBrand)}
        gap="0.5rem"
        iconHeight={3}
        iconWidth={3}
        logoHeight={8}
        logoWidth={8}
        position="absolute"
        style={{ bottom: '-20px' }}
      />
    </Box>
  );
};

export default MultibrandBanner;
