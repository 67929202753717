import { apiCall } from '~/utils';
import { environment, config } from '~/services';
import { ENVIRONMENTS } from '~/constants';

const getSmartWebformConfig = () => {
  const environmentName =
    environment.name === ENVIRONMENTS.LOCAL
      ? ENVIRONMENTS.TEST
      : environment.name;

  return apiCall(
    `https://mh-subscriber-settings.s3.eu-west-1.amazonaws.com/public/${environmentName}/${config.brand}/webform.json`,
    {
      method: 'GET',
    },
  );
};

export default getSmartWebformConfig;
