import { BRANDS } from '~/constants';
import { locationValidator } from '~/services';

/* eslint-disable no-undef */
const getBrandsWherePostalCodeIsRestricted = async ({
  postalCode,
  country,
  chosenUserSubscription,
  requestOrigin,
}) => {
  const { brand, internalSubscriptionType, modules } =
    chosenUserSubscription || {};
  const results = {};

  const isDL = brand === BRANDS.DL;
  const isHBVL = brand === BRANDS.HBVL;

  const isNotSLIMSubscription =
    internalSubscriptionType?.toUpperCase() !== 'SLIM';
  const hasTheNeededModule =
    requestOrigin === 'addresschange'
      ? modules?.addressChangeReader === true
      : modules?.vacation === true;

  if ((isDL || (isHBVL && isNotSLIMSubscription)) && hasTheNeededModule) {
    results[brand] = true;
    const response = await locationValidator.validate(
      brand,
      postalCode,
      country,
    );
    results[chosenUserSubscription.brand] = response.status === 200;
  }

  return Object.keys(results).filter(
    chosenUserSubscriptionBrand => !results[chosenUserSubscriptionBrand],
  );
};
export default getBrandsWherePostalCodeIsRestricted;
