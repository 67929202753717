import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  LinkText,
  WideList,
  WideListItem,
  Banner,
  Button,
  Icon,
  Text,
} from '@mediahuis/chameleon-react-legacy';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import dayjs from 'dayjs'; // load on demand
import { Dialog } from '~/components';
import { scrollTopWithTimeout } from '~/utils';
import { config, ts } from '~/services';
import postStudentCard from '~/api/postStudentCard';
import { useBanner } from '~/context';
import UploadStudentCardBanner from '~/pages/Subscription/components/UploadStudentCardBanner';
import ManageSubscriptionSection from '~/pages/Subscription/components/ManageSubscriptionSection';
import {
  DATE_FORMAT_READABLE,
  CREDIT_CARD,
  DIRECT_DEBIT,
  ENTITIES,
  BANNER_TYPES,
} from '~/constants';
import { getCancelSubscriptionYouthUrl } from '~/utils/getCancelSubscriptionYouthUrl';
import {
  FEATURE_FLAG_ONLINE_CANCELLATION_YOUTH,
  getFlagOnlineCancellation,
} from '~/constants/featureSwitch';
import useMollieStatus from '~/hooks/useMollieStatus';
import { useFlag } from '@unleash/proxy-client-react';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import { DIALOG_TYPES, SUBSCRIPTION_STATUS } from './constants';
import DialogContent from './components/DialogContent';

const getPaymentCopy = (paymentMethod, isAcceptGiro) => {
  switch (paymentMethod) {
    case DIRECT_DEBIT:
      return config.brand === 'dl' ? 'Automatische Incasso' : 'Domiciliëring';
    case CREDIT_CARD:
      return 'Kredietkaart';
    default:
      return isAcceptGiro ? 'Acceptgiro' : '';
  }
};

const SubscriptionContent = () => {
  const { chosenUserSubscription, userInfo } = useGlobalContext();
  const [dialogShown, setDialogShown] = useState(false);
  const [newReceiverAddress, setNewReceiverAddress] = useState(null);
  const [newPayerAddress, setNewPayerAddress] = useState(null);
  const [dialogType, setDialogType] = useState('');
  const [showPayerAddress, setShowPayerAddress] = useState(false);
  const [files, setFiles] = useState([]);
  const [isSubmittingStudentCards, setIsSubmittingStudentCards] = useState(
    false,
  );
  const [
    studentCardIsAlreadySubmitted,
    setStudentCardIsAlreadySubmitted,
  ] = useState(false);

  const { showBanner } = useBanner();
  const onlineCancellationIsEnabled = useFlag(
    getFlagOnlineCancellation({ env: config.brand }),
  );
  const onlineCancellationYouthIsEnabled = useFlag(
    FEATURE_FLAG_ONLINE_CANCELLATION_YOUTH,
  );

  /* accepted reasons should come from BE */
  const reasons = ['reason 1', 'reason 2', 'reason 3'];

  useEffect(() => {
    setNewReceiverAddress(null);
    setNewPayerAddress(null);
  }, [chosenUserSubscription]);

  const handleClick = ({ type }) => {
    setDialogType(type);
    setDialogShown(true);
  };

  const handleStudentUploadSubmit = async () => {
    try {
      setIsSubmittingStudentCards(true);
      await postStudentCard({
        subscriptionId: chosenUserSubscription?.id,
        body: { files },
        accountGuid: userInfo.accountGuid,
      });
      setStudentCardIsAlreadySubmitted(true);
      showBanner({
        type: BANNER_TYPES.SUCCESS,
        message: <Text>{ts.t('StudentUploadDialog.SuccessMessage')}</Text>,
      });
    } catch (error) {
      showBanner({
        message: <Text>{ts.t('StudentUploadDialog.ErrorMessage')}</Text>,
        type: BANNER_TYPES.ERROR,
      });
    } finally {
      setIsSubmittingStudentCards(false);
      setDialogShown(false);
    }
  };

  const giroPhasedOut = localStorage.getItem('giroPhasedOut') === '1';

  const RECEIVER_ADDRESS =
    chosenUserSubscription?.receiver?.address &&
    `${chosenUserSubscription.receiver.address.street} ${
      chosenUserSubscription.receiver.address.houseNumber
    }${
      chosenUserSubscription.receiver.address.box
        ? `/${chosenUserSubscription.receiver.address.box}`
        : ''
    } - ${chosenUserSubscription.receiver.address.postalCode} ${
      chosenUserSubscription.receiver.address.city
    } - ${chosenUserSubscription.receiver.address.countryCode}`;

  const NEW_RECEIVER_ADDRESS =
    newReceiverAddress &&
    `${newReceiverAddress.address.street} ${
      newReceiverAddress.address.houseNumber
    }${
      newReceiverAddress.address.box ? `/${newReceiverAddress.address.box}` : ''
    } - 
            ${newReceiverAddress.address.postalCode} ${
      newReceiverAddress.address.city
    } - ${newReceiverAddress.address.countryCode}`;
  const NEW_PAYER_ADDRESS =
    newPayerAddress &&
    `${newPayerAddress.address.street} ${newPayerAddress.address.houseNumber}${
      newPayerAddress.address.box ? `/${newPayerAddress.address.box}` : ''
    } - 
            ${newPayerAddress.address.postalCode} ${
      newPayerAddress.address.city
    } - ${newPayerAddress.address.countryCode}`;

  const PAYER_ADDRESS =
    chosenUserSubscription?.payer?.address &&
    `${chosenUserSubscription.payer.address.street} ${
      chosenUserSubscription.payer.address.houseNumber
    }${
      chosenUserSubscription.payer.address.box
        ? `/${chosenUserSubscription.payer.address.box}`
        : ''
    } - ${chosenUserSubscription.payer.address.postalCode} ${
      chosenUserSubscription.payer.address.city
    } - ${chosenUserSubscription.payer.address.countryCode}`;

  const END_DATE =
    chosenUserSubscription &&
    dayjs(chosenUserSubscription.endDate).format(DATE_FORMAT_READABLE);

  const receiverName =
    chosenUserSubscription?.receiver?.companyName ||
    `${
      chosenUserSubscription?.receiver?.firstName
        ? chosenUserSubscription?.receiver?.firstName
        : ''
    } ${
      chosenUserSubscription?.receiver?.lastName
        ? chosenUserSubscription?.receiver?.lastName
        : ''
    }`;

  const payerName =
    chosenUserSubscription?.payer?.companyName ||
    `${
      chosenUserSubscription?.payer?.firstName
        ? chosenUserSubscription?.payer?.firstName
        : ''
    } ${
      chosenUserSubscription?.payer?.lastName
        ? chosenUserSubscription?.payer?.lastName
        : ''
    }`;

  const evalPayerAddress = () => {
    setShowPayerAddress(RECEIVER_ADDRESS !== PAYER_ADDRESS);
  };

  useEffect(() => {
    if (chosenUserSubscription) {
      evalPayerAddress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenUserSubscription]);

  /* Check for Mollie status */
  useMollieStatus();

  const showUploadStudentCardBanner =
    chosenUserSubscription?.modules?.studentCard &&
    config.entity === ENTITIES.MHBE &&
    !studentCardIsAlreadySubmitted;
  const showDialogFooter = dialogType === DIALOG_TYPES.STUDENT_UPLOAD;

  return (
    <React.Fragment>
      <Box mb={8}>
        {showUploadStudentCardBanner && (
          <UploadStudentCardBanner handleClick={handleClick} />
        )}
        <Box mb={6} mt={8}>
          <Heading level={3}>{ts.t('Subscription.General')}</Heading>
        </Box>
        <Box>
          <WideList>
            <WideListItem
              title={ts.t('Subscription.Type')}
              loading={!chosenUserSubscription}
              description={
                chosenUserSubscription?.description ||
                chosenUserSubscription?.name
              }
            />
            {chosenUserSubscription?.edition && (
              <WideListItem
                title={ts.t('Subscription.Edition')}
                loading={!chosenUserSubscription}
                description={chosenUserSubscription?.edition}
              />
            )}
            <WideListItem
              title={ts.t('Subscription.Number')}
              loading={!chosenUserSubscription}
              description={chosenUserSubscription?.id}
            />
            {chosenUserSubscription?.receiver && (
              <>
                <WideListItem
                  title={ts.t('Subscription.DeliveryAddress', {
                    values: {
                      NEW_ADDRESS: NEW_RECEIVER_ADDRESS
                        ? ` - ${ts.t('Subscription.Current')}`
                        : '',
                    },
                  })}
                  loading={!chosenUserSubscription}
                  description={
                    <>
                      {receiverName && (
                        <>
                          {receiverName}
                          <br />
                        </>
                      )}
                      {RECEIVER_ADDRESS}
                    </>
                  }
                  action={
                    chosenUserSubscription?.modules?.addressChangeReader && (
                      <LinkText
                        onClick={() =>
                          handleClick({
                            type: DIALOG_TYPES.ADDRESS,
                          })
                        }
                      >
                        {ts.t('Common.Edit')}
                      </LinkText>
                    )
                  }
                />
                {NEW_RECEIVER_ADDRESS && (
                  <WideListItem
                    title={ts.t('Subscription.DateFrom', {
                      values: {
                        dateFrom: dayjs(newReceiverAddress.dateFrom).format(
                          DATE_FORMAT_READABLE,
                        ),
                      },
                    })}
                    description={NEW_RECEIVER_ADDRESS}
                  />
                )}
              </>
            )}
            {showPayerAddress && (
              <>
                <WideListItem
                  title={ts.t('Subscription.InvoiceAddress', {
                    values: {
                      NEW_ADDRESS: NEW_PAYER_ADDRESS
                        ? ` - ${ts.t('Subscription.Current')}`
                        : '',
                    },
                  })}
                  loading={!chosenUserSubscription}
                  description={
                    <>
                      {payerName && (
                        <>
                          {payerName}
                          <br />
                        </>
                      )}
                      {PAYER_ADDRESS}
                    </>
                  }
                  action={
                    chosenUserSubscription?.modules?.addressChangePayer && (
                      <LinkText
                        onClick={() =>
                          handleClick({
                            type: DIALOG_TYPES.ADDRESS_INVOICE,
                          })
                        }
                      >
                        {ts.t('Common.Edit')}
                      </LinkText>
                    )
                  }
                />
                {NEW_PAYER_ADDRESS && (
                  <WideListItem
                    title={ts.t('Subscription.InvoiceAddressNextPeriod')}
                    description={NEW_PAYER_ADDRESS}
                  />
                )}
              </>
            )}
            <WideListItem
              title={ts.t('Subscription.EndDate')}
              loading={!chosenUserSubscription}
              description={END_DATE}
            />
            <WideListItem
              title={ts.t('Subscription.State')}
              loading={!chosenUserSubscription}
              description={SUBSCRIPTION_STATUS[chosenUserSubscription?.state]}
            />
          </WideList>
        </Box>
      </Box>

      <Box mb={6}>
        <Heading level={3}>{ts.t('Subscription.PaymentHeading')}</Heading>
      </Box>
      <Box mb={8}>
        <WideList>
          {chosenUserSubscription.paymentInterval !== 'OneShot' && (
            <WideListItem
              title={ts.t('Subscription.WayOfPayment')}
              loading={!chosenUserSubscription}
              description="Automatische betaling"
            />
          )}
          <WideListItem
            title={ts.t('Subscription.PaymentMethod')}
            loading={!chosenUserSubscription}
            description={getPaymentCopy(
              chosenUserSubscription?.paymentMethod,
              chosenUserSubscription?.isAcceptGiro,
            )}
          />
          {chosenUserSubscription.isAcceptGiro && !giroPhasedOut && (
            <Banner show closeHidden={true} appearance="error">
              {ts.t('Subscription.AcceptGiroWarning', {
                richContent: true,
              })}
              <Button
                onClick={() =>
                  handleClick({
                    type: DIALOG_TYPES.GIRO_PHASE_OUT,
                  })
                }
              >
                {ts.t('Subscription.AcceptGiroButtonText')}{' '}
                <Icon as={ChevronForward} />
              </Button>
            </Banner>
          )}
          {chosenUserSubscription?.modules?.paymentUpdate && (
            <WideListItem
              title={ts.t('Subscription.PaymentInfo')}
              loading={!chosenUserSubscription}
              description={chosenUserSubscription?.paymentInfo}
              action={
                <LinkText
                  onClick={() =>
                    handleClick({
                      type: DIALOG_TYPES.PAYMENT,
                    })
                  }
                >
                  {ts.t('Common.Edit')}
                </LinkText>
              }
            />
          )}
        </WideList>
      </Box>

      {chosenUserSubscription?.modules?.cancelSubscription &&
        onlineCancellationIsEnabled && (
          <ManageSubscriptionSection
            onClick={() =>
              handleClick({
                type: DIALOG_TYPES.ONLINCE_CANCELLATION,
              })
            }
          />
        )}
      {chosenUserSubscription?.modules?.cancelSubscriptionYouth &&
        onlineCancellationYouthIsEnabled && (
          <ManageSubscriptionSection
            onClick={() =>
              window.open(
                getCancelSubscriptionYouthUrl({
                  subscriptionId: chosenUserSubscription?.id,
                  accountGuid: userInfo.accountGuid,
                }),
              )
            }
          />
        )}
      <Dialog
        show={dialogShown}
        onClose={() => {
          setDialogShown(false);
          if (!dialogType === DIALOG_TYPES.ONLINCE_CANCELLATION) {
            scrollTopWithTimeout();
          }
        }}
        closeOnBackdropClick={false}
        headerHidden={dialogType === DIALOG_TYPES.ONLINCE_CANCELLATION}
        footer={
          showDialogFooter && (
            <>
              <Button
                size="large"
                appearance="outline"
                onClick={() => setDialogShown(false)}
              >
                {ts.t('StudentUploadDialog.Cancel')}
              </Button>
              <Button
                size="large"
                iconRight={ChevronForward}
                disabled={files.length === 0}
                onClick={handleStudentUploadSubmit}
                loading={isSubmittingStudentCards}
              >
                {ts.t('StudentUploadDialog.Confirm')}
              </Button>
            </>
          )
        }
      >
        <DialogContent
          dialogType={dialogType}
          setDialogShown={setDialogShown}
          setNewReceiverAddress={setNewReceiverAddress}
          setNewPayerAddress={setNewPayerAddress}
          reasons={reasons}
          files={files}
          setFiles={setFiles}
          dialogShown={dialogShown}
        />
      </Dialog>
    </React.Fragment>
  );
};

export default SubscriptionContent;
