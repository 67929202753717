import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Box, Flex, Icon, Paragraph } from '@mediahuis/chameleon-react-legacy';
import { ChevronBack } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import { ts } from '~/services';

const Absolute = styled.div`
  position: absolute;
  left: 16px;
`;

const Link = styled.a`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const OverviewLink = () => {
  const history = useHistory();

  if (history.location.pathname === '/') {
    return null;
  }

  return (
    <Absolute>
      <Link onClick={() => history.push('/')}>
        <Flex alignItems="center">
          <Box mr={3}>
            <Icon as={ChevronBack} size="medium" />
          </Box>
          <Paragraph>{ts.t('Common.BackToOverview')}</Paragraph>
        </Flex>
      </Link>
    </Absolute>
  );
};

export default OverviewLink;
