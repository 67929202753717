import { config } from '~/services';
import { apiCall } from '~/utils';

const getHouseNumbers = async ({ body }) => {
  const res = await apiCall(
    `${config.addressServiceApiUrl}/Address/AutoComplete/HouseNumbers/LU?api-version=1.0`,
    {
      method: 'POST',
      data: JSON.stringify(body),
    },
  );
  return res;
};

export default getHouseNumbers;
