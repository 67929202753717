import React from 'react';
import { DidomiSDK } from '@didomi/react';
import { loadTealium } from '~/utils';
import { ts } from '~/services';
import normaliseConsentData from './normaliseConsentData';

const Didomi = () => (
  <DidomiSDK
    apiKey="11ef8ac9-6270-4d5e-8b99-8d6a5bd60059"
    iabVersion={2}
    gdprAppliesGlobally={true}
    config={{
      user: {
        bots: {
          consentRequired: false,
          types: ['crawlers', 'performance'],
          extraUserAgents: ['mh_monitoring'],
        },
      },
      languages: {
        enabled: [ts.getLanguage().substring(0, 2)],
        default: ts.getLanguage().substring(0, 2),
      },
    }}
    onReady={didomi => {
      window.gdprConsents = normaliseConsentData(didomi);
      loadTealium();
    }}
    onConsentChanged={() => {
      window.gdprConsents = normaliseConsentData(window.Didomi);
    }}
  />
);

export default Didomi;
