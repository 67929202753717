import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Table,
  useMediaQuery,
} from '@mediahuis/chameleon-react-legacy';
import { isMobileOnly } from 'react-device-detect';
import { TableMobile } from '~/components';
import { holidayTableColumns } from '../data';

const HolidaysList = ({
  heading,
  tableAction,
  tableData,
  tableMessage,
  isLoading,
}) => {
  const hasTableData = tableData && tableData.length > 0;
  return (
    <Box pb={10}>
      <Flex
        justifyContent="space-between"
        alignItems={useMediaQuery(
          {
            md: 'flex-end',
          },
          'column-reverse',
        )}
        flexDirection={useMediaQuery(
          {
            md: 'row',
          },
          'column-reverse',
        )}
        pb={5}
      >
        <Heading fontWeight="bold" level="4" fontFamily="primary">
          {heading}
        </Heading>
        {tableAction}
      </Flex>
      {isMobileOnly ? (
        <TableMobile
          columns={holidayTableColumns}
          data={hasTableData && tableData}
          message={tableMessage}
          loading={isLoading}
        />
      ) : (
        <Table
          columns={holidayTableColumns}
          data={hasTableData && tableData}
          emptyDescription={tableMessage}
          tableLayout="fixed"
          loading={isLoading}
        />
      )}
    </Box>
  );
};

export default HolidaysList;
