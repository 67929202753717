import { Button, useMediaQuery } from '@mediahuis/chameleon-react-legacy';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import React from 'react';
import { config, environment, ts } from '~/services';

export default function ActivateButton() {
  const buttonWidth = useMediaQuery({ sm: 'auto' }, 'full');
  const isLW = config.brand === BRANDS.LW;

  const href = isLW
    ? `https://${environment.apiPrefix}activation.wort.lu`
    : `https://${environment.sitePrefix}.${config.siteUrl}/activeer`;

  return ![BRANDS.CO, BRANDS.TC, BRANDS.APORTAL].includes(config.brand) ? (
    <Button
      iconRight={ChevronForward}
      width={buttonWidth}
      as="a"
      href={href}
      target="_blank"
    >
      {ts.t('Subscription.NotFound.ButtonText')}
    </Button>
  ) : null;
}
