import { Box, Select, TextField } from '@mediahuis/chameleon-react-legacy';
import React from 'react';
import { BROWSERS, OPERATING_SYSTEMS } from '~/pages/SmartWebform/constants';
import { handleSelectChange } from '~/pages/SmartWebform/utils';
import { ts } from '~/services';
import { clearValidationOnFocus } from '~/utils';

export default function NotCurrentDevice({
  isDesktopLaptop,
  state,
  setState,
  errors,
  setErrors,
}) {
  return isDesktopLaptop ? (
    <>
      <Box width="100%">
        <Select
          label={ts.t('SmartWebform.OsLabel')}
          placeholder={ts.t('SmartWebform.OsPlaceholder')}
          id="os"
          name="os"
          value={state?.os}
          onChange={e => handleSelectChange({ e, setState })}
          onFocus={e => clearValidationOnFocus({ e, errors, setErrors })}
          mb={4}
          required
          error={!!errors?.os}
          message={errors?.os}
        >
          {OPERATING_SYSTEMS.map(os => (
            <option key={os.value} value={os.value}>
              {os.label}
            </option>
          ))}
        </Select>
      </Box>
      <Box width="100%">
        <Select
          label={ts.t('SmartWebform.BrowserLabel')}
          placeholder={ts.t('SmartWebform.BrowserPlaceholder')}
          id="browser"
          name="browser"
          value={state?.browser}
          onChange={e => handleSelectChange({ e, setState })}
          onFocus={e => clearValidationOnFocus({ e, errors, setErrors })}
          mb={4}
          required
          error={!!errors?.browser}
          message={errors?.browser}
        >
          {BROWSERS.map(browser => (
            <option key={browser.value} value={browser.value}>
              {browser.label}
            </option>
          ))}
        </Select>
      </Box>
    </>
  ) : (
    <TextField
      id="deviceBrandAndModel"
      name="deviceBrandAndModel"
      label={ts.t('SmartWebform.DeviceBrandAndModelLabel')}
      placeholder={ts.t('SmartWebform.DeviceBrandAndModelPlaceholder')}
      value={state?.deviceBrandAndModel}
      onChange={e => {
        const value = e.target.value;
        return setState(prevState => ({
          ...prevState,
          deviceBrandAndModel: value,
        }));
      }}
      onFocus={e => clearValidationOnFocus({ e, errors, setErrors })}
      width="100%"
      mb={4}
      error={!!errors?.deviceBrandAndModel}
      message={errors?.deviceBrandAndModel}
      maxlength={50}
    />
  );
}
