export const mapAddress = (
  address,
  namesPerson = {},
  demographicsPerson = {},
) => {
  const {
    street,
    postalCode,
    city,
    additionalInformation,
    houseNumber,
    countryCode,
    busNumber,
    country,
  } = address || {};

  return {
    id: Buffer.from(
      JSON.stringify({ ...address, ...namesPerson, ...demographicsPerson }),
    ).toString('base64'),
    street,
    houseNumber,
    postalCode,
    city,
    countryCode,
    country,
    box: busNumber,
    extra: additionalInformation,
    extraInfo: additionalInformation,
  };
};
export const mapPreviousThirdPartiesAddresses = (addresses = []) => {
  return addresses.map(
    ({ namesPerson: { firstName, lastName }, address, demographicsPerson }) => {
      const mappedAddress = mapAddress(
        address,
        { firstName, lastName },
        demographicsPerson,
      );
      return {
        firstName,
        lastName,
        title: demographicsPerson?.genderCode?.toUpperCase(),
        ...mappedAddress,
      };
    },
  );
};

export const mapPreviousAddresses = (addresses = []) => {
  return addresses.map(address => mapAddress(address));
};
