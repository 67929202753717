import { config } from '~/services';
import ahApiCall from '~/api/ah/ahApiCall';

const getTemporaryDeliveryChanges = async ({ subscriptionId }) => {
  const res = await ahApiCall(
    `${config.ahServiceApiUrl}/subscriptions/${subscriptionId}/temporary-delivery-changes`,
  );

  return res;
};

export default getTemporaryDeliveryChanges;
