/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import useHolidayContext from '~/context/hooks/useHolidayContext';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import { HOLIDAY_SOLUTIONS, HOLIDAY_STEPS } from '~/pages/Holiday/data';
import getInitialEndDate from '../../../utils/getInitialEndDate';
import getEmptySolutionData from '../../../utils/getEmptySolutionData';
import ChoiceStep from './ChoiceStep';
import AddressStep from './AddressStep';
import SummaryStep from './SummaryStep';
import AddressChoiceStep from './AddressChoiceStep';

const HolidayDialog = ({
  isDialogShown,
  setIsDialogShown,
  chosenHoliday,
  currentStep,
  setCurrentStep,
}) => {
  const globalState = useGlobalContext();
  const holidayState = useHolidayContext();
  const { holidayArrangementOptions, holidays } = holidayState;

  const { chosenUserSubscription } = globalState;
  const [submissionData, setSubmissionData] = useState({});

  const defaultState = {
    id: '',
    start: dayjs(holidayArrangementOptions.minimumCreateDate).toDate(),
    end: getInitialEndDate({
      daysOfWeek: holidayState.daysOfWeek,
      minDuration: holidayArrangementOptions.minDuration + 1,
      minimumDate: holidayArrangementOptions.minimumCreateDate,
    }),
    solutionData: getEmptySolutionData(),
    subscriptionId: chosenUserSubscription.id,
    state: '',
    vacationSolution: holidayArrangementOptions?.resolutions?.ContinueDigital
      ? HOLIDAY_SOLUTIONS.CONTINUE_DIGITAL
      : holidayArrangementOptions?.resolutions?.DiscontinueDigital
      ? HOLIDAY_SOLUTIONS.DISCONTINUE_DIGITAL
      : holidayArrangementOptions?.resolutions?.HolidayAddress
      ? HOLIDAY_SOLUTIONS.HOLIDAY_ADDRESS
      : holidayArrangementOptions?.resolutions?.DiscontinuePrint
      ? HOLIDAY_SOLUTIONS.DISCONTINUE_PRINT
      : holidayArrangementOptions?.resolutions?.FamilyAddress
      ? HOLIDAY_SOLUTIONS.FAMILY_ADDRESS
      : holidayArrangementOptions?.resolutions?.Charity
      ? HOLIDAY_SOLUTIONS.CHARITY
      : '',
  };
  useEffect(() => {
    if (chosenHoliday !== null) {
      setSubmissionData({
        ...defaultState,
        ...holidays[chosenHoliday],
        solutionData: {
          ...holidays[chosenHoliday].solutionData,
          extra: holidays[chosenHoliday]?.solutionData?.extraInfo,
        },
        start: new Date(holidays[chosenHoliday].start),
        end: new Date(holidays[chosenHoliday].end),
      });
    } else {
      setSubmissionData(defaultState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenHoliday, isDialogShown]);

  return (
    <React.Fragment>
      {currentStep === HOLIDAY_STEPS.CHOICE_STEP && (
        <ChoiceStep
          submissionData={submissionData}
          setSubmissionData={setSubmissionData}
          setCurrentStep={setCurrentStep}
          isDialogShown={isDialogShown}
        />
      )}
      {currentStep === HOLIDAY_STEPS.ADDRESS_CHOICE_STEP && (
        <AddressChoiceStep
          submissionData={submissionData}
          setSubmissionData={setSubmissionData}
          setCurrentStep={setCurrentStep}
        />
      )}
      {currentStep === HOLIDAY_STEPS.ADDRESS_STEP && (
        <AddressStep
          submissionData={submissionData}
          setSubmissionData={setSubmissionData}
          setCurrentStep={setCurrentStep}
        />
      )}
      {currentStep === HOLIDAY_STEPS.SUMMARY_STEP && (
        <SummaryStep
          submissionData={submissionData}
          setCurrentStep={setCurrentStep}
          setIsDialogShown={setIsDialogShown}
        />
      )}
    </React.Fragment>
  );
};

export default HolidayDialog;
