import isEmpty from 'lodash.isempty';

export const DAY_TO_INDEX = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 0,
};

const mapToDaysOfWeek = (allowedDays = {}, allowedOnly = false) => {
  if (isEmpty(allowedDays)) {
    return [];
  }

  const daysOfWeek = [0, 1, 2, 3, 4, 5, 6];
  const daysToIndex = Object.keys(allowedDays).map(day => DAY_TO_INDEX[day]);

  return daysOfWeek.filter(day =>
    allowedOnly ? daysToIndex.includes(day) : !daysToIndex.includes(day),
  );
};

export default mapToDaysOfWeek;
