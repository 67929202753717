import { Button, useMediaQuery } from '@mediahuis/chameleon-react-legacy';
import { Add } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import React, { useEffect, useState } from 'react';
import getTemporaryDeliveryChanges from '~/api/ah/getTemporaryDeliveryChanges';
import { BANNER_TYPES, BRANDS } from '~/constants';
import { useBanner, useGlobalContext, useHolidayContext } from '~/context';
import HolidaysList from '~/pages/Holiday/components/HolidaysList';
import { LIFE_CYCLES } from '~/pages/Holiday/data';
import getLanguage from '~/pages/Holiday/language';
import mapHolidayData from '~/pages/Holiday/utils/ah/mapHolidayData';
import sortHolidays from '~/pages/Holiday/utils/sortHolidays';
import { config } from '~/services';
import {
  SUBSCRIPTION_COMPONENT_TYPES,
  subscriptionHasComponent,
} from '~/utils';

export default function AhHolidayLists({ onAction, setChosenHoliday }) {
  const holidayContext = useHolidayContext();
  const globalContext = useGlobalContext();

  const { chosenUserSubscription, deeplink } = globalContext;
  const { setHolidayState, holidays = [] } = holidayContext;
  const { showBanner } = useBanner();
  const language = getLanguage(
    chosenUserSubscription?.internalSubscriptionType,
  );
  const [isLoading, setIsLoading] = useState(true);
  const [hasFailed, setHasFailed] = useState(true);

  const [plannedHolidaysState, setPlannedHolidaysState] = useState({});
  const [activeHolidaysState, setActiveHolidaysState] = useState({});
  const [pastHolidaysState, setPastHolidaysState] = useState({});

  const hasDigitalComponent = subscriptionHasComponent(
    chosenUserSubscription,
    SUBSCRIPTION_COMPONENT_TYPES.DIGITAL,
  );

  // eslint-disable-next-line consistent-return
  const fetchAllHolidays = async () => {
    try {
      setHasFailed(false);
      setIsLoading(true);
      const allHolidays = await getTemporaryDeliveryChanges({
        subscriptionId: chosenUserSubscription.id,
      });
      setHolidayState({
        holidays: allHolidays,
      });
      if (!allHolidays.length) {
        setPlannedHolidaysState({
          data: [],
          message: language.messages.noVacationPlanned,
        });
        setPastHolidaysState({
          data: [],
          message: language.messages.noVacationHistory,
        });
      }
    } catch {
      setHasFailed(true);
      showBanner({
        message: language.messages.noVacationError,
        type: BANNER_TYPES.ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchAllHolidays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenUserSubscription]);

  const isReadOnlyMode =
    !deeplink?.params['internal-case-id'] && config.brand === BRANDS.APORTAL;

  useEffect(() => {
    if (holidays) {
      const mappedHolidayData = mapHolidayData({
        holidayData: holidays,
        onAction,
        setChosenHoliday,
        isReadOnlyMode,
        subscriptionHasDigitalComponent: hasDigitalComponent,
      });

      const pastHolidays = mappedHolidayData.filter(
        holidayData => holidayData.lifeCycle === LIFE_CYCLES.PAST,
      );
      const activeHolidays = mappedHolidayData.filter(
        holidayData => holidayData.lifeCycle === LIFE_CYCLES.ACTIVE,
      );
      const plannedHolidays = mappedHolidayData.filter(
        holidayData => holidayData.lifeCycle === LIFE_CYCLES.FUTURE,
      );

      const hasPlannedHolidays = plannedHolidays.length > 0;
      const plannedHolidaysMessage = hasPlannedHolidays
        ? null
        : language.messages.noVacationPlanned;
      const hasPastHolidays = pastHolidays.length > 0;
      const pastHolidaysMessage = hasPastHolidays
        ? null
        : language.messages.noVacationHistory;

      setActiveHolidaysState({ data: sortHolidays(activeHolidays, 'desc') });
      setPlannedHolidaysState({
        data: sortHolidays(plannedHolidays, 'desc'),
        message: plannedHolidaysMessage,
      });
      setPastHolidaysState({
        data: sortHolidays(pastHolidays, 'asc'),
        message: pastHolidaysMessage,
      });
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [holidays]);
  const showActiveHolidays =
    !isLoading && activeHolidaysState?.data?.length > 0;
  const buttonWidth = useMediaQuery(
    {
      md: 'auto',
    },
    'full',
  );
  const buttonMarginButton = useMediaQuery(
    {
      md: 0,
    },
    7,
  );

  if (hasFailed) {
    return null;
  }
  return (
    <>
      <HolidaysList
        tableData={plannedHolidaysState.data}
        tableMessage={plannedHolidaysState.message}
        isLoading={isLoading}
        heading={language.plannedHolidaysHeading}
        tableAction={
          !isReadOnlyMode && (
            <Button
              width={buttonWidth}
              mb={buttonMarginButton}
              onClick={() => {
                setChosenHoliday && setChosenHoliday(null);
                onAction && onAction({ type: 'holidayRule' });
              }}
              iconLeft={Add}
              disabled={!chosenUserSubscription}
            >
              {language.newHolidayArrangement}
            </Button>
          )
        }
      />
      {showActiveHolidays && (
        <HolidaysList
          tableData={activeHolidaysState.data}
          isLoading={isLoading}
          heading={language.activeHolidaysHeading}
        />
      )}
      <HolidaysList
        tableData={pastHolidaysState.data}
        isLoading={isLoading}
        heading={language.pastHolidaysHeading}
        tableMessage={pastHolidaysState.message}
      />
    </>
  );
}
