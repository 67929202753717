import React from 'react';
import EntitySwitch from '~/components/ah/EntitySwitch';
import AhCancelDialog from '~/pages/Holiday/components/ah/AhCancelDialog';
import AhHolidayDialog from '~/pages/Holiday/components/ah/AhHolidayDialog';
import AhWebformDialog from '../ah/Webform/AhWebformDialog';
import CancelDialog from './CancelDialog';
import HolidayDialog from './Steps';

const DialogContent = ({
  dialogType,
  isDialogShown,
  setIsDialogShown,
  chosenHoliday,
  currentStep,
  setCurrentStep,
  setUpdateHolidayOverviewHasFailed,
}) => {
  const cancelDialogProps = {
    isDialogShown,
    setIsDialogShown,
    chosenHoliday,
  };
  const holidayDialogProps = {
    isDialogShown,
    setIsDialogShown,
    chosenHoliday,
    currentStep,
    setCurrentStep,
  };
  switch (dialogType) {
    case 'cancel':
      return (
        <EntitySwitch
          defaultComponent={<CancelDialog {...cancelDialogProps} />}
          mhlu={
            <AhCancelDialog
              {...cancelDialogProps}
              setUpdateHolidayOverviewHasFailed={
                setUpdateHolidayOverviewHasFailed
              }
            />
          }
        />
      );
    case 'holidayRule':
      return (
        <EntitySwitch
          defaultComponent={<HolidayDialog {...holidayDialogProps} />}
          mhlu={
            <AhHolidayDialog
              {...holidayDialogProps}
              setUpdateHolidayOverviewHasFailed={
                setUpdateHolidayOverviewHasFailed
              }
            />
          }
        />
      );
    case 'webform':
      return <AhWebformDialog {...holidayDialogProps} />;
    default:
      return null;
  }
};

export default DialogContent;
