export const hasEmptyAddressFields = addressFields =>
  !addressFields?.countryCode ||
  !addressFields?.postalCode ||
  !addressFields?.street ||
  !addressFields?.houseNumber;

export const hasEmptyFamilyFields = ({ solutionData, isFamilyAddress }) => {
  if (!isFamilyAddress) return false;
  return (
    !solutionData.title || !solutionData.firstName || !solutionData.lastName
  );
};
