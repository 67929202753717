import React, { useEffect, useState } from 'react';
import { Select } from '@mediahuis/chameleon-react-legacy';
import { environment, config, ts } from '~/services';
import language from './language';

const getDefaultCountry = (allCountries = [], allowedCountries = []) => {
  const brandCountryMap = {
    dl: 'NL',
    gva: 'BE',
    nb: 'BE',
    hbvl: 'BE',
    ds: 'BE',
  };
  const defaultBrandCountryIsAllowed = allowedCountries?.includes(
    brandCountryMap[config.brand],
  );
  if (defaultBrandCountryIsAllowed) {
    return brandCountryMap[config.brand];
  }
  return allowedCountries?.[0] ?? allCountries[0].IsoCode;
};
const getAhDefaultCountry = ({ address, countries, allowedCountries }) => {
  if (allowedCountries.length === 0) {
    return null;
  }
  return (
    countries.find(country => country.IsoCode === address.countryCode)
      ?.IsoCode || countries[0].IsoCode
  );
};

const SelectCountry = ({
  address,
  handleAddressChange,
  allowedCountries,
  setErrors,
  errors,
}) => {
  // TODO: Temporary fix until Chameleon fixes
  // error when having <React.Fragment> in options.
  // The correct way should be without .map as this is
  // noticable in performance when rendering the modal.

  // TODO: remove complexity of all countries available, should only use certain countries
  const [availableCountries, setAvailableCountries] = useState([]);
  const isMHLU = config.isMHLU;
  const lang = ts.getLanguage().substring(0, 2).toUpperCase();

  useEffect(() => {
    fetch(
      `https://${environment.apiPrefix}addressservice.mediahuis.be/Countries/Language/${lang}`,
    )
      .then(response => response.json())
      .then(allCountries => {
        if (allowedCountries.length === 0) {
          setAvailableCountries([...allCountries]);
          handleAddressChange({
            countryCode: isMHLU
              ? getAhDefaultCountry({
                  address,
                  countries: allCountries,
                  allowedCountries,
                })
              : address.countryCode ||
                getDefaultCountry(allCountries, allowedCountries),
          });
        } else {
          const allowedCountriesWithName = allowedCountries
            .map(allowedCountry => {
              let countriesObject;
              allCountries.forEach(country => {
                if (country.IsoCode === allowedCountry.toUpperCase()) {
                  countriesObject = {
                    ...countriesObject,
                    ...country,
                  };
                }
                return country;
              });
              return countriesObject;
            })
            .filter(Boolean);
          setAvailableCountries(allowedCountriesWithName);

          handleAddressChange({
            countryCode: isMHLU
              ? getAhDefaultCountry({
                  address,
                  countries: allowedCountriesWithName,
                  allowedCountries,
                })
              : address.countryCode ||
                getDefaultCountry(allCountries, allowedCountries),
          });
        }
        return null;
      })
      .catch(error => {
        return error;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      id="country"
      label={language.country}
      data-testid="country"
      value={address.countryCode}
      onChange={e => {
        handleAddressChange({
          box: '',
          city: '',
          houseNumber: '',
          postalCode: '',
          street: '',
          countryCode: e.target.value,
        });

        const spreadedErrors = { ...errors };
        ['box', 'city', 'houseNumber', 'postalCode', 'street'].map(key => {
          delete spreadedErrors[key];
          return key;
        });
        setErrors(spreadedErrors);
      }}
    >
      {availableCountries.map(country => (
        <option key={country.Name} value={country.IsoCode}>
          {country.Name}
        </option>
      ))}
    </Select>
  );
};

export default SelectCountry;
