import { config, ts } from '~/services';

const defaultError = ts.t('Messages.Errors.DEFAULTERROR', {
  richContent: true,
  values: {
    href: config.errorContactUrl,
  },
});

export default defaultError;
