import { jsonTitles } from '../data';

/**
 * @param {string} strDomain
 * @param {string} subscriptionLink
 * @return {{}}
 */
const getDomainInfo = (strDomain, subscriptionLink) => {
  const strCheckLink = subscriptionLink;
  let data = {};

  const key = Object.keys(jsonTitles).find(title => title === strDomain);

  if (key) {
    data = jsonTitles[key];

    data.subscriptionpage = window.location.href.includes(strCheckLink);
  }

  return data;
};

export default getDomainInfo;
