import {
  space1,
  space3,
  space4,
  space5,
  colorRed30,
  colorGrey50,
  colorGrey90,
  colorRedBase,
  borderRadius2,
  colorWhiteBase,
  colorBlackBase,
  colorPrimary30,
  fontSizeMedium,
  lineHeightSmall,
  fontFamilySystem,
  fontWeightMedium,
  colorPrimaryBase,
  colorSecondaryBase,
} from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  /* Mollie */

  .mollie-component {
    background-color: ${colorWhiteBase};
    border-radius: ${borderRadius2};
    border: none;
    border: solid 1px ${colorGrey50};
    color: ${colorGrey90};
    font-family: ${fontFamilySystem};
    font-size: ${fontSizeMedium};
    padding: ${space4} ${space5};
    width: 100%;

    &:hover {
      border-color: ${colorPrimaryBase};
    }

    &.has-focus {
      box-shadow: 0 0 0 2px ${colorPrimary30};
      outline: none;
    }

    &.is-invalid {
      border-color: ${colorRedBase};

      &.has-focus {
        box-shadow: 0 0 0 2px ${colorRed30};
      }
    }
  }

  .mollie-label {
    display: inline-block;
    font-family: ${fontFamilySystem};
    font-size: ${fontSizeMedium};
    font-weight: ${fontWeightMedium};
    color: ${colorBlackBase};
    margin-right: ${space1};
    margin-bottom: ${space3};
    line-height: ${lineHeightSmall};
  }

  /* Misc */

  :target {
    border-radius: ${borderRadius2};
    animation: highlight 1500ms ease-out;
  }

  @keyframes highlight {
    from {
      background-color: ${colorSecondaryBase};
    }
  }
`;

export const HEADER_HEIGHT = '64px';
