/* eslint-disable babel/camelcase */
import {
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Text,
} from '@mediahuis/chameleon-react-legacy';
import { space5 } from '@mediahuis/chameleon-theme-wl/legacy/tokens';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import React, { useEffect, useState } from 'react';
import {
  CANCELLATION_STEPS,
  DS_ZADI_SUBSCRIPTION_TYPE,
} from '~/pages/Subscription/constants/onlineCancellation';
import MultibrandLogos from '~/pages/Subscription/components/DialogContent/OnlineCancellation/AlternativeOffer/MultibrandLogos';
import getNewsArticles from '~/api/getNewsArticles';
import { ContentLoader } from '~/components';
import ArticlesList from '~/pages/Subscription/components/DialogContent/OnlineCancellation/ArticlesList';
import { useGlobalContext } from '~/context';
import { pushTrackingEvent } from '~/utils/tealium';
import { getCancellationReasonCategory } from '~/pages/Subscription/utils/onlineCancellation';

const crossIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: space5, minWidth: '24px', minHeight: '24px' }}
  >
    <path
      d="M24 20.6667C24 22.508 22.508 24 20.6667 24H3.33333C1.492 24 0 22.508 0 20.6667V3.33333C0 1.492 1.492 0 3.33333 0H20.6667C22.508 0 24 1.492 24 3.33333V20.6667Z"
      fill="#F44336"
    />
    <path
      d="M7.21875 18.667L5.33316 16.7813L16.6669 5.44803L18.5525 7.33368L7.21875 18.667Z"
      fill="#FFEBEE"
    />
    <path
      d="M18.6836 16.6289L16.798 18.5146L5.40349 7.12044L7.28908 5.23479L18.6836 16.6289Z"
      fill="#FFEBEE"
    />
  </svg>
);
export default function UspSection({
  setOnlineCancellationStep,
  handleClose,
  articles,
  setArticles,
  selectedCancellationReason,
}) {
  const { chosenUserSubscription } = useGlobalContext();

  const [isLoading, setIsLoading] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);
  const getArticles = async () => {
    try {
      setIsLoading(true);
      const res = await getNewsArticles();
      setArticles(res);
    } catch (error) {
      setHasFailed(true);
    } finally {
      setIsLoading(false);
    }
  };
  const hasArticles = articles?.length > 0;
  useEffect(() => {
    if (!hasArticles) {
      getArticles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasArticles]);
  const defaultTrackingParams = {
    cancellation_step: 2,
    cancellation_reason: selectedCancellationReason?.label,
    cancellation_reason_id: selectedCancellationReason?.code,
    cancellation_reason_category: getCancellationReasonCategory(),
  };
  useEffect(() => {
    if (selectedCancellationReason) {
      pushTrackingEvent(chosenUserSubscription, {
        tealium_event: 'view_cancellation_show_loss',
        ...defaultTrackingParams,
      });
    }
  }, [selectedCancellationReason]);

  if (isLoading) {
    return <ContentLoader />;
  }
  const isZadi =
    chosenUserSubscription?.subscriptionType === DS_ZADI_SUBSCRIPTION_TYPE;
  return (
    <div>
      <Heading level={3} mb={6} fontFamily="primary">
        Het spijt ons dat te horen. Dit is wat we zullen stopzetten:
      </Heading>
      <ul>
        <li>
          <Flex>
            {crossIcon}
            <Heading level={5} mb={5} fontFamily="secondary">
              {isZadi
                ? 'De levering van de papieren krant op zaterdag en de digitale krant doorheen de week'
                : 'Je toegang tot de digitale krant, de digitale versie van de papieren krant'}
            </Heading>
          </Flex>
          <Divider mb={5} />
        </li>
        <li>
          <Flex>
            {crossIcon}
            <Flex flexDirection="column">
              <Heading level={5} mb={5} fontFamily="secondary">
                Je toegang tot de{' '}
                <img
                  src="https://shared.mediahuis.be/logos/ds/v3/brand-plus.svg"
                  style={{
                    verticalAlign: 'middle',
                  }}
                  height="16px"
                  alt="plusartikels"
                />{' '}
                plusartikels van De Standaard {!hasFailed && ', zoals deze:'}
              </Heading>
              {hasArticles && (
                <ArticlesList
                  articles={articles}
                  selectedCancellationReason={selectedCancellationReason}
                />
              )}
            </Flex>
          </Flex>
          <Divider mb={5} />
        </li>
        <li>
          <Flex>
            {crossIcon}
            <Flex flexDirection="column" mb={6}>
              <Heading level={5} mb={5} fontFamily="secondary">
                Je toegang tot de plusartikels van deze topkranten:
              </Heading>
              <MultibrandLogos
                brands={['nb', 'gva', 'hbvl', 'dg']}
                iconHeight={4}
                iconWidth={4}
                logoHeight={8}
                logoWidth={8}
                mb={5}
              />
              <Text
                size="Caption1"
                fontFamily="secondary"
                fontWeight="bold"
                mb={1}
              >
                Gebruik je deze functie nog niet?
              </Text>
              <Text
                size="Heading6"
                fontFamily="secondary"
                decoration="underline"
                as="a"
                href="https://www.standaard.be/activeerplus"
                target="_blank"
              >
                Breng het nu snel in orde{' '}
                <Icon as={ChevronForward} size="small" />
              </Text>
            </Flex>
          </Flex>
          <Button
            appearance="secondary"
            width="full"
            mb={5}
            onClick={() => {
              setOnlineCancellationStep(CANCELLATION_STEPS.THIRD_STEP);
              pushTrackingEvent(chosenUserSubscription, {
                tealium_event: 'click_continue_cancellation',
                ...defaultTrackingParams,
              });
            }}
          >
            Doorgaan met opzeggen
          </Button>
          <Button
            appearance="secondary"
            width="full"
            onClick={() => {
              handleClose();
              pushTrackingEvent(chosenUserSubscription, {
                tealium_event: 'click_keep_subscription',
                ...defaultTrackingParams,
              });
            }}
          >
            Toch behouden
          </Button>
        </li>
      </ul>
    </div>
  );
}
