/* eslint-disable babel/camelcase */
import apiCall from '~/utils/apiCall';
import { config, ts } from '~/services';

const language = ts.getLanguage().substring(0, 2);

const getAllFaqItems = () => {
  return apiCall(
    `${config.kbaHelperUrl}/articles/${config.brand}?scope=Eportal&language=${language}&isRichContent=false`,
    {
      method: 'GET',
    },
  );
};

export default getAllFaqItems;
