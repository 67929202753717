import React from 'react';
import StudentUploadDialog from '~/pages/Subscription/components/DialogContent/StudentUploadDialog';
import PhoneNumbersDialog from '~/pages/Subscription/components/DialogContent/PhoneNumbersDialog';
import EntitySwitch from '~/components/ah/EntitySwitch';
import AhAddressDialog from '~/pages/Subscription/ah/AhAddressDialog';
import CancelAddressChangeDialog from '~/pages/Subscription/ah/CancelAddressChangeDialog';
import OnlineCancellationDialog from '~/pages/Subscription/components/DialogContent/OnlineCancellation/OnlineCancellationDialog';
import AddressDialog from './AddressDialog';
import AccountDialog from './AccountDialog';
import PaymentDialog from './PaymentDialog';
import GiroPhaseOutDialog from './GiroPhaseOutDialog';
import { DIALOG_TYPES } from '../../constants';

const DialogContent = ({
  dialogType,
  setDialogShown,
  setNewReceiverAddress,
  setNewPayerAddress,
  reasons,
  editPayerPhoneNumbers,
  dialogShown,
  setRefreshAddressChangesHasFailed,
  files,
  setFiles,
  selectedAddressChangeToCancel,
}) => {
  const addressDialogProps = {
    setDialogShown,
    setNewReceiverAddress,
    setNewPayerAddress,
    isInvoice: dialogType === DIALOG_TYPES.ADDRESS_INVOICE,
  };
  switch (dialogType) {
    case DIALOG_TYPES.ADDRESS:
    case DIALOG_TYPES.ADDRESS_INVOICE:
      return (
        <EntitySwitch
          defaultComponent={<AddressDialog {...addressDialogProps} />}
          mhlu={
            <AhAddressDialog
              {...addressDialogProps}
              setRefreshAddressChangesHasFailed={
                setRefreshAddressChangesHasFailed
              }
            />
          }
        />
      );
    case DIALOG_TYPES.ACCOUNT:
      return (
        <AccountDialog setDialogShown={setDialogShown} reasons={reasons} />
      );
    case DIALOG_TYPES.PAYMENT:
      return <PaymentDialog setDialogShown={setDialogShown} />;
    case DIALOG_TYPES.GIRO_PHASE_OUT:
      return <GiroPhaseOutDialog setDialogShown={setDialogShown} />;
    case DIALOG_TYPES.STUDENT_UPLOAD:
      return <StudentUploadDialog files={files} setFiles={setFiles} />;
    case DIALOG_TYPES.PHONE_NUMBERS:
      return (
        <PhoneNumbersDialog
          editPayerPhoneNumbers={editPayerPhoneNumbers}
          setDialogShown={setDialogShown}
          dialogShown={dialogShown}
        />
      );
    case DIALOG_TYPES.CANCEL_PAYER_ADDRESS_CHANGE:
    case DIALOG_TYPES.CANCEL_RECEIVER_ADDRESS_CHANGE:
      return (
        <CancelAddressChangeDialog
          setDialogShown={setDialogShown}
          setNewReceiverAddress={setNewReceiverAddress}
          setNewPayerAddress={setNewPayerAddress}
          isPayer={dialogType === DIALOG_TYPES.CANCEL_PAYER_ADDRESS_CHANGE}
          setRefreshAddressChangesHasFailed={setRefreshAddressChangesHasFailed}
          selectedAddressChangeToCancel={selectedAddressChangeToCancel}
        />
      );
    case DIALOG_TYPES.ONLINCE_CANCELLATION:
      return (
        <OnlineCancellationDialog
          setDialogShown={setDialogShown}
          dialogShown={dialogShown}
        />
      );
    default:
      return null;
  }
};

export default DialogContent;
