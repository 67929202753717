export const BROWSERS = [
  { value: 'InternetExplorer', label: 'Internet Explorer' },
  { value: 'MozillaFirefox', label: 'Mozilla Firefox ' },
  { value: 'Chrome', label: 'Google Chrome ' },
  { value: 'Safari', label: 'Safari ' },
  { value: 'Unknown', label: 'Ik weet het niet' },
];

export const OPERATING_SYSTEMS = [
  { value: 'Windows', label: 'Microsoft Windows' },
  { value: 'MacOS', label: 'mac OS van Apple' },
  { value: 'ChromeOS', label: 'Chrome OS van Google' },
  { value: 'Linux', label: 'Linux' },
  { value: 'Unknown', label: 'Ik weet het niet' },
];
