/**
 * @typedef SRConfig
 * @property {string} siteUrl
 * @property {string} brandNameFull
 * @property {string} logoVersion
 * @property {string} urlPrefix
 * @property {string} apiPrefix
 */

/**
 * @param {SRConfig} config
 * @returns {string}
 */
export const getSubscriptionUrl = config => {
  return `https://${config.apiPrefix}subscriptionservice.${config.siteUrl}/api/subscription`;
};
