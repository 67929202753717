/* eslint-disable eqeqeq */
import { STATUSES } from '../enums';
import getPublicHolidayName from './getPublicHolidayName';

const isOpen = ({ arrOpenHours, strTypeCheck, dToday, widgetData }) => {
  const { OPENSOON, HOLIDAY } = STATUSES;
  const iOpenHour = arrOpenHours[0].split('h')[0];
  const iOpenMinutes = arrOpenHours[0].split('h')[1];
  const iClosedHour = arrOpenHours[1].split('h')[0];
  const iClosedMinutes = arrOpenHours[1].split('h')[1];

  switch (strTypeCheck) {
    case OPENSOON:
      if (
        dToday.getHours() === parseInt(iOpenHour, 10) &&
        dToday.getMinutes() < parseInt(iOpenMinutes, 10)
      ) {
        return true;
      }
      if (dToday.getHours() < parseInt(iOpenHour, 10)) {
        return true;
      }
      return false;
    case HOLIDAY:
      if (getPublicHolidayName({ dToday, widgetData }) === '') {
        return false;
      }
      return true;
    default:
      // "OPEN"
      if (
        dToday.getHours() == parseInt(iOpenHour, 10) &&
        dToday.getMinutes() < parseInt(iOpenMinutes, 10)
      ) {
        return false;
      }
      if (
        dToday.getHours() == parseInt(iClosedHour, 10) &&
        dToday.getMinutes() > parseInt(iClosedMinutes, 10)
      ) {
        return false;
      }
      if (
        dToday.getHours() >= parseInt(iOpenHour, 10) &&
        dToday.getHours() < parseInt(iClosedHour, 10)
      ) {
        return true;
      }
      return false;
  }
};

export default isOpen;
