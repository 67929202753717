import { RECURRING } from '../../../constants/payment';

const paymentIntervalMap = {
  [RECURRING]: 'Domic',
};

const getPaymentIntervalMapping = chosenPaymentInterval => {
  return paymentIntervalMap[chosenPaymentInterval] || 'Overschr';
};

export default getPaymentIntervalMapping;
