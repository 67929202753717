import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { scrollTopWithTimeout } from '~/utils';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    scrollTopWithTimeout();
  }, [pathname]);

  return null;
};

export default ScrollToTop;
