const { PAGES } = require('~/constants/pages');

export const EXTERNAL_CASE_TYPES = {
  ROLE_CHANGE: 'RoleChange',
  ADDRESS_CHANGE: 'AddressChange',
  TEMPORARY_DELIVERY_CHANGE: 'TemporaryDeliveryChange',
  SUBSCRIPTION_SELECT: 'SubscriptionSelect',
};
export const APORTAL_REDIRECT_MAP = {
  AddressChange: PAGES.customerDetails,
  TemporaryDeliveryChange: PAGES.temporaryDeliveryChanges,
  RoleChange: PAGES.subscriptions,
  SubscriptionSelect: PAGES.subscriptions,
};

export const APORTAL_PAYER_READER_STATUS = {
  IS_READER: 0,
  IS_PAYER: 1,
  IS_PAYER_AND_READER: 2,
};

export const COULD_NOT_UPDATE_IN_DYNAMICS_ERROR = 'Could not update in D365';
