import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Box, Heading, Table } from '@mediahuis/chameleon-react-legacy';
import { isMobileOnly } from 'react-device-detect';
import { BANNER_TYPES, DATE_FORMAT_BE } from '~/constants';
import { TableMobile } from '~/components';
import { useBanner, useGlobalContext, useHolidayContext } from '~/context';
import cleanHolidayData from '../utils/cleanHolidayData';
import getAllHolidays from '../utils/getAllHolidays';
import sortHolidays from '../utils/sortHolidays';
import { HOLIDAY_STATES, holidayTableColumns } from '../data';
import getLanguage from '../language';

const PastHolidays = ({ onAction, setChosenHoliday }) => {
  const holidayContext = useHolidayContext();
  const globalContext = useGlobalContext();

  const { chosenUserSubscription } = globalContext;
  const language = getLanguage(
    chosenUserSubscription?.internalSubscriptionType,
  );
  const { holidayArrangementOptions } = holidayContext;
  const { showBanner } = useBanner();

  const [pastHolidays, setPastHolidays] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchPastHolidays = async () => {
    if (holidayArrangementOptions) {
      try {
        /* Get holiday history */
        const historyResponse = await getAllHolidays({
          subscriptionId: chosenUserSubscription.id,
          /* eslint-disable-next-line babel/camelcase */
          max_end: dayjs(new Date()).format(DATE_FORMAT_BE),
          states: HOLIDAY_STATES.CONFIRMED,
        });
        const cleanedHolidayData = cleanHolidayData({
          holidayData: historyResponse,
          holidayOptions: holidayArrangementOptions,
          onAction,
          setChosenHoliday,
        });
        const sortedHolidayData = sortHolidays(cleanedHolidayData, 'asc');
        setPastHolidays({
          data: sortedHolidayData,
        });
      } catch (error) {
        setPastHolidays({
          data: null,
          message: language.messages.noVacationHistory,
        });
        if (error.status !== 404) {
          showBanner({
            message: language.messages.noVacationError,
            type: BANNER_TYPES.ERROR,
          });
        }
      }
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    await fetchPastHolidays();
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenUserSubscription]);

  return (
    <Box pb={10}>
      <Box pb={5}>
        <Heading fontWeight="bold" level="4" fontFamily="primary">
          {language.pastHolidaysHeading}
        </Heading>
      </Box>
      {isMobileOnly ? (
        <TableMobile
          columns={holidayTableColumns}
          data={pastHolidays.data}
          message={pastHolidays.message}
          loading={isLoading}
        />
      ) : (
        <Table
          columns={holidayTableColumns}
          data={pastHolidays.data}
          emptyDescription={pastHolidays.message}
          tableLayout="fixed"
          loading={isLoading}
        />
      )}
    </Box>
  );
};

export default PastHolidays;
