import { ts } from '~/services';

const base = {
  box: ts.t('AddressFields.Box'),
  country: ts.t('AddressFields.Country'),
  countryMessage: ts.t('AddressFields.CountryMessage'),
  countryIsRequired: ts.t('AddressFields.CountryIsRequired'),
  countryIsNotAllowed: ts.t('AddressFields.CountryIsNotAllowed'),
  street: ts.t('AddressFields.Street'),
  city: ts.t('AddressFields.City'),
  optional: ts.t('AddressFields.Optional'),
  addition: ts.t('AddressFields.Addition'),
  postalCode: ts.t('AddressFields.PostalCode'),
  houseNumber: ts.t('AddressFields.HouseNumber'),
  postalCodeIsRequired: ts.t('AddressFields.PostalCodeIsRequired'),
  streetIsRequired: ts.t('AddressFields.StreetIsRequired'),
  houseNumberIsRequired: ts.t('AddressFields.HouseNumberIsRequired'),
  cityIsRequired: ts.t('AddressFields.CityNumberIsRequired'),
  postalCodeOptionalLabel: ts.t('AddressFields.PostalCodeOptionalLabel'),
  postalCodeHouseNumberComboNotFound: ts.t(
    'AddressFields.PostalCodeHouseNumberNotFound',
  ),
  postalCodeIsNotInDeliveryArea: ts.t(
    'AddressFields.PostalCodeIsNotInDeliveryArea',
  ),
  invalid: {
    country: ts.t('AddressFields.CountryIsNotValid'),
    city: ts.t('AddressFields.CityIsNotValid'),
    postalcode: ts.t('AddressFields.PostalCodeIsNotValid'),
    street: ts.t('AddressFields.StreetIsNotValid'),
    number: ts.t('AddressFields.HouseNumberIsNotValid'),
  },
};

export default base;
