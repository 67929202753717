import { Box, LinkText } from '@mediahuis/chameleon-react-legacy';
import { useFlag } from '@unleash/proxy-client-react';
import { FEATURE_FLAG_CONSENT_CHOICES_TEST_LINK } from '~/constants/featureSwitch';
import React from 'react';
import { getUserStatus } from '~/components/Didomi/getUserStatus';
import { environment } from '~/services';

export default function TestLinkWithUserChoices() {
  const isFeatureFlagEnabled = useFlag(FEATURE_FLAG_CONSENT_CHOICES_TEST_LINK);
  return (
    !environment.isProd &&
    isFeatureFlagEnabled && (
      <Box mt={5}>
        <LinkText
          href={`https://${
            environment.apiPrefix
          }my.mediahuis.com/be?didomiConfig.user.externalConsent.value=${getUserStatus()}`}
          target="_blank"
        >
          Link to My Mediahuis with user choices (Didomi)
        </LinkText>
      </Box>
    )
  );
}
