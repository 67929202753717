import React from 'react';
import { Box, Textarea } from '@mediahuis/chameleon-react-legacy';
import useDeliveryComplaintContext from '~/context/hooks/useDeliveryComplaintContext';
import { ts } from '~/services';
import { ERROR_TOO_MANY_CHARACTERS, WRONG_MAILBOX } from '../constants';

const Comment = () => {
  const {
    deliveryComplaintState,
    setDeliveryComplaintState,
    deliveryComplaintErrors,
    setDeliveryComplaintErrors,
  } = useDeliveryComplaintContext();

  const getLabel = () => {
    if (deliveryComplaintState?.chosenComplaint?.complaint === WRONG_MAILBOX) {
      return ts.t('DeliveryComplaint.Labels.CommentWrongMailBox');
    }
    return ts.t('DeliveryComplaint.Labels.Comment');
  };

  return (
    <Box mb={5}>
      <Textarea
        id="comment"
        data-testid="comment"
        label={getLabel()}
        onChange={e => {
          if (e.target.value.length > 200) {
            setDeliveryComplaintErrors({
              comment: ERROR_TOO_MANY_CHARACTERS,
            });
          } else if (deliveryComplaintErrors.comment) {
            const cleanedUpDeliveryComplaintErrors = deliveryComplaintErrors;
            delete cleanedUpDeliveryComplaintErrors.comment;
            setDeliveryComplaintErrors({
              ...cleanedUpDeliveryComplaintErrors,
            });
          }
          return setDeliveryComplaintState({
            comment: e.target.value,
          });
        }}
        value={deliveryComplaintState.comment}
        error={deliveryComplaintErrors.comment}
        message={deliveryComplaintErrors.comment}
      />
    </Box>
  );
};

export default Comment;
