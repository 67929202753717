import React, { useCallback, useEffect } from 'react';
import { Box, Icon, Text } from '@mediahuis/chameleon-react-legacy';
import CountryAutoComplete from '~/components/aportal/CountryAutoComplete';
import ahAddressFieldsValidations from '~/components/AddressFields/ahAddressFieldsValidations';
import { Info } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import { config, ts } from '~/services';
import SelectCountry from '~/components/AddressFields/SelectCountry';
import noop from '~/components/AddressFields/noop';
import BelgianAddressFields from '../../AddressFields/BelgianAddressFields';
import DefaultAddresFields from '../../AddressFields/DefaultAddresFields';
import DutchAddressFields from '../../AddressFields/DutchAddressFields';
import LuxemburgsAddressFields from '../../AddressFields/LuxemburgsAddressFields';

const AhAddressFields = ({
  setAddress,
  setErrors,
  allowedCountries,
  isChangingAddress,
  setDialogShown,
  setCountryError = noop,
  ...props
}) => {
  const handleAddressChange = useCallback(
    data => setAddress(state => ({ ...state, ...data })),
    [setAddress],
  );

  const clearError = e => {
    const spreadedErrors = { ...props.errors };
    delete spreadedErrors[e.target.id];
    setErrors(spreadedErrors);
  };

  const clearFields = () => {
    const fields = ['houseNumber', 'box', 'street', 'city', 'postalCode'];
    const spreadedState = { ...props.address };
    fields.forEach(field => {
      spreadedState[field] = '';
    });
    handleAddressChange(spreadedState);
  };

  const validateOnBlur = async e => {
    const addressErrors = await ahAddressFieldsValidations({
      fields: { [e.target.id]: e.target.value },
      country: props.address.countryCode,
    });

    setErrors(prevState => {
      return {
        ...prevState,
        ...addressErrors,
      };
    });
  };

  const getCountryAddressField = () => {
    switch (props.address.countryCode) {
      case 'BE':
        return (
          <BelgianAddressFields
            {...props}
            clearFields={clearFields}
            handleAddressChange={handleAddressChange}
            clearError={clearError}
            validateOnBlur={validateOnBlur}
            setErrors={setErrors}
          />
        );
      case 'NL':
        return (
          <DutchAddressFields
            {...props}
            clearFields={clearFields}
            handleAddressChange={handleAddressChange}
            clearError={clearError}
            validateOnBlur={validateOnBlur}
            setErrors={setErrors}
          />
        );
      case 'LU':
        return (
          <LuxemburgsAddressFields
            {...props}
            clearFields={clearFields}
            handleAddressChange={handleAddressChange}
            clearError={clearError}
            validateOnBlur={validateOnBlur}
            setErrors={setErrors}
          />
        );
      default:
        return (
          <DefaultAddresFields
            {...props}
            clearFields={clearFields}
            handleAddressChange={handleAddressChange}
            clearError={clearError}
            validateOnBlur={validateOnBlur}
          />
        );
    }
  };
  const onlyLuCountryIsAllowedMessage = ts.t(
    isChangingAddress
      ? 'Subscription.AddressOutsideLU'
      : 'Holiday.AddressOutsideLU',
    {
      richContent: true,
      values: {
        href: '#',
      },
    },
  );

  useEffect(() => {
    if (config.isAportal) {
      return;
    }
    const link = document.getElementById('link-to-contact-section');
    const contactSection = document.getElementById('customer-service');
    const handleClick = () => {
      setDialogShown(false);
      const timeout = setTimeout(() => {
        contactSection.scrollIntoView();
        clearTimeout(timeout);
      }, 100);
    };
    link.addEventListener('click', handleClick);
    // eslint-disable-next-line consistent-return
    return function cleanup() {
      link?.removeEventListener('click', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box mb={4}>
        {config.isAportal ? (
          <CountryAutoComplete
            value={props.address.countryCode}
            allowedCountries={allowedCountries}
            setErrors={setErrors}
            setCountryError={setCountryError}
            onChange={country => {
              handleAddressChange({
                box: '',
                city: '',
                houseNumber: '',
                postalCode: '',
                street: '',
                countryCode: country?.IsoCode,
                country: country?.Name,
              });

              const spreadedErrors = { ...props.errors };
              ['box', 'city', 'houseNumber', 'postalCode', 'street'].map(
                key => {
                  delete spreadedErrors[key];
                  return key;
                },
              );
              setErrors(spreadedErrors);
            }}
          />
        ) : (
          <SelectCountry
            address={props.address}
            handleAddressChange={handleAddressChange}
            allowedCountries={allowedCountries}
            setErrors={setErrors}
            errors={props.errors}
          />
        )}
      </Box>
      {!config.isAportal && (
        <Box mb={4} display="flex">
          <Icon as={Info} mr={3} color="colorPrimaryBase" />
          <Text size="Caption1">{onlyLuCountryIsAllowedMessage}</Text>
        </Box>
      )}
      {getCountryAddressField()}
    </>
  );
};

export default AhAddressFields;
