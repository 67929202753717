import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Select,
  TextField,
  Tooltip,
} from '@mediahuis/chameleon-react-legacy';
import {
  ChevronBack,
  ChevronForward,
} from '@mediahuis/chameleon-theme-wl/legacy/icons';
import useHolidayContext from '~/context/hooks/useHolidayContext';
import { validateAddresseeStepsData } from '~/utils';
import addressFieldsValidations from '~/components/AddressFields/addressFieldsValidations';
import AddressFields from '~/components/AddressFields';
import { ts } from '~/services';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import {
  AH_HOLIDAY_SOLUTIONS,
  HOLIDAY_SOLUTIONS,
  HOLIDAY_STEPS,
  salutationSelectOptions,
} from '../../../data';
import getEmptySolutionData from '../../../utils/getEmptySolutionData';

const AddressStep = ({ submissionData, setSubmissionData, setCurrentStep }) => {
  const holidayContext = useHolidayContext();
  const { holidayArrangementOptions } = holidayContext;
  const { chosenUserSubscription } = useGlobalContext();

  // Here we work with local state as the AddressFields component
  // expects this.
  const [localAddress, setLocalAddress] = useState({
    title: salutationSelectOptions[0].value,
    firstName: '',
    lastName: '',
    countryCode: '',
    postalCode: '',
    city: '',
    street: '',
    houseNumber: '',
    box: '',
    ...submissionData.solutionData,
  });
  const [localErrors, setLocalErrors] = useState({});

  useEffect(() => {
    setSubmissionData(prevState => ({
      ...prevState,
      solutionData: {
        ...localAddress,
      },
    }));
  }, [localAddress, setSubmissionData]);

  const validateOnBlur = e => {
    setLocalErrors({
      ...localErrors,
      ...validateAddresseeStepsData({
        [e.target.name]: e.target.value,
      }),
    });
  };

  const clearValidationOnFocus = e => {
    const spreadedErrors = { ...localErrors };
    delete spreadedErrors[e.target.name];
    setLocalErrors(spreadedErrors);
  };

  const onNextStep = () => {
    setCurrentStep(HOLIDAY_STEPS.SUMMARY_STEP);
  };

  const isFamilyAddress =
    submissionData.vacationSolution === HOLIDAY_SOLUTIONS.FAMILY_ADDRESS ||
    submissionData.vacationSolution === AH_HOLIDAY_SOLUTIONS.FAMILY_ADDRESS;

  const isHolidayAddress =
    submissionData.vacationSolution === HOLIDAY_SOLUTIONS.HOLIDAY_ADDRESS ||
    submissionData.vacationSolution === AH_HOLIDAY_SOLUTIONS.HOLIDAY_ADDRESS;

  const validateStep = async () => {
    const addresseeErrors =
      isFamilyAddress &&
      validateAddresseeStepsData({
        firstName: submissionData.solutionData.firstName,
        lastName: submissionData.solutionData.lastName,
      });

    const requestOrigin = 'holiday';

    const addressErrors = await addressFieldsValidations(
      localAddress,
      localAddress.countryCode,
      chosenUserSubscription,
      requestOrigin,
    );

    const combinedErrors = {
      ...(isFamilyAddress && addresseeErrors),
      ...addressErrors,
    };
    if (Object.keys(combinedErrors).length === 0) {
      onNextStep();
    }
    return setLocalErrors({ ...combinedErrors });
  };

  const handleOnPreviousStep = () => {
    if (isFamilyAddress || isHolidayAddress) {
      if (
        (isFamilyAddress &&
          holidayArrangementOptions?.knownFamilyAddresses?.length > 0) ||
        (isHolidayAddress &&
          holidayArrangementOptions?.knownHolidayAddresses?.length > 0)
      ) {
        setSubmissionData(prevState => ({
          ...prevState,
          solutionData: getEmptySolutionData(),
        }));
        setCurrentStep(HOLIDAY_STEPS.ADDRESS_CHOICE_STEP);
      } else {
        setCurrentStep(HOLIDAY_STEPS.CHOICE_STEP);
      }
    } else {
      setCurrentStep(HOLIDAY_STEPS.CHOICE_STEP);
    }
  };

  return (
    <React.Fragment>
      <Heading level={3} mb={6} fontFamily="primary" color="colorPrimaryBase">
        {ts.t('Holiday.AddressStep.ForwardPaper')}
      </Heading>
      {isFamilyAddress && (
        <Box mb={8}>
          <Select
            id="salutation"
            value={submissionData.solutionData.title}
            onChange={e => {
              const value = e.target.value;
              return setLocalAddress(prevState => ({
                ...prevState,
                title: value,
              }));
            }}
            label={ts.t('Holiday.AddressStep.Salutation')}
            mb={3}
          >
            {salutationSelectOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          <TextField
            id="first-name-textfield"
            label={ts.t('Holiday.AddressStep.Firstname')}
            mb={3}
            name="firstName"
            value={submissionData.solutionData.firstName}
            onChange={e => {
              const value = e.target.value;
              return setLocalAddress(prevState => ({
                ...prevState,
                firstName: value,
              }));
            }}
            onBlur={validateOnBlur}
            onFocus={clearValidationOnFocus}
            message={localErrors.firstName}
            error={!!localErrors.firstName}
          />
          <TextField
            id="last-name-textfield"
            label={ts.t('Holiday.AddressStep.LastName')}
            mb={3}
            name="lastName"
            value={submissionData.solutionData.lastName}
            onChange={e => {
              const value = e.target.value;
              return setLocalAddress(prevState => ({
                ...prevState,
                lastName: value,
              }));
            }}
            onBlur={validateOnBlur}
            onFocus={clearValidationOnFocus}
            message={localErrors.lastName}
            error={!!localErrors.lastName}
          />
        </Box>
      )}
      <AddressFields
        allowedCountries={holidayArrangementOptions?.allowedCountries}
        address={localAddress}
        setAddress={setLocalAddress}
        errors={localErrors}
        setErrors={setLocalErrors}
      />
      <TextField
        id="extra-textfield"
        label={
          <React.Fragment>
            <span style={{ marginRight: '2px' }}>
              {ts.t('Holiday.AddressStep.ExtraAdressLine')}
            </span>
            <Tooltip
              id="extraInfo"
              label={ts.t('Holiday.AddressStep.ExtraAdressLineTooltip')}
              usePortal
            />
          </React.Fragment>
        }
        mb={3}
        name="extra"
        maxLength={60}
        value={submissionData.solutionData.extra}
        onChange={e => {
          const value = e.target.value;
          return setLocalAddress(prevState => ({
            ...prevState,
            extra: value,
          }));
        }}
      />
      <Flex mt={5} justifyContent="flex-end">
        <Button mr={3} onClick={handleOnPreviousStep} iconLeft={ChevronBack}>
          {ts.t('Common.Back')}
        </Button>
        <Button onClick={validateStep} iconRight={ChevronForward}>
          {submissionData?.id ? ts.t('Common.Edit') : ts.t('Common.Next')}
        </Button>
      </Flex>
    </React.Fragment>
  );
};

export default AddressStep;
