import React from 'react';
import { CREDIT_CARD, DIRECT_DEBIT } from '~/constants/payment';
import CreditCard from './CreditCard';
import DirectDebit from './DirectDebit';

const PaymentProviders = ({ method, setDialogShown }) => {
  switch (method) {
    case DIRECT_DEBIT:
      return <DirectDebit setDialogShown={setDialogShown} />;
    case CREDIT_CARD:
      return <CreditCard setDialogShown={setDialogShown} />;
    default:
      return null;
  }
};

export default PaymentProviders;
