import { CATEGORY_ID_BY_PAGE } from '~/components/DynamicFaq/constants';

export const getSelectedArticle = (category, internalArticleId) => {
  return (
    category?.knowledgeArticles.find(
      knowledgeArticle =>
        knowledgeArticle.articleInternalNumber === internalArticleId,
    ) ||
    category?.subCategories
      .find(subCategory =>
        subCategory.knowledgeArticles.some(
          a => a.articleInternalNumber === internalArticleId,
        ),
      )
      ?.knowledgeArticles.find(
        knowledgeArticle =>
          knowledgeArticle.articleInternalNumber === internalArticleId,
      )
  );
};

export const getSelectedCategory = (categories, articleInternalNumber) =>
  categories.find(category =>
    getSelectedArticle(category, articleInternalNumber),
  );

export const getSelectedArticleIndex = (questions, selectedArticle) =>
  questions.findIndex(
    question =>
      question.articleInternalNumber === selectedArticle?.articleInternalNumber,
  );

export const sortByOrder = list => {
  return list.sort((a, b) => a.order - b.order);
};

export const getCategoryIdByPathname = pathname => {
  return CATEGORY_ID_BY_PAGE[pathname];
};
