import { ts } from '~/services';

export const OBJECT_PRONOUN = ts.t('Constants.OBJECT_PRONOUN');
export const PERSONAL_PRONOUN = ts.t('Constants.PERSONAL_PRONOUN');
export const POSSESSIVE_ADJECTIVE = ts.t('Constants.POSSESSIVE_ADJECTIVE');
export const VERB_TO_BE = ts.t('Constants.VERB_TO_BE');
export const VERB_TO_HAVE = ts.t('Constants.VERB_TO_HAVE');
export const VERB_TO_HAVE_IMPERATIVE = ts.t(
  'Constants.VERB_TO_HAVE_IMPERATIVE',
);
export const VERB_TO_AVOID = ts.t('Constants.VERB_TO_AVOID');
export const VERB_TO_CAN = ts.t('Constants.VERB_TO_CAN');
export const VERB_SHALL_IMPERATIVE = ts.t('Constants.VERB_SHALL_IMPERATIVE');
export const VERB_TO_WISH_IMPERATIVE = ts.t(
  'Constants.VERB_TO_WISH_IMPERATIVE',
);
