import { ts } from '~/services';
import getIsIbanCountryAllowed from './getIsIbanCountryAllowed';

const getDirectDebitErrors = cardNumber => {
  const errors = {};
  const IBANRegex = /^([A-Z]{2}[ \\-]?[0-9]{2})(?=(?:[ \\-]?[A-Z0-9]){9,30}$)((?:[ \\-]?[A-Z0-9]{3,5}){2,7})([ \\-]?[A-Z0-9]{1,3})?$/;

  if (!getIsIbanCountryAllowed(cardNumber)) {
    errors.cardNumber = ts.t(
      'Subscription.PaymentProviders.CardNumberErrors.AllowedCountry',
    );
  }
  if (!IBANRegex.test(cardNumber)) {
    errors.cardNumber = ts.t(
      'Subscription.PaymentProviders.CardNumberErrors.Format',
    );
  }
  if (!cardNumber || cardNumber.length === 0) {
    errors.cardNumber = ts.t(
      'Subscription.PaymentProviders.CardNumberErrors.Empty',
    );
  }

  return errors;
};

export default getDirectDebitErrors;
