import {
  colorGrey10,
  colorGrey70,
} from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';
import styled from 'styled-components';

export const Section = styled.section`
  padding: 20px;
  background-color: ${colorGrey10};
`;
export const Li = styled.li`
  margin-bottom: 20px;
`;
export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 1px;
  border-color: ${colorGrey70};
  border-style: dashed;
  background-color: ${colorGrey10};
  outline: none;
  transition: border 0.24s ease-in-out;
  opacity: ${props => (props.disabled ? 0.3 : 1)};
`;
