import { BRANDS } from '~/constants';
import { USECASES } from './constants';

export const touchPointIds = {
  [BRANDS.DS]: {
    [USECASES.DELIVERYCOMPLAINT]: '55e911a3-ab9a-4f4a-aa4e-0625b547d58f',
    [USECASES.HOLIDAY]: '2e1fe1c8-7cb0-4779-8b4c-7d709774ae1a',
  },
  [BRANDS.GVA]: {
    [USECASES.DELIVERYCOMPLAINT]: '912cb1f8-7a5e-4502-a689-38c444faa8a5',
    [USECASES.HOLIDAY]: 'fd769603-7f86-4820-bad0-437b4fdf2b1c',
  },
  [BRANDS.HBVL]: {
    [USECASES.DELIVERYCOMPLAINT]: '0f5a0f08-64c3-46e9-a50e-d82e00c6d219',
    [USECASES.HOLIDAY]: '32afbb60-f296-49d7-804d-7885a332ebe6',
  },
  [BRANDS.NB]: {
    [USECASES.DELIVERYCOMPLAINT]: 'a27efa14-0374-4d4e-97a3-1aa58744464b',
    [USECASES.HOLIDAY]: '20b578bd-7871-48b9-9c05-dfe680f2fd89',
  },
  TEST: {
    [USECASES.DELIVERYCOMPLAINT]: '10bb16da-b0d5-4e04-8b95-911318d6d763',
    [USECASES.HOLIDAY]: '210ebaee-8af7-4188-a928-99e7f12deec8',
  },
};
