import { config } from '~/services';

export const FEATURE_FLAG_MAINTENANCE_MODE_BRAND = `maintenance-${MH_BRAND}`;
export const FEATURE_FLAG_MAINTENANCE_MODE_ENTITY = `maintenance-entity-${config.entity}`;
export const FEATURE_FLAG_OUTSTANDING_PAYMENT_BRAND = `outstanding-payment-${MH_BRAND}`;
export const FEATURE_FLAG_OUTSTANDING_PAYMENT_ENTITY = `outstanding-payment-entity-${config.entity}`;
export const FEATURE_FLAG_ROLE_CHANGE = 'rolechange-aportal';

export const FEATURE_FLAG_SMART_WEBFORM_ENTITY = `smart-webform-entity-${config.entity}`;
export const FEATURE_FLAG_ONLINE_CANCELLATION_ENTITY = `online-cancellation-entity-${config.entity}`;
export const FEATURE_FLAG_CONTACT_FORM_MHLI = 'contact-form-mhli';
export const FEATURE_FLAG_ONLINE_CANCELLATION_YOUTH =
  'online-cancellation-youth';
export const FEATURE_FLAG_CONSENT_CHOICES_TEST_LINK =
  'consent-choices-test-link';

export const getFlagOutstandingPayment = ({ isEntity = false, env }) => {
  if (isEntity) return `outstanding-payment-entity-${env}`;
  return `outstanding-payment-${env}`;
};
export const getFlagSmartWebform = ({ isEntity = false, env }) => {
  if (isEntity) return `smart-webform-entity-${env}`;
  return `smart-webform-${env}`;
};
export const getFlagOnlineCancellation = ({ isEntity = false, env }) => {
  if (isEntity) return `online-cancellation-entity-${env}`;
  return `online-cancellation-${env}`;
};

export const featureSwitchConfig = {
  url: config.featureSwithUrl,
  clientKey: 'eloket-client',
  refreshInterval: 15 * 60, // IN SECONDS --> POLL EVERY 15 MINUTES
  appName: 'eportal',
};
