export const LOCALES = Object.freeze({
  BE: 'nl_BE',
  NL: 'nl_NL',
  DE: 'de_DE',
});

export const COUNTRIES = Object.freeze({
  BE: 'BE',
  NL: 'NL',
  DE: 'DE',
  LU: 'LU',
});
