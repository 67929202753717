/* eslint-disable babel/camelcase */
import {
  Box,
  Button,
  Heading,
  Textarea,
  WideList,
  WideListItem,
} from '@mediahuis/chameleon-react-legacy';
import {
  ChevronForward,
  ChevronDown,
} from '@mediahuis/chameleon-theme-wl/legacy/icons';
import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '~/context';
import {
  CANCELLATION_REASONS,
  CANCELLATION_STEPS,
  DEATH_REASON,
  MEDICAL_REASON,
  OTHER_CANCELLATION_REASON,
  OTHER_CANCELLATION_REASON_LABEL,
  OTHER_REASON,
} from '~/pages/Subscription/constants/onlineCancellation';
import {
  filterReasons,
  getCancellationReasonCategory,
  sortReasons,
} from '~/pages/Subscription/utils/onlineCancellation';
import { clearValidationOnFocus } from '~/utils';
import { pushTrackingEvent } from '~/utils/tealium';

export default function FirstStep({
  setSelectedCancellationReason,
  setOnlineCancellationStep,
  errors,
  setErrors,
  reasonDetails,
  setReasonDetails,
  dialogShown,
}) {
  const { userInfo, chosenUserSubscription } = useGlobalContext();
  const [showTextArea, setShowTextArea] = useState(false);

  const handleClick = reason => {
    const defaultTrackingParams = {
      tealium_event: 'click_cancellation_reason',
      cancellation_step: 1,
      cancellation_reason: reason.label,
      cancellation_reason_id: reason.code,
      cancellation_reason_position: reason.position,
    };
    if (reason.code === OTHER_REASON) {
      pushTrackingEvent(chosenUserSubscription, {
        ...defaultTrackingParams,
        cancellation_reason_category: getCancellationReasonCategory({
          otherReason: true,
        }),
      });
      setShowTextArea(!showTextArea);
      return;
    }
    const medicalOrDeathReason = [DEATH_REASON, MEDICAL_REASON].includes(
      reason.code,
    );

    if (medicalOrDeathReason) {
      pushTrackingEvent(chosenUserSubscription, {
        ...defaultTrackingParams,
        cancellation_reason_category: getCancellationReasonCategory({
          medicalOrDeathReason: true,
        }),
      });
    } else {
      pushTrackingEvent(chosenUserSubscription, {
        ...defaultTrackingParams,
        cancellation_reason_category: getCancellationReasonCategory(),
      });
    }
    setSelectedCancellationReason(reason);
    setOnlineCancellationStep(CANCELLATION_STEPS.SECOND_STEP);
  };
  const filteredAndSortedReasons = sortReasons(
    filterReasons(CANCELLATION_REASONS, chosenUserSubscription),
  );

  useEffect(() => {
    if (dialogShown) {
      pushTrackingEvent(chosenUserSubscription, {
        tealium_event: 'view_cancellation',
        cancellation_step: 0,
      });
    }
  }, [dialogShown]);

  useEffect(() => {
    if (showTextArea) {
      pushTrackingEvent(chosenUserSubscription, {
        tealium_event: 'view_other_cancellation_reason',
        cancellation_step: 1,
        cancellation_reason: OTHER_CANCELLATION_REASON_LABEL,
        cancellation_reason_id: OTHER_REASON,
        cancellation_reason_category: getCancellationReasonCategory({
          otherReason: true,
        }),
      });
    }
  }, [showTextArea]);

  return (
    <Box>
      <Heading level={3} mb={6} fontFamily="primary">
        Dag {userInfo?.firstName}, wat is de reden dat je je abonnement wenst op
        te zeggen?
      </Heading>
      <WideList
        elevation={1}
        style={{
          border: '1px solid #E0DAD1',
        }}
      >
        {filteredAndSortedReasons.map(reason => {
          return (
            <React.Fragment key={reason.label}>
              <WideListItem
                title={reason.label}
                iconRight={
                  showTextArea && reason.code === OTHER_REASON
                    ? ChevronDown
                    : ChevronForward
                }
                onClick={() => handleClick(reason)}
                clickable
              />
              {showTextArea && reason.code === OTHER_REASON && (
                <Box px={6} mt={-5} mb={4}>
                  <Box mb={4}>
                    <Textarea
                      id="other-reason"
                      name="otherReason"
                      value={reasonDetails}
                      onChange={e => setReasonDetails(e.target.value)}
                      onFocus={e =>
                        clearValidationOnFocus({ e, errors, setErrors })
                      }
                      placeholder="Vul reden in ..."
                      error={!!errors.otherReason}
                      message={errors.otherReason}
                    />
                  </Box>

                  <Button
                    appearence="primary"
                    width="full"
                    onClick={() => {
                      if (reasonDetails) {
                        setSelectedCancellationReason(
                          OTHER_CANCELLATION_REASON,
                        );
                        setOnlineCancellationStep(
                          CANCELLATION_STEPS.SECOND_STEP,
                        );
                        pushTrackingEvent(chosenUserSubscription, {
                          tealium_event: 'confirm_other_cancellation_reason',
                          cancellation_step: 1,
                          cancellation_reason: OTHER_CANCELLATION_REASON_LABEL,
                          cancellation_reason_id: OTHER_REASON,
                          cancellation_reason_category: getCancellationReasonCategory(
                            { otherReason: true },
                          ),
                        });
                      } else {
                        setErrors({
                          ...errors,
                          otherReason: 'Reden is verplicht',
                        });
                      }
                    }}
                  >
                    Verder gaan
                  </Button>
                </Box>
              )}
            </React.Fragment>
          );
        })}
      </WideList>
    </Box>
  );
}
