import { days } from '../data';

const validateOpeninghours = openinghours => {
  const day = new Date();
  const dayName = days[day.getDay()];
  const hours = openinghours?.[dayName];

  if (!hours) {
    return false;
  }

  const minHour = parseInt(hours[0], 10);
  const minMinutes = parseInt(hours[0].slice(3, 5), 10);
  const maxHour = parseInt(hours[1], 10);
  const maxMinutes = parseInt(hours[1].slice(3, 5), 10);
  const todayHour = day.getHours();
  const todayMinutes = day.getMinutes();

  if (todayHour >= minHour && todayHour < maxHour) {
    // check minutes
    return (
      todayMinutes >= minMinutes &&
      (todayMinutes < maxMinutes || maxMinutes === 0)
    );
  } else if (todayHour >= minHour && todayHour === maxHour) {
    if (todayMinutes <= maxMinutes) {
      return true;
    }
  }
  return todayHour >= minHour && (todayHour < maxHour || maxHour === 0);
};

export default validateOpeninghours;
