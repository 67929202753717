import ahApiCall from '~/api/ah/ahApiCall';
import { config } from '~/services';

const getTemporaryDeliveryChangesOptionsEndDates = async ({
  subscriptionId,
  startDate,
  temporaryDeliveryChangeId,
}) => {
  const queryParams = startDate ? `?startDate=${startDate}` : '';
  const url = temporaryDeliveryChangeId
    ? `${config.ahServiceApiUrl}/subscriptions/${subscriptionId}/temporary-delivery-changes/${temporaryDeliveryChangeId}/options/end-dates${queryParams} `
    : `${config.ahServiceApiUrl}/subscriptions/${subscriptionId}/temporary-delivery-changes/options/end-dates${queryParams}`;

  const res = await ahApiCall(url);

  return res;
};

export default getTemporaryDeliveryChangesOptionsEndDates;
