import getCustomerById from '~/api/ah/getCustomerById';

const getReceiver = ({
  customerIsEqualToReceiver,
  customer,
  receiverIsDifferentFromPayer,
  receiver,
  payer,
}) => {
  if (customerIsEqualToReceiver) {
    return customer;
  }
  return receiverIsDifferentFromPayer ? receiver : payer;
};

const getPayerAndReceiver = async (subscription, customer) => {
  if (!subscription) {
    return undefined;
  }
  const requests = [];
  const customerId = customer?.id;
  const customerIsEqualToBillToCustomer =
    subscription?.billToCustomerId === customerId;

  if (subscription?.billToCustomerId && !customerIsEqualToBillToCustomer) {
    requests.push(
      getCustomerById({
        id: subscription.billToCustomerId,
      }),
    );
  }

  const shipToCustomerId = subscription.components.find(
    component => component.type.toLowerCase() === 'print',
  )?.shipToCustomerId;
  const receiverIsDifferentFromPayer =
    shipToCustomerId !== subscription?.billToCustomerId;
  const customerIsEqualToReceiver = shipToCustomerId === customerId;

  if (
    shipToCustomerId &&
    receiverIsDifferentFromPayer &&
    !customerIsEqualToReceiver
  ) {
    requests.push(
      getCustomerById({
        id: shipToCustomerId,
      }),
    );
  }

  const [payer, receiver] = await Promise.all(requests);

  return {
    ...subscription,
    payer: customerIsEqualToBillToCustomer ? customer : payer,
    receiver: getReceiver({
      customerIsEqualToReceiver,
      receiver: customerIsEqualToBillToCustomer ? payer : receiver,
      customer,
      payer,
      receiverIsDifferentFromPayer,
    }),
  };
};

export default getPayerAndReceiver;
