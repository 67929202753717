import React from 'react';
import { Box, Flex, TextField } from '@mediahuis/chameleon-react-legacy';
import { config } from '~/services';
import noop from './noop';
import HouseNumberTextField from './HouseNumberTextField';
import language from './language';

const DefaultAddresFields = ({
  address,
  handleAddressChange = noop,
  clearError,
  errors = {},
  validateOnBlur,
}) => {
  return (
    <React.Fragment>
      <Flex pb={4}>
        <Box pb={4} mr={3} width="30%">
          <TextField
            label={language.postalCode}
            id="postalCode"
            data-testid="input-postalCode"
            value={address.postalCode}
            onFocus={clearError}
            onBlur={validateOnBlur}
            message={errors.postalCode}
            error={!!errors.postalCode}
            onChange={event =>
              handleAddressChange({ postalCode: event.target.value })
            }
            autoComplete="new-postalCode"
            maxlength={10}
          />
        </Box>
        <Box width="70%">
          <TextField
            label={language.city}
            data-testid="input-city"
            id="city"
            value={address.city}
            onFocus={clearError}
            onBlur={validateOnBlur}
            message={errors.city}
            error={!!errors.city}
            onChange={event =>
              handleAddressChange({ city: event.target.value })
            }
            maxlength={50}
          />
        </Box>
      </Flex>
      <Box>
        <Flex>
          <Box pb={4} flex={3}>
            <TextField
              label={language.street}
              data-testid="input-street"
              id="street"
              value={address.street}
              onFocus={clearError}
              onBlur={validateOnBlur}
              message={errors.street}
              error={!!errors.street}
              onChange={event =>
                handleAddressChange({ street: event.target.value })
              }
              autoComplete="new-street"
              maxlength={50}
            />
          </Box>
        </Flex>
        <Box pb={4} flex={2}>
          <Flex>
            <Box mr={3} flex={1}>
              <HouseNumberTextField
                validateOnBlur={validateOnBlur}
                clearError={clearError}
                houseNumber={address.houseNumber}
                errors={errors}
                onChange={handleAddressChange}
              />
            </Box>
            {!config.isMHLU && (
              <Box flex={1}>
                <TextField
                  label={language.box}
                  data-testid="input-box"
                  id="box"
                  onChange={event => {
                    if (event.target.value?.length > 6) return;
                    handleAddressChange({ box: event.target.value });
                  }}
                  value={address.box}
                  optionalLabel={language.optional}
                  autoComplete="new-box"
                  maxlength={6}
                />
              </Box>
            )}
          </Flex>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default DefaultAddresFields;
