import { Flex, Icon } from '@mediahuis/chameleon-react-legacy';
import { ArrowDown } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import { ts } from '~/services';

export const DIALOG_TYPES = Object.freeze({
  ADDRESS: 'address',
  CANCEL_ADDRESS_CHANGE: 'cancelAddressChange',
});

export const addressChangesTableColumns = [
  {
    title: (
      <Flex alignItems="center">
        {ts.t('CustomerDetails.Table.StartDate')}
        <Icon as={ArrowDown} size="small" color="colorBlackBase" ml={2} />
      </Flex>
    ),
    field: 'startDate',
  },
  { title: ts.t('CustomerDetails.Table.Address'), field: 'address' },
  {
    title: ts.t('CustomerDetails.Table.ImpactedSubscriptions'),
    field: 'impactedSubscriptions',
  },
  { title: '', field: 'cancel', textAlign: 'right' },
];
