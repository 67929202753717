import React, { useEffect } from 'react';
import { Select, Box, Tooltip } from '@mediahuis/chameleon-react-legacy';
import { ts } from '~/services';
import useDeliveryComplaintContext from '~/context/hooks/useDeliveryComplaintContext';
import { getDay } from '~/utils';
import dayjs from 'dayjs';
import { DATE_FORMAT_BE } from '~/constants';

const TimeSelection = () => {
  const {
    deliveryComplaintState,
    setDeliveryComplaintState,
    deliveryComplaintErrors,
    setDeliveryComplaintErrors,
  } = useDeliveryComplaintContext();

  const actualDate = new Date();
  const actualHour = actualDate.getHours();
  const actualMinutes = actualDate.getMinutes();
  const timeSlots =
    deliveryComplaintState.data.allowedReceivingTimes[
      getDay(deliveryComplaintState.deliveryDate)
    ];
  const limitHourOfSelectedDate =
    deliveryComplaintState.data.deliveries?.[
      dayjs(deliveryComplaintState.deliveryDate).format(DATE_FORMAT_BE)
    ]?.TooLate?.limitHour;

  const filteredTimeSlots = timeSlots.filter(timeSlot => {
    const [limitHours, limitMinutes] = limitHourOfSelectedDate?.split(':');
    const [timeSlotHours, timeSlotMinutes] = timeSlot.split('u');
    if (Number(limitHours) === Number(timeSlotHours)) {
      return timeSlotMinutes >= limitMinutes;
    }
    return Number(timeSlotHours) > Number(limitHours);
  });
  /**
   * Compare selected date with the actualDate and return result of comparison
   */
  const checkSameDate = () =>
    deliveryComplaintState.deliveryDate.getDate() === actualDate.getDate() &&
    deliveryComplaintState.deliveryDate.getMonth() === actualDate.getMonth() &&
    deliveryComplaintState.deliveryDate.getFullYear() ===
      actualDate.getFullYear();

  /**
   * When actual date is equal to the selected date we need to do extra time checks to be sure that the selected time won't be greater then the actualTime
   * @param hour check if hour is greater then actualHour
   * @param minutes if hour is equal to actualHour check if the minutes are greater then actualMinutes
   */
  const validateDate = (hour, minutes) => {
    const isSameDate = checkSameDate();
    if (isSameDate) {
      return (
        actualHour > hour || (actualHour === hour && actualMinutes > minutes)
      );
    }
    return deliveryComplaintState.deliveryDate < actualDate;
  };

  const handleOnChangeSelect = event => {
    const selectedTime = event.target.value;
    const selectedTimeArray = selectedTime.split('u');
    const selectedHour = Number(selectedTimeArray[0]);
    const selectedMinutes = Number(selectedTimeArray[1]);
    const isValid = validateDate(selectedHour, selectedMinutes);
    setDeliveryComplaintState({
      comment: isValid ? selectedTime : '',
    });
    setDeliveryComplaintErrors(
      isValid
        ? {}
        : { comment: 'Gelieve te kiezen voor een tijdstip in het verleden.' },
    );
  };

  /**
   * This is needed to set an initial comment (minimum) when mounting
   * We also use it to reset the comment when deliveryDate has changed
   */
  useEffect(() => {
    setDeliveryComplaintState({ comment: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSlots, deliveryComplaintState.deliveryDate]);

  return (
    <Select
      mb={5}
      id="comment"
      data-testid="comment"
      label={
        <Box display="inline-flex">
          {ts.t('DeliveryComplaint.Labels.Time')}
          <Box ml={2} mt={-2}>
            <Tooltip
              id="tooltip"
              label={<Box>{ts.t('DeliveryComplaint.TooLateTooltip')}</Box>}
              usePortal={true}
            />
          </Box>
        </Box>
      }
      onChange={handleOnChangeSelect}
      placeholder="Maak een keuze"
      value={deliveryComplaintState.comment}
      error={deliveryComplaintErrors.comment}
      message={deliveryComplaintErrors.comment}
      disabled={deliveryComplaintErrors.deliveryDate}
    >
      {filteredTimeSlots.map(timeSlot => (
        <option key={timeSlot} value={timeSlot}>
          {timeSlot}
        </option>
      ))}
    </Select>
  );
};

export default TimeSelection;
