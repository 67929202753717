import React from 'react';

const Delivery = props => (
  <svg viewBox="0 0 1000 1000" {...props}>
    <title>Bezorging</title>
    <circle cx="298.5" cy="688.7" r="70.1" />
    <circle cx="749.9" cy="688.7" r="70.1" />
    <path d="M119.5,680.9h93.4c0,0,0-77.8,85.6-77.8s85.6,85.6,85.6,85.6h217.9L690,245H205.1L119.5,680.9z" />
    <path d="M890.1,455.2L822,354H684L618,689l46.4-0.4c0,0,0-85.6,85.6-85.6s85.6,85.6,85.6,85.6h54.5L936,455.2H890.1z M779,455v-68 h34l46,68H779z" />
    <polygon points="141,498 98,498 98,455 149,455 " />
    <polygon points="131,552 98,552 98,509 139,509 " />
    <polygon points="121,606 98,606 98,563 129,563 " />
  </svg>
);

export default Delivery;
