import React, { useState } from 'react';

const DeliveryComplaintContext = React.createContext();

const DeliveryComplaintContextProvider = ({ children }) => {
  const [deliveryComplaintState, setState] = useState({
    deliveryDate: '',
    comment: '',
    data: {},
    chosenComplaint: {},
  });

  const [deliveryComplaintErrors, setDeliveryComplaintErrors] = useState({});

  const setDeliveryComplaintState = values => {
    setState(state => ({ ...state, ...values }));
  };

  const setDeliveryComplaintStateWithPrevState = getNewState =>
    setState(prevState => getNewState(prevState));

  return (
    <DeliveryComplaintContext.Provider
      value={{
        deliveryComplaintState,
        setDeliveryComplaintState,
        deliveryComplaintErrors,
        setDeliveryComplaintErrors,
        setDeliveryComplaintStateWithPrevState,
      }}
    >
      {children}
    </DeliveryComplaintContext.Provider>
  );
};

export { DeliveryComplaintContext, DeliveryComplaintContextProvider };
