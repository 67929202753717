import { useEffect } from 'react';
import { config } from '~/services';
import { UrlBuilder, ModalSurvey } from '@hello-customer/website-touchpoint';
import { colorPrimaryBase } from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';
import getModalConfig from './utils/getModalConfig';

const HelloCustomer = ({ chosenUserSubscription, show, userInfo, useCase }) => {
  const urlBuilder = new UrlBuilder(
    getModalConfig({ chosenUserSubscription, userInfo, useCase }),
  );

  const modalSurvey = new ModalSurvey(urlBuilder, {
    modalStyle: {
      windowBarDivStyle: {
        backgroundColor: colorPrimaryBase,
      },
      rootDivStyle: {
        zIndex: 3,
      },
    },
    quarantineConfig: {
      period: config.helloCustomer.quarantineDays,
    },
  });

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        modalSurvey?.show();
      }, config.helloCustomer.delay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default HelloCustomer;
export * from './data';
export * from './constants';
