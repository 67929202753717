import apiCall from '~/utils/apiCall';
import { config } from '~/services';

const getHolidayOptions = async ({ subscriptionId }) => {
  const options = await apiCall(
    `${config.serviceApiUrl}/VacationOptions/${subscriptionId}`,
    {
      withCredentials: true,
      params: {
        'api-version': '2.0',
      },
    },
  );
  processAddresses(options, 'knownFamilyAddresses');
  processAddresses(options, 'knownHolidayAddresses');
  return options;
};

function processAddresses(options, addressesProperty) {
  if (options?.[addressesProperty]?.length > 0) {
    options[addressesProperty] = options[addressesProperty].map(a => {
      a.id = Buffer.from(JSON.stringify(a)).toString('base64');
      return a;
    });
  }
}
export default getHolidayOptions;
