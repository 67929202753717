import { ERRORS } from '~/constants';

const validateDates = ({ errors = {}, fields = [] }) => {
  const newErrors = { ...errors };

  fields.forEach(({ id, value, meta = {} }) => {
    if (!value) {
      newErrors[id] = ERRORS.NOT_VALID_DATE;
    } else if (meta.disabled) {
      newErrors[id] = ERRORS.SELECTED_DATE_NOT_ALLOWED;
    } else {
      delete newErrors[id];
    }
  });

  return newErrors;
};

export default validateDates;
