import axios from 'axios';
import defaultError from './defaultError';

const apiCall = (url, { headers, ...config } = {}) =>
  axios({
    url,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    ...config,
  })
    .then(response => response.data)
    .catch(({ response }) => {
      const { data, status } = response;

      const error = {
        status: data.status || status,
        headers: response.headers || {},
        message: data.title || defaultError,
        errors: data.errors,
      };

      throw error;
    });

export default apiCall;
