import { Box } from '@mediahuis/chameleon-react-legacy';
import React from 'react';
import { Header, Wrapper } from '~/components';
import { SERVICE_MESSAGE_PAGES } from '~/constants/serviceMessage';
import { config, ts } from '~/services';
import ServiceMessage from '../../components/ServiceMessage';
import { METADATA, PAGES } from '../../constants';
import { Language } from './language';

const ReadDigital = () => {
  return (
    <React.Fragment>
      {METADATA[PAGES.readDigital]}
      <Header heading={ts.t('ReadDigital.Heading')} />
      <Wrapper>
        <ServiceMessage mb={5} page={SERVICE_MESSAGE_PAGES.readDigital} />
        <Box mt={6} mb={5}>
          {ts.t('ReadDigital.Description', {
            richContent: true,
            values: { siteName: config.siteName },
          })}
        </Box>
        <Language />
      </Wrapper>
    </React.Fragment>
  );
};

export default ReadDigital;
