/* eslint-disable babel/camelcase */
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
} from '@mediahuis/chameleon-react-legacy';
import React, { useEffect } from 'react';
import { useGlobalContext } from '~/context';
import { StyledBox } from '~/pages/Subscription/components/DialogContent/OnlineCancellation/MedicalForm';
import AlternativeOffer from '~/pages/Subscription/components/DialogContent/OnlineCancellation/AlternativeOffer/AlternativeOfferCard';
import {
  CANCELLATION_STEPS,
  DIGI_ALTERNATIVE_OFFERS,
  DS_ZADI_SUBSCRIPTION_TYPE,
  FLOW_TYPES,
  ZADI_ALTERNATIVE_OFFERS,
} from '~/pages/Subscription/constants/onlineCancellation';
import { getCancellationReasonCategory } from '~/pages/Subscription/utils/onlineCancellation';
import { getCurrentBreakpoint, isMobile, useResponsive } from '~/utils';
import { pushTrackingEvent } from '~/utils/tealium';

export default function AlternativeOffers({
  setOnlineCancellationStep,
  setSelectedAlternativeOffer,
  handleSubmit,
  isSubmitting,
  error,
  setSubmittingHasFailed,
  selectedCancellationReason,
}) {
  const { chosenUserSubscription } = useGlobalContext();
  const offers =
    chosenUserSubscription.subscriptionType === DS_ZADI_SUBSCRIPTION_TYPE
      ? ZADI_ALTERNATIVE_OFFERS
      : DIGI_ALTERNATIVE_OFFERS;
  const mappedOffers = offers.map(offer => offer.trackingData);
  const defaultTrackingParams = {
    cancellation_step: 3,
    cancellation_reason: selectedCancellationReason?.label,
    cancellation_reason_id: selectedCancellationReason?.code,
    cancellation_reason_category: getCancellationReasonCategory(),
  };
  useEffect(() => {
    pushTrackingEvent(chosenUserSubscription, {
      ...defaultTrackingParams,
      tealium_event: 'view_offer_screen',
      offer: mappedOffers,
    });
  }, []);
  useResponsive();
  return (
    <Box>
      <Heading level={3} mb={5} fontFamily="primary">
        Misschien lees je liever voor minder geld?
      </Heading>
      {error}
      <Flex
        mb={5}
        flexDirection={{
          xs: 'column',
          sm: 'row',
        }}
      >
        {offers.map((offer, index) => {
          const {
            title,
            price,
            highlight,
            features,
            buttonText,
            plusarticlesAreFree,
          } = offer;
          const isFirst = index === 0;
          return (
            <Box
              key={title}
              width={isMobile() ? '100%' : '50%'}
              mr={isMobile() || !isFirst ? 0 : 5}
              mb={!isMobile() || !isFirst ? 0 : 5}
            >
              <AlternativeOffer
                buttonText={buttonText}
                price={price}
                screenSize={getCurrentBreakpoint()}
                title={title}
                highlight={highlight}
                highlightColor="color-primary-base"
                highlightColorText="color-text-primary-base"
                features={features}
                multibrandProps={{
                  activeBrand: 'ds',
                  text: isMobile()
                    ? 'Gratis alle plusartikels'
                    : 'Lees gratis alle plusartikels op sites en apps',
                }}
                hideMultiBrandBanner={!plusarticlesAreFree}
                onClick={() => {
                  setSubmittingHasFailed(false);
                  setSelectedAlternativeOffer(offer);
                  setOnlineCancellationStep(CANCELLATION_STEPS.FOURTH_STEP);
                  pushTrackingEvent(chosenUserSubscription, {
                    ...defaultTrackingParams,
                    tealium_event: 'click_offer',
                    offer: [offer.trackingData],
                  });
                }}
              />
            </Box>
          );
        })}
      </Flex>

      <StyledBox p={5}>
        <Box mb={4}>
          <Text size="Caption1" fontFamily="primary">
            Ik wens geen alternatief. Zet alles stop.
          </Text>
        </Box>

        <Button
          appearance="secondary"
          width="full"
          onClick={() => {
            setSelectedAlternativeOffer(null);
            handleSubmit(CANCELLATION_STEPS.FOURTH_STEP, FLOW_TYPES.CANCEL);
            pushTrackingEvent(chosenUserSubscription, {
              ...defaultTrackingParams,
              tealium_event: 'click_confirm_cancellation',
            });
          }}
          loading={isSubmitting}
        >
          Doorgaan met opzeggen
        </Button>
      </StyledBox>
    </Box>
  );
}
