import React, { useState } from 'react';
import {
  Heading,
  Box,
  Flex,
  Fieldset,
  Button,
} from '@mediahuis/chameleon-react-legacy';
import {
  ChevronBack,
  ChevronForward,
} from '@mediahuis/chameleon-theme-wl/legacy/icons';
import isEmpty from 'lodash.isempty';
import { ts } from '~/services';
import HolidayAddresses from '../HolidayAddresses';
import { HOLIDAY_STEPS } from '../../../data';
import getEmptySolutionData from '../../../utils/getEmptySolutionData';

const AddressChoiceStep = ({
  submissionData,
  setSubmissionData,
  setCurrentStep,
}) => {
  const [errors] = useState({});
  const onNextStep = () => {
    setCurrentStep(HOLIDAY_STEPS.ADDRESS_STEP);
  };
  const handleOnPreviousStep = () => {
    setSubmissionData(prevState => ({
      ...prevState,
      solutionData: getEmptySolutionData(),
    }));
    setCurrentStep(HOLIDAY_STEPS.CHOICE_STEP);
  };
  const isEditting = !!submissionData?.id;
  return (
    <>
      <Heading level={3} mb={6} fontFamily="primary" color="colorPrimaryBase">
        {isEditting ? ts.t('Holiday.EditHoliday') : ts.t('Holiday.NewHoliday')}
      </Heading>
      <Fieldset
        labelProps={{ size: 'Heading6', fontWeight: 'regular' }}
        label={ts.t('Holiday.AddressChoiceStep.FieldSetLabel')}
      >
        <Box pb={6}>
          <HolidayAddresses
            chosenResolution={submissionData.vacationSolution}
            chosenAddress={submissionData.solutionData}
            setSubmissionData={setSubmissionData}
          />
        </Box>
      </Fieldset>
      <Flex justifyContent="flex-end" mb={5}>
        <Button mr={3} onClick={handleOnPreviousStep} iconLeft={ChevronBack}>
          {ts.t('Common.Back')}
        </Button>
        <Button
          onClick={onNextStep}
          iconRight={ChevronForward}
          disabled={!isEmpty(errors)}
        >
          {isEditting ? ts.t('Common.Edit') : ts.t('Common.Next')}
        </Button>
      </Flex>
    </>
  );
};

export default AddressChoiceStep;
