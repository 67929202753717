import { COUNTRIES } from '~/constants';
import React, { useEffect } from 'react';
import { Box, Flex, TextField } from '@mediahuis/chameleon-react-legacy';
import { config } from '~/services';
import PostalCodeAutoComplete from './PostalCodeAutocomplete';
import useCity from './hooks/useCity';
import useDutchStreets from './hooks/useDutchStreets';
import HouseNumberTextField from './HouseNumberTextField';
import language from './language';

const DutchAddresFields = ({
  handleAddressChange,
  address,
  clearFields,
  errors = {},
  validateOnBlur,
  clearError,
  allowedPostalCodesByCountry,
  setErrors,
}) => {
  const { matchedCities, isLoading: isLoadingCities } = useCity({
    // Remove possible spacing of postalCode.
    postalCode: address.postalCode?.replace(/\s+/g, ''),
    countryCode: COUNTRIES.NL,
    allowedPostalCodesByCountry,
    setErrors,
  });
  const streets = useDutchStreets({
    // remove letters for validation
    houseNumber: address.houseNumber?.replace(/\D/g, ''),
    postalCode: address.postalCode,
    setErrors,
  });

  const resetFields = value => {
    if (value !== address.postalCode && address.postalCode !== '') {
      // emptying array by removing index 0
      // so all street suggestions get removed
      [streets].splice(0, 1);
      clearFields();
    }
  };

  useEffect(() => {
    if (address.street !== streets && address.houseNumber !== '') {
      handleAddressChange({ street: streets });
    } else if (address.houseNumber === '' && address.street !== '') {
      handleAddressChange({ street: '' });
    }
  }, [handleAddressChange, address.houseNumber, address.street, streets]);

  return (
    <React.Fragment>
      <Box pb={4}>
        <Box pb={4} flex={3}>
          <PostalCodeAutoComplete
            onChange={selection => {
              if (selection && selection.PostalCode && selection.Name) {
                handleAddressChange({
                  postalCode: selection.PostalCode,
                  city: selection.Name,
                });
              }
            }}
            itemToString={item => (item ? item.PostalCode : '')}
            onInputValueChange={value => {
              resetFields(value);
              handleAddressChange({
                postalCode: value,
              });
            }}
            postalCode={address.postalCode}
            label={language.postalCode}
            items={matchedCities}
            errors={errors}
            validateOnBlur={validateOnBlur}
            clearError={clearError}
            autoComplete="new-postalCode"
            optionalLabel={language.postalCodeOptionalLabel}
            isLoading={isLoadingCities}
          />
        </Box>
        <Flex>
          <Box flex={2}>
            <Flex>
              <Box pb={2} mr={3} flex={1}>
                <HouseNumberTextField
                  validateOnBlur={validateOnBlur}
                  clearError={clearError}
                  houseNumber={address.houseNumber}
                  errors={errors}
                  disabled={!address.postalCode}
                  onChange={handleAddressChange}
                  maxLength={5}
                />
              </Box>
              {!config.isMHLU && (
                <Box flex={1}>
                  <TextField
                    label={language.addition}
                    data-testid="input-box"
                    id="box"
                    onChange={event => {
                      if (event.target.value?.length > 6) return;
                      handleAddressChange({ box: event.target.value });
                    }}
                    onBlur={validateOnBlur}
                    onFocus={clearError}
                    value={address.box}
                    message={errors.box}
                    error={!!errors.box}
                    optionalLabel={language.optional}
                    disabled={!address.postalCode}
                    autoComplete="new-box"
                    maxlength={6}
                  />
                </Box>
              )}
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Box pb={4} flex={2}>
        <TextField
          label={language.city}
          data-testid="input-city"
          id="city"
          value={address.city}
          disabled
          autoComplete="new-city"
        />
      </Box>
      <Box pb={4}>
        <TextField
          label={language.street}
          data-testid="input-street"
          id="street"
          value={address.street}
          disabled
          autoComplete="new-street"
        />
      </Box>
    </React.Fragment>
  );
};

export default DutchAddresFields;
