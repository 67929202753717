import React, { useState } from 'react';
import { Dialog } from '~/components';
import { scrollTopWithTimeout } from '~/utils';
import { HOLIDAY_STEPS } from '~/pages/Holiday/data';
import DialogContent from '~/pages/Holiday/components/DialogContent';
import AhHolidayLists from '~/pages/Holiday/components/ah/AhHolidayLists';

const AhHolidayContent = ({ setUpdateHolidayOverviewHasFailed }) => {
  const [dialogType, setDialogType] = useState('');
  const [isDialogShown, setIsDialogShown] = useState(false);
  const [chosenHoliday, setChosenHoliday] = useState(null);
  const [currentStep, setCurrentStep] = useState(HOLIDAY_STEPS.CHOICE_STEP);

  const handleDialog = ({ type }) => {
    setDialogType(type);
    setIsDialogShown(true);
  };

  return (
    <>
      <AhHolidayLists
        onAction={handleDialog}
        setChosenHoliday={setChosenHoliday}
      />
      <Dialog
        show={isDialogShown}
        onClose={() => {
          setIsDialogShown(false);
          setCurrentStep(HOLIDAY_STEPS.CHOICE_STEP);
          scrollTopWithTimeout();
        }}
        closeOnBackdropClick={false}
      >
        <DialogContent
          dialogType={dialogType}
          isDialogShown={isDialogShown}
          setIsDialogShown={setIsDialogShown}
          chosenHoliday={chosenHoliday}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          setUpdateHolidayOverviewHasFailed={setUpdateHolidayOverviewHasFailed}
        />
      </Dialog>
    </>
  );
};

export default AhHolidayContent;
