import { AH_HOLIDAY_SOLUTIONS } from './index';
import getLanguage from '../language';

const ahHolidayResolutionOptions = (
  holidayArrangementOptions,
  internalSubscriptionType,
  subscriptionHasDigitalComponent,
) => {
  const { resolutions = [] } = holidayArrangementOptions || {};
  const language = getLanguage(internalSubscriptionType);
  return [
    {
      resolution: resolutions?.includes(AH_HOLIDAY_SOLUTIONS.CONTINUE_DIGITAL),
      value: AH_HOLIDAY_SOLUTIONS.CONTINUE_DIGITAL,
      language: subscriptionHasDigitalComponent
        ? language.continueDigital
        : language.continueDigitalPrintOnly,
    },
    {
      resolution: resolutions?.includes(
        AH_HOLIDAY_SOLUTIONS.DISCONTINUE_DIGITAL,
      ),
      value: AH_HOLIDAY_SOLUTIONS.DISCONTINUE_DIGITAL,
      language: subscriptionHasDigitalComponent
        ? language.discontinueDigital
        : language.discontinueDigitalPrintOnly,
    },
    {
      resolution: resolutions?.includes(AH_HOLIDAY_SOLUTIONS.HOLIDAY_ADDRESS),
      value: AH_HOLIDAY_SOLUTIONS.HOLIDAY_ADDRESS,
      language: language.holidayAddress,
    },
    {
      resolution: resolutions?.includes(AH_HOLIDAY_SOLUTIONS.FAMILY_ADDRESS),
      value: AH_HOLIDAY_SOLUTIONS.FAMILY_ADDRESS,
      language: language.familyAddress,
    },
    {
      resolution: resolutions?.includes(AH_HOLIDAY_SOLUTIONS.CHARITY),
      value: AH_HOLIDAY_SOLUTIONS.CHARITY,
      language: language.charity,
    },
  ];
};

export default ahHolidayResolutionOptions;
