import { apiCall } from '~/utils';
import { environment, config } from '~/services';
import { ENVIRONMENTS } from '~/constants';
import validateServiceMessage from './validateServiceMessage';

const getServiceMessage = ({ page }) => {
  const environmentName =
    environment.name === ENVIRONMENTS.LOCAL
      ? ENVIRONMENTS.TEST
      : environment.name;

  return apiCall(
    `https://mh-subscriber-settings.s3.eu-west-1.amazonaws.com/public/${environmentName}/eloket/${config.brand}/${page}/service-message.json`,
    {
      method: 'GET',
    },
  )
    .then(response => {
      if (response.message) {
        const serviceMessageIsValid = validateServiceMessage({
          startDate: response.startDate,
          endDate: response.endDate,
        });
        return serviceMessageIsValid ? response.message : false;
      }
      return false;
    })
    .catch(() => false);
};

export default getServiceMessage;
