import apiCall from '~/utils/apiCall';
import { config } from '~/services';

const getRedirectUrl = ({ user, payment, subscription } = {}) => {
  const body = {
    user,
    payment,
    subscription,
    redirectUrl: window.location.href,
  };

  return apiCall(`${config.serviceApiUrl}/payments`, {
    method: 'POST',
    data: JSON.stringify(body),
    withCredentials: true,
    params: { 'api-version': '2.0' },
  });
};

export default getRedirectUrl;
