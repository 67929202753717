import React from 'react';
import {
  Box,
  LinkText,
  List,
  ListItem,
  Text,
} from '@mediahuis/chameleon-react-legacy';
import { environment } from '~/services';

export const Language = () => (
  <>
    <Box mb={5}>
      <Text size="Heading6" fontFamily="system">
        In der Nachrichten-App des „Luxemburger Wort“ finden Sie neben{' '}
        <Text fontWeight="bold">
          aktuellen Berichten alle Familienanzeigen sowie Wetter- und
          Verkehrsinfos.
        </Text>{' '}
        Als Abonnent haben Sie nach dem Login Zugriff auf alle{' '}
        <Text fontWeight="bold">exklusiven Premiuminhalte, </Text>
        die mit “Wort+” gekennzeichnet sind. Bei besonders wichtigen
        Nachrichtenlagen werden Sie auf Wunsch{' '}
        <Text fontWeight="bold">per Push-Benachrichtigung informiert.</Text> So
        sind Sie nicht nur jederzeit auf dem neuesten Informationsstand, sondern
        erhalten Einblick in Hintergründe und größere Zusammenhänge.
      </Text>
    </Box>
    <List mb={5}>
      <ListItem>
        <LinkText
          href="https://apps.apple.com/lu/app/wort-lu/id313634992?l=de"
          target="_blank"
        >
          ZUM APPLE APP STORE
        </LinkText>
      </ListItem>
      <ListItem>
        <LinkText
          href="https://play.google.com/store/apps/details?id=lu.wort.main&hl=de&gl=US"
          target="_blank"
        >
          ZUM GOOGLE PLAY STORE
        </LinkText>
      </ListItem>
    </List>
    <Box mb={5}>
      <Text size="Heading6" fontFamily="system" fontWeight="bold">
        Wort E-Paper App
      </Text>
    </Box>
    <Box mb={5}>
      <Text size="Heading6" fontFamily="system">
        Mit der „E-Paper“-App haben Sie Zugriff auf{' '}
        <Text fontWeight="bold">
          Wort E-Paper und Digital Paper. Wort E-Paper{' '}
        </Text>{' '}
        ist die 1:1-Digitalversion der Printausgabe als PDF. Es umfasst alle
        Seiten der jeweiligen Ausgabe. Mit dem{' '}
        <Text fontWeight="bold">Digital Paper </Text>
        lesen Sie die tägliche Zeitungsausgabe in einer für Tablets und
        Smartphones optimierten Form. Hier stehen Ihnen neben den redaktionellen
        Inhalten auch die Familienanzeigen zur Verfügung. In der App können Sie{' '}
        <Text fontWeight="bold">
          jederzeit zwischen Wort E-Paper und Digital Paper hin- und
          herwechseln.{' '}
        </Text>
      </Text>
    </Box>
    <List mb={5}>
      <ListItem>
        <LinkText
          href="https://apps.apple.com/lu/app/wort-e-paper/id376971280?l=de"
          target="_blank"
        >
          ZUM APPLE APP STORE
        </LinkText>
      </ListItem>
      <ListItem>
        <LinkText
          href="https://play.google.com/store/apps/details?id=lu.wort.main&hl=de&gl=US"
          target="_blank"
        >
          ZUM GOOGLE PLAY STORE
        </LinkText>
      </ListItem>
    </List>

    <Box mb={5}>
      <Text size="Heading6" fontFamily="system" fontWeight="bold">
        Sie sind bereits Abonnent/in und haben noch keinen Online Zugang?
      </Text>
    </Box>
    <List mb={5}>
      <ListItem>
        <LinkText
          href={`https://${environment.apiPrefix}activation.wort.lu`}
          target="_blank"
        >
          Aktivieren Sie Ihren Zugang hier
        </LinkText>{' '}
        oder wenden Sie sich an unseren
      </ListItem>
      <ListItem>
        <LinkText href="mailto:abo@wort.lu">Kundenservice</LinkText>
      </ListItem>
    </List>
  </>
);
