import { RichContent } from '@mediahuis/chameleon-react-legacy';
import Polyglot from 'node-polyglot';
import locales from '~/locales';
import capitalize from '~/utils/capitalize';
import { BRANDS } from '~/constants/config';
import { config } from './config';
import { environment } from './environment';

const languages = {
  [BRANDS.GVA]: 'nl-BE',
  [BRANDS.DS]: 'nl-BE',
  [BRANDS.HBVL]: 'nl-BE',
  [BRANDS.NB]: 'nl-BE',
  [BRANDS.DL]: 'nl-NL',
  [BRANDS.LT]: 'en-US',
  [BRANDS.LW]: 'de-LU',
  [BRANDS.TC]: 'de-LU',
  [BRANDS.CO]: 'pt-PT',
  [BRANDS.APORTAL]: 'en-US',
};

export class TranslationService {
  #source;
  #debug;
  #language;
  constructor() {
    const language = languages[config.brand];

    if (Object.keys(locales).length === 0)
      throw new Error('Must have atleast one source');

    this.setLanguage(language);

    const qs = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    this.#language = language;
    this.#debug = !environment.isProd && qs.ts === 'true';
  }
  get #constants() {
    return {
      OBJECT_PRONOUN: this.#source.t('Constants.OBJECT_PRONOUN'),
      PERSONAL_PRONOUN: this.#source.t('Constants.PERSONAL_PRONOUN'),
      POSSESSIVE_ADJECTIVE: this.#source.t('Constants.POSSESSIVE_ADJECTIVE'),
      VERB_TO_BE: this.#source.t('Constants.VERB_TO_BE'),
      VERB_TO_HAVE: this.#source.t('Constants.VERB_TO_HAVE'),
      VERB_TO_HAVE_IMPERATIVE: this.#source.t(
        'Constants.VERB_TO_HAVE_IMPERATIVE',
      ),
      VERB_TO_AVOID: this.#source.t('Constants.VERB_TO_AVOID'),
      VERB_TO_CAN: this.#source.t('Constants.VERB_TO_CAN'),
      VERB_SHALL_IMPERATIVE: this.#source.t('Constants.VERB_SHALL_IMPERATIVE'),
      VERB_TO_WISH_IMPERATIVE: this.#source.t(
        'Constants.VERB_TO_WISH_IMPERATIVE',
      ),
      OBJECT_PRONOUN_CAPITALIZED: capitalize(
        this.#source.t('Constants.OBJECT_PRONOUN'),
      ),
      PERSONAL_PRONOUN_CAPITALIZED: capitalize(
        this.#source.t('Constants.PERSONAL_PRONOUN'),
      ),
      POSSESSIVE_ADJECTIVE_CAPITALIZED: capitalize(
        this.#source.t('Constants.POSSESSIVE_ADJECTIVE'),
      ),
      VERB_TO_BE_CAPITALIZED: capitalize(
        this.#source.t('Constants.VERB_TO_BE'),
      ),
      VERB_TO_HAVE_CAPITALIZED: capitalize(
        this.#source.t('Constants.VERB_TO_HAVE'),
      ),
      VERB_TO_HAVE_IMPERATIVE_CAPITALIZED: capitalize(
        this.#source.t('Constants.VERB_TO_HAVE_IMPERATIVE'),
      ),
      VERB_TO_AVOID_CAPITALIZED: capitalize(
        this.#source.t('Constants.VERB_TO_AVOID'),
      ),
      VERB_TO_CAN_CAPITALIZED: capitalize(
        this.#source.t('Constants.VERB_TO_CAN'),
      ),
      VERB_SHALL_IMPERATIVE_CAPITALIZED: capitalize(
        this.#source.t('Constants.VERB_SHALL_IMPERATIVE'),
      ),
      VERB_TO_WISH_IMPERATIVE_CAPITALIZED: capitalize(
        this.#source.t('Constants.VERB_TO_WISH_IMPERATIVE'),
      ),
      BRAND_FULL_NAME: this.#source.t('Constants.BRAND_FULL_NAME'),
    };
  }
  t(key, options = {}) {
    // if (
    //   options.exclusiveFor &&
    //   !options.exclusiveFor.find(x => config.brand === x)
    // ) {
    //   return null;
    // }

    const isConstant = key.startsWith('Constants.');
    let values = options.values ? { ...options.values } : {};

    if (!isConstant) {
      values = { ...values, ...this.#constants };
    }

    const value = this.#debug ? key : this.#source.t(key, values);

    if (options.richContent) {
      return (
        <RichContent
          as="span"
          fontFamily="system"
          dangerouslySetInnerHTML={{ __html: value }}
        />
      );
    }
    return value;
  }
  getLanguage = () => this.#language;
  setLanguage(lang) {
    this.#language = lang;
    const phrases = locales[lang];
    if (!phrases) throw new Error(`No sources set for '${lang}'`);

    this.#source = new Polyglot({
      phrases,
    });
  }
}

const instance = new TranslationService();

export { instance as ts };
