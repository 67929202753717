import {
  Box,
  Flex,
  Heading,
  Select,
  TextField,
  useMediaQuery,
} from '@mediahuis/chameleon-react-legacy';
import React from 'react';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import nl from 'react-phone-number-input/locale/nl.json';
import AddressFields from '~/components/AddressFields';
import { genderOptions } from '~/pages/Contact/constants';
import { validateContactFormFields } from '~/pages/Contact/utils';
import { StyledFlex } from '~/pages/Subscription/components/DialogContent/PhoneNumbersDialog';
import { ts } from '~/services';
import { clearValidationOnFocus } from '~/utils';

export default function PersonalInformation({
  personalInformation,
  setPersonalInformation,
  setErrors,
  errors,
}) {
  const validateOnBlur = e => {
    setErrors({
      ...errors,
      ...validateContactFormFields({
        [e.target.name]: e.target.value,
      }),
    });
  };
  const flexDirection = useMediaQuery({ md: 'row' }, 'column');

  return (
    <>
      <Heading level={3} mb={4}>
        {ts.t('Contact.PersonalInformation')}
      </Heading>
      <Flex mb={4} flexDirection={flexDirection}>
        <Box
          width={useMediaQuery({ md: '40%' }, '100%')}
          mr={5}
          mb={useMediaQuery({ md: 0 }, 4)}
        >
          <Select
            id="gender"
            name="gender"
            placeholder=" "
            value={personalInformation.gender}
            onChange={e => {
              const value = e.target.value;
              return setPersonalInformation(prevState => ({
                ...prevState,
                gender: value,
              }));
            }}
            label={ts.t('Contact.Form.Gender')}
            message={errors.gender}
            error={!!errors.gender}
            onFocus={e => clearValidationOnFocus({ e, errors, setErrors })}
          >
            {genderOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </Box>
        <TextField
          id="first-name-textfield"
          label={ts.t('Contact.Form.FirstName')}
          mr={5}
          mb={useMediaQuery({ md: 0 }, 4)}
          name="firstName"
          value={personalInformation.firstName}
          onChange={e => {
            const value = e.target.value;
            return setPersonalInformation(prevState => ({
              ...prevState,
              firstName: value,
            }));
          }}
          onBlur={validateOnBlur}
          onFocus={e => clearValidationOnFocus({ e, errors, setErrors })}
          message={errors.firstName}
          error={!!errors.firstName}
          width="100%"
        />
        <TextField
          id="last-name-textfield"
          label={ts.t('Contact.Form.LastName')}
          name="lastName"
          value={personalInformation.lastName}
          onChange={e => {
            const value = e.target.value;
            return setPersonalInformation(prevState => ({
              ...prevState,
              lastName: value,
            }));
          }}
          onBlur={validateOnBlur}
          onFocus={e => clearValidationOnFocus({ e, errors, setErrors })}
          message={errors.lastName}
          error={!!errors.lastName}
          width="100%"
        />
      </Flex>
      <TextField
        id="email-textfield"
        label={ts.t('Contact.Form.Email')}
        autoComplete="email"
        mb={4}
        name="email"
        value={personalInformation.email}
        onChange={e => {
          const value = e.target.value;
          return setPersonalInformation(prevState => ({
            ...prevState,
            email: value,
          }));
        }}
        onBlur={validateOnBlur}
        onFocus={e => clearValidationOnFocus({ e, errors, setErrors })}
        message={errors.email}
        error={!!errors.email}
        width="100%"
      />
      <StyledFlex
        position="relative"
        mb={4}
        width="100%"
        error={!!errors.phone}
      >
        <PhoneInput
          placeholder="88 824 2222"
          value={personalInformation?.phone}
          label={ts.t('Contact.Form.Phone')}
          name="phone"
          id="phone"
          onFocus={e => clearValidationOnFocus({ e, errors, setErrors })}
          onChange={value =>
            setPersonalInformation(prevState => {
              return {
                ...prevState,
                phone: value || null,
              };
            })
          }
          labels={nl}
          onBlur={() => {
            setErrors(prevState => {
              const errorMessage =
                personalInformation?.phone &&
                !isPossiblePhoneNumber(personalInformation?.phone)
                  ? ts.t('PhoneNumbersDialog.InputError')
                  : '';
              if (!errorMessage) {
                return prevState;
              }
              return {
                ...prevState,
                phone: errorMessage,
              };
            });
          }}
          international
          defaultCountry="NL"
          inputComponent={TextField}
          countryOptionsOrder={['BE', 'DE', 'NL']}
          error={!!errors?.phone}
          message={errors?.phone}
          required={false}
        />
      </StyledFlex>
      <AddressFields
        address={personalInformation}
        setAddress={setPersonalInformation}
        setErrors={setErrors}
        allowedCountries={['NL', 'BE', 'DE']}
        errors={errors}
      />
    </>
  );
}
