import dayjs from 'dayjs';
import { COUNTRIES } from '~/constants';
import { DATE_FORMAT_BE } from '~/constants/date';
import {
  AH_HOLIDAY_SOLUTIONS,
  UPDATE_POSSIBILITIES,
} from '~/pages/Holiday/data';

const getAhHolidaySolutionBody = ({
  submissionData,
  selectedDeliveryOptionBySubscription,
}) => {
  const { start, end, vacationSolution, solutionData } = submissionData;
  const holidayIsPartiallyUpdateable =
    submissionData.updatePossibility === UPDATE_POSSIBILITIES.PARTIALLY;

  const defaultParams = {
    type: vacationSolution,
    ...(!holidayIsPartiallyUpdateable && {
      startDate: dayjs(start).format(DATE_FORMAT_BE),
    }),
    endDate: dayjs(end).format(DATE_FORMAT_BE),
  };
  const changeSubscriptionDelivery = selectedDeliveryOptionBySubscription && {
    typeCode: Object.values(selectedDeliveryOptionBySubscription)?.[0]
      ?.typeCode,
  };

  const isBelgianAddress = solutionData.countryCode === COUNTRIES.BE;

  switch (vacationSolution) {
    case AH_HOLIDAY_SOLUTIONS.CONTINUE_DIGITAL:
    case AH_HOLIDAY_SOLUTIONS.DISCONTINUE_DIGITAL:
    case AH_HOLIDAY_SOLUTIONS.CHARITY:
      return defaultParams;
    case AH_HOLIDAY_SOLUTIONS.FAMILY_ADDRESS:
      return {
        ...defaultParams,
        address: {
          street: solutionData.bpostStreetName || solutionData.street,
          houseNumber: solutionData.houseNumber,
          postalCode: solutionData.postalCode,
          city: solutionData.city,
          countryCode: solutionData.countryCode,
          additionalInformation: solutionData.extra,
          ...(isBelgianAddress && {
            busNumber: solutionData.box,
          }),
        },
        namesPerson: {
          firstName: solutionData.firstName,
          lastName: solutionData.lastName,
        },
        demographicsPerson: {
          genderCode: solutionData?.title?.toUpperCase(),
        },
        ...(changeSubscriptionDelivery && {
          changeSubscriptionDelivery,
        }),
      };
    case AH_HOLIDAY_SOLUTIONS.HOLIDAY_ADDRESS:
      return {
        ...defaultParams,
        address: {
          street: solutionData.bpostStreetName || solutionData.street,
          houseNumber: solutionData.houseNumber,
          postalCode: solutionData.postalCode,
          city: solutionData.city,
          countryCode: solutionData.countryCode,
          additionalInformation: solutionData.extra,
          ...(isBelgianAddress && {
            busNumber: solutionData.box,
          }),
        },
        ...(changeSubscriptionDelivery && {
          changeSubscriptionDelivery,
        }),
      };
    default:
      break;
  }
  return submissionData;
};

export default getAhHolidaySolutionBody;
