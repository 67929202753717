import React from 'react';
import { Flex, Box } from '@mediahuis/chameleon-react-legacy';
import {
  goToBrandUrl,
  getBrandLogoUrl,
  isMobile,
  isDesktop,
  useResponsive,
} from '~/utils';
import Image from '~/components/Image';
import { config } from '~/services';
import Wrapper from '../Wrapper';
import MenuItems from './MenuItems';
import MobileNavigation from './MobileNavigation';
import SignOut from './SignOut';

const Navigation = () => {
  useResponsive();
  const brandLogoUrl = getBrandLogoUrl();
  return (
    <Box bgColor="colorGrey10">
      <Wrapper>
        {isDesktop() && (
          <Flex alignItems="center">
            <Box mr={9}>
              <Image
                height={10}
                alt={config.siteName}
                src={brandLogoUrl}
                onClick={goToBrandUrl}
                style={{ cursor: 'pointer' }}
              />
            </Box>
            <MenuItems />
          </Flex>
        )}
        {isMobile() && <MobileNavigation />}
      </Wrapper>
      {isDesktop() && <SignOut />}
    </Box>
  );
};

export default Navigation;
