/* eslint-disable babel/camelcase */
import {
  Banner,
  Box,
  Flex,
  IconButton,
} from '@mediahuis/chameleon-react-legacy';
import { ChevronBack, Close } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import React, { useEffect, useState } from 'react';
import cancelSubscritpion from '~/api/cancelSubscritpion';
import { useGlobalContext } from '~/context';
import FifthStep from '~/pages/Subscription/components/DialogContent/OnlineCancellation/FifthStep';
import FirstStep from '~/pages/Subscription/components/DialogContent/OnlineCancellation/FirstStep';
import FourthStep from '~/pages/Subscription/components/DialogContent/OnlineCancellation/FourthStep';
import SecondStep from '~/pages/Subscription/components/DialogContent/OnlineCancellation/SecondStep';
import ThirdStep from '~/pages/Subscription/components/DialogContent/OnlineCancellation/ThirdStep';
import {
  CANCELLATION_STEPS,
  DEATH_REASON,
  MEDICAL_REASON,
  OTHER_REASON,
} from '~/pages/Subscription/constants/onlineCancellation';
import {
  getCancellationReasonCategory,
  mapToCancellationBody,
} from '~/pages/Subscription/utils/onlineCancellation';
import { pushTrackingEvent } from '~/utils/tealium';

export default function OnlineCancellationDialog({
  setDialogShown,
  dialogShown,
}) {
  const { userInfo, chosenUserSubscription } = useGlobalContext();
  const [onlineCancellationStep, setOnlineCancellationStep] = useState(
    CANCELLATION_STEPS.FIRST_STEP,
  );
  const [selectedCancellationReason, setSelectedCancellationReason] = useState(
    null,
  );
  const [selectedAlternativeOffer, setSelectedAlternativeOffer] = useState(
    null,
  );
  const [errors, setErrors] = useState({});
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [reasonDetails, setReasonDetails] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submittingHasFailed, setSubmittingHasFailed] = useState(false);

  const [articles, setArticles] = useState([]);

  const handleClose = () => {
    setDialogShown(false);
    setSubmittingHasFailed(false);
    setOnlineCancellationStep(CANCELLATION_STEPS.FIRST_STEP);
    setSelectedAlternativeOffer(null);
    setPhoneNumber('');
    setSelectedCancellationReason(null);
    setErrors({});
    setReasonDetails(null);
    const timeout = setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      clearTimeout(timeout);
    }, 100);
  };
  const handleBack = () => {
    setSubmittingHasFailed(false);
    setOnlineCancellationStep(prevState => prevState - 1);
  };
  const handleSubmit = async (nextStep, type) => {
    try {
      setSubmittingHasFailed(false);
      setIsSubmitting(true);
      const body = mapToCancellationBody({
        phoneNumber,
        reasonDetails,
        selectedAlternativeOffer,
        selectedCancellationReason,
        userInfo,
        type,
      });
      await cancelSubscritpion({
        body,
        subscriptionId: chosenUserSubscription.id,
      });
      setOnlineCancellationStep(nextStep);
    } catch (error) {
      setSubmittingHasFailed(true);
    } finally {
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    if (!dialogShown) {
      handleClose();
    }
  }, [dialogShown]);

  const isSecondStep =
    onlineCancellationStep === CANCELLATION_STEPS.SECOND_STEP;
  const isFourthStep =
    onlineCancellationStep === CANCELLATION_STEPS.FOURTH_STEP;
  const isThirdStep = onlineCancellationStep === CANCELLATION_STEPS.THIRD_STEP;

  const medicalOrDeathReason = [DEATH_REASON, MEDICAL_REASON].includes(
    selectedCancellationReason?.code,
  );
  const otherReason = selectedCancellationReason?.code === OTHER_REASON;
  const showBackButton =
    isSecondStep ||
    (selectedAlternativeOffer && isFourthStep) ||
    (!medicalOrDeathReason && isThirdStep);

  const header = (
    <Flex justifyContent={showBackButton ? 'space-between' : 'end'} mb={5}>
      {showBackButton && (
        <IconButton
          appearance="default"
          icon={ChevronBack}
          onClick={handleBack}
          circular
        />
      )}
      <IconButton
        appearance="default"
        icon={Close}
        circular
        onClick={() => {
          handleClose();
          pushTrackingEvent(chosenUserSubscription, {
            tealium_event: 'close_cancellation',
            cancellation_reason: selectedCancellationReason?.label,
            cancellation_reason_id: selectedCancellationReason?.code,
            cancellation_reason_category: getCancellationReasonCategory({
              medicalOrDeathReason,
              otherReason,
            }),
          });
        }}
      />
    </Flex>
  );
  const error = (
    <Banner
      appearance="error"
      show={submittingHasFailed}
      mb={5}
      onClose={() => setSubmittingHasFailed(false)}
    >
      Oeps, er liep iets mis. Probeer het later nog eens opnieuw.
    </Banner>
  );
  const renderContent = () => {
    switch (onlineCancellationStep) {
      case CANCELLATION_STEPS.FIRST_STEP:
        return (
          <FirstStep
            setOnlineCancellationStep={setOnlineCancellationStep}
            setSelectedCancellationReason={setSelectedCancellationReason}
            errors={errors}
            setErrors={setErrors}
            reasonDetails={reasonDetails}
            setReasonDetails={setReasonDetails}
            dialogShown={dialogShown}
          />
        );
      case CANCELLATION_STEPS.SECOND_STEP:
        return (
          <SecondStep
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            errors={errors}
            setErrors={setErrors}
            setOnlineCancellationStep={setOnlineCancellationStep}
            selectedCancellationReason={selectedCancellationReason}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            error={error}
            handleClose={handleClose}
            articles={articles}
            setArticles={setArticles}
          />
        );

      case CANCELLATION_STEPS.THIRD_STEP:
        return (
          <ThirdStep
            setOnlineCancellationStep={setOnlineCancellationStep}
            medicalOrDeathReason={medicalOrDeathReason}
            setSelectedAlternativeOffer={setSelectedAlternativeOffer}
            phoneNumber={phoneNumber}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            error={error}
            setSubmittingHasFailed={setSubmittingHasFailed}
            selectedCancellationReason={selectedCancellationReason}
          />
        );
      case CANCELLATION_STEPS.FOURTH_STEP:
        return (
          <FourthStep
            selectedAlternativeOffer={selectedAlternativeOffer}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            error={error}
            handleClose={handleClose}
            selectedCancellationReason={selectedCancellationReason}
          />
        );
      case CANCELLATION_STEPS.FIFTH_STEP:
        return (
          <FifthStep
            selectedAlternativeOffer={selectedAlternativeOffer}
            selectedCancellationReason={selectedCancellationReason}
          />
        );

      default:
        return null;
    }
  };
  return (
    <>
      {header}
      <Box
        m="auto"
        style={{
          maxWidth: isThirdStep && !medicalOrDeathReason ? '100%' : '400px',
        }}
      >
        {renderContent()}
      </Box>
    </>
  );
}
