import dayjs from 'dayjs';
import { DATE_FORMAT_READABLE } from '~/constants';

export const getEndDateField = subscription => {
  return (
    subscription?.expirationSubscriptionDate &&
    dayjs(subscription?.expirationSubscriptionDate).format(DATE_FORMAT_READABLE)
  );
};

export const getAddressField = address => {
  if (address) {
    return `${address.street} ${address.houseNumber}${
      address.busNumber ? `/${address.busNumber}` : ''
    } - ${address.postalCode} ${address.city} - ${address.countryCode}`;
  }
  return '';
};

export const getFullNameOfCustomerField = customer => {
  return (
    customer &&
    (customer?.namesOrganisation?.name || customer?.namesPerson?.fullName)
  );
};
