import React from 'react';

const Image = ({ height, width, src, alt, style = {}, onClick }) => {
  const imgStyle = {
    verticalAlign: 'middle',
    maxWidth: '100%',
    width: width ? width : 'auto',
    borderRadius: 'var(--image-wrapper-border-radius)',
    ...style,
    ...(height && { height: `var(--space-${height})` }),
  };

  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
  return <img src={src} alt={alt} style={imgStyle} onClick={onClick} />;
};

export default Image;
