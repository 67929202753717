/* eslint-disable no-undef */
import { SITE_NAMES } from '~/constants/config';
import { config } from '~/services';
import language from './language';
import getBrandsWherePostalCodeIsRestricted from './getBrandsWherePostalCodeIsRestricted';

const getAddressErrorsBE = async (
  { postalCode, street, houseNumber, isBillingAddress = false },
  country,
  chosenUserSubscription,
  requestOrigin,
) => {
  let errors = {};
  if (postalCode != null) {
    const invalidBrands = await getBrandsWherePostalCodeIsRestricted({
      postalCode,
      country,
      chosenUserSubscription,
      requestOrigin,
    });
    if (invalidBrands.length > 0 && !isBillingAddress) {
      errors = {
        ...errors,
        postalCode: `${
          language.postalCodeIsNotInDeliveryArea
        } ${invalidBrands.map(brand => SITE_NAMES[brand]).join(' en ')}`,
      };
    }
    if (postalCode.length === 0) {
      errors = { ...errors, postalCode: language.postalCodeIsRequired };
    }
  }
  if (street != null && street.length === 0) {
    errors = { ...errors, street: language.streetIsRequired };
  }
  if (houseNumber != null && houseNumber.length === 0) {
    errors = { ...errors, houseNumber: language.houseNumberIsRequired };
  }

  return errors;
};

const getAddressErrorsNL = async (
  { postalCode, street, houseNumber, isBillingAddress = false },
  country,
  chosenUserSubscription,
  requestOrigin,
) => {
  let errors = {};
  if (postalCode != null) {
    const invalidBrands = await getBrandsWherePostalCodeIsRestricted({
      postalCode,
      country,
      chosenUserSubscription,
      requestOrigin,
    });
    if (invalidBrands.length > 0 && !isBillingAddress) {
      errors = {
        ...errors,
        postalCode: `${
          language.postalCodeIsNotInDeliveryArea
        } ${invalidBrands.map(brand => SITE_NAMES[brand]).join(' en ')}`,
      };
    }
    if (postalCode.length === 0) {
      errors = { ...errors, postalCode: language.postalCodeIsRequired };
    }
  }
  if (houseNumber != null) {
    if (street != null && street.length === 0) {
      errors = {
        ...errors,
        houseNumber: language.postalCodeHouseNumberComboNotFound,
      };
    }
    if (houseNumber.length === 0) {
      errors = { ...errors, houseNumber: language.houseNumberIsRequired };
    }
  }
  return errors;
};
export const getAhAddressErrorsNL = ({
  postalCode,
  street,
  houseNumber,
  countryCode,
}) => {
  let errors = {};
  if (postalCode != null) {
    if (postalCode.length === 0) {
      errors = { ...errors, postalCode: language.postalCodeIsRequired };
    }
  }
  if (houseNumber != null) {
    if (street != null && street.length === 0) {
      errors = {
        ...errors,
        houseNumber: language.postalCodeHouseNumberComboNotFound,
      };
    }
    if (houseNumber.length === 0) {
      errors = { ...errors, houseNumber: language.houseNumberIsRequired };
    }
  }
  if (countryCode != null && countryCode.length === 0) {
    errors = { ...errors, countryCode: language.country };
  }

  return errors;
};

export const getAddressErrorsDefault = ({
  postalCode,
  street,
  houseNumber,
  city,
  countryCode,
}) => {
  let errors = {};

  if (city != null && city.length === 0) {
    errors = { ...errors, city: language.cityIsRequired };
  }
  if (postalCode != null && postalCode.length === 0) {
    errors = { ...errors, postalCode: language.postalCodeIsRequired };
  }
  if (street != null && street.length === 0) {
    errors = { ...errors, street: language.streetIsRequired };
  }
  if (houseNumber != null && houseNumber.length === 0) {
    errors = { ...errors, houseNumber: language.houseNumberIsRequired };
  }
  if (config.isMHLU && countryCode != null && countryCode.length === 0) {
    errors = { ...errors, countryCode: language.country };
  }

  return errors;
};

const addressFieldsValidations = (
  fields,
  country,
  chosenUserSubscription,
  requestOrigin,
) => {
  switch (country) {
    case 'BE':
      return getAddressErrorsBE(
        fields,
        country,
        chosenUserSubscription,
        requestOrigin,
      );
    case 'NL':
      return getAddressErrorsNL(
        fields,
        country,
        chosenUserSubscription,
        requestOrigin,
      );
    default:
      return getAddressErrorsDefault(fields);
  }
};

export default addressFieldsValidations;
