import React, { useState } from 'react';
import isEmpty from 'lodash.isempty';
import styled from 'styled-components';
import {
  Heading,
  Flex,
  TextField,
  Select,
  Button,
  Box,
  Icon,
  Banner,
} from '@mediahuis/chameleon-react-legacy';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import useBanner from '~/context/hooks/useBanner';
import { BANNER_TYPES, ERRORS } from '~/constants/messages';
import { useResponsive, apiCall, scrollTopWithTimeout } from '~/utils';
import { config, ts } from '~/services';
import getDirectDebitErrors from '../../utils/getDirectDebitErrors';
import defaultError from '../../../../utils/defaultError';

const TextFieldWithUpperCaseInput = styled(TextField)`
  input {
    text-transform: uppercase;
  }
`;

const postNewPayment = ({
  firstName,
  lastName,
  accountNumber,
  emailAddress,
  paymentInterval,
} = {}) => {
  const body = {
    firstName,
    lastName,
    accountNumber,
    emailAddress,
    paymentInterval,
  };

  return apiCall(`${config.serviceApiUrl}/payments/paymentmethod/update`, {
    method: 'POST',
    data: JSON.stringify(body),
    withCredentials: true,
    params: { 'api-version': '2.0' },
  });
};

const paymentIntervalOptions = [
  {
    value: 'monthly',
    label: 'Maandelijks',
  },
  {
    value: 'quarterly',
    label: 'Per kwartaal',
  },
  {
    value: 'semi-annualy',
    label: 'Halfjaarlijks',
  },
  {
    value: 'yearly',
    label: 'Jaarlijks',
  },
];

const GiroPhaseOutDialog = ({ setDialogShown }) => {
  useResponsive();
  const { showBanner } = useBanner();
  const { userInfo } = useGlobalContext();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    lastName: userInfo.name,
    firstName: userInfo.firstName,
    emailAddress: userInfo.emailAddress,
    paymentInterval: 'monthly',
  });

  const [isLoading, setIsLoading] = useState(false);

  const onChange = event => {
    const input = event.target.value;
    const id = event.target.id;
    setValues(prevState => ({ ...prevState, [id]: input }));
  };

  const onBlur = event => {
    const input = event.target.value;
    const id = event.target.id;
    if (id === 'accountNumber') {
      const ibanErrors = getDirectDebitErrors(input);
      setErrors(ibanErrors);
    }
  };

  const onFocus = event => {
    const currentErrors = { ...errors };
    delete currentErrors[event.target.id];
    setErrors(currentErrors);
  };

  const onSubmit = () => {
    const ibanErrors = getDirectDebitErrors(values.accountNumber);
    setErrors(ibanErrors);
    setIsLoading(true);
    if (isEmpty(ibanErrors)) {
      const {
        firstName,
        lastName,
        accountNumber,
        paymentInterval,
        emailAddress,
      } = values;
      if (paymentInterval) {
        postNewPayment({
          firstName,
          lastName,
          accountNumber,
          paymentInterval,
          emailAddress,
        })
          .then(data => {
            localStorage.setItem('giroPhasedOut', '1');
            setIsLoading(false);
            setDialogShown(false);
            showBanner({
              message: ts.t('Subscription.GiroPhaseOutDialog.SuccessMessage'),
              type: BANNER_TYPES.SUCCESS,
            });
            scrollTopWithTimeout();
            return data;
          })
          .then(() => setDialogShown(false))
          .catch(err => {
            setErrors({
              api:
                err?.status === 400 && err?.errors?.[0]
                  ? ERRORS[err.errors[0]]
                  : defaultError,
            });
          });
      }
    }
  };

  return (
    <React.Fragment>
      <Heading level={4} mb={5} color="primaryBase">
        {ts.t('Subscription.GiroPhaseOutDialog.Heading')}
      </Heading>
      <Flex flexDirection="column">
        <Flex flexGrow="1">
          <Box mr={3} flex={1}>
            <TextField
              data-testid="firstName"
              onChange={onChange}
              onBlur={onBlur}
              label={ts.t('Subscription.GiroPhaseOutDialog.FirstName')}
              id="firstName"
              value={values.firstName}
              message={
                errors.firstName ||
                ts.t('Subscription.GiroPhaseOutDialog.FirstNameError')
              }
              error={!!errors.firstName}
            />
          </Box>
          <Box mr={3} flex={1}>
            <TextField
              data-testid="lastName"
              onChange={onChange}
              onBlur={onBlur}
              label={ts.t('Subscription.GiroPhaseOutDialog.LastName')}
              id="lastName"
              value={values.lastName}
              message={
                errors.lastName ||
                ts.t('Subscription.GiroPhaseOutDialog.LastNameError')
              }
              error={!!errors.lastName}
            />
          </Box>
        </Flex>
        <Flex flexGrow="1" mt={3}>
          <Box mr={3} flex={1}>
            <TextFieldWithUpperCaseInput
              data-testid="accountNumber"
              onChange={onChange}
              onBlur={onBlur}
              label={ts.t('Subscription.GiroPhaseOutDialog.CardNumber')}
              id="accountNumber"
              value={values.accountNumber}
              message={
                errors.cardNumber || ts.t('Subscription.CardNumberMessage')
              }
              error={!!errors.cardNumber}
              onFocus={onFocus}
            />
          </Box>
        </Flex>
      </Flex>
      <Heading level={4} mb={5} mt={5} color="primaryBase">
        {ts.t('Subscription.GiroPhaseOutDialog.PaymentIntervalHeading')}
      </Heading>
      <Box mr={3} flex={1}>
        <Select
          id="paymentInterval"
          value={values.paymentInterval}
          onChange={e => {
            const value = e.target.value;
            return setValues(prevState => ({
              ...prevState,
              paymentInterval: value,
            }));
          }}
          label={ts.t('Subscription.GiroPhaseOutDialog.PaymentInterval')}
          mb={3}
        >
          {paymentIntervalOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </Box>
      {errors && errors.api && (
        <Banner show closeHidden appearance="error">
          {errors.api}
        </Banner>
      )}
      <Flex flexDirection="row" flexGrow="1" justifyContent="end">
        <Button
          mt={5}
          disabled={!isEmpty(errors)}
          isLoading={isLoading}
          onClick={onSubmit}
          icon={ChevronForward}
          data-testid="button-save-payment-info"
        >
          {ts.t('Common.Confirm')}
          <Icon as={ChevronForward} />
        </Button>
      </Flex>
    </React.Fragment>
  );
};

export default GiroPhaseOutDialog;
