/* eslint-disable babel/camelcase */
export const getUserStatus = () => {
  let status = null;
  window.didomiOnReady = window.didomiOnReady || [];
  window.didomiOnReady.push(function (Didomi) {
    // Get user status from the SDK
    const userStatus = Didomi.getUserStatus();

    // List of vendor IDs to pass to the receiver page
    // The IDs are the "SDK ID" from the Didomi Console
    const vendorIds = ['google', 'googleana-4TXnJigR', 'googleana-n6UMhJ7e'];
    const filterVendorIds = v => vendorIds.includes(v);

    // Build value for query-string parameter with the user status
    // We only keep a subset of the user status
    const config = {
      purposes: {
        consent: {
          enabled: userStatus.purposes.consent.enabled,
          disabled: userStatus.purposes.consent.disabled,
        },
        legitimate_interest: {
          enabled: userStatus.purposes.legitimate_interest.enabled,
          disabled: userStatus.purposes.legitimate_interest.disabled,
        },
      },
      vendors: {
        consent: {
          enabled: userStatus.vendors.consent.enabled.filter(filterVendorIds),
          disabled: userStatus.vendors.consent.disabled.filter(filterVendorIds),
        },
        legitimate_interest: {
          enabled: userStatus.vendors.legitimate_interest.enabled.filter(
            filterVendorIds,
          ),
          disabled: userStatus.vendors.legitimate_interest.disabled.filter(
            filterVendorIds,
          ),
        },
      },
      created: userStatus.created,
      updated: userStatus.updated,
    };
    const userStatusForQueryString = encodeURIComponent(JSON.stringify(config));
    console.log(config);
    status = userStatusForQueryString;
  });
  return status;
};
