/* eslint-disable babel/camelcase */
import { Box, Button, Heading, Text } from '@mediahuis/chameleon-react-legacy';
import React, { useEffect } from 'react';
import { useGlobalContext } from '~/context';
import { getCancellationReasonCategory } from '~/pages/Subscription/utils/onlineCancellation';
import { pushTrackingEvent } from '~/utils/tealium';

export default function MedicalConfirmation({
  phoneNumber,
  selectedCancellationReason,
}) {
  const { chosenUserSubscription } = useGlobalContext();

  useEffect(() => {
    pushTrackingEvent(chosenUserSubscription, {
      tealium_event: 'view_medical_cancellation_confirmation',
      cancellation_step: 4,
      cancellation_reason: selectedCancellationReason?.label,
      cancellation_reason_id: selectedCancellationReason?.code,
      cancellation_reason_category: getCancellationReasonCategory({
        medicalOrDeathReason: true,
      }),
    });
  }, []);

  return (
    <>
      <Heading level={3} mb={6} fontFamily="primary">
        We gaan samen met jou op zoek naar de juiste oplossing.
      </Heading>
      <Box mb={2}>
        <Text size="Heading5" fontFamily="primary" fontWeight="medium">
          We doen ons uiterste best om jou zo snel mogelijk te bereiken op{' '}
          {phoneNumber}.
        </Text>
      </Box>
      <Box mb={6}>
        <Text size="Heading5" fontFamily="primary" fontWeight="medium">
          Afhankelijk van de drukte kan dat 1 tot 3 werkdagen duren.
        </Text>
      </Box>
      <Button
        appearance="primary"
        width="full"
        as="a"
        href="https://www.standaard.be/"
        target="_blank"
      >
        Terug naar De Standaard
      </Button>
    </>
  );
}
