import { useEffect, useState } from 'react';
import { COUNTRIES, ERRORS } from '~/constants';
import { config } from '~/services';

export default ({ streetName, postalCode, countryCode, setErrors }) => {
  const [matchedStreets, setMatchedStreets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (streetName) {
      setIsLoading(true);
      const isBpost = config.isMHLU && countryCode === COUNTRIES.BE;
      const apiVersion = isBpost ? '2.0' : '1.0';

      fetch(
        `${
          config.addressServiceApiUrl
        }/Address/AutoComplete/Streets/${countryCode}${
          isBpost ? '/Bpost' : ''
        }?api-version=${apiVersion}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            Key: streetName,
            PostalCode: postalCode,
            // language: ts.getLanguage().substring(0, 2).toUpperCase(),
          }),
        },
      )
        .then(response => response.json())
        .then(response => {
          setIsLoading(false);
          return setMatchedStreets(response.Streets);
        })
        .catch(error => {
          setIsLoading(false);
          if (error) setErrors({ street: ERRORS.FETCHING_STREETS });
        });
    }
  }, [postalCode, streetName]);

  return { matchedStreets, isLoading };
};
