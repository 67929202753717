import React from 'react';
import { Box, Caption, Flex } from '@mediahuis/chameleon-react-legacy';
import parse from 'html-react-parser';
import { StatusIcon } from '~/components/CustomerServiceWidget/PhoneInfo';
import styled from 'styled-components';
import MailImg from './img/mail.svg';

const MailIcon = styled.div`
  margin-left: 9px;
  background-image: url(${MailImg});
  width: 56px;
  height: 56px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const EmailInfo = ({ componentData }) => {
  const { mainText, editText } = componentData;

  return (
    <Flex alignItems="center">
      <Box mr={4}>
        <Flex>
          <MailIcon />
          {/* <Icon as={MailFill} color="colorSecondaryBase" />*/}
          <StatusIcon output="OPEN" />
        </Flex>
      </Box>
      <Box>
        <Caption level={2}>{parse(mainText)}</Caption>
        <Caption level={2}>{parse(editText)}</Caption>
      </Box>
    </Flex>
  );
};

export default EmailInfo;
