import dayjs from 'dayjs';

const getInitialEndDate = ({
  daysOfWeek = [],
  minDuration = 2,
  minimumDate = '',
}) => {
  let endDate = dayjs(minimumDate).add(minDuration - 1, 'day');

  while (daysOfWeek.includes(endDate.day())) {
    endDate = endDate.add(1, 'day');
  }

  return endDate.toDate();
};

export default getInitialEndDate;
