import { apiCall } from '~/utils';
import { config } from '~/services';

const getDeliveryComplaintData = ({ subscriptionId }) =>
  apiCall(
    `${config.serviceApiUrl}/DeliveryComplaintOptions/${subscriptionId}`,
    {
      method: 'GET',
      withCredentials: true,
      params: { 'api-version': '2.0' },
    },
  );

export default getDeliveryComplaintData;
