import { useEffect, useState } from 'react';
import getHouseNumbers from '~/api/getHouseNumbers';
import { ERRORS } from '~/constants';

export default ({ streetName, postalCode, inputValue, setErrors }) => {
  const [houseNumbers, setHouseNumbers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    try {
      setIsLoading(true);
      const body = {
        streetName,
        postalCode,
        key: inputValue,
      };
      const { HouseNumbers } = await getHouseNumbers({ body });
      setHouseNumbers(HouseNumbers);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrors({ street: ERRORS.FETCHING_STREETS });
    }
  };

  useEffect(() => {
    if (inputValue && streetName && postalCode) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, postalCode, streetName]);

  return { houseNumbers, isLoading };
};
