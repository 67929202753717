import { Select } from '@mediahuis/chameleon-react-legacy';
import React from 'react';
import { CATEGORIES, SUB_CATEGORIES } from '~/pages/Contact/constants';
import { handleSelectChange } from '~/pages/Contact/utils';
import { ts } from '~/services';

export default function CategoryAndSubCategory({ state, setState }) {
  return (
    <>
      <Select
        label={ts.t('Contact.Form.MakeAChoice')}
        id="category"
        value={state.category}
        onChange={e => handleSelectChange({ e, setState })}
        mb={4}
        required
      >
        {CATEGORIES.map(category => (
          <option key={category.value} value={category.value}>
            {category.label}
          </option>
        ))}
      </Select>

      <Select
        label={ts.t('Contact.Form.SubCategoryLabel')}
        id="subCategory"
        value={state.subCategory}
        onChange={e => handleSelectChange({ e, setState })}
        mb={4}
        required
      >
        {SUB_CATEGORIES.map(subCategory => (
          <option key={subCategory.value} value={subCategory.value}>
            {subCategory.label}
          </option>
        ))}
      </Select>
    </>
  );
}
