import React, { useState, useCallback } from 'react';

const GlobalContext = React.createContext();

const GlobalContextProvider = ({ children }) => {
  const [globalState, setState] = useState({
    userSubscriptions: [],
    chosenUserSubscription: null,
    hasFetchedUserSubscriptions: false,
    userInfo: {},
    bannerData: { type: 'info', message: '', mayShow: false },
    isFetchingUser: true,
    language: 'nl-BE',
    subscriptionRights: [],
    chosenSubscriptionRight: null,
    deeplink: null,
    latestAddressChange: null,
    isLoadingPayerAndReceiver: false,
    faqCategories: [],
    isLoadingFaqCategories: true,
    faqCategoriesHasFailed: false,
  });

  const setGlobalState = useCallback(
    data => setState(state => ({ ...state, ...data })),
    [setState],
  );

  return (
    <GlobalContext.Provider value={{ ...globalState, setGlobalState }}>
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalContextProvider };
