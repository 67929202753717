import {
  Banner,
  Button,
  Flex,
  Paragraph,
  Text,
  useMediaQuery,
} from '@mediahuis/chameleon-react-legacy';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import { getOutstandingPaymentMessage } from '~/constants/outstandingPayment';
import { useSubscriptionContext } from '~/context';
import { config, ts } from '~/services';

const OutstandingPaymentBanners = () => {
  const subscriptionState = useSubscriptionContext();
  const { outstandingPayments } = subscriptionState;

  const wrapperAlignItems = useMediaQuery(
    {
      md: 'center',
    },
    'flex-start',
  );
  const wrapperFlexDirection = useMediaQuery(
    {
      md: 'row',
    },
    'column',
  );
  const textMarginRight = useMediaQuery(
    {
      md: 5,
    },
    0,
  );
  const textMarginBottom = useMediaQuery(
    {
      md: 0,
    },
    5,
  );
  return (
    <>
      {outstandingPayments.map(payment => {
        return (
          <Banner mb={5} show closeHidden key={payment.orderId}>
            <Flex
              justifyContent="space-between"
              alignItems={wrapperAlignItems}
              flexDirection={wrapperFlexDirection}
            >
              <Text flex={1} mr={textMarginRight} mb={textMarginBottom}>
                {getOutstandingPaymentMessage({
                  type: payment?.outstandingPaymentReason,
                  amount: payment?.amount?.toFixed(2),
                  paymentReference: payment?.paymentReference,
                })}
              </Text>
              <Button
                size="small"
                as="a"
                href={`${config.aboshopUrl}/settlement?paymentReference=${payment?.paymentReference}`}
                target="_blank"
                iconRight={ChevronForward}
              >
                {ts.t('Subscription.OutstandingPaymentBanner.Button')}
              </Button>
            </Flex>
          </Banner>
        );
      })}
      <Paragraph my={5}>
        {ts.t('Subscription.OutstandingPaymentIntroText', {
          richContent: true,
        })}
      </Paragraph>
    </>
  );
};

export default OutstandingPaymentBanners;
