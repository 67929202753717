/* eslint-disable babel/camelcase */
import { Flex, Text } from '@mediahuis/chameleon-react-legacy';

export const CANCELLATION_STEPS = {
  FIRST_STEP: 1,
  SECOND_STEP: 2,
  THIRD_STEP: 3,
  FOURTH_STEP: 4,
  FIFTH_STEP: 5,
};

export const DS_ZADI_SUBSCRIPTION_TYPE = 'DSZADI';
export const DS_DIGI_SUBSCRIPTION_TYPE = 'DSDIGI';

export const FLOW_TYPES = {
  CANCEL: 'Cancel',
  CANCEL_WITH_CALL: 'CancelWithCall',
  CONVINCED_TO_STAY: 'ConvincedToStay',
};
export const OTHER_REASON = 'OVERIG';
export const MEDICAL_REASON = 'ZIEK';
export const DEATH_REASON = 'RIP';
export const OTHER_CANCELLATION_REASON_LABEL = 'Andere reden';
export const OTHER_CANCELLATION_REASON = {
  label: OTHER_CANCELLATION_REASON_LABEL,
  code: OTHER_REASON,
  position: 13,
  enabled: true,
  subscriptionTypes: [DS_ZADI_SUBSCRIPTION_TYPE, DS_DIGI_SUBSCRIPTION_TYPE],
};
export const CANCELLATION_REASONS = [
  {
    label: 'Geen, tijd / beperkt gebruik',
    code: 'GNTIJD',
    position: 1,
    enabled: true,
    subscriptionTypes: [DS_ZADI_SUBSCRIPTION_TYPE, DS_DIGI_SUBSCRIPTION_TYPE],
  },
  {
    label: 'Te duur',
    code: 'PRIJSB',
    position: 2,
    enabled: true,
    subscriptionTypes: [DS_ZADI_SUBSCRIPTION_TYPE, DS_DIGI_SUBSCRIPTION_TYPE],
  },
  {
    label: 'Reeds abonnement bij GVA, HBVL of Nieuwsblad',
    code: 'A MH',
    position: 3,
    enabled: true,
    subscriptionTypes: [DS_ZADI_SUBSCRIPTION_TYPE, DS_DIGI_SUBSCRIPTION_TYPE],
  },
  {
    label: 'Ander betalend abonnement',
    code: 'A ABON',
    position: 4,
    enabled: true,
    subscriptionTypes: [DS_ZADI_SUBSCRIPTION_TYPE, DS_DIGI_SUBSCRIPTION_TYPE],
  },
  {
    label: 'Gratis nieuws is voldoende',
    code: 'ANIEWS',
    position: 5,
    enabled: true,
    subscriptionTypes: [DS_ZADI_SUBSCRIPTION_TYPE, DS_DIGI_SUBSCRIPTION_TYPE],
  },
  {
    label: 'Andere inhoudelijke interesses',
    code: 'REDACT',
    position: 6,
    enabled: true,
    subscriptionTypes: [DS_ZADI_SUBSCRIPTION_TYPE, DS_DIGI_SUBSCRIPTION_TYPE],
  },
  {
    label: 'Wil abonnement wijzigen',
    code: 'CORR',
    position: 7,
    enabled: true,
    subscriptionTypes: [DS_ZADI_SUBSCRIPTION_TYPE, DS_DIGI_SUBSCRIPTION_TYPE],
  },
  {
    label: 'Problemen met bezorging',
    code: 'BEZORG',
    position: 8,
    enabled: true,
    subscriptionTypes: [DS_ZADI_SUBSCRIPTION_TYPE],
  },
  {
    label: 'Problemen met digitale toegang',
    code: 'TECHRD',
    position: 9,
    enabled: true,
    subscriptionTypes: [DS_ZADI_SUBSCRIPTION_TYPE, DS_DIGI_SUBSCRIPTION_TYPE],
  },
  {
    label: 'Medische redenen',
    code: 'ZIEK',
    position: 10,
    enabled: true,
    subscriptionTypes: [DS_ZADI_SUBSCRIPTION_TYPE, DS_DIGI_SUBSCRIPTION_TYPE],
  },
  {
    label: 'Overlijden',
    code: 'RIP',
    position: 11,
    enabled: true,
    subscriptionTypes: [DS_ZADI_SUBSCRIPTION_TYPE, DS_DIGI_SUBSCRIPTION_TYPE],
  },
  {
    label: 'Ik geef liever geen reden',
    code: 'GNREDE',
    position: 12,
    enabled: true,
    subscriptionTypes: [DS_ZADI_SUBSCRIPTION_TYPE, DS_DIGI_SUBSCRIPTION_TYPE],
  },
  {
    label: 'Andere reden',
    code: OTHER_REASON,
    position: 13,
    enabled: true,
    subscriptionTypes: [DS_ZADI_SUBSCRIPTION_TYPE, DS_DIGI_SUBSCRIPTION_TYPE],
  },
];

const currentSubscriptionWithDiscount = (
  <Text fontWeight="regular" size="Heading5" fontFamily="system">
    Je huidige abonnement
    <br />
    <Text size="Heading5" fontWeight="bold">
      met korting
    </Text>
  </Text>
);

const allPlusartikels = (
  <Text alignSelf="center" fontFamily="system" size="Caption1">
    Onbeperkt alle{' '}
    <Text size="Caption1" fontWeight="bold">
      <img
        src="https://shared.mediahuis.be/logos/ds/v3/brand-plus.svg"
        style={{
          verticalAlign: 'text-bottom',
        }}
        height="16px"
        alt="brand-plus-icon"
      />{' '}
      plusartikels
    </Text>
  </Text>
);

const monthlyTerminable = (
  <Text alignSelf="center" fontFamily="system" size="Caption1">
    Maandelijks opzegbaar
  </Text>
);

export const ZADI_ALTERNATIVE_OFFERS = [
  {
    title: currentSubscriptionWithDiscount,
    price: (
      <Flex flexDirection="column">
        <Text size="Caption1" fontFamily="system" fontWeight="bold">
          € 23,50
          <br />
          /maand
        </Text>
        <Text
          size="Caption1"
          fontFamily="system"
          color="grey50"
          fontWeight="medium"
        >
          ipv € 29,50
        </Text>
      </Flex>
    ),
    features: [
      {
        children: allPlusartikels,
        isAvailable: true,
        key: 0,
      },
      {
        children: (
          <Text alignSelf="center" fontFamily="system" size="Caption1">
            Elke dag de{' '}
            <Text size="Caption1" fontWeight="bold">
              krant digitaal
            </Text>
          </Text>
        ),
        isAvailable: true,
        key: 1,
      },
      {
        children: (
          <Text alignSelf="center" fontFamily="system" size="Caption1">
            <Text size="Caption1" fontWeight="bold">
              Zaterdag
            </Text>{' '}
            een{' '}
            <Text size="Caption1" fontWeight="bold">
              papieren krant
            </Text>
          </Text>
        ),
        isAvailable: true,
        key: 2,
      },
      {
        children: (
          <Text alignSelf="center" fontFamily="system" size="Caption1">
            Looptijd van 12 maanden
          </Text>
        ),
        isAvailable: true,
        key: 3,
      },
    ],
    scenario: 'KeepWithDiscountZadi',
    highlight: true,
    plusarticlesAreFree: true,
    confirmationTitle: 'Je behoudt je wknd plus abonnement:',
    confirmationItems: [
      'Je leest aan een voordeeltarief van 23,50 euro per maand*',
      'Je krijgt deze korting omdat je voor 12 maanden intekent',
      'Daarna geldt opnieuw het reguliere abonnementstarief en is het weer elke maand opzegbaar',
    ],
    trackingData: {
      offer_id: '1',
      offer_type: 'discount',
      offer_price: 23.5,
      offer_title: 'keep zadi',
    },
    buttonText: 'Ik wil deze korting',
  },
  {
    title: (
      <Text fontWeight="regular" size="Heading5" fontFamily="system">
        Je huidige abonnement{' '}
        <Text size="Heading5" fontWeight="bold">
          zonder zaterdagkrant
        </Text>
      </Text>
    ),
    price: (
      <Flex flexDirection="column">
        <Text size="Caption1" fontFamily="system" fontWeight="bold">
          € 26,50
          <br />
          /maand
        </Text>
      </Flex>
    ),
    features: [
      {
        children: allPlusartikels,
        isAvailable: true,
        key: 0,
      },
      {
        children: (
          <Text alignSelf="center" fontFamily="system" size="Caption1">
            Elke dag de{' '}
            <Text size="Caption1" fontWeight="bold">
              krant digitaal
            </Text>
          </Text>
        ),
        isAvailable: true,
        key: 1,
      },
      {
        children: (
          <Text alignSelf="center" fontFamily="system" size="Caption1">
            Zaterdag een papieren krant
          </Text>
        ),
        isAvailable: false,
        key: 2,
      },
      {
        children: monthlyTerminable,
        isAvailable: true,
        key: 3,
      },
    ],
    scenario: 'DowngradeZadiToDigi',
    highlight: false,
    plusarticlesAreFree: true,
    confirmationTitle: 'Je kiest voor een abonnement zonder zaterdagkrant:',
    confirmationItems: [
      'Je abonnement wordt aangepast van wknd plus naar digi plus',
      'Je leest aan 26,50 euro per maand*',
      'Je kan elke maand opzeggen',
    ],
    trackingData: {
      offer_id: '2',
      offer_type: 'downgrade',
      offer_price: 26.5,
      offer_title: 'downgrade from wknd to digi',
    },
    buttonText: 'Ik pas mijn abonnement aan',
  },
];

export const DIGI_ALTERNATIVE_OFFERS = [
  {
    title: currentSubscriptionWithDiscount,
    price: (
      <Flex flexDirection="column">
        <Text size="Caption1" fontFamily="system" fontWeight="bold">
          € 21,00
          <br />
          /maand
        </Text>
        <Text
          size="Caption1"
          fontFamily="system"
          color="grey50"
          fontWeight="medium"
        >
          ipv € 26,50
        </Text>
      </Flex>
    ),
    features: [
      {
        children: allPlusartikels,
        isAvailable: true,
        key: 0,
      },
      {
        children: (
          <Text alignSelf="center" fontFamily="system" size="Caption1">
            Elke dag de{' '}
            <Text size="Caption1" fontWeight="bold">
              krant digitaal
            </Text>
          </Text>
        ),
        isAvailable: true,
        key: 1,
      },
      {
        children: (
          <Text alignSelf="center" fontFamily="system" size="Caption1">
            Looptijd van 12 maanden
          </Text>
        ),
        isAvailable: true,
        key: 2,
      },
    ],
    scenario: 'KeepWithDiscountDigi',
    highlight: true,
    plusarticlesAreFree: true,
    confirmationTitle: 'Je behoudt je digi plus abonnement:',
    confirmationItems: [
      'Je leest aan een voordeeltarief van 21,00 euro per maand*',
      'Je krijgt deze korting omdat je voor 12 maanden intekent',
      'Daarna geldt opnieuw het reguliere abonnementstarief en is het weer elke maand opzegbaar',
    ],
    trackingData: {
      offer_id: '3',
      offer_type: 'discount',
      offer_price: 21.0,
      offer_title: 'keep digi',
    },
    buttonText: 'Ik wil deze korting',
  },
  {
    title: (
      <Text fontWeight="regular" size="Heading5" fontFamily="system">
        Enkel nog{' '}
        <img
          src="https://shared.mediahuis.be/logos/ds/v3/brand-plus.svg"
          style={{
            verticalAlign: 'middle',
          }}
          height="16px"
          alt="brand-plus-icon"
        />{' '}
        <Text size="Heading5" fontWeight="bold">
          plusartikels
        </Text>
      </Text>
    ),
    price: (
      <Flex flexDirection="column">
        <Text size="Caption1" fontFamily="system" fontWeight="bold">
          € 16,00
          <br />
          /maand
        </Text>
      </Flex>
    ),
    features: [
      {
        children: allPlusartikels,
        isAvailable: true,
        key: 0,
      },
      {
        children: (
          <Text alignSelf="center" fontFamily="system" size="Caption1">
            Elke dag de krant digitaal
          </Text>
        ),
        isAvailable: false,
        key: 1,
      },
      {
        children: monthlyTerminable,
        isAvailable: true,
        key: 2,
      },
    ],
    scenario: 'DowngradeDigiToArt',
    highlight: false,
    plusarticlesAreFree: false,
    confirmationTitle:
      'Je kiest voor een abonnement met uitsluitend toegang tot de plusartikels van De Standaard:',
    confirmationItems: [
      'Je abonnement wordt aangepast van digi plus naar basic',
      'Je leest aan 16,00 euro per maand*',
      'Je kan elke maand opzeggen',
    ],
    trackingData: {
      offer_id: '4',
      offer_type: 'downgrade',
      offer_price: 16,
      offer_title: 'downgrade from digi to basic',
    },
    buttonText: 'Ik pas mijn abonnement aan',
  },
];
