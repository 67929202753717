import { config } from '~/services';
import { apiCall } from '~/utils';

const postStudentCard = async ({ subscriptionId, body }) => {
  await apiCall(
    `${config.serviceApiUrl}/subscriptions/${subscriptionId}/student-card?api-version=2`,
    {
      method: 'POST',
      data: JSON.stringify(body),
      withCredentials: true,
    },
  );
};

export default postStudentCard;
