import { SUBSCRIPTION_STATUS_CODES } from '~/constants';
import getLanguage from '../language';

const getNotAvailableCopy = ({ chosenUserSubscription }) => {
  const language = getLanguage(
    chosenUserSubscription?.internalSubscriptionType,
  );

  const {
    NotAvailableModule,
    NoActiveSubscription,
    NotAvailableWaiting,
    NotAvailableSuspended,
    NotAvailableTemporarySuspended,
    NotAvailableWKSubscription,
  } = language;

  const {
    WAITING,
    SUSPENDED,
    TEMPORARY_SUSPENSION,
  } = SUBSCRIPTION_STATUS_CODES;
  const subscriptionScopes =
    chosenUserSubscription?.subscriptionScopes?.scopes || [];
  const subscriptionState = chosenUserSubscription?.state;
  const subscriptionType = chosenUserSubscription?.subscriptionType;
  const isVacationModuleActive = chosenUserSubscription?.modules?.vacation;

  if (!isVacationModuleActive && subscriptionScopes.length > 0) {
    return <NotAvailableModule />;
  }
  if (subscriptionType?.slice(-2)?.toLowerCase() === 'wk') {
    return <NotAvailableWKSubscription />;
  }
  if (subscriptionState?.toLowerCase() === WAITING) {
    return <NotAvailableWaiting />;
  } else if (subscriptionState?.toLowerCase() === SUSPENDED) {
    return <NotAvailableSuspended />;
  } else if (subscriptionState?.toLowerCase() === TEMPORARY_SUSPENSION) {
    return <NotAvailableTemporarySuspended />;
  }
  return <NoActiveSubscription />;
};

export default getNotAvailableCopy;
