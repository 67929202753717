import isEmpty from 'lodash.isempty';
import validateAddress from '~/api/validateAddress';
import {
  getAddressErrorsDefault,
  getAhAddressErrorsNL,
} from '~/components/AddressFields/addressFieldsValidations';
import { COUNTRIES } from '~/constants';
import language from './language';

const VALIDATION_PER_FIELD_KEYS_MAP = {
  country: 'country',
  postalcode: 'postalCode',
  city: 'city',
  street: 'street',
  number: 'houseNumber',
};

const ahAddressFieldsValidations = async ({
  fields,
  country,
  useAddressService = false,
}) => {
  const errors =
    country === COUNTRIES.NL
      ? getAhAddressErrorsNL(fields)
      : getAddressErrorsDefault(fields);

  if (
    [COUNTRIES.BE, COUNTRIES.NL, COUNTRIES.LU].includes(country) &&
    isEmpty(errors) &&
    useAddressService
  ) {
    try {
      const response = await validateAddress({ address: fields });
      if (response?.ValidationResult?.IsValid) {
        return {};
      }
      // There is no validation per field for Dutch addresses in the address service api
      if (country === COUNTRIES.NL) {
        return {
          houseNumber: language.postalCodeHouseNumberComboNotFound,
        };
      }
      const invalidFields = response?.ValidationResult?.ValidationPerField?.filter(
        field => !field.Value,
      ).reduce((acc, val) => {
        return {
          ...acc,
          [VALIDATION_PER_FIELD_KEYS_MAP[val.Key]]: language.invalid[val.Key],
        };
      }, {});

      return invalidFields;
    } catch (error) {
      console.log(error);
    }
  }
  return errors;
};
export default ahAddressFieldsValidations;
