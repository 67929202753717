import { config } from '~/services';
import { apiCall } from '~/utils';

const getStreetFullName = async ({ address }) => {
  const { street, postalCode } = address;
  const res = await apiCall(
    `${config.addressServiceApiUrl}/Streets/BE/GetFullName?streetname=${street}&postalCode=${postalCode}&api-version=2.0`,
  );
  return res;
};

export default getStreetFullName;
