import React, { useEffect } from 'react';
import {
  Button,
  Flex,
  Heading,
  Text,
  Caption,
  Box,
} from '@mediahuis/chameleon-react-legacy';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import useHolidayContext from '~/context/hooks/useHolidayContext';
import { config, ts } from '~/services';
import { cleanDate } from '../../utils/cleanHolidayData';

const BaseCancelDialog = ({
  isDialogShown,
  chosenHoliday,
  onCancel,
  isLoading,
  setSubmissionData,
  submissionData,
}) => {
  const holidayContext = useHolidayContext();
  const { holidays } = holidayContext;

  useEffect(() => {
    if (chosenHoliday !== null) {
      setSubmissionData(holidays[chosenHoliday]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenHoliday, isDialogShown]);

  return (
    <>
      <Heading
        level={3}
        mb={5}
        fontFamily="primary"
        color={config.isAportal ? 'colorSecondaryBase' : 'colorPrimaryBase'}
      >
        {ts.t('Holiday.CancelDialog.Heading')}
      </Heading>
      <Box mb={7}>
        <Text fontFamily="primary" size="Heading4" fontWeight="medium">
          {ts.t('Holiday.CancelDialog.SubHeading')}
        </Text>
      </Box>
      <Flex pb={7}>
        <Box mr={7}>
          <Box pb={4}>
            <Text fontFamily="primary" size="caption1">
              {ts.t('Holiday.CancelDialog.StartDate')}
            </Text>
          </Box>
          {submissionData && (
            <Caption color="colorGrey60">
              {cleanDate(submissionData.start || submissionData.startDate)}
            </Caption>
          )}
        </Box>
        <Box>
          <Box pb={4}>
            <Text fontFamily="primary" size="caption1">
              {ts.t('Holiday.CancelDialog.EndDate')}
            </Text>
          </Box>
          {submissionData && (
            <Caption color="colorGrey60">
              {cleanDate(submissionData.end || submissionData.endDate)}
            </Caption>
          )}
        </Box>
      </Flex>
      <Flex justifyContent="flex-end">
        <Button
          onClick={onCancel}
          loading={isLoading}
          iconRight={ChevronForward}
        >
          {ts.t('Holiday.CancelDialog.CancelHolidayButtonText')}
        </Button>
      </Flex>
    </>
  );
};

export default BaseCancelDialog;
