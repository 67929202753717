import React from 'react';
import MedicalForm from '~/pages/Subscription/components/DialogContent/OnlineCancellation/MedicalForm';
import UspSection from '~/pages/Subscription/components/DialogContent/OnlineCancellation/UspSection';
import {
  DEATH_REASON,
  MEDICAL_REASON,
} from '~/pages/Subscription/constants/onlineCancellation';

export default function SecondStep({
  errors,
  setErrors,
  setPhoneNumber,
  phoneNumber,
  selectedCancellationReason,
  setOnlineCancellationStep,
  handleSubmit,
  isSubmitting,
  error,
  handleClose,
  articles,
  setArticles,
}) {
  const medicalOrDeathReason = [DEATH_REASON, MEDICAL_REASON].includes(
    selectedCancellationReason?.code,
  );
  return medicalOrDeathReason ? (
    <MedicalForm
      errors={errors}
      setErrors={setErrors}
      phoneNumber={phoneNumber}
      setPhoneNumber={setPhoneNumber}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      error={error}
      selectedCancellationReason={selectedCancellationReason}
    />
  ) : (
    <UspSection
      setOnlineCancellationStep={setOnlineCancellationStep}
      handleClose={handleClose}
      articles={articles}
      setArticles={setArticles}
      selectedCancellationReason={selectedCancellationReason}
    />
  );
}
