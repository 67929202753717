import { config, ts } from '~/services';
import { TYPES } from '../constants';
import getNotReceivedMessage from './getNotReceivedMessage';

const postSuccessMessage = ({
    chosenComplaint = {},
    deliveryDate = new Date(),
}) => {
    switch (chosenComplaint.complaint) {
        case TYPES.NOT_RECEIVED:
            return getNotReceivedMessage(deliveryDate);
        case TYPES.TOO_LATE:
        case TYPES.DAMAGED:
            return ts.t('DeliveryComplaint.Messages.Damaged');
        case TYPES.WRONG_TITLE_OR_EDITION:
            return ts.t('DeliveryComplaint.Messages.WrongTitleOrEdition', {
                richContent: true,
                values: { siteUrl: config.siteUrl },
            });
        default:
            return ts.t('DeliveryComplaint.Messages.DefaultSuccess');
    }
};

export default postSuccessMessage;
