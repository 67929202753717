import { ts } from '~/services';

export const DAMAGED = 'Damaged';
export const TOO_LATE = 'TooLate';
export const NOT_RECEIVED = 'NotReceived';
export const ANNEX_NOT_RECEIVED = 'AnnexNotReceived';
export const WRONG_TITLE_OR_EDITION = 'WrongTitleOrEdition';
export const WRONG_MAILBOX = 'WrongMailbox';
export const ERROR_NO_SELECTION = ts.t(
    'DeliveryComplaint.Constants.ERROR_NO_SELECTION',
);
export const ERROR_COMMENT_REQUIRED = ts.t(
    'DeliveryComplaint.Constants.ERROR_COMMENT_REQUIRED',
);
export const ERROR_TOO_MANY_CHARACTERS = ts.t(
    'DeliveryComplaint.Constants.ERROR_TOO_MANY_CHARACTERS',
);

export const TYPES = {
    DAMAGED,
    TOO_LATE,
    NOT_RECEIVED,
    ANNEX_NOT_RECEIVED,
    WRONG_TITLE_OR_EDITION,
    WRONG_MAILBOX,
};
