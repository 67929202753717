import { useEffect, useState } from 'react';
import { ENTITIES, ERRORS } from '~/constants';
import { config } from '~/services';

export default ({
  postalCode,
  countryCode,
  allowedPostalCodesByCountry,
  setErrors,
}) => {
  const [matchedCities, setMatchedCities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (postalCode) {
      setIsLoading(true);
      fetch(
        `${config.addressServiceApiUrl}/Address/AutoComplete/Cities/${countryCode}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            Key: postalCode,
          }),
        },
      )
        .then(response => {
          return response.json();
        })
        .then(response => {
          if (config.entity !== ENTITIES.MHLU) {
            setIsLoading(false);
            setMatchedCities(response.Cities);
            return response.Cities;
          }
          const allowAllPostalCodes =
            allowedPostalCodesByCountry?.[countryCode]?.allowAllPostalCodes;
          const allowedPostalCodes =
            allowedPostalCodesByCountry?.[countryCode]?.allowedPostalCodes;

          const filteredCities = allowAllPostalCodes
            ? response.Cities
            : response.Cities.filter(city =>
                allowedPostalCodes.includes(city.PostalCode),
              );
          setMatchedCities(filteredCities);
          setIsLoading(false);

          return filteredCities;
        })
        .catch(error => {
          setIsLoading(false);
          if (error) setErrors({ postalCode: ERRORS.FETCHING_POSTALCODES });
        });
    }
  }, [postalCode, countryCode, allowedPostalCodesByCountry]);

  return { matchedCities, isLoading };
};
