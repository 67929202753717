import { config } from '~/services';
import apiCall from './apiCall';

const getPaymentStatus = (paymentReference = '') =>
  apiCall(`${config.serviceApiUrl}/payments/${paymentReference}/status`, {
    params: { 'api-version': '2.0' },
    withCredentials: true,
  });

export default getPaymentStatus;
