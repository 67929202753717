import { config } from '~/services';

/**
 * @class LocationValidator
 */
class LocationValidator {
  /**
   *
   * @param brand
   * @param postalCode
   * @param country
   * @return {Promise<Response>}
   */
  validate = (brand, postalCode, country) => {
    return fetch(
      `${config.getLocationValidatorUrl(
        brand,
      )}&postalCode=${postalCode}&country=${country}`,
      {
        headers: {
          'x-api-version': '2.0',
        },
      },
    );
  };
}

const instance = new LocationValidator();
export { instance as locationValidator };
