import { TextField } from '@mediahuis/chameleon-react-legacy';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import { getFullNameOfCustomerField } from '~/pages/Subscription/ah/utils/fields';
import { ts } from '~/services';
import removeLeadingZeroes from '~/utils/removeLeadingZeroes';

const AhDisabledSubscriptionInfo = () => {
  const { chosenUserSubscription } = useGlobalContext();

  const organistaionNameOrFullName = getFullNameOfCustomerField(
    chosenUserSubscription?.receiver,
  );

  return (
    <TextField
      id="textfield-your-subscription"
      label={ts.t('Subscription.SubscriptionSelectLabel')}
      placeholder=""
      disabled
      value={`${
        organistaionNameOrFullName ? `${organistaionNameOrFullName} - ` : ''
      }${chosenUserSubscription?.name} - ${removeLeadingZeroes(
        chosenUserSubscription?.id,
      )}`}
    />
  );
};

export default AhDisabledSubscriptionInfo;
