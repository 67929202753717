import { apiCall } from '~/utils';
import { config } from '~/services';

const getHolidayArrangementRules = ({ method = 'POST', body, id }) =>
  apiCall(
    id
      ? `${config.serviceApiUrl}/VacationArrangements/${id}`
      : `${config.serviceApiUrl}/VacationArrangements`,
    {
      method,
      withCredentials: true,
      data: JSON.stringify(body),
      params: { 'api-version': '2.0' },
    },
  );

export default getHolidayArrangementRules;
