import React from 'react';
import {
  Heading,
  Paragraph,
  Box,
  Flex,
  useMediaQuery,
  Button,
} from '@mediahuis/chameleon-react-legacy';
import { space7 } from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';
import { Subscription } from '~/svg/Icons';
import { Wrapper } from '~/components';
import { ICON_FILL } from '~/components/SelfHelp/Icon';
import { config, ts } from '~/services';

import ActivateButton from '~/pages/Subscription/components/ActivateButton';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/lib-legacy/web/icons';

const NotFound = () => {
  const buttonWidth = useMediaQuery({ sm: 'auto' }, 'full');

  return (
    <Wrapper>
      <Flex
        alignItems="center"
        flexDirection={useMediaQuery({ md: 'row' }, 'column')}
      >
        <Box
          width={useMediaQuery({ sm: '50%', lg: '75%' }, '75%')}
          mr={useMediaQuery({ md: space7 }, 0)}
          mb={useMediaQuery({ md: 0 }, space7)}
          style={{ fill: ICON_FILL[config.brand] }}
        >
          <Subscription />
        </Box>
        <Box width="100%">
          <Heading level={3} fontFamily="primary" mb={3}>
            {ts.t('Subscription.NotFound.Title')}
          </Heading>
          <Paragraph mb={6}>
            {ts.t('Subscription.NotFound.Text', {
              richContent: true,
            })}
          </Paragraph>
          {config.brand === BRANDS.LT ? (
            <Button
              iconRight={ChevronForward}
              width={buttonWidth}
              as="a"
              href={config.aboshopUrl}
              target="_blank"
            >
              {ts.t('Subscription.NotFound.ButtonText')}
            </Button>
          ) : (
            <ActivateButton />
          )}
        </Box>
      </Flex>
    </Wrapper>
  );
};

export default NotFound;
