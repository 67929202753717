import React from 'react';
import { Paper, Flex } from '@mediahuis/chameleon-react-legacy';
import { getBrandLogoUrl } from '~/utils';
import { ts } from '~/services';

const Maintenance = () => {
  return (
    <Flex
      style={{
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper p={4}>
        <Flex style={{ alignItems: 'center', justifyContent: 'center' }}>
          <img src={getBrandLogoUrl()} alt="brand-logo" />
          <Flex ml={4} style={{ height: '100%' }}>
            {ts.t('Maintenance.Message', { richContent: true })}
          </Flex>
        </Flex>
      </Paper>
    </Flex>
  );
};

export default Maintenance;
