export const CREDIT_CARD = 'CreditCard';
export const DIRECT_DEBIT = 'DirectDebit';
export const FIRST_RECURRING = 'FirstRecurring';
export const RECURRING = 'RECURRING';
export const PAYMENT_REFERENCE = 'paymentReference';
export const PAYMENT_STATUSES = Object.freeze({
  PAID: 'paid',
  FAILED: 'failed',
  CANCELED: 'canceled',
  OPEN: 'open',
  PENDING: 'pending',
  EXPIRED: 'expired',
});
