/* eslint-disable babel/camelcase */
import {
  Box,
  Button,
  Flex,
  Heading,
  Paragraph,
  Text,
  TextField,
} from '@mediahuis/chameleon-react-legacy';
import { borderRadius3 } from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';
import React, { useEffect } from 'react';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import nl from 'react-phone-number-input/locale/nl.json';
import styled from 'styled-components';
import { useGlobalContext } from '~/context';
import { StyledFlex } from '~/pages/Subscription/components/DialogContent/PhoneNumbersDialog';
import {
  CANCELLATION_STEPS,
  FLOW_TYPES,
  MEDICAL_REASON,
} from '~/pages/Subscription/constants/onlineCancellation';
import { getCancellationReasonCategory } from '~/pages/Subscription/utils/onlineCancellation';
import { clearValidationOnFocus } from '~/utils';
import { pushTrackingEvent } from '~/utils/tealium';

export const StyledBox = styled(Box)`
  box-shadow: 0px 0px 10px 0px #0000000d;
  border: solid 1px #e2e2e2;
  border-radius: ${borderRadius3};
`;
export default function MedicalForm({
  phoneNumber,
  setPhoneNumber,
  setErrors,
  errors,
  handleSubmit,
  isSubmitting,
  error,
  selectedCancellationReason,
}) {
  const { chosenUserSubscription } = useGlobalContext();

  useEffect(() => {
    if (selectedCancellationReason) {
      pushTrackingEvent(chosenUserSubscription, {
        tealium_event: 'view_cancellation_medical',
        cancellation_step: 2,
        cancellation_reason: selectedCancellationReason?.label,
        cancellation_reason_id: selectedCancellationReason?.code,
        cancellation_reason_category: getCancellationReasonCategory({
          medicalOrDeathReason: true,
        }),
      });
    }
  }, [selectedCancellationReason]);

  const paperPronoun =
    selectedCancellationReason?.code === MEDICAL_REASON ? 'je' : 'de';

  return (
    <Box>
      <Heading level={3} mb={5} fontFamily="primary">
        Het spijt ons dat te horen.
      </Heading>
      {error}
      <Heading level={4} mb={6} fontFamily="primary">
        We helpen we je graag persoonlijk verder:
      </Heading>
      <StyledBox mb={4} p={5}>
        <Paragraph fontWeight="medium" mb={1} fontFamily="primary">
          Misschien kunnen we {paperPronoun} krant (tijdelijk) naar een ander
          adres sturen, of leest er graag iemand van je familie mee?
        </Paragraph>
        <Paragraph fontFamily="primary">
          Bel ons op een werkdag tussen 8u en 17u of zaterdag tussen 9u en 13u
          op 02 790 21 10. Of wij kunnen jou bellen binnen de 3 werkdagen:
        </Paragraph>
        <Flex alignItems="flex-end">
          <StyledFlex position="relative" error={!!errors.phoneNumber} flex={1}>
            <PhoneInput
              placeholder="Telefoonnummer"
              value={phoneNumber}
              name="phoneNumber"
              id="phoneNumber"
              onChange={value => {
                setPhoneNumber(value || null);
              }}
              onFocus={e => clearValidationOnFocus({ e, errors, setErrors })}
              onBlur={() => {
                setErrors(prevState => {
                  const errorMessage =
                    phoneNumber && !isPossiblePhoneNumber(phoneNumber)
                      ? 'Telefoonnummer is niet geldig'
                      : '';
                  return {
                    ...prevState,
                    phoneNumber: errorMessage,
                  };
                });
              }}
              labels={nl}
              international
              defaultCountry="BE"
              inputComponent={TextField}
              countryOptionsOrder={['BE', 'FR', 'DE', 'LU', 'NL']}
              error={!!errors.phoneNumber}
              message={errors.phoneNumber}
            />
          </StyledFlex>
          <Button
            onClick={() => {
              if (!phoneNumber) {
                setErrors({
                  ...errors,
                  phoneNumber: 'Telefoonnummer is verplicht',
                });
                return;
              }
              handleSubmit(
                CANCELLATION_STEPS.THIRD_STEP,
                FLOW_TYPES.CANCEL_WITH_CALL,
              );
              pushTrackingEvent(chosenUserSubscription, {
                tealium_event: 'click_cancellation_medical_phone',
                cancellation_step: 2,
                cancellation_reason: selectedCancellationReason?.label,
                cancellation_reason_id: selectedCancellationReason?.code,
                cancellation_reason_category: getCancellationReasonCategory({
                  medicalOrDeathReason: true,
                }),
              });
            }}
            disabled={!!errors?.phoneNumber}
            loading={isSubmitting}
            appearance="secondary"
            ml={3}
            style={{
              marginBottom: !!errors.phoneNumber && '22px',
              transition: 'margin-bottom 0s',
            }}
          >
            Bel mij
          </Button>
        </Flex>
      </StyledBox>

      <StyledBox p={4}>
        <Flex flexDirection="column">
          <Text size="Caption1" fontFamily="primary" mb={4}>
            Toch liever meteen opzeggen?
          </Text>
          <Button
            onClick={() => {
              handleSubmit(CANCELLATION_STEPS.FOURTH_STEP, FLOW_TYPES.CANCEL);
              pushTrackingEvent(chosenUserSubscription, {
                tealium_event: 'click_cancellation_medical_confirm',
                cancellation_step: 2,
                cancellation_reason: selectedCancellationReason?.label,
                cancellation_reason_id: selectedCancellationReason?.code,
                cancellation_reason_category: getCancellationReasonCategory({
                  medicalOrDeathReason: true,
                }),
              });
            }}
            appearance="secondary"
            width="full"
            loading={isSubmitting}
          >
            Bevestig stopzetting
          </Button>
        </Flex>
      </StyledBox>
    </Box>
  );
}
