import React from 'react';

const viewBox = '0 0 1000 1000';

export const Other = () => (
  <svg viewBox={viewBox}>
    <title>Overig</title>
    <g>
      <path d="M453.1,609.8l-2.1-22.5c-6.2-46.8,12.5-97.8,64.3-149.8c46.7-45.9,72.6-79.7,72.6-118.6c0-44.2-33.2-73.6-98.6-74.5 c-37.4,0-78.9,10.4-104.8,26.8l-24.9-54.6C394,195.9,453.1,182,508.1,182c119.3,0,173.3,61.5,173.3,127.3 c0,58.9-39.4,101.3-89.2,150.7c-45.7,45-62.3,83.1-59.2,127.3l1,22.5H453.1z M430.3,731c0-32,25.9-54.6,62.3-54.6 s61.2,22.5,61.2,54.6c0,30.3-23.9,53.7-62.3,53.7C455.2,784.7,430.3,761.3,430.3,731z" />
    </g>
  </svg>
);

export const Holiday = () => (
  <svg viewBox={viewBox}>
    <title>Vakantie</title>
    <path d="M500.1,126C264.6,126,73.6,316.9,73.6,552.5c0,149.1,76.6,280.3,192.5,356.5C313.4,846,375.4,802.1,445,786.7 c-14.8-39.2-54-162-54-363.2v-41.3c-78.1,73-72.3,88.9-109.1,190.1c-61.8-139.1,26.8-211.9,69-237.3c-6-4.8-12.1-9.2-18.2-12.8 c-8.7,11.5-17.9,22.2-31,32c-9.9-19.8,0-39.7,0-39.7c29.8-29.8,39.7-29.8,69.4-39.7c14.1,4.7,28.2,16.1,40.1,28.9 c15.3-20.4,40-48.7,59-48.7c39.7,19.8,59.5,29.8,59.5,29.8s19.8,9.9,39.7,39.7c-19.6-9.8-39.2-22-58.8-30.7 c-15.3,7.7-36.8,19.5-53,32.7c85.5,11.7,112.3,43,121.6,126.9C538,403.6,455.3,374.7,429,366.5c-10.7,74.8-32.3,166.2,45.5,415.5 c8.5-0.9,17-1.4,25.6-1.4c91.7,0,174.5,49.2,234,128.4C850,832.7,926.5,701.6,926.5,552.5C926.5,316.9,735.6,126,500.1,126z" />
  </svg>
);

export const Benefits = () => (
  <svg viewBox={viewBox}>
    <title>Abonneevoordeel</title>
    <ellipse cx="513" cy="602.5" rx="351.6" ry="301.4" />
    <path d="M591.6,50c-97.1-2.2-177.6,74.6-179.8,171.7c-0.5,21,2.9,41.1,9.3,60c29-5.3,59-8.1,90-7.4c21.1,0.5,41.6,2.5,61.7,5.8 c-3.4-2.6-7-5.2-11.1-7.9C524.4,248.7,504.1,222,512,187c8.7-38.7,47.2-60.2,95.4-49.3c25.4,5.7,42.5,15.8,52.1,24.5l-14.7,24.1 c-7.1-6.2-22-17.3-44.5-22.4c-33.9-7.6-51.3,9.7-55.2,26.6c-5.2,23.2,7.3,38,38.6,59c20.5,13.7,34.6,27.4,42.3,42.6 c35.4,10.5,68.4,25.3,98.2,43.7c23.5-29.1,38.1-65.8,39-106.2C765.5,132.8,688.7,52.3,591.6,50z" />
    <path d="M220.4,435.4c0,0,91.7-84,16.3-159.4c75.3,25.1,87.6,26,257.3,25.6C481.8,350.9,220.4,435.4,220.4,435.4z" />
    <path d="M186.5,527.2H86.1V703h25.1c0,0,75.3,0,83.1,27C228.9,776.5,186.5,527.2,186.5,527.2z" />
    <rect x="337.2" y="828.5" width="125.6" height="100.5" />
    <rect x="613.5" y="828.5" width="125.6" height="100.5" />
    <path d="M841.1,494c0,0,23.5,33.2,98.9,33.2c-50.2,50.2-67,81.1-83.7,141C797.6,662.4,841.1,494,841.1,494z" />
  </svg>
);

export const Delivery = () => (
  <svg viewBox={viewBox}>
    <title>Bezorging</title>
    <circle cx="298.5" cy="688.7" r="70.1" />
    <circle cx="749.9" cy="688.7" r="70.1" />
    <path d="M119.5,680.9h93.4c0,0,0-77.8,85.6-77.8s85.6,85.6,85.6,85.6h217.9L690,245H205.1L119.5,680.9z" />
    <path d="M890.1,455.2L822,354H684L618,689l46.4-0.4c0,0,0-85.6,85.6-85.6s85.6,85.6,85.6,85.6h54.5L936,455.2H890.1z M779,455v-68 h34l46,68H779z" />
    <polygon points="141,498 98,498 98,455 149,455 " />
    <polygon points="131,552 98,552 98,509 139,509 " />
    <polygon points="121,606 98,606 98,563 129,563 " />
  </svg>
);

export const ReadDigital = () => {
  const css = '.st0{fill:#FFFFFF;} .st1{fill:#FCFCFC;}';

  return (
    <svg viewBox={viewBox}>
      <title>Digitaal lezen</title>
      <style type="text/css">{css}</style>
      <path d="M717.1,305H161.5c-14.4,0-26,11.7-26,26v347.2c0,14.4,11.7,26,26,26h555.6c14.4,0,26-11.7,26-26V331 C743.1,316.7,731.5,305,717.1,305z M708.4,678.3H170.2V331h538.2V678.3z" />
      <path d="M797.6,756.4H81c-8.3,0-15-6.7-15-15v-22.1c0-8.3,6.7-15,15-15h716.6c8.3,0,15,6.7,15,15v22.1	C812.6,749.7,805.8,756.4,797.6,756.4z" />
      <rect x="66" y="704.3" width="746.6" height="26" />
      <path
        class="st0"
        d="M490,730.4H388.5c-5.5,0-10-4.5-10-10v-6c0-5.5,4.5-10,10-10H490c5.5,0,10,4.5,10,10v6 C500,725.9,495.6,730.4,490,730.4z"
      />
      <rect x="169.6" y="332.1" class="st0" width="538.3" height="348.3" />
      <path d="M830.9,756.4H585.8c-6.6,0-12-5.4-12-12V403.8c0-6.6,5.4-12,12-12h245.1c6.6,0,12,5.4,12,12v340.6	C842.9,751,837.6,756.4,830.9,756.4z" />
      <rect x="595.2" y="417.9" class="st1" width="226.3" height="295.2" />
      <circle class="st1" cx="708.4" cy="730.4" r="8.7" />
      <path d="M908.7,756.4H794.2c-5.5,0-10-4.5-10-10V497.3c0-5.5,4.5-10,10-10h114.6c5.5,0,10,4.5,10,10v249.1	C918.7,751.9,914.3,756.4,908.7,756.4z" />
      <rect x="794.9" y="506.5" class="st1" width="113.1" height="217.8" />
      <circle class="st1" cx="851.5" cy="737.2" r="6.4" />
    </svg>
  );
};

export const Registration = () => (
  <svg viewBox={viewBox}>
    <title>Registratie</title>
    <path d="M850,877l-0.5-649.5h-199c0,0,4-10,4-27s-11-78-78-78s-78,65-78,78s5,27,5,27h-200v216l-100-100L103,445 l201,200v232H850z M347,689l112,111l100-100L347.5,487.5v-209h84c0,0-37,31-37,78c45,0,364,0,364,0s4-44-35-78c27,1,82,0,82,0v546 L347,825V689z" />
  </svg>
);

export const Subscription = () => (
  <svg viewBox={viewBox}>
    <title>Abonnement</title>
    <polygon points="767.8,333.9 767.8,286.3 435,286.3 435,215 126,215 126,785.5 886.7,785.5 886.7,381.4 244.9,381.4 244.9,714.2 197.3,714.2 197.3,333.9 " />
  </svg>
);

export const Person = () => (
  <svg viewBox={viewBox}>
    <title>Persoon</title>
    <path d="m 472.44094,472.44094 c 130.51182,0 236.22048,-105.70866 236.22048,-236.22047 C 708.66142,105.70866 602.95276,0 472.44094,0 341.92913,0 236.22047,105.70866 236.22047,236.22047 c 0,130.51181 105.70866,236.22047 236.22047,236.22047 z m 0,118.11024 C 314.76378,590.55118 0,669.68504 0,826.77165 V 944.88189 H 944.88189 V 826.77165 c 0,-157.08661 -314.76378,-236.22047 -472.44095,-236.22047 z" />
  </svg>
);
export const Upload = ({ color }) => (
  <svg
    width="70"
    height="50"
    viewBox="0 0 70 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.1547 4.4469C50.5503 7.31796 52.8142 11.2979 53.5432 15.6781C57.9861 16.0168 62.1246 18.0592 65.0901 21.3765C68.0555 24.6938 69.6173 29.0279 69.4474 33.4691C69.2775 37.9104 67.3893 42.1133 64.1791 45.1956C60.9689 48.2779 56.6864 49.9998 52.2305 50H17.7704C13.3145 49.9998 9.03202 48.2779 5.82182 45.1956C2.61162 42.1133 0.723402 37.9104 0.55355 33.4691C0.383699 29.0279 1.94542 24.6938 4.91087 21.3765C7.87633 18.0592 12.0149 16.0168 16.4578 15.6781C17.1868 11.2979 19.4506 7.31796 22.8463 4.4469C26.2419 1.57583 30.549 0 35.0005 0C39.452 0 43.759 1.57583 47.1547 4.4469Z"
      fill={color}
    />
    <path
      d="M44.5877 31.1727V38.0477C44.5877 38.2756 44.4973 38.4942 44.3365 38.6554C44.1757 38.8165 43.9575 38.9071 43.7301 38.9071H26.5783C26.3508 38.9071 26.1327 38.8165 25.9719 38.6554C25.8111 38.4942 25.7207 38.2756 25.7207 38.0477V31.1727C25.7207 30.9448 25.8111 30.7262 25.9719 30.565C26.1327 30.4039 26.3508 30.3133 26.5783 30.3133C26.8057 30.3133 27.0239 30.4039 27.1847 30.565C27.3455 30.7262 27.4359 30.9448 27.4359 31.1727V37.1883H42.8725V31.1727C42.8725 30.9448 42.9628 30.7262 43.1237 30.565C43.2845 30.4039 43.5026 30.3133 43.7301 30.3133C43.9575 30.3133 44.1757 30.4039 44.3365 30.565C44.4973 30.7262 44.5877 30.9448 44.5877 31.1727ZM35.1542 35.4696C35.3816 35.4696 35.5998 35.379 35.7606 35.2179C35.9214 35.0567 36.0118 34.8381 36.0118 34.6102V24.6535L37.9782 26.624C38.14 26.7805 38.3566 26.8672 38.5815 26.8652C38.8063 26.8633 39.0214 26.7729 39.1804 26.6135C39.3394 26.4542 39.4296 26.2387 39.4316 26.0133C39.4335 25.788 39.3471 25.5709 39.1909 25.4088L35.7605 21.9713C35.5997 21.8102 35.3816 21.7197 35.1542 21.7197C34.9268 21.7197 34.7087 21.8102 34.5479 21.9713L31.1175 25.4088C31.0356 25.4881 30.9703 25.583 30.9253 25.6878C30.8804 25.7926 30.8567 25.9054 30.8557 26.0195C30.8547 26.1336 30.8764 26.2468 30.9196 26.3524C30.9627 26.458 31.0264 26.554 31.1069 26.6347C31.1874 26.7153 31.2832 26.7792 31.3886 26.8224C31.4939 26.8656 31.6069 26.8873 31.7207 26.8863C31.8346 26.8853 31.9471 26.8616 32.0518 26.8166C32.1564 26.7716 32.251 26.7061 32.3301 26.624L34.2966 24.6535V34.6102C34.2966 34.8381 34.3869 35.0567 34.5478 35.2179C34.7086 35.379 34.9267 35.4696 35.1542 35.4696Z"
      fill="white"
    />
  </svg>
);

const Icons = {
  Other,
  Holiday,
  Benefits,
  Delivery,
  ReadDigital,
  Registration,
  Subscription,
  Person,
  Upload,
};

export default Icons;
