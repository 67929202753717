import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash.isempty';
import {
  Box,
  Flex,
  Button,
  Icon,
  Caption,
} from '@mediahuis/chameleon-react-legacy';
import {
  ChevronForward,
  Error as IcoError,
} from '@mediahuis/chameleon-theme-wl/legacy/icons';
import { MOLLIE_ERRORS } from '~/constants/messages';
import { PAYMENT_REFERENCE } from '~/constants/payment';
import useBanner from '~/context/hooks/useBanner';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import { getMollie, handleBannerMessage, scrollTopWithTimeout } from '~/utils';
import { ts } from '~/services';
import getRedirectUrl from '../../utils/getRedirectUrl';

const Message = props => {
  const { id, children, error } = props;
  return (
    <Caption
      as="div"
      color={error ? 'redBase' : 'grey50'}
      id={id}
      role={error ? 'alert' : undefined}
    >
      {error && <Icon as={IcoError} size="small" />}
      {children}
    </Caption>
  );
};

const CreditCard = ({ setDialogShown }) => {
  const { showBanner } = useBanner();
  const { userInfo, chosenUserSubscription } = useGlobalContext();

  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const exitDialog = () => {
    setIsLoading(false);
    setDialogShown(false);
    scrollTopWithTimeout();
  };

  const handleChange = (e, id) => {
    if (e.error && (e.dirty || e.touched)) {
      setErrors(s => ({ ...s, [id]: `${e.error}.` }));
    } else if (e.valid && (e.dirty || e.touched)) {
      setErrors(s => ({ ...s, [id]: '' }));
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);

    const { mollie } = getMollie(userInfo.countryCode);
    const { token, error } = await mollie.createToken();

    if (error) {
      setIsLoading(false);
    } else {
      try {
        const { id, redirectUrl } = await getRedirectUrl({
          user: userInfo,
          payment: {
            token,
            amount: 0,
          },
          subscription: chosenUserSubscription,
        });

        if (id && redirectUrl) {
          sessionStorage.setItem(PAYMENT_REFERENCE, id);
          window.location.replace(redirectUrl); // Reload de pagina
          return; // Make sure the "useMollie" is not triggered by exiting the dialog
        }
      } catch (err) {
        handleBannerMessage({ err, showBanner });
      }

      exitDialog();
    }
  };

  useEffect(() => {
    // setup components
    const { components } = getMollie(userInfo.countryCode);
    const mollieIds = Object.keys(components);

    mollieIds.forEach(id => {
      components[id].mount(`#${id}`);
      components[id].addEventListener('change', e => handleChange(e, id));
    });

    return () => {
      // cleanup components
      mollieIds.forEach(id => {
        components[id].unmount();
      });
    };
  }, [userInfo.countryCode]);

  useEffect(() => {
    // form validation
    if (!isEmpty(errors)) {
      const values = Object.values(errors);
      // checking for 4 required input values
      if (values.length === 4) {
        const newIsValid = isEmpty(values.join(''));
        if (isValid !== newIsValid) {
          setIsValid(newIsValid);
        }
      }
    }
  }, [errors, isValid, setIsValid]);

  return (
    <>
      <Box pb={5}>
        <label className="mollie-label" htmlFor="cardHolder">
          {ts.t('Subscription.PaymentProviders.CreditCard.CardHolder')}
        </label>
        <div id="cardHolder" data-testid="textbox-cardHolder" />
        <Box pt={2}>
          {errors.cardHolder && (
            <Message error={!!errors.cardHolder} id="cardHolderMessage">
              {MOLLIE_ERRORS.CARDHOLDER}
            </Message>
          )}
        </Box>
      </Box>
      <Box pb={5}>
        <label className="mollie-label" htmlFor="cardNumber">
          {ts.t('Subscription.PaymentProviders.CreditCard.CardNumber')}
        </label>
        <div id="cardNumber" data-testid="textbox-cardNumber" />
        <Box pt={2}>
          <Message error={!!errors.cardNumber} id="cardNumberMessage">
            {errors.cardNumber
              ? errors.cardNumber
              : ts.t(
                  'Subscription.PaymentProviders.CreditCard.CardNumberError',
                )}
          </Message>
        </Box>
      </Box>
      <Flex>
        <Box pb={2} mr={3} flex={1}>
          <label className="mollie-label" htmlFor="expiryDate">
            {ts.t('Subscription.PaymentProviders.CreditCard.ExpiryDate')}
          </label>
          <div id="expiryDate" data-testid="textbox-expiryDate" />
          <Box pt={2}>
            <Message error={!!errors.expiryDate} id="expiryDateMessage">
              {errors.expiryDate}
            </Message>
          </Box>
        </Box>
        <Box pb={2} flex={1}>
          <label className="mollie-label" htmlFor="verificationCode">
            {ts.t('Subscription.PaymentProviders.CreditCard.VerificationCode')}
          </label>
          <div id="verificationCode" data-testid="textbox-verificationCode" />
          <Box pt={2}>
            <Message
              error={!!errors.verificationCode}
              id="verificationCodeMessage"
            >
              {errors.verificationCode}
            </Message>
          </Box>
        </Box>
      </Flex>
      <Flex justifyContent="flex-end">
        <Button
          mt={5}
          loading={isLoading}
          disabled={!isValid}
          onClick={onSubmit}
          iconRight={ChevronForward}
          data-testid="button-save-payment-info"
        >
          {ts.t('Common.Confirm')}
        </Button>
      </Flex>
    </>
  );
};

export default CreditCard;
