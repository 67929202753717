import React, { useState } from 'react';
import { Text, Icon, Flex } from '@mediahuis/chameleon-react-legacy';
import { ChevronDown } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import styled from 'styled-components';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import { colorGrey10 } from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';
import { authService, ts } from '~/services';

export const AbsoluteText = styled(Text)`
  position: absolute;
  right: 34px;
  top: 22px;
`;

const DropdownContent = styled.div`
  display: ${props => (props.isNavOpen ? 'block' : 'none')};
  position: absolute;
  background-color: ${colorGrey10};
  right: 16px;
  top: 63px;
  min-width: 100px;
  padding: 16px;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const DropdownText = styled(Text)`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;

  max-height: 20px;
  max-width: 20px;
  position: absolute;
  right: 16px;
  top: 24px;
`;

const SignOut = () => {
  const { userInfo } = useGlobalContext();
  const [isNavOpen, setIsNavOpen] = useState(false);
  if (!userInfo?.accountGuid) return null;
  return (
    <Flex>
      <AbsoluteText fontFamily="system" color="colorTextPrimary">
        {userInfo.firstName} {userInfo.name}{' '}
      </AbsoluteText>
      <IconWrapper>
        <Icon
          onClick={() => setIsNavOpen(prevState => !prevState)}
          as={ChevronDown}
          size="small"
        />
      </IconWrapper>
      <DropdownContent isNavOpen={isNavOpen}>
        <DropdownText
          fontFamily="system"
          color="colorTextPrimary"
          onClick={authService.logout}
        >
          {ts.t('SignOut.SignOutLabel')}
        </DropdownText>
      </DropdownContent>
    </Flex>
  );
};

export default SignOut;
