import { Flex, Logo } from '@mediahuis/chameleon-react-legacy';
import { Fragment } from 'react';

const MultibrandLogos = ({
  brands,
  gap = '0.25rem',
  iconHeight,
  iconWidth,
  logoHeight,
  logoWidth,
  ...flexProps
}) => {
  return (
    <Flex
      {...flexProps}
      alignItems="center"
      style={{ gap, minWidth: 'fit-content', ...flexProps.style }}
    >
      {brands.map((brand, brandIndex) => {
        if (brandIndex === brands.length - 1) {
          return (
            <Logo
              height={logoHeight}
              key={brand}
              name={`subscriptions.plus-brand-square-${brand}`}
              width={logoWidth}
            />
          );
        }

        return (
          <Fragment key={brand}>
            <Logo
              height={logoHeight}
              name={`subscriptions.plus-brand-square-${brand}`}
              width={logoWidth}
            />
            <Logo
              height={iconHeight}
              name="subscriptions.plus-icon"
              width={iconWidth}
            />
          </Fragment>
        );
      })}
    </Flex>
  );
};

export default MultibrandLogos;
