import { Banner as ChameleonBanner } from '@mediahuis/chameleon-react-legacy';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useBanner from '~/context/hooks/useBanner';

const Banner = props => {
  const history = useHistory();
  const {
    type,
    message,
    mayShow,
    hideBanner,
    closeHidden = false,
  } = useBanner();

  useEffect(() => {
    const cleanupListener = history.listen(() => {
      if (mayShow) {
        hideBanner();
      }
    });

    return cleanupListener;
  }, [history, mayShow, hideBanner]);

  return (
    <ChameleonBanner
      {...props}
      show={mayShow}
      appearance={type}
      onClose={hideBanner}
      closeHidden={closeHidden}
    >
      {message}
    </ChameleonBanner>
  );
};

export default Banner;
