import dayjs from 'dayjs';

const getWebformStartDate = (day = new Date()) => {
  const currentDate = dayjs(day);

  const currentDatePlusTwoDays = currentDate.add(2, 'day');

  const THURSDAY = 4;
  const FRIDAY = 5;
  const SATURDAY = 6;
  const SUNDAY = 0;

  if (
    currentDate.day() === THURSDAY ||
    currentDate.day() === FRIDAY ||
    currentDate.day() === SATURDAY
  )
    return currentDate.add(4, 'day').toDate();

  if (currentDate.day() === SUNDAY) return currentDate.add(3, 'day').toDate();

  return currentDatePlusTwoDays.toDate();
};

export default getWebformStartDate;
