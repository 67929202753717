import dayjs from 'dayjs';
import { DATE_FORMAT_BE } from '~/constants/date';
import { apiCall } from '~/utils';
import { config } from '~/services';

const postAddress = ({
  address,
  addressFields,
  chosenUserSubscription,
  isInvoice,
}) => {
  const body = {
    subscriptionId: chosenUserSubscription.id,
    customerId: address.customerId.toString(),
    address: addressFields,
    dateFrom: isInvoice ? '' : dayjs(address.date).format(DATE_FORMAT_BE),
  };

  return apiCall(`${config.serviceApiUrl}/AddressChange?api-version=2.0`, {
    withCredentials: true,
    method: 'POST',
    data: JSON.stringify(body),
  });
};

export default postAddress;
