import dayjs from 'dayjs';
import { PAGES, ssoConfig } from '~/constants';
import { environment, config } from '~/services';

function generateRandomString(length) {
  let text = '';
  const possible = 'abcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}

async function generateCodeChallenge(codeVerifier) {
  const digest = await crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(codeVerifier),
  );

  return btoa(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
}

const parseJwt = token => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const VERIFYKEY = 'code-verifier';
const TOKENKEY = 'oauth-token';

const env = environment.name;

class AuthenticationService {
  async login() {
    const qs = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    if (!qs.code) {
      const storedToken = localStorage.getItem(TOKENKEY);
      if (storedToken) {
        const token = JSON.parse(storedToken);
        const isTokenExpired = dayjs(token.expiryDate).isBefore(dayjs());
        if (isTokenExpired) {
          return this.refresh(token.refreshToken);
        }
        return token;
      }
      const codeVerifier = generateRandomString(56);
      const challengeMethod = crypto.subtle ? 'S256' : 'plain';
      const codeChallenge =
        challengeMethod === 'S256'
          ? await generateCodeChallenge(codeVerifier)
          : codeVerifier;

      localStorage.setItem(VERIFYKEY, codeVerifier);

      const oauthParams = new URLSearchParams({
        /* eslint-disable babel/camelcase */
        scope: 'openid',
        response_type: 'code',
        client_id: ssoConfig[env].clientId,
        redirect_uri: `${config.eloketUrl}/auth/oauthcallback`,
        code_challenge_method: challengeMethod,
        code_challenge: codeChallenge,
        state: window.location.pathname.substring(1),
        /* eslint-enable babel/camelcase */
      });

      window.location = `${
        ssoConfig[env].baseApiUrl
      }/sso/v20/authorize?${oauthParams.toString()}`;
    }
    return null;
  }

  getStoredToken() {
    const src = localStorage.getItem(TOKENKEY);
    return src ? JSON.parse(src) : null;
  }
  async authenticate(history) {
    const qs = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    const { code, state } = qs;

    const codeVerifier = localStorage.getItem(VERIFYKEY);

    const data = {
      /* eslint-disable babel/camelcase */
      code,
      grant_type: 'authorization_code',
      code_verifier: codeVerifier,
      client_id: ssoConfig[env].clientId,
      redirect_uri: encodeURI(
        `${window.location.protocol}//${window.location.hostname}/auth/oauthcallback`,
      ),
      /* eslint-enable babel/camelcase */
    };

    try {
      const apiResponse = await fetch(
        `${ssoConfig[env].baseApiUrl}/sso/v20/oauth/token`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        },
      );

      const res = await apiResponse.json();

      const token = {
        accessToken: res.access_token,
        refreshToken: res.refresh_token,
        expiryDate: dayjs().add(res.expires_in, 'second').format(),
        idToken: parseJwt(res.id_token),
      };

      localStorage.removeItem(VERIFYKEY);
      localStorage.setItem(TOKENKEY, JSON.stringify(token));
      history.replace(
        Object.keys(PAGES)
          .map(x => PAGES[x])
          .includes(state)
          ? `/${state}`
          : '/',
      );
      return token;
    } catch (e) {
      return null;
    }
  }
  async refresh(refreshToken) {
    const data = {
      /* eslint-disable babel/camelcase */
      grant_type: 'refresh_token',
      client_id: ssoConfig[env].clientId,
      refresh_token: refreshToken,
      /* eslint-enable babel/camelcase */
    };

    try {
      const apiResponse = await fetch(
        `${ssoConfig[env].baseApiUrl}/sso/v20/oauth/token`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        },
      );
      if (!apiResponse.ok) {
        throw Error('Refresh token not valid');
      }

      const res = await apiResponse.json();

      const token = {
        accessToken: res.access_token,
        refreshToken: res.refresh_token,
        expiryDate: dayjs().add(res.expires_in, 'second').format(),
        idToken: parseJwt(res.id_token),
      };

      localStorage.removeItem(VERIFYKEY);
      localStorage.setItem(TOKENKEY, JSON.stringify(token));

      return token;
    } catch (e) {
      localStorage.removeItem(TOKENKEY);
      return this.login();
    }
  }
  logout() {
    if (ssoConfig.ssoEnabled) {
      localStorage.removeItem(TOKENKEY);
      localStorage.removeItem(VERIFYKEY);
      window.location = ssoConfig[env].logoutUri;
    } else {
      window.location = ssoConfig.logoutUri;
    }
  }
}

const instance = new AuthenticationService();

export { instance as authService };
