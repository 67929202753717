import React, { useState } from 'react';
import dayjs from 'dayjs'; // load on demand
import useHolidayContext from '~/context/hooks/useHolidayContext';
import { validateDates } from '~/utils';

import { HOLIDAY_SOLUTIONS, HOLIDAY_STEPS } from '~/pages/Holiday/data';
import BaseChoiceStep from '~/pages/Holiday/components/DialogContent/Steps/BaseChoiceStep';
import getEndDateValue from '../../../utils/getEndDateValue';

const ChoiceStep = ({
  submissionData,
  setSubmissionData,
  setCurrentStep,
  isDialogShown,
}) => {
  const holidayState = useHolidayContext();
  const [errors, setErrors] = useState({});

  const { daysOfWeek, holidayArrangementOptions } = holidayState;

  const onNextStep = () => {
    switch (submissionData.vacationSolution) {
      case HOLIDAY_SOLUTIONS.CONTINUE_DIGITAL:
      case HOLIDAY_SOLUTIONS.DISCONTINUE_DIGITAL:
      case HOLIDAY_SOLUTIONS.DISCONTINUE_PRINT:
      case HOLIDAY_SOLUTIONS.CHARITY:
        setCurrentStep(HOLIDAY_STEPS.SUMMARY_STEP);
        break;
      case HOLIDAY_SOLUTIONS.FAMILY_ADDRESS:
        if (holidayArrangementOptions?.knownFamilyAddresses?.length > 0) {
          setCurrentStep(HOLIDAY_STEPS.ADDRESS_CHOICE_STEP);
        } else {
          setCurrentStep(HOLIDAY_STEPS.ADDRESS_STEP);
        }
        break;
      case HOLIDAY_SOLUTIONS.HOLIDAY_ADDRESS:
        if (holidayArrangementOptions?.knownHolidayAddresses?.length > 0) {
          setCurrentStep(HOLIDAY_STEPS.ADDRESS_CHOICE_STEP);
        } else {
          setCurrentStep(HOLIDAY_STEPS.ADDRESS_STEP);
        }
        break;
      default:
        break;
    }
  };

  const onStartDateChange = (value, meta) => {
    const fields = [{ id: 'startDate', value, meta }];
    if (meta.formattedDateIsValid) {
      const endDate = getEndDateValue({
        value,
        holidayArrangementOptions,
        daysOfWeek,
        endDate: submissionData.end,
      });

      setSubmissionData(prevState => ({
        ...prevState,
        start: value,
        end: endDate,
      }));
    } else {
      setSubmissionData(prevState => ({
        ...prevState,
        start: value,
      }));
    }

    setErrors(validateDates({ errors, fields }));
  };

  const onEndDateChange = (value, meta) => {
    setSubmissionData(prevState => ({
      ...prevState,
      end: value,
    }));

    const fields = [{ id: 'endDate', value, meta }];
    setErrors(validateDates({ errors, fields }));
  };

  const getStartDateDisabledDays = () => {
    const startMinDate = dayjs(
      holidayArrangementOptions.minimumCreateDate,
    ).toDate();
    // Holidays can be planned 1 year in advance max
    const startMaxDate = new Date(startMinDate);
    startMaxDate.setFullYear(startMaxDate.getFullYear() + 1);

    return [
      { daysOfWeek },
      {
        before: startMinDate,
        after: startMaxDate,
      },
    ];
  };
  const getEndDateDisabledDays = () => {
    const start = dayjs(submissionData.start);
    const minimumCreateDate = dayjs(
      holidayArrangementOptions.minimumCreateDate,
    );

    return [
      { daysOfWeek },
      {
        before: (start.isBefore(minimumCreateDate) ? minimumCreateDate : start)
          .add(holidayArrangementOptions.minDuration - 1, 'day')
          .toDate(),
        after: start
          .add(holidayArrangementOptions.maxDuration - 1, 'day')
          .toDate(),
      },
    ];
  };
  return (
    <BaseChoiceStep
      onEndDateChange={onEndDateChange}
      onStartDateChange={onStartDateChange}
      getEndDateDisabledDays={getEndDateDisabledDays()}
      getStartDateDisabledDays={getStartDateDisabledDays()}
      isDialogShown={isDialogShown}
      setSubmissionData={setSubmissionData}
      submissionData={submissionData}
      setCurrentStep={setCurrentStep}
      errors={errors}
      setErrors={setErrors}
      onNextStep={onNextStep}
    />
  );
};

export default ChoiceStep;
