import { Flex, Logo, Text } from '@mediahuis/chameleon-react-legacy';

import MultibrandLogos from './MultibrandLogos';

const multibrandBlue = '#000C7E';
const defaultBlack = '#000';
const sortedBrands = ['nb', 'ds', 'gva', 'hbvl', 'dg'];

const MultibrandBannerInline = ({ activeBrand, text, ...flexProps }) => {
  return (
    <Flex
      {...flexProps}
      alignItems="center"
      bgColor="colorGrey10"
      justifyContent="space-between"
      px={4}
      py={2}
      style={{
        color: activeBrand === 'dl' ? defaultBlack : multibrandBlue,
        ...flexProps.style,
      }}
    >
      <Flex alignItems="center" style={{ gap: '0.25rem' }}>
        <Logo height={4} name="subscriptions.plus-icon" width={4} />
        <Text fontFamily="system" fontWeight="bold" size="Caption2">
          {text}
        </Text>
      </Flex>

      <MultibrandLogos
        brands={sortedBrands.filter(brand => brand !== activeBrand)}
        iconHeight={3}
        iconWidth={3}
        logoHeight={6}
        logoWidth={6}
      />
    </Flex>
  );
};

export default MultibrandBannerInline;
