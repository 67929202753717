import {
  Box,
  Dialog as ChameleonDialog,
  useMediaQuery,
} from '@mediahuis/chameleon-react-legacy';
import { useRef, useLayoutEffect } from 'react';
import { DIALOG_WIDTH } from '~/constants';

const Dialog = ({ scrollCondition = true, children, ...props }) => {
  const ref = useRef(null);
  const width = useMediaQuery({ md: DIALOG_WIDTH }, 'auto');

  useLayoutEffect(() => {
    if (ref.current && scrollCondition) {
      // temporary, only way to link with the wrapper div
      ref.current?.parentNode?.parentNode?.scrollTo(0, 0);
    }
  }, [scrollCondition]);

  return (
    <ChameleonDialog {...props}>
      <Box ref={ref} width={width} maxWidth="full">
        {children}
      </Box>
    </ChameleonDialog>
  );
};

export default Dialog;
