import { config } from '~/services';
import ahApiCall from '~/api/ah/ahApiCall';
import { mapPreviousAddresses } from '~/api/ah/getTemporaryDeliveryChangesOptions';

const getTdcPreviousAddresses = async ({ subscriptionId }) => {
  try {
    const { previousTemporaryAddresses } = await ahApiCall(
      `${config.ahServiceApiUrl}/subscriptions/${subscriptionId}/temporary-delivery-changes/options/delivery/addresses`,
    );

    return mapPreviousAddresses(previousTemporaryAddresses);
  } catch (error) {
    return [];
  }
};

export default getTdcPreviousAddresses;
