import {
  isAndroid,
  isIOS,
  isMacOs,
  isWinPhone,
  isWindows,
} from 'react-device-detect';

const getDeviceBrand = () => {
  if (isAndroid) {
    return 'Android';
  } else if (isWinPhone) {
    return 'Windows Phone';
  } else if (isIOS) {
    return 'IOS';
  } else if (isWindows) {
    return 'Windows';
  } else if (isMacOs) {
    return 'MAC OS';
  }
  return 'unknown';
};

export default getDeviceBrand;
