import { apiCall } from '~/utils';
import { config } from '~/services';

const getAddressOptions = ({ subscriptionId }) =>
  apiCall(`${config.serviceApiUrl}/AddressChangeOptions/${subscriptionId}`, {
    method: 'GET',
    withCredentials: true,
    params: { 'api-version': '2.0' },
  });

export default getAddressOptions;
