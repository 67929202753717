import {
  Text,
  WideListItem,
  IconButton,
  Banner,
} from '@mediahuis/chameleon-react-legacy';
import {
  colorPrimaryBase,
  aliasInputBorderColorSuccess,
  aliasFieldBorderColorError,
  aliasFieldBorderColorFocus,
} from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';
import { Delete } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import uniqBy from 'lodash.uniqby';
import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Container, Li, Section } from '~/components/DragAndDrop/styles';
import { Upload } from '~/svg/Icons';
import defaultError from '~/utils/defaultError';

export default function DragAndDrop({
  maxFiles = 1,
  maxSize,
  accept,
  files,
  setFiles,
  errorLabels,
  text,
  subText,
}) {
  const convertToBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result.split(',')[1]);
      };
      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  const onDrop = async acceptedFiles => {
    const keepPreviousFiles = files.length + acceptedFiles.length === maxFiles;
    const previousFiles = keepPreviousFiles ? files : [];
    setFiles([
      ...previousFiles,
      ...(await Promise.all(
        acceptedFiles.map(async file => {
          const base64 = await convertToBase64(file);
          return {
            fileName: file.name,
            blob: base64,
          };
        }),
      )),
    ]);
  };
  const removeFile = fileToRemove => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(fileToRemove), 1);
    setFiles(newFiles);
  };

  function nameValidator(fileToValidate) {
    const fileIsAlreadyUploaded = files.find(
      file => file.fileName === fileToValidate.name,
    );
    if (fileIsAlreadyUploaded) {
      return {
        code: 'same-file',
      };
    }

    return null;
  }

  const disabled = maxFiles === files.length;

  const {
    getRootProps,
    getInputProps,
    fileRejections,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept,
    multiple: maxFiles > 1,
    disabled,
    maxFiles,
    maxSize,
    validator: nameValidator,
  });

  const allErrors = uniqBy(
    fileRejections.map(({ errors }) => errors).flat(),
    'code',
  );

  const focusedStyle = {
    borderColor: aliasFieldBorderColorFocus,
  };

  const acceptStyle = {
    borderColor: aliasInputBorderColorSuccess,
  };

  const rejectStyle = {
    borderColor: aliasFieldBorderColorError,
  };
  const style = useMemo(
    () => ({
      cursor: 'pointer',
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );
  return (
    <Section className="container">
      <aside>
        <ul>
          {files.map(file => (
            <Li key={file.fileName}>
              <WideListItem
                title={
                  <Text
                    color="colorGrey60"
                    fontWeight="regular"
                    size="Heading6"
                    style={{
                      wordBreak: 'break-all',
                    }}
                  >
                    {file.fileName}
                  </Text>
                }
                action={
                  <IconButton
                    icon={Delete}
                    size="small"
                    onClick={removeFile(file)}
                  />
                }
              />
            </Li>
          ))}
        </ul>
      </aside>
      <ul>
        {allErrors.map(error => {
          return (
            <Banner appearance="error" show closeHidden key={error.code} mb={4}>
              {errorLabels[error.code] || defaultError}
            </Banner>
          );
        })}
      </ul>
      <Container
        {...getRootProps({ className: 'dropzone', style })}
        disabled={disabled}
      >
        <input {...getInputProps()} />
        <>
          <Upload color={colorPrimaryBase} />
          <Text color="colorGrey70" size="Heading4" my={4} textAlign="center">
            {text}
          </Text>
          <Text color="colorGrey70" size="Heading6" mb={4} textAlign="center">
            {subText}
          </Text>
        </>
      </Container>
    </Section>
  );
}
