import { ts } from '~/services';
import {
  osName,
  osVersion as operatingSystemVersion,
  browserName,
  fullBrowserVersion,
  mobileVendor,
  mobileModel,
} from 'react-device-detect';

export const getSelectedLevel = (items, id) =>
  items?.find(item => item.id === id);

export const getConfigOfSelectedLevel = ({
  selectedLevel1,
  selectedLevel2,
}) => {
  return {
    showWebform: selectedLevel2
      ? selectedLevel2?.webform
      : selectedLevel1?.webform,
    noWebformText: selectedLevel2
      ? selectedLevel2?.noWebformText
      : selectedLevel1?.noWebformText,
    faqIntroLabel:
      (selectedLevel2
        ? selectedLevel2?.faqIntroLabel
        : selectedLevel1?.faqIntroLabel) ||
      ts.t('SmartWebform.DefaultFaqIntroLabel'),
    faqTag: selectedLevel2 ? selectedLevel2?.faqTag : selectedLevel1?.faqTag,
    faqHref: selectedLevel2
      ? selectedLevel2?.faqAllLink
      : selectedLevel1?.faqAllLink,
  };
};
export const sortBySequence = (items = []) => {
  return items.sort((a, b) => a?.sequence - b?.sequence);
};
export const mapUserInfo = userInfo => {
  return {
    gender: userInfo.gender?.charAt(0) ?? '',
    firstName: userInfo.firstName ?? '',
    lastName: userInfo.name ?? '',
    email: userInfo.emailAddress ?? '',
    phone: userInfo.phoneNumber ?? '',
    countryCode: userInfo.countryCode ?? 'BE',
    postalCode: userInfo.zipCode ?? '',
    city: userInfo.city ?? '',
    street: userInfo.street ?? '',
    houseNumber: userInfo.houseNumber ?? '',
    box: userInfo.box ?? '',
  };
};
export const handleSelectChange = ({ e, setState }) => {
  const value = e.target.value;
  const id = e.target.id;
  return setState(prevState => ({ ...prevState, [id]: value }));
};

export const validateSmartWebformFields = ({
  selectedLevel1,
  selectedLevel2,
  selectedLevel3,
  selectedLevel4,
  firstName,
  lastName,
  email,
  description,
  currentDevice,
  isDesktopLaptop,
  deviceBrandAndModel,
  os,
  browser,
}) => {
  let errors = {};
  if (firstName != null && !firstName?.length) {
    errors = {
      ...errors,
      firstName: ts.t('SmartWebform.fields.FirstNameRequired'),
    };
  }

  if (lastName != null && !lastName?.length) {
    errors = {
      ...errors,
      lastName: ts.t('SmartWebform.fields.LastNameNameRequired'),
    };
  }
  if (email != null && !email?.length) {
    errors = {
      ...errors,
      email: ts.t('SmartWebform.fields.EmailRequired'),
    };
  }

  if (description != null && !description?.length) {
    errors = {
      ...errors,
      description: ts.t('SmartWebform.fields.DescriptionRequired'),
    };
  }
  if (!selectedLevel1 && selectedLevel1 !== undefined) {
    errors = {
      ...errors,
      level1: ts.t('SmartWebform.fields.SelectedLevel1Required'),
    };
  }
  if (selectedLevel1?.children?.length > 0 && !selectedLevel2) {
    errors = {
      ...errors,
      level2: ts.t('SmartWebform.fields.SelectedLevel2Required'),
    };
  }
  if (selectedLevel2?.children?.length > 0 && !selectedLevel3) {
    errors = {
      ...errors,
      level3: ts.t('SmartWebform.fields.SelectedLevel3Required'),
    };
  }
  if (selectedLevel3?.children?.length > 0 && !selectedLevel4) {
    errors = {
      ...errors,
      level4: ts.t('SmartWebform.fields.SelectedLevel4Required'),
    };
  }
  if (!currentDevice && currentDevice !== undefined) {
    if (isDesktopLaptop) {
      if (!browser) {
        errors = {
          ...errors,
          browser: ts.t('SmartWebform.fields.BrowserRequired'),
        };
      }
      if (!os) {
        errors = {
          ...errors,
          os: ts.t('SmartWebform.fields.OsRequired'),
        };
      }
    } else if (!deviceBrandAndModel) {
      errors = {
        ...errors,
        deviceBrandAndModel: ts.t(
          'SmartWebform.fields.DeviceBrandAndModelRequired',
        ),
      };
    }
  }
  return errors;
};

export const mapToSmartWebformBody = ({
  state,
  personalInformation,
  isDesktopLaptop,
  files,
}) => {
  const {
    description,
    currentDevice,
    os,
    browser,
    deviceBrandAndModel,
    level1,
    level2,
    level3,
    level4,
  } = state;
  const { houseNumber, box, ...restPersonalInformation } = personalInformation;

  const hasToPostTechnicalInformation = !!level4;

  const notCurrentDeviceInformation = isDesktopLaptop
    ? {
        operatingSystem: os,
        browser,
      }
    : {
        deviceBrandAndModel,
      };

  return {
    level1,
    level2,
    level3,
    level4,
    body: description,
    personal: {
      ...restPersonalInformation,
      houseNumber,
      boxNumber: box,
    },
    ...(hasToPostTechnicalInformation && {
      technical: {
        currentDevice,
        ...(currentDevice
          ? {
              currentDeviceOperatingSystem: `${osName} ${operatingSystemVersion}`,
              currentDeviceBrowser: `${browserName} ${fullBrowserVersion}`,
              currentDeviceBrandAndModel: `${mobileVendor} ${mobileModel}`,
            }
          : notCurrentDeviceInformation),
      },
    }),
    ...(files.length > 0 && { files }),
  };
};
