import { ts } from '~/services';

const outstandingPaymentTypes = {
  NEW_INVOICE: 'NewInvoice',
  RENEWAL_INVOICE: 'RenewalInvoice',
  REJECTION: 'Rejection',
};
export const getOutstandingPaymentMessage = ({
  type,
  amount,
  paymentReference,
}) => {
  switch (type) {
    case outstandingPaymentTypes.REJECTION:
      return ts.t('Subscription.OutstandingPaymentBanner.Messages.Rejection', {
        values: { amount, paymentReference },
      });
    case outstandingPaymentTypes.NEW_INVOICE:
      return ts.t('Subscription.OutstandingPaymentBanner.Messages.NewInvoice', {
        values: { amount, paymentReference },
      });
    case outstandingPaymentTypes.RENEWAL_INVOICE:
      return ts.t(
        'Subscription.OutstandingPaymentBanner.Messages.RenewalInvoice',
        {
          values: { amount, paymentReference },
        },
      );
    default:
      return ts.t('Subscription.OutstandingPaymentBanner.Messages.Default', {
        values: { amount, paymentReference },
      });
  }
};
