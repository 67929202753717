import { cancelSubscriptionYouthConfig } from '~/constants/cancelSubscriptionYouth';
import { environment } from '~/services';

export const getCancelSubscriptionYouthUrl = ({
  accountGuid,
  subscriptionId,
}) => {
  const baseUrl = cancelSubscriptionYouthConfig[environment.name].baseUrl;
  return `${baseUrl}&GUID=${accountGuid}&ABONR=${subscriptionId}`;
};
