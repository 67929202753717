import { LOCALES, COUNTRIES } from '~/constants/locale';
import { environment, config } from '~/services';

let mollie;
let components;

const getMollie = (country = COUNTRIES.BE) => {
  if (!mollie) {
    // eslint-disable-next-line
    mollie = Mollie(config.mollieId, {
      locale: LOCALES[country],
      testmode: !environment.isProd,
    });

    components = {
      cardHolder: mollie.createComponent('cardHolder'),
      cardNumber: mollie.createComponent('cardNumber'),
      expiryDate: mollie.createComponent('expiryDate'),
      verificationCode: mollie.createComponent('verificationCode'),
    };
  }

  return {
    mollie,
    components,
  };
};

export default getMollie;
