const productMap = {
  super: 'FULL',
  slim: 'ZADI',
  digitaal: 'DIGI',
  snel: 'PLUS',
  weekend: 'Fractions',
};

const getProductMapping = product => {
  const cleanedProduct = product?.replace(/ /g, '')?.toLowerCase();
  return productMap[cleanedProduct] || null;
};

export default getProductMapping;
