import { colorPrimaryBase } from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';

export const base = {
  statusColors: {
    active: '#49e305',
    inactive: '#e20714',
  },
  chatbotBgColor: colorPrimaryBase,
};

export default base;
