import { Icon } from '@mediahuis/chameleon-react-legacy';
import { Checkmark, Close } from '@mediahuis/chameleon-theme-wl/legacy/icons';

const FeaturesList = ({
  availableOnly = false,
  className,
  gap = '0.25rem',
  hideUnavailable = false,
  lineThroughUnavailable = false,
  iconSize = 'medium',
  items,
}) => {
  const sortedItems = [...items].sort(
    (a, b) => Number(b.isAvailable) - Number(a.isAvailable),
  );

  return (
    <ul
      className={className}
      style={{ display: 'flex', flexDirection: 'column', gap }}
    >
      {(hideUnavailable ? sortedItems : items).map(item => {
        if (
          (availableOnly && item.isAvailable) ||
          !availableOnly ||
          hideUnavailable
        ) {
          return (
            <li
              key={item.key}
              style={{
                color: item.isAvailable ? 'inherit' : 'var(--color-grey-40)',
                display: 'flex',
                gap: '0.25rem',
                visibility:
                  hideUnavailable && !item.isAvailable ? 'hidden' : undefined,
                textDecoration:
                  !item.isAvailable && lineThroughUnavailable
                    ? 'line-through'
                    : undefined,
              }}
            >
              <Icon
                as={item.isAvailable ? Checkmark : Close}
                color={item.isAvailable ? 'colorGreen50' : 'colorGrey40'}
                size={iconSize}
              />
              {item.children}
            </li>
          );
        }

        return null;
      })}
    </ul>
  );
};

export default FeaturesList;
