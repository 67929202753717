import React from 'react';
import styled from 'styled-components';
import { isMobileOnly } from 'react-device-detect';
import {
  space5,
  space10,
  colorBlackBase,
} from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';
import {
  Box,
  Heading,
  Caption,
  Flex,
  LinkText,
} from '@mediahuis/chameleon-react-legacy';

import { DynamicFaq } from '~/components';
import { config, ts } from '~/services';
import CustomerServiceWidget from '~/components/CustomerServiceWidget';
import Wrapper from '../Wrapper';
import { OtherServices } from './language';
import DidomiPreferences from '../Didomi/DidomiPreferences';

export const ServiceCaption = styled(Caption)`
  width: 100%;
  padding-bottom: ${space5};
  @media (max-width: 767px) {
    margin-bottom: 8px;
  }
  a {
    text-decoration: underline;
  }
`;

const Link = styled(LinkText)`
  color: ${colorBlackBase} !important;
  text-decoration: none;
  &&:hover {
    text-decoration: none;
  }
`;

const CustomerServiceWrapper = styled(Box)`
  padding: ${space5};
  margin: -${space5};
  margin-bottom: ${space10};
`;

const Footer = () => {
  return (
    <React.Fragment>
      <DynamicFaq />
      <Box py={10}>
        <Wrapper>
          <CustomerServiceWrapper id="customer-service">
            <CustomerServiceWidget
              title={ts.t('CustomerServiceWidget.Title')}
              position="footer"
              chatIsOpen={!isMobileOnly}
              destination="eloket"
              config={config.srConfig}
            />
          </CustomerServiceWrapper>
          <Flex>
            {!config.isMHLU && (
              <Box mr={8}>
                <Heading level={4} fontWeight="regular" mb={6}>
                  {ts.t('Footer.OtherServices')}
                </Heading>
                <OtherServices />
              </Box>
            )}
            <Box>
              {!config.isMHLU && (
                <Heading level={4} fontWeight="regular" mb={6}>
                  {ts.t('Footer.Privacy')}
                </Heading>
              )}
              <Caption decoration="underline">
                <DidomiPreferences />
              </Caption>
              <Caption mt={5} decoration="underline">
                <Link
                  href={config.privacyUrl}
                  target="_blank"
                  data-testid="button-privacy-policy"
                >
                  {ts.t('Footer.PrivacyPolicy')}
                </Link>
              </Caption>
            </Box>
          </Flex>
        </Wrapper>
      </Box>
    </React.Fragment>
  );
};

export default Footer;
