import {
  Paragraph,
  WideList,
  WideListItem,
} from '@mediahuis/chameleon-react-legacy';
import styled from 'styled-components';
import React from 'react';
import { colorGrey10 } from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';
import { Checkmark, Clock } from '@mediahuis/chameleon-theme-wl/legacy/icons';

const WideListItemHeader = styled(WideListItem)`
  background-color: ${colorGrey10};
`;

const TableMobile = ({ columns, data, message = '' }) => {
  return data?.length > 0
    ? data.map((vacation, index) => (
        <WideList key={data.id} mb={6} elevation={3}>
          <WideListItemHeader
            action={data[index].cancel}
            iconLeft={data[index].state === 'confirmed' ? Checkmark : Clock}
          />
          {columns.map(
            column =>
              column.field !== 'cancel' && (
                <WideListItem
                  key={column.field}
                  title={column.title}
                  description={vacation[column.field] || '-'}
                  m={0}
                  p={0}
                />
              ),
          )}
        </WideList>
      ))
    : message && <Paragraph>{message}</Paragraph>;
};

export default TableMobile;
