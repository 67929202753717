import ahApiCall from '~/api/ah/ahApiCall';
import { config } from '~/services';

const postHolidayWebformCase = async ({ subscriptionId, body }) => {
  await ahApiCall(
    `${config.ahServiceApiUrl}/subscriptions/${subscriptionId}/temporary-delivery-changes/webform`,
    {
      method: 'POST',
      data: JSON.stringify(body),
    },
  );
};

export default postHolidayWebformCase;
