import { ts } from '~/services';

export const HOLIDAY_STEPS = Object.freeze({
  CHOICE_STEP: 'choiceStep',
  ADDRESS_STEP: 'addressStep',
  ADDRESS_CHOICE_STEP: 'addressChoiceStep',
  SUMMARY_STEP: 'summaryStep',
});

export const HOLIDAY_SOLUTIONS = Object.freeze({
  CONTINUE_DIGITAL: 'ContinueDigital',
  DISCONTINUE_DIGITAL: 'DiscontinueDigital',
  DISCONTINUE_PRINT: 'DiscontinuePrint',
  FAMILY_ADDRESS: 'FamilyAddress',
  HOLIDAY_ADDRESS: 'HolidayAddress',
  CHARITY: 'Charity',
});

export const AH_HOLIDAY_SOLUTIONS = Object.freeze({
  CONTINUE_DIGITAL: 'TemporaryStopPrint',
  DISCONTINUE_DIGITAL: 'TemporaryStopAll',
  FAMILY_ADDRESS: 'TemporaryThirdParty',
  HOLIDAY_ADDRESS: 'TemporaryAddress',
  CHARITY: 'TemporaryStopPrintCharity',
});

export const AH_HOLIDAY_SOLUTIONS_MAP = Object.freeze({
  TemporaryStopPrint: 'continueDigital',
  TemporaryStopAll: 'discontinueDigital',
  TemporaryThirdParty: 'familyAddress',
  TemporaryAddress: 'holidayAddress',
  TemporaryStopPrintCharity: 'charity',
});

export const HOLIDAY_SOLUTIONS_TRANSLATED = Object.freeze({
  EXTEND: ts.t('Holiday.Solution.EXTEND'),
  INTERRUPT: ts.t('Holiday.Solution.INTERRUPT'),
  FORWARD: ts.t('Holiday.Solution.FORWARD'),
  CHARITY: ts.t('Holiday.Solution.CHARITY'),
  FORWARD_ADDRESS: ts.t('Holiday.Solution.FORWARD_ADDRESS'),
  FORWARD_THIRD_PARTY: ts.t('Holiday.Solution.FORWARD_THIRD_PARTY'),
});

export const HOLIDAY_SOLUTIONS_TRANSLATED_PRINT_ONLY = Object.freeze({
  EXTEND: ts.t('Holiday.SolutionPrintOnly.EXTEND'),
  INTERRUPT: ts.t('Holiday.SolutionPrintOnly.INTERRUPT'),
  FORWARD: ts.t('Holiday.SolutionPrintOnly.FORWARD'),
  CHARITY: ts.t('Holiday.SolutionPrintOnly.CHARITY'),
  FORWARD_ADDRESS: ts.t('Holiday.SolutionPrintOnly.FORWARD_ADDRESS'),
  FORWARD_THIRD_PARTY: ts.t('Holiday.SolutionPrintOnly.FORWARD_THIRD_PARTY'),
});

export const HOLIDAY_STATES = Object.freeze({
  CONFIRMED: 'confirmed',
  CREATION_FAILED: 'creationFailed',
  CREATION_REQUESTED: 'creationRequested',
  CREATION_AWAITING_CONFIRMATION: 'creationAwaitingConfirmation',
  UPDATE_FAILED: 'updateFailed',
  UPDATE_REQUESTED: 'updateRequested',
  UPDATE_AWAITING_CONFIRMATION: 'updateAwaitingConfirmation',
  CANCELLATION_FAILED: 'cancellationFailed',
  CANCELLATION_REQUESTED: 'cancellationRequested',
  CANCELLATION_AWAITING_CONFIRMATION: 'cancellationAwaitingConfirmation',
});

export const salutationSelectOptions = [
  { label: ts.t('Common.Salution.M'), value: 'M' },
  { label: ts.t('Common.Salution.F'), value: 'F' },
];

export const holidayTableColumns = [
  { title: ts.t('Holiday.Table.Start'), field: 'start' },
  { title: ts.t('Holiday.Table.End'), field: 'end' },
  { title: ts.t('Holiday.Table.Solution'), field: 'vacationSolution' },
  { title: ts.t('Holiday.Table.Address'), field: 'solutionData' },
  { title: '', field: 'cancel' },
];

export const countries = {
  BE: ts.t('Countries.BE'),
  DE: ts.t('Countries.DE'),
  NL: ts.t('Countries.NL'),
  LU: ts.t('Countries.LU'),
};

export const titles = {
  M: ts.t('Common.Salution.M'),
  F: ts.t('Common.Salution.F'),
};

export const LIFE_CYCLES = {
  PAST: 'Past',
  ACTIVE: 'Active',
  FUTURE: 'Future',
};
export const SUBSCRIPTION_LIFE_CYCLES = {
  CANCELLED: 'Cancelled',
  ACTIVE: 'Active',
  FUTURE: 'Future',
};

export const UPDATE_POSSIBILITIES = {
  COMPLETELTY: 'Completely',
  PARTIALLY: 'Partially',
  NOT: 'Not',
};
