import React, { useCallback } from 'react';
import { Box } from '@mediahuis/chameleon-react-legacy';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import SelectCountry from './SelectCountry';
import BelgianAddressFields from './BelgianAddressFields';
import DefaultAddresFields from './DefaultAddresFields';
import DutchAddressFields from './DutchAddressFields';
import addressFieldsValidations from './addressFieldsValidations';

const AddressFields = ({
  allowedCountries,
  setAddress,
  setErrors,
  optionalFields = false,
  ...props
}) => {
  const { chosenUserSubscription } = useGlobalContext();

  const handleAddressChange = useCallback(
    data => setAddress(state => ({ ...state, ...data })),
    [setAddress],
  );

  const clearError = e => {
    const spreadedErrors = { ...props.errors };
    delete spreadedErrors[e.target.id];
    setErrors(spreadedErrors);
  };

  const clearFields = () => {
    const fields = ['houseNumber', 'box', 'street', 'city', 'postalCode'];
    const spreadedState = { ...props.address };
    fields.forEach(field => {
      spreadedState[field] = '';
    });
    handleAddressChange(spreadedState);
  };

  const validateOnBlur = async e => {
    if (optionalFields) {
      return;
    }
    const addressErrors = await addressFieldsValidations(
      { [e.target.id]: e.target.value },
      props.address.countryCode,
      chosenUserSubscription,
    );

    setErrors(prevState => {
      return {
        ...prevState,
        ...addressErrors,
      };
    });
  };

  const getCountryAddressField = () => {
    switch (props.address.countryCode) {
      case 'BE':
        return (
          <BelgianAddressFields
            {...props}
            clearFields={clearFields}
            handleAddressChange={handleAddressChange}
            clearError={clearError}
            validateOnBlur={validateOnBlur}
            setErrors={setErrors}
          />
        );
      case 'NL':
        return (
          <DutchAddressFields
            {...props}
            clearFields={clearFields}
            handleAddressChange={handleAddressChange}
            clearError={clearError}
            validateOnBlur={validateOnBlur}
            setErrors={setErrors}
          />
        );
      default:
        return (
          <DefaultAddresFields
            {...props}
            clearFields={clearFields}
            handleAddressChange={handleAddressChange}
            clearError={clearError}
            validateOnBlur={validateOnBlur}
            setErrors={setErrors}
          />
        );
    }
  };

  return (
    <React.Fragment>
      <Box mb={4}>
        <SelectCountry
          address={props.address}
          handleAddressChange={handleAddressChange}
          allowedCountries={allowedCountries}
          setErrors={setErrors}
          errors={props.errors}
        />
      </Box>
      {getCountryAddressField()}
    </React.Fragment>
  );
};

// /* eslint react/prop-types: 0 */
// AddressFields.propTypes = {
//   allowedCountries: PropTypes.array,
//   address: PropTypes.object,
//   setAddress: PropTypes.func,
//   error: PropTypes.object,
//   setError: PropTypes.func,
// };

export default AddressFields;
