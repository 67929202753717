export const EMAIL = 'EMAIL';
export const CHAT = 'CHAT';
export const PHONE = 'PHONE';

export const STATUSES = Object.freeze({
  CLOSED: 'CLOSED',
  OPEN: 'OPEN',
  HOLIDAY: 'HOLIDAY',
  CHATBOT: 'CHATBOT',
  OPENSOON: 'OPENSOON',
  RESTDAY: 'RESTDAY',
});

export const STRING_REPLACE = Object.freeze({
  HOLIDAYNAME: 'HOLIDAYNAME',
  PHONENR: 'PHONENR',
  OPENHOUR: 'OPENHOUR',
  CLOSEHOUR: 'CLOSEHOUR',
});
