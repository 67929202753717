import { ts } from '~/services';

export const DIALOG_TYPES = Object.freeze({
  ACCOUNT: 'account',
  ADDRESS: 'address',
  ADDRESS_INVOICE: 'addressInvoice',
  PAYMENT: 'payment',
  GIRO_PHASE_OUT: 'giroPhaseOut',
  STUDENT_UPLOAD: 'studentUpload',
  PHONE_NUMBERS: 'phoneNumbers',
  CANCEL_PAYER_ADDRESS_CHANGE: 'cancePayerAddressChange',
  CANCEL_RECEIVER_ADDRESS_CHANGE: 'cancelReceiverAddressChange',
  ONLINCE_CANCELLATION: 'onlineCancellation',
});

export const SUBSCRIPTION_STATUS = Object.freeze({
  Active: ts.t('Subscription.Status.Active'),
  PendingRenewal: ts.t('Subscription.Status.PendingRenewal'),
  Suspended: ts.t('Subscription.Status.Suspended'),
  Waiting: ts.t('Subscription.Status.Waiting'),
  TemporarySuspension: ts.t('Subscription.Status.TemporarySuspension'),
  GracePeriod: ts.t('Subscription.Status.GracePeriod'),
});

export const ROLES = {
  BILL_TO: 'BillTo',
  SHIP_TO: 'ShipTo',
};

export const AH_SUBSCRIPTION_STATUS = {
  Active: ts.t('Subscription.LifeCycles.Active'),
  Future: ts.t('Subscription.LifeCycles.Future'),
  Cancelled: ts.t('Subscription.LifeCycles.Cancelled'),
};
