import React from 'react';
import Downshift from 'downshift';
import { Flex, Loader, TextField } from '@mediahuis/chameleon-react-legacy';
import {
  colorGrey30,
  colorWhiteBase,
  fontWeightBold,
  fontWeightRegular,
  fontFamilySystem,
} from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';
import SuggestionWrapper from './SuggestionWrapper';
import SuggestionItem from './SuggestionItem';
import FormGroup from './FormGroup';
import noop from './noop';

const HouseNumbersAutocomplete = ({
  onChange,
  itemToString,
  onInputValueChange,
  items,
  label,
  houseNumber,
  errors = {},
  validateOnBlur = noop,
  clearError = noop,
  autoComplete = '',
  optionalLabel = '',
  disabled,
  isLoading,
}) => (
  <Downshift
    onChange={onChange}
    itemToString={itemToString}
    onInputValueChange={onInputValueChange}
    initialSelectedItem={houseNumber}
    stateReducer={(_, changes) => {
      // We need to check if the changes made
      // are of a valid type or undefined to know
      // how to update the state which uses this component
      if (changes.inputValue === undefined) {
        return { ...changes, inputValue: houseNumber };
      }
      return changes;
    }}
  >
    {({
      getInputProps,
      getItemProps,
      getMenuProps,
      getRootProps,
      isOpen,
      inputValue,
      highlightedIndex,
      selectedItem,
      clearSelection,
    }) => (
      <FormGroup {...getRootProps({ refKey: 'innerRef' })}>
        <TextField
          data-testid="houseNumber-autocomplete"
          disabled={disabled}
          maxlength={5}
          {...getInputProps({
            onChange: e => {
              if (e.target.value !== houseNumber) {
                clearSelection();
              }
            },
            onBlur: e => {
              // Event does not work as expected with Downshift,
              // we pass a custom object with eiter an empty
              // string or selected object.
              const validHouseNumber = items.find(
                item => item.Number === e.target.value,
              );
              const downshiftValues = {
                target: {
                  id: 'houseNumber',
                  value: validHouseNumber ? houseNumber : '',
                },
              };
              validateOnBlur(downshiftValues);
            },
          })}
          label={label}
          id="houseNumber"
          onFocus={clearError}
          value={houseNumber}
          message={errors.houseNumber}
          error={!!errors.houseNumber}
          autoComplete={autoComplete}
          optionalLabel={optionalLabel}
        />
        <SuggestionWrapper {...getMenuProps()}>
          {isLoading && !selectedItem && (
            <Flex justifyContent="center">
              <SuggestionItem>
                <Loader />
              </SuggestionItem>
            </Flex>
          )}
          {isOpen &&
            !isLoading &&
            items
              .filter(
                item =>
                  !inputValue ||
                  item.Number.toLowerCase().includes(
                    // Remove possible spacing of houseNumber.
                    inputValue.replace(/\s+/g, '').toLowerCase(),
                  ),
              )
              .map((item, index) => (
                <SuggestionItem
                  key={item}
                  {...getItemProps({
                    key: index,
                    index,
                    item,
                    style: {
                      backgroundColor:
                        highlightedIndex === index
                          ? colorGrey30
                          : colorWhiteBase,
                      fontWeight:
                        selectedItem === item
                          ? fontWeightBold
                          : fontWeightRegular,
                      fontFamily: fontFamilySystem,
                    },
                  })}
                >
                  {item.Number}
                </SuggestionItem>
              ))}
        </SuggestionWrapper>
      </FormGroup>
    )}
  </Downshift>
);
export default HouseNumbersAutocomplete;
