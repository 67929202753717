import { isMobileOnly, isTablet } from 'react-device-detect';

const getDeviceType = () => {
  if (isMobileOnly) {
    return 'Mobile';
  } else if (isTablet) {
    return 'Tablet';
  } else if (!isMobileOnly && !isTablet) {
    return 'PC';
  }
  return 'unknown';
};

export default getDeviceType;
