import ahApiCall from '~/api/ah/ahApiCall';
import { config } from '~/services';

const updatePhoneNumbers = async ({ customerId, body }) => {
  await ahApiCall(
    `${config.ahServiceApiUrl}/customers/${customerId}/contactpoints-phone`,
    {
      method: 'PUT',
      data: JSON.stringify(body),
    },
  );
};

export default updatePhoneNumbers;
