import React from 'react';
import { Box, Caption, Flex } from '@mediahuis/chameleon-react-legacy';
import parse from 'html-react-parser';
import styled from 'styled-components';
import { styles } from './styles/';
import { STATUSES } from './enums';
import ChatImg from './img/chat.svg';

const StatusIcon = styled.div`
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => {
    switch (props.output) {
      case STATUSES.CLOSED:
      case STATUSES.HOLIDAY:
      case STATUSES.OPENSOON:
      case STATUSES.RESTDAY:
      case STATUSES.CHATBOT:
        return styles.statusColors.inactive;
      default:
        return styles.statusColors.active;
    }
  }};
  margin-right: 10px;
`;

const ChatIcon = styled.div`
  background-image: url(${ChatImg});
  width: 64px;
  height: 64px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const ChatInfo = ({ componentData }) => {
  const { mainText, editText, output, settings } = componentData;
  const { closedInfoText, closed } = settings;
  return (
    <Flex alignItems="center">
      <Box mr={4}>
        <Flex>
          <ChatIcon />
          {/* <Icon as={CustomerSupportFill} color="colorSecondaryBase" />*/}
          <StatusIcon output={output.status} />
        </Flex>
      </Box>
      <Box>
        <Caption level={2}>{parse(mainText)}</Caption>
        <Caption level={2}>{parse(closed ? closedInfoText : editText)}</Caption>
      </Box>
    </Flex>
  );
};

export default ChatInfo;
