import {
  Banner,
  Button,
  Flex,
  Text,
  useMediaQuery,
} from '@mediahuis/chameleon-react-legacy';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import React from 'react';
import { DIALOG_TYPES } from '~/pages/Subscription/constants';
import { ts } from '~/services';

export default function UploadStudentCardBanner({ handleClick }) {
  return (
    <Banner show closeHidden appearance="info">
      <Flex
        justifyContent="space-between"
        alignItems={useMediaQuery(
          {
            md: 'center',
          },
          'flex-start',
        )}
        flexDirection={useMediaQuery(
          {
            md: 'row',
          },
          'column',
        )}
      >
        <Text
          mr={useMediaQuery(
            {
              md: 5,
            },
            0,
          )}
          mb={useMediaQuery(
            {
              md: 0,
            },
            5,
          )}
          flex={1}
        >
          {ts.t('StudentUploadBanner.Text')}{' '}
        </Text>
        <Button
          onClick={() =>
            handleClick({
              type: DIALOG_TYPES.STUDENT_UPLOAD,
            })
          }
          iconRight={ChevronForward}
          size="small"
        >
          {ts.t('StudentUploadBanner.ButtonText')}
        </Button>
      </Flex>
    </Banner>
  );
}
