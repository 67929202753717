export const getSubscriptionRights = ({
  subscriptionId,
  subscriptionsRights = [],
}) => {
  return subscriptionsRights?.[subscriptionId];
};

export const getCustomerRights = ({ customerId, customersRights = [] }) => {
  return customersRights?.[customerId];
};
