const getChatLink = ({ link, user, openAfterHours }) => {
  let strChatLink = link;

  if (window.MEDIAHUIS) {
    if (window.MEDIAHUIS.user) {
      const strEmail = window.MEDIAHUIS.user.email;
      const strName = window.MEDIAHUIS.user.firstName;

      strChatLink += `?email=${encodeURI(strEmail || '')}&name=${
        strName || ''
      }&offline=${openAfterHours ? '1' : ''}`;
    }
  } else if (user) {
    strChatLink += `?email=${user.emailAddress || ''}&name=${
      user.name || ''
    }&offline=${openAfterHours ? '1' : ''}`;
  } else {
    strChatLink += `?offline=${openAfterHours ? '1' : ''}`;
  }

  return strChatLink;
};

export default getChatLink;
