import {
  Box,
  Heading,
  WideList,
  WideListItem,
} from '@mediahuis/chameleon-react-legacy';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import React from 'react';

export default function ManageSubscriptionSection({ onClick }) {
  return (
    <>
      <Box mb={6}>
        <Heading level={3}>Abonnement beheren</Heading>
      </Box>
      <Box mb={8}>
        <WideList>
          <WideListItem
            title="Abonnement opzeggen"
            iconRight={ChevronForward}
            onClick={onClick}
            clickable
          />
        </WideList>
      </Box>
    </>
  );
}
