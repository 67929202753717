import { BRANDS } from '~/constants';
import { config } from '~/services';

const getBrandLogoUrl = () => {
  switch (config.brand) {
    case BRANDS.DS:
      return 'https://shared.mediahuis.be/logos/ds/v2/brand-square-icon-white.svg';
    case BRANDS.DL:
      return 'https://shared.mediahuis.be/logos/dl/v2/brand-square-main.svg';
    case BRANDS.LW:
    case BRANDS.CO:
    case BRANDS.TC:
    case BRANDS.LT:
      return `https://shared.mediahuis.be/logos/${config.brand}/v1/brand-square-main.svg`;
    case BRANDS.APORTAL:
      return 'https://shared.mediahuis.be/logos/mh/v1/brand-square-main.svg';
    default:
      return `https://shared.mediahuis.be/logos/${config.brand}/v1/brand-square-icon.svg`;
  }
};

export default getBrandLogoUrl;
