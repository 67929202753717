import React, { useEffect, useState } from 'react';
import { Button, useMediaQuery } from '@mediahuis/chameleon-react-legacy';
import { Add } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import { mapToDeliveryDate, scrollTopWithTimeout } from '~/utils';
import { BRANDS } from '~/constants';
import { config, ts } from '~/services';
import { Box, USECASES } from '~/components';
import useDeliveryComplaintContext from '~/context/hooks/useDeliveryComplaintContext';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import HelloCustomer from '../../../components/HelloCustomer';
import DeliveryComplaintDialog from './DeliveryComplaintDialog';

const getUpdateBody = ({ prevState }) => {
  const { deliveryDates } = prevState.data;
  return {
    ...prevState,
    deliveryDate: mapToDeliveryDate({
      deliveryDates,
      reverse: true,
    }),
    comment: '',
    chosenComplaint: {
      chosenOption: '',
      complaint: '',
    },
    errors: {},
  };
};

const DeliveryComplaintContent = ({ setLoginShown }) => {
  const [disabled, setDisabled] = useState(true);
  const [dialogShown, setDialogShown] = useState(false);
  const [helloCustomerShown, setHelloCustomerShown] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(true);

  const {
    setDeliveryComplaintStateWithPrevState,
  } = useDeliveryComplaintContext();
  const {
    userInfo,
    chosenUserSubscription,
    isFetchingUser,
  } = useGlobalContext();

  const isLoggedIn = userInfo.accountGuid;

  useEffect(() => {
    if (!isLoggedIn && !isFetchingUser) {
      setIsBtnLoading(false);
      setDisabled(false);
    }
  }, [isFetchingUser, isLoggedIn]);

  useEffect(() => {
    if (
      isLoggedIn &&
      !isFetchingUser &&
      chosenUserSubscription !== null &&
      !chosenUserSubscription
    ) {
      setIsBtnLoading(false);
    }
  }, [chosenUserSubscription, isFetchingUser, isLoggedIn]);

  const handleBtnClick = () => {
    if (isLoggedIn) {
      setDialogShown(true);
    } else {
      setLoginShown(true);
    }
  };

  return (
    <>
      <Box mb={5} mt={7}>
        {ts.t('DeliveryComplaint.Body', {
          richContent: true,
          values: { siteName: config.siteName },
        })}
      </Box>
      <Button
        iconLeft={Add}
        width={useMediaQuery({ sm: 'auto' }, 'full')}
        onClick={handleBtnClick}
        disabled={disabled}
        loading={isBtnLoading}
      >
        {ts.t('DeliveryComplaint.ReportDeliveryComplaint')}
      </Button>
      <DeliveryComplaintDialog
        show={dialogShown}
        setDisabled={setDisabled}
        setIsBtnLoading={setIsBtnLoading}
        onClose={() => {
          setDeliveryComplaintStateWithPrevState(prevState =>
            getUpdateBody({ prevState }),
          );
          setDialogShown(false);
          scrollTopWithTimeout();
          setHelloCustomerShown(true);
        }}
      />
      {config.brand !== BRANDS.DL && helloCustomerShown && (
        <HelloCustomer
          show={helloCustomerShown}
          chosenUserSubscription={chosenUserSubscription}
          userInfo={userInfo}
          useCase={USECASES.DELIVERYCOMPLAINT}
        />
      )}
    </>
  );
};

export default DeliveryComplaintContent;
