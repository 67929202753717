import {
  Banner,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Loader,
  Paragraph,
  RichContent,
  Textarea,
} from '@mediahuis/chameleon-react-legacy';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import isEmpty from 'lodash.isempty';
import React, { useEffect, useState } from 'react';
import { Header, HeaderWithContentLoader, Wrapper } from '~/components';

import { filterArticlesByTag, getAllFaqArticles } from '~/pages/Contact/utils';
import { ts } from '~/services';
import { clearValidationOnFocus } from '~/utils';
import { useBanner, useGlobalContext } from '~/context';
import postSmartWebform from '~/api/postSmartWebform';
import FaqArticles from '~/pages/Contact/components/FaqArticles';
import { BANNER_TYPES } from '~/constants';
import getSmartWebformConfig from '~/api/getSmartWebformConfig';
import Level3 from '~/pages/SmartWebform/components/Level3';
import PersonalInformation from '~/pages/SmartWebform/components/PersonalInformation';
import {
  getConfigOfSelectedLevel,
  getSelectedLevel,
  handleSelectChange,
  mapToSmartWebformBody,
  mapUserInfo,
  validateSmartWebformFields,
} from '~/pages/SmartWebform/utils';
import SelectWrapper from '~/pages/SmartWebform/components/SelectWrapper';
import CurrentDeviceChoice from '~/pages/SmartWebform/components/CurrentDeviceChoice';
import NotCurrentDevice from '~/pages/SmartWebform/components/NotCurrentDevice';
import Attachments from '~/pages/SmartWebform/components/Attachments';

export default function SmartWebform() {
  const {
    faqCategories,
    isLoadingFaqCategories,
    userInfo,
  } = useGlobalContext();
  const { showBanner } = useBanner();

  const defaultState = {
    level1: '',
    level2: '',
    level3: '',
    level4: '',
    description: '',
    currentDevice: true,
    deviceBrandAndModel: '',
    os: '',
    browser: '',
  };
  const defaultPersonalInformation = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    countryCode: 'BE',
    postalCode: '',
    city: '',
    street: '',
    houseNumber: '',
    box: '',
  };
  const [state, setState] = useState(defaultState);
  const [personalInformation, setPersonalInformation] = useState(
    defaultPersonalInformation,
  );
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingConfig, setIsLoadingConfig] = useState(false);
  const [config, setConfig] = useState({});
  const [configHasFailed, setConfigHasFailed] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const getConfig = async () => {
      try {
        setConfigHasFailed(false);
        setIsLoadingConfig(true);
        const res = await getSmartWebformConfig();
        setConfig(res);
      } catch (error) {
        setConfigHasFailed(true);
      } finally {
        setIsLoadingConfig(false);
      }
    };
    getConfig();
  }, []);

  useEffect(() => {
    if (!isEmpty(userInfo)) {
      setPersonalInformation(mapUserInfo(userInfo));
    }
  }, [userInfo]);

  const header = <Header heading={ts.t('SmartWebform.Title')} />;
  const intro = (
    <Wrapper>
      <Paragraph mb={9}>
        {ts.t('SmartWebform.IntroText', { richContent: true })}
      </Paragraph>
    </Wrapper>
  );

  if (isLoadingConfig) {
    return <HeaderWithContentLoader title={ts.t('SmartWebform.Title')} />;
  }
  if (configHasFailed) {
    return (
      <>
        {header}
        {intro}
        <Wrapper>
          <Banner appearance="error" show closeHidden>
            {ts.t('SmartWebform.GetConfigError')}
          </Banner>
        </Wrapper>
      </>
    );
  }

  const selectedLevel1 = getSelectedLevel(config?.items, state.level1);
  const selectedLevel2 = getSelectedLevel(
    selectedLevel1?.children,
    state.level2,
  );
  const selectedLevel3 = getSelectedLevel(
    selectedLevel2?.children,
    state.level3,
  );
  const selectedLevel4 = getSelectedLevel(
    selectedLevel3?.children,
    state.level4,
  );

  const {
    faqIntroLabel,
    noWebformText,
    showWebform,
    faqTag,
    faqHref,
  } = getConfigOfSelectedLevel({ selectedLevel1, selectedLevel2 });

  const isDesktopLaptop = selectedLevel4?.isDesktopLaptop;
  const hasLevel4 = selectedLevel3?.children?.length > 0;

  const faqArticles = getAllFaqArticles(faqCategories);
  const filteredArticles = filterArticlesByTag({
    articles: faqArticles,
    tag: faqTag,
  });
  const showArticles = filteredArticles.length > 0;

  // eslint-disable-next-line consistent-return
  const handleSubmit = async () => {
    try {
      const { firstName, lastName, email } = personalInformation;
      const {
        description,
        currentDevice,
        deviceBrandAndModel,
        os,
        browser,
      } = state;
      setIsLoading(true);
      const smartWebformFieldsErrors = validateSmartWebformFields({
        firstName,
        lastName,
        email,
        description,
        selectedLevel1,
        selectedLevel2,
        selectedLevel3,
        selectedLevel4,
        currentDevice,
        deviceBrandAndModel,
        os,
        browser,
        isDesktopLaptop,
      });

      if (Object.keys(smartWebformFieldsErrors).length === 0) {
        const body = mapToSmartWebformBody({
          state,
          personalInformation,
          isDesktopLaptop,
          files,
        });
        await postSmartWebform({ body });
        showBanner({
          type: BANNER_TYPES.SUCCESS,
          message: ts.t('SmartWebform.SuccessMessage', {
            richContent: true,
          }),
          closeHidden: true,
        });
        window.scrollTo(0, 0);
        setState(defaultState);
        setSubmitted(true);
        setPersonalInformation(defaultPersonalInformation);
      }
      return setErrors(smartWebformFieldsErrors);
    } catch (error) {
      showBanner({
        type: BANNER_TYPES.ERROR,
        message: ts.t('SmartWebform.PostErrorMessage'),
      });
      window.scrollTo(0, 0);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      {header}
      {!submitted && intro}
      {!submitted && (
        <Box bgColor="grey10" py={9}>
          <Wrapper>
            <Box maxWidth="800px" mx="auto" p={7} bgColor="whiteBase">
              <Heading level={3} mb={4}>
                {ts.t('SmartWebform.Level1Title')}
              </Heading>
              <SelectWrapper
                items={config.items}
                handleChange={e => {
                  handleSelectChange({ e, setState });
                  setState(prevState => ({
                    ...prevState,
                    level2: '',
                    level3: '',
                    level4: '',
                  }));
                }}
                value={state.level1}
                id="level1"
                label={ts.t('SmartWebform.level1Label')}
                placeholder={ts.t('SmartWebform.level1Placeholder')}
                error={!!errors?.level1}
                message={errors?.level1}
                errors={errors}
                setErrors={setErrors}
              />
              {selectedLevel1?.children?.length > 0 && (
                <SelectWrapper
                  items={selectedLevel1?.children}
                  handleChange={e => {
                    handleSelectChange({ e, setState });
                    setState(prevState => ({
                      ...prevState,
                      level3: '',
                      level4: '',
                    }));
                  }}
                  value={state.level2}
                  id="level2"
                  label={ts.t('SmartWebform.leve2Label')}
                  placeholder={ts.t('SmartWebform.level2Placeholder')}
                  error={!!errors?.level2}
                  message={errors?.level2}
                  errors={errors}
                  setErrors={setErrors}
                />
              )}
              {isLoadingFaqCategories && faqTag && (
                <Flex justifyContent="center" p={6}>
                  <Loader />
                </Flex>
              )}
              {showArticles && (
                <FaqArticles
                  articles={filteredArticles}
                  subCategory={state.subCategory}
                  href={faqHref}
                  introLabel={faqIntroLabel}
                />
              )}
              {selectedLevel2?.children?.length > 0 && (
                <Level3
                  items={selectedLevel2?.children}
                  state={state}
                  setState={setState}
                  errors={errors}
                  setErrors={setErrors}
                />
              )}
              {hasLevel4 && (
                <>
                  <SelectWrapper
                    items={selectedLevel3?.children}
                    handleChange={e => {
                      handleSelectChange({ e, setState });
                    }}
                    value={state.level4}
                    id="level4"
                    label={ts.t('SmartWebform.level4Label')}
                    placeholder={ts.t('SmartWebform.level4Placeholder')}
                    error={!!errors?.level4}
                    message={errors?.level4}
                    errors={errors}
                    setErrors={setErrors}
                  />
                  <CurrentDeviceChoice
                    setState={setState}
                    state={state}
                    errors={errors}
                    setErrors={setErrors}
                  />
                  {!state.currentDevice && (
                    <NotCurrentDevice
                      isDesktopLaptop={isDesktopLaptop}
                      setState={setState}
                      state={state}
                      errors={errors}
                      setErrors={setErrors}
                    />
                  )}
                </>
              )}

              {showWebform ? (
                <>
                  <Box mb={9}>
                    <Textarea
                      label={ts.t('SmartWebform.DescriptionLabel')}
                      id="description"
                      name="description"
                      value={state.description}
                      placeholder={
                        hasLevel4
                          ? ts.t('SmartWebform.DescriptionPlaceholder')
                          : ''
                      }
                      message={
                        errors.description
                          ? errors.description
                          : ts.t('SmartWebform.DescriptionMessage')
                      }
                      maxLength={10_000}
                      required
                      onChange={e => {
                        const value = e.target.value;
                        setState(prevState => ({
                          ...prevState,
                          description: value,
                        }));
                      }}
                      rows={6}
                      error={!!errors.description}
                      onBlur={e =>
                        setErrors({
                          ...errors,
                          ...validateSmartWebformFields({
                            [e.target.name]: e?.target?.value,
                          }),
                        })
                      }
                      onFocus={e =>
                        clearValidationOnFocus({ e, errors, setErrors })
                      }
                    />
                  </Box>
                  {config?.attachments?.enabled &&
                    !isEmpty(config?.attachments?.mimeTypes) && (
                      <Flex flexDirection="column" mb={6}>
                        <Heading level={3} mb={4}>
                          {ts.t('SmartWebform.Attachments.Title')}
                        </Heading>
                        <Attachments
                          maxFiles={Number(
                            config?.attachments?.amountOfFiles || '0',
                          )}
                          accept={config?.attachments?.mimeTypes}
                          files={files}
                          setFiles={setFiles}
                        />
                      </Flex>
                    )}
                  <PersonalInformation
                    personalInformation={personalInformation}
                    setPersonalInformation={setPersonalInformation}
                    setErrors={setErrors}
                    errors={errors}
                  />
                  <Divider color="grey40" my={8} />
                  <Flex justifyContent="center">
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      iconRight={ChevronForward}
                      loading={isLoading}
                      disabled={!isEmpty(errors)}
                    >
                      {ts.t('SmartWebform.SubmitButton')}
                    </Button>
                  </Flex>
                </>
              ) : (
                <RichContent
                  as="span"
                  fontFamily="system"
                  dangerouslySetInnerHTML={{
                    __html: noWebformText,
                  }}
                />
              )}
            </Box>
          </Wrapper>
        </Box>
      )}
    </div>
  );
}
