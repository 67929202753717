import React, { useEffect, useState } from 'react';
import {
  Banner,
  Box,
  Button,
  DatePicker,
  Flex,
  Heading,
  TextField,
  Tooltip,
} from '@mediahuis/chameleon-react-legacy';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import isEmpty from 'lodash.isempty';
import dayjs from 'dayjs';
import useBanner from '~/context/hooks/useBanner';
import { BANNER_TYPES } from '~/constants/messages';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import { ContentLoader } from '~/components';
import { DIALOG_WIDTH } from '~/constants/style';
import { hasEmptyAddressFields, validateDates } from '~/utils';
import getAllowedPostalCodesByCustomer from '~/api/ah/getAllowedPostalCodesByCustomer';
import { DATE_FORMAT_BE, DATE_FORMAT_READABLE } from '~/constants';
import AhAddressFields from '~/components/ah/AhAddressFields';
import { ts } from '~/services';
import getAdressChangesOptionsStartDate from '~/api/ah/getAdressChangesOptionsStartDate';
import getStartOrEndDate from '~/pages/Holiday/utils/ah/getStartOrEndDate';
import ahAddressFieldsValidations from '~/components/AddressFields/ahAddressFieldsValidations';
import postAddress from '~/api/ah/postAddress';
import getFirstUpcomingAddressChange from '~/components/ah/AhSubscriptionSelect/util/getFirstUpcomingAddressChange';
// eslint-disable-next-line import/max-dependencies
import getAddressChanges from '~/api/ah/getAddressChanges';

const AhAddressDialog = ({
  setDialogShown,
  setNewPayerAddress,
  setNewReceiverAddress,
  isInvoice,
  setRefreshAddressChangesHasFailed,
}) => {
  const { showBanner } = useBanner();
  const { chosenUserSubscription } = useGlobalContext();

  const [state, setState] = useState({});
  const [addressFields, setAddressFields] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingOnSubmit, setIsLoadingOnSubmit] = useState(false);
  const [isSubmitErrorBannerVisible, setSubmitErrorBannerVisibility] = useState(
    false,
  );

  const chosenCustomerToModifyHisAddress = isInvoice
    ? chosenUserSubscription.payer
    : chosenUserSubscription.receiver;

  const setAddressState = data => {
    setState(prevState => ({
      ...prevState,
      ...data,
    }));
  };

  const getAddressOptionsData = async () => {
    try {
      setIsLoading(true);
      const [startDates, allowedPostalCodes] = await Promise.all([
        getAdressChangesOptionsStartDate({
          customerId: chosenCustomerToModifyHisAddress.id,
        }),
        getAllowedPostalCodesByCustomer({
          id: chosenCustomerToModifyHisAddress.id,
        }),
      ]);

      setAddressState({
        startDates: startDates.dates,
        date: new Date(getStartOrEndDate(startDates.dates)),
        allowedCountries: Object.keys(
          allowedPostalCodes.allowedCountryPostalCodes,
        ),
        allowedPostalCodesByCountry:
          allowedPostalCodes.allowedCountryPostalCodes,
      });
    } catch (err) {
      showBanner({
        message: ts.t('Subscription.AddressDialog.Error.Options'),
        type: BANNER_TYPES.ERROR,
      });
      setDialogShown(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAddressOptionsData();
  }, []);

  const refreshAddressChanges = async () => {
    try {
      if (isInvoice) {
        const addressChanges = await getAddressChanges({
          customerId: chosenUserSubscription?.payer?.id,
        });
        setNewPayerAddress(getFirstUpcomingAddressChange(addressChanges));
      } else {
        const addressChanges = await getAddressChanges({
          customerId: chosenUserSubscription?.receiver?.id,
        });
        setNewReceiverAddress(getFirstUpcomingAddressChange(addressChanges));
      }
    } catch (error) {
      setRefreshAddressChangesHasFailed(true);
    }
  };
  const onSubmit = async () => {
    setRefreshAddressChangesHasFailed(false);
    setIsLoadingOnSubmit(true);
    setSubmitErrorBannerVisibility(false);
    const addressErrors = await ahAddressFieldsValidations({
      fields: addressFields,
      country: addressFields.countryCode,
      useAddressService: true,
    });
    setErrors(addressErrors);
    if (isEmpty(addressErrors)) {
      try {
        await postAddress({
          customerId: chosenCustomerToModifyHisAddress.id,
          startDate: state.date,
          addressFields,
        });

        const addressText = `${addressFields.street} ${
          addressFields.houseNumber
        }${addressFields.box ? `/${addressFields.box}` : ' '} - ${
          addressFields.postalCode
        } ${addressFields.city} - ${addressFields.countryCode}`;

        showBanner({
          type: BANNER_TYPES.SUCCESS,
          message: isInvoice
            ? ts.t('Subscription.AddressDialog.SuccessInvoice', {
                richContent: true,
                values: { addressText },
              })
            : ts.t('Subscription.AddressDialog.Success', {
                richContent: true,
                values: {
                  addressText,
                  date: dayjs(state.date).format(DATE_FORMAT_READABLE),
                },
              }),
        });
        await refreshAddressChanges();

        setIsLoadingOnSubmit(false);
        setDialogShown(false);
      } catch (err) {
        setSubmitErrorBannerVisibility(true);
        setIsLoadingOnSubmit(false);
      }
    } else {
      setIsLoadingOnSubmit(false);
    }
  };

  if (isLoading) {
    return (
      <Box width={DIALOG_WIDTH} maxWidth="full">
        <ContentLoader />
      </Box>
    );
  }

  const getStartDateDisabledDays = day => {
    const formattedDay = dayjs(day).format(DATE_FORMAT_BE);
    const dates = state.startDates;
    const isDaySelectable = dates?.[formattedDay]?.selectable;
    return !isDaySelectable;
  };
  const heading = (
    <Heading level={4} mb={5} color="primaryBase">
      {isInvoice
        ? ts.t('Subscription.EditInvoiceAddress')
        : ts.t('Subscription.EditDeliveryAddress')}
    </Heading>
  );

  if (state.allowedCountries.length === 0) {
    return (
      <>
        {heading}
        <Banner appearance="error" show closeHidden>
          {ts.t('Holiday.NoAllowedCountries')}
        </Banner>
      </>
    );
  }
  return (
    <Box width={DIALOG_WIDTH} maxWidth="full">
      <>
        {heading}
        <AhAddressFields
          data-testid="addressFields"
          setAddress={setAddressFields}
          setErrors={setErrors}
          address={addressFields}
          errors={errors}
          internalSubscriptionType={
            chosenUserSubscription?.internalSubscriptionType
          }
          allowedCountries={state.allowedCountries}
          allowedPostalCodesByCountry={state.allowedPostalCodesByCountry}
          isChangingAddress
          setDialogShown={setDialogShown}
        />
        <TextField
          id="extra-textfield"
          label={
            <>
              <span style={{ marginRight: '2px' }}>
                {ts.t('Subscription.AddressDialog.ExtraAddressField.Label')}
              </span>
              <Tooltip
                id="extraInfo"
                label={ts.t(
                  'Subscription.AddressDialog.ExtraAddressField.Tooltip',
                )}
                usePortal
              />
            </>
          }
          mb={3}
          name="extra"
          maxLength={60}
          value={addressFields.extra}
          onChange={e => {
            const value = e.target.value;
            return setAddressFields(prevState => ({
              ...prevState,
              extra: value,
            }));
          }}
        />
        <DatePicker
          pickerInputOnly
          mb={5}
          id="date"
          data-testid="date"
          label={ts.t('Subscription.AddressDialog.Date')}
          onChange={(value, meta) => {
            setAddressState({ date: value });
            const fields = [{ id: 'date', value, meta }];
            setErrors(validateDates({ errors, fields }));
          }}
          error={errors?.date}
          message={errors?.date}
          value={state.date}
          disabledDays={getStartDateDisabledDays}
        />
        <Banner
          appearance="error"
          show={isSubmitErrorBannerVisible}
          closeHidden
        >
          {ts.t('Subscription.AddressDialog.Error.Submit')}
        </Banner>
        <Flex justifyContent="flex-end">
          <Button
            mt={5}
            onClick={onSubmit}
            data-testid="submit"
            iconRight={ChevronForward}
            disabled={!isEmpty(errors) || hasEmptyAddressFields(addressFields)}
            loading={isLoadingOnSubmit}
          >
            {ts.t('Common.Confirm')}
          </Button>
        </Flex>
      </>
    </Box>
  );
};
export default AhAddressDialog;
