import { Helmet } from 'react-helmet';

/**
 * @class SeoService
 */
class SeoService {
  getFaqStructuredData(articles) {
    if (!articles?.length) {
      return null;
    }
    const structuredData = articles.map(article => {
      return {
        '@type': 'Question',
        name: article.title,
        acceptedAnswer: {
          '@type': 'Answer',
          text: article.content,
        },
      };
    });
    const seoScript = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: structuredData,
    };
    return (
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(seoScript)}</script>
      </Helmet>
    );
  }
}

const instance = new SeoService();
export { instance as seoService };
