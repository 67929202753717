import isEmpty from 'lodash.isempty';
import { useGlobalContext } from '~/context';
import { authService } from '~/services';
import BaseLoginWall from './BaseLoginWall';

const LoginWall = () => {
  const globalState = useGlobalContext();
  const { setGlobalState, userInfo, isFetchingUser } = globalState;

  const handleClick = () => {
    if (isEmpty(userInfo) && !isFetchingUser) {
      setGlobalState({ isFetchingUser: true });
      authService
        .login()
        .then(token => {
          const newUserInfo = token
            ? {
                accountGuid: token.idToken.sub,
                name: token.idToken.family_name,
                firstName: token.idToken.given_name,
              }
            : {};
          setGlobalState({
            userInfo: newUserInfo,
            isFetchingUser: !token,
          });
          return newUserInfo;
        })
        .catch(e => {
          setGlobalState({ isFetchingUser: false });
          throw e;
        });
    }
  };
  return <BaseLoginWall handleClick={handleClick} />;
};

export default LoginWall;
