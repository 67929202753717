import complaintTypes from '../data/deliveryComplaintTypes';

const ChosenComplaintExtra = ({ chosenComplaint }) => {
  return (
    complaintTypes?.find(el => el.value === chosenComplaint?.complaint)
      ?.extra || null
  );
};

export default ChosenComplaintExtra;
