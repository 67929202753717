import { config } from '~/services';
import apiCall from './apiCall';

const getUserSubscriptions = ({ accountGuid } = {}) =>
  apiCall(`${config.serviceApiUrl}/Subscriptions`, {
    withCredentials: true,
    params: {
      'api-version': '2.0',
      accountId: accountGuid,
    },
  });

export default getUserSubscriptions;
