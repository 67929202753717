/* eslint-disable import/max-dependencies */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Hidden } from '@mediahuis/chameleon-react-legacy';
import { ABOSHOP, ELOKET } from '~/components/CustomerServiceWidget/constants';
import { getSubscriptionUrl } from '~/components/CustomerServiceWidget/utils/getSubscriptionUrl';
import { config as configService } from '~/services';
import { ENTITIES } from '~/constants';
import getDomainInfo from './utils/getDomainInfo';
import getTodaysOpeningHours from './utils/getTodaysOpeningHours';
import getStatus from './utils/getStatus';
import getText from './utils/getText';
import buildContactItem from './utils/buildContactItem';
import Header from './Header';
import Footer from './Footer';
import openNormalChat from './utils/openNormalChat';
import { CHAT, EMAIL, PHONE, STATUSES } from './enums';
import Chatbot from './Chatbot';
import validateOpeninghours from './utils/validateOpeningHours';
import getPublicHolidayName from './utils/getPublicHolidayName';
import { jsonMainSettings } from './data';

const LiveChatWrapper = styled.div`
  position: fixed;
  z-index: 10;
  bottom: -5px;
  right: ${isMobile ? '0' : '2%'};
  margin: ${isMobile ? '0' : '0 20px 0 0'};
  width: ${isMobile && '100%'};
  max-width: ${!isMobile && '380px'};
`;

const CustomerServiceWidget = ({
  position = '',
  title = '',
  user = {},
  dropdownAlignment = 'left',
  chatIsOpen = false,
  destination = ELOKET,
  config,
}) => {
  const [isOnline, setIsOnline] = useState(true);
  const [isHoliday, setIsHoliday] = useState(false);
  const [isChatbotActive, setIsChatbotActive] = useState(false);
  const [isScriptLoading, setIsScriptLoading] = useState(true);
  const [customerServiceState, setCustomerServiceState] = useState({
    dToday: new Date(),
    domainInfo: getDomainInfo(
      config.siteUrl.split('.')[0],
      getSubscriptionUrl(config),
    ),
    chat: { bChatActive: false, link: `https://livechat.${config.siteUrl}` },
    phone: {},
    email: {},
    jsonSettings: {},
  });
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [isChatbot, setIsChatbot] = useState(false);

  const { dToday, domainInfo, chat, email, phone } = customerServiceState;
  const types = [PHONE, CHAT, EMAIL];

  const openLiveChat = ({ openAfterHours = false }) => {
    openNormalChat({
      link: `${chat.link}`,
      user,
      openAfterHours,
    });
    setIsChatWindowOpen(true);
  };

  const openChatbot = () => {
    setIsChatWindowOpen(true);
    setIsChatbotActive(true);
  };

  const openChat = () => {
    if (!isChatWindowOpen) {
      const chatSettings = customerServiceState.jsonSettings.chat;
      if (!chatSettings?.closed) {
        const isValidDaytime = validateOpeninghours(
          chatSettings?.openinghours.hours,
        );
        const openAfterHours = chatSettings?.openafterhours;
        if (isChatbot && isValidDaytime && !isHoliday) {
          openChatbot();
        } else if (isValidDaytime && !isHoliday) {
          openLiveChat({ openAfterHours: false });
        } else if (openAfterHours) {
          if (
            configService.entity === ENTITIES.MHBE &&
            customerServiceState.email.link
          ) {
            window.location = customerServiceState.email.link;
          } else {
            openLiveChat({ openAfterHours });
          }
        }
      }
    }
  };

  // Event handler for Live Chat.
  const receiveMessage = event => {
    if (event.data === 'chatStopped') {
      // If iFrame sends message with 'chatStopped',
      // then remove iFrame from DOM.
      const parentFrame = document.getElementById('livechat');
      const childFrame = document.getElementById('iframeLivechat');
      parentFrame.removeChild(childFrame);
      setIsChatWindowOpen(false);
    }
  };

  // Build widget contact items.
  const setContactItems = ({ widgetData }) => {
    const contactElement = types.map(type => {
      const lowerCaseType = type.toLowerCase();
      const objContactData = widgetData.jsonSettings[lowerCaseType];

      const todaysopeninghours = getTodaysOpeningHours({
        openingHours: widgetData.jsonSettings[lowerCaseType].openinghours.hours,
        dToday,
      });

      const strStatus = getStatus({
        strType: type,
        todaysopeninghours,
        isClosed: objContactData.closed,
        openinghours: objContactData.openinghours,
        dToday,
        widgetData,
      });

      const objDisplay = getText({
        strType: type,
        strStatus,
        todaysopeninghours,
        objContactData,
        bSubscriptionpage: domainInfo.subscriptionpage,
        dToday,
        widgetData,
      });

      const contactData = {
        output: {
          type,
          status: strStatus,
          subscriptionpage: domainInfo.subscriptionpage,
          display: objDisplay,
        },
        contactdetails: domainInfo[lowerCaseType],
        settings: objContactData,
      };

      const itemVisibilitySettings = buildContactItem({
        data: contactData,
      });

      const updatedContactData = {
        ...contactData,
        ...itemVisibilitySettings,
      };

      return updatedContactData;
    });

    // Must update out of .map because setCustomerServiceState
    // is to slow for mapper.
    setCustomerServiceState({
      ...customerServiceState,
      [types[0].toLowerCase()]: contactElement[0],
      [types[1].toLowerCase()]: contactElement[1],
      [types[2].toLowerCase()]: contactElement[2],
      jsonSettings: widgetData.jsonSettings,
    });

    // Check status of global active/inactive icon.
    const allStatusses = contactElement.map(element => {
      if (element.mayShow) {
        switch (element.output.status) {
          case STATUSES.CLOSED:
          case STATUSES.HOLIDAY:
          case STATUSES.OPENSOON:
          case STATUSES.RESTDAY:
          case STATUSES.CHATBOT:
            return 'closed';
          default:
            return 'open';
        }
      }
      return null;
    });

    const isClosed = allStatusses.every(
      value => value === 'closed' || value === null,
    );

    if (isClosed) {
      setIsOnline(false);
    } else if (!isOnline) {
      setIsOnline(true);
    }

    // Logic should run every so often to be as much up to date
    // with opening / closing hours of the services.
    if (jsonMainSettings.autoUpdate.active) {
      setTimeout(() => {
        setContactItems({ widgetData });
      }, jsonMainSettings.autoUpdate.interval * 1000);
    }
  };

  const getFetchUrl = () => {
    const apiPrefix = config.apiPrefix || 'production';
    if (MH_BRAND === BRANDS.DL && destination === ABOSHOP) {
      return `https://mh-subscriber-settings.s3.eu-west-1.amazonaws.com/public/${apiPrefix}/${ABOSHOP}/${MH_BRAND}/customer-service-widget.json`;
    } else if (MH_BRAND === BRANDS.DL && destination === ELOKET) {
      return `https://mh-subscriber-settings.s3.eu-west-1.amazonaws.com/public/${apiPrefix}/${ELOKET}/${MH_BRAND}/customer-service-widget.json`;
    }
    return `https://mh-subscriber-settings.s3.eu-west-1.amazonaws.com/public/${apiPrefix}/${MH_BRAND}/customer-service-widget.json`;
  };

  const { hash } = new URL(window.location);
  useEffect(() => {
    if (hash.includes('chat=1')) {
      openChat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  useEffect(() => {
    // Get widget settings.
    fetch(getFetchUrl())
      .then(response => response.json())
      .then(data => {
        const chatSettings = data?.jsonSettings?.chat || {};
        const queryParams = new URLSearchParams(window.location.search);
        const isOpenQueryParam =
          queryParams.get('chat') || hash.includes('chat=1');
        const isOpen = chatSettings.isOpen || isOpenQueryParam;

        const isValidDayTime = validateOpeninghours(
          chatSettings.openinghours?.hours,
        );
        const holidayName = getPublicHolidayName({
          dToday: customerServiceState.dToday,
          widgetData: data,
        });
        setIsHoliday(holidayName);
        setContactItems({ widgetData: data });
        // Check if chatbot is active, if not fallback to livechat
        if (data.jsonSettings?.settings?.chatbot?.active) {
          // Load Chatbot script and set isChatbot to true
          setIsChatbot(true);
          return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src =
              // eslint-disable-next-line no-template-curly-in-string
              `https://${config.apiPrefix}ifr-chatbot.${config.siteUrl}/js/chatbot.js?v=${chatSettings.chatbotScriptVersion}`;
            script.async = true;
            script.addEventListener('load', () => {
              setIsScriptLoading(false);
              resolve(script);
              if (
                !holidayName &&
                isOpen &&
                chatIsOpen &&
                isValidDayTime &&
                !chatSettings.closed &&
                chatSettings.visible
              ) {
                openChatbot();
              }
            });
            script.addEventListener('error', e => {
              reject(e);
            });
            document.body.appendChild(script);
          });
        }
        if (
          !holidayName &&
          isOpen &&
          chatIsOpen &&
          !chatSettings.closed &&
          chatSettings.visible &&
          isValidDayTime
        ) {
          openLiveChat({ openAfterHours: false });
        }
        return data;
      })
      .catch(() => {
        // If API call fails hide Chatbot
        setIsChatbot(false);
      });

    // EventListener which listens to Chatbot iFrame messages.
    window.addEventListener('message', receiveMessage, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO:: check what happens when you have footer and header
  // render so that live chat does not render twice.
  if (position === 'footer') {
    return (
      <React.Fragment>
        <Footer
          title={title}
          chat={chat}
          email={email}
          phone={phone}
          openChat={openChat}
          phoneSettings={customerServiceState.jsonSettings.phone}
          isChatbot={isChatbot}
        />
        <LiveChatWrapper id="livechat" />
        <Hidden screen={!isChatbotActive}>
          <Chatbot
            isScriptLoading={isScriptLoading}
            setIsChatbotActive={setIsChatbotActive}
            setIsChatWindowOpen={setIsChatWindowOpen}
          />
        </Hidden>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Header
        title={title}
        chat={chat}
        email={email}
        phone={phone}
        isOnline={isOnline}
        openChat={openChat}
        dropdownAlignment={dropdownAlignment}
        phoneSettings={customerServiceState.jsonSettings.phone}
      />
      <LiveChatWrapper id="livechat" />
      <Hidden screen={!isChatbotActive}>
        <Chatbot
          isScriptLoading={isScriptLoading}
          setIsChatbotActive={setIsChatbotActive}
          setIsChatWindowOpen={setIsChatWindowOpen}
        />
      </Hidden>
    </React.Fragment>
  );
};

CustomerServiceWidget.propTypes = {
  position: PropTypes.string,
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
};

export default CustomerServiceWidget;
