import {
  Box,
  Heading,
  LinkText,
  WideList,
  WideListItem,
} from '@mediahuis/chameleon-react-legacy';
import React from 'react';
import { getCustomerRights } from '~/components/ah/AhSubscriptionSelect/util/rights';
import { APORTAL_PAYER_READER_STATUS } from '~/constants/aportal';
import { useGlobalContext } from '~/context';
import { formatPhoneNumbers } from '~/pages/Subscription/ah/utils/contactDetails';
import { getFullNameOfCustomerField } from '~/pages/Subscription/ah/utils/fields';
import { DIALOG_TYPES } from '~/pages/Subscription/constants';
import { ts } from '~/services';

export default function ContactDetailsSection({ handleClick }) {
  const { chosenUserSubscription, userModuleRights } = useGlobalContext();
  const payer = chosenUserSubscription?.payer;
  const receiver = chosenUserSubscription?.receiver;
  const receiverFullName = getFullNameOfCustomerField(receiver);
  const payerFullName = getFullNameOfCustomerField(payer);

  const showPayerPhoneNumbers = receiverFullName !== payerFullName;
  const payerHasPhoneNumbers = payer?.contactPointsPhone?.some(
    phoneNumber => phoneNumber.phone,
  );

  const showReceiverPhoneNumbers = receiver;
  const receiverHasPhoneNumbers = receiver?.contactPointsPhone?.some(
    phoneNumber => phoneNumber.phone,
  );
  const hasTheRightToChangePayerPhoneNumber = getCustomerRights({
    customerId: payer?.id,
    customersRights: userModuleRights?.customers,
  })?.phoneChange?.access;

  const hasTheRightToChangeReceiverPhoneNumber = getCustomerRights({
    customerId: receiver?.id,
    customersRights: userModuleRights?.customers,
  })?.phoneChange?.access;

  const noPhoneNumbers = ts.t('Subscription.NoPhoneNumbers');

  return (
    <>
      <Box mb={6}>
        <Heading level={3}>{ts.t('Subscription.ContactDetails')}</Heading>
      </Box>
      <Box mb={8}>
        <WideList>
          {showReceiverPhoneNumbers && (
            <WideListItem
              title={receiverFullName}
              loading={!chosenUserSubscription}
              description={
                receiverHasPhoneNumbers
                  ? formatPhoneNumbers(
                      chosenUserSubscription?.receiver?.contactPointsPhone,
                    )
                  : noPhoneNumbers
              }
              action={
                hasTheRightToChangeReceiverPhoneNumber && (
                  <LinkText
                    onClick={() =>
                      handleClick({
                        type: DIALOG_TYPES.PHONE_NUMBERS,
                      })
                    }
                  >
                    {ts.t('Common.Edit')}
                  </LinkText>
                )
              }
            />
          )}
          {showPayerPhoneNumbers && (
            <WideListItem
              title={payerFullName}
              loading={!chosenUserSubscription}
              description={
                payerHasPhoneNumbers
                  ? formatPhoneNumbers(
                      chosenUserSubscription?.payer?.contactPointsPhone,
                    )
                  : noPhoneNumbers
              }
              action={
                hasTheRightToChangePayerPhoneNumber && (
                  <LinkText
                    onClick={() =>
                      handleClick({
                        type: DIALOG_TYPES.PHONE_NUMBERS,
                        payerReaderStatus: APORTAL_PAYER_READER_STATUS.IS_PAYER,
                      })
                    }
                  >
                    {ts.t('Common.Edit')}
                  </LinkText>
                )
              }
            />
          )}
        </WideList>
      </Box>
    </>
  );
}
