import ahApiCall from '~/api/ah/ahApiCall';
import { config } from '~/services';

const postTemporaryDeliveryChange = async ({
  subscriptionId,
  body,
  temporaryDeliveryChangeId,
}) => {
  const url = temporaryDeliveryChangeId
    ? `${config.ahServiceApiUrl}/subscriptions/${subscriptionId}/temporary-delivery-changes/${temporaryDeliveryChangeId}/update`
    : `${config.ahServiceApiUrl}/subscriptions/${subscriptionId}/temporary-delivery-changes`;

  await ahApiCall(url, {
    method: 'POST',
    data: JSON.stringify(body),
  });
};

export default postTemporaryDeliveryChange;
