import {
  Box,
  Button,
  Fieldset,
  Flex,
  Heading,
  Radio,
  Select,
  TextField,
} from '@mediahuis/chameleon-react-legacy';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import React, { useState } from 'react';
import useBanner from '~/context/hooks/useBanner';
import { BANNER_TYPES } from '~/constants/messages';
import { ts } from '~/services';

const AccountDialog = ({ setDialogShown, reasons }) => {
  const { showBanner } = useBanner();
  const [account, setAccount] = useState({
    gender: '',
    firstName: '',
    lastName: '',
    reason: '',
  });

  const handleSave = () => {
    setDialogShown(false);
    showBanner({
      type: BANNER_TYPES.SUCCESS,
      message: ts.t(
        'Subscription.AccountDialog.TransferSubscription.SuccessMessage',
      ),
    });
  };

  return (
    <React.Fragment>
      <Heading level={4} mb={5} color="primaryBase">
        {ts.t('Subscription.AccountDialog.TransferSubscription.Title')}
      </Heading>
      <Fieldset
        label={ts.t('Subscription.AccountDialog.TransferSubscription.Question')}
      >
        <Box key="Male" mb={5}>
          <Radio
            name="gender"
            id="label-male"
            value="male"
            label={ts.t('Subscription.AccountDialog.Gender.Male')}
            checked={account.gender === 'male'}
            onChange={e => setAccount({ ...account, gender: e.target.value })}
          />
        </Box>
        <Box key="Female" mb={5}>
          <Radio
            name="gender"
            id="label-female"
            value="female"
            label={ts.t('Subscription.AccountDialog.Gender.Female')}
            checked={account.gender === 'female'}
            onChange={e => setAccount({ ...account, gender: e.target.value })}
          />
        </Box>
      </Fieldset>
      <Flex>
        <Box mr={3} flex={1}>
          <TextField
            id="firstName"
            label={ts.t('Subscription.AccountDialog.FirstName')}
            mb={5}
            onChange={e =>
              setAccount({
                ...account,
                firstName: e.target.value,
              })
            }
          />
        </Box>
        <Box ml={3} flex={1}>
          <TextField
            id="lastName"
            label={ts.t('Subscription.AccountDialog.LastName')}
            mb={5}
            onChange={e => setAccount({ ...account, lastName: e.target.value })}
          />
        </Box>
      </Flex>
      <Select
        id="reason"
        label={ts.t('Subscription.AccountDialog.Reason')}
        onChange={e => setAccount({ ...account, reason: e.target.value })}
        value={account.reason}
        mb={5}
      >
        {reasons.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
      <Button onClick={handleSave} iconRight={ChevronForward}>
        {ts.t('Subscription.AccountDialog.SubmitTransfer')}
      </Button>
    </React.Fragment>
  );
};

export default AccountDialog;
