import { useCallback, useEffect } from 'react';
import useBanner from '~/context/hooks/useBanner';
import { PAYMENT_REFERENCE } from '~/constants/payment';
import { getPaymentStatus, handleBannerMessage } from '~/utils';
import {
  MOLLIE_BANNER_MESSAGES,
  MOLLIE_BANNER_TYPES,
} from '~/constants/messages';

const useMollieStatus = () => {
  const { showBanner } = useBanner();

  const fetchStatus = useCallback(async () => {
    const paymentReference = sessionStorage.getItem(PAYMENT_REFERENCE);

    if (paymentReference) {
      try {
        sessionStorage.removeItem(PAYMENT_REFERENCE);
        const data = await getPaymentStatus(paymentReference);
        const { status } = data;
        const type = MOLLIE_BANNER_TYPES[status];
        const message = MOLLIE_BANNER_MESSAGES[status];
        if (type && message) {
          showBanner({ type, message });
        }
        return data;
      } catch (err) {
        handleBannerMessage({ err, showBanner });
      }
    }
    return undefined;
  }, [showBanner]);

  useEffect(() => {
    fetchStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStatus]);
};

export default useMollieStatus;
