import { config } from '~/services';
import { apiCall } from '~/utils';

const cancelSubscritpion = async ({ subscriptionId, body }) => {
  await apiCall(
    `${config.serviceApiUrl}/subscriptions/${subscriptionId}/cancel?api-version=2`,
    {
      method: 'POST',
      data: JSON.stringify(body),
      withCredentials: true,
    },
  );
};

export default cancelSubscritpion;
