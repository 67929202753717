import { Loader } from '@mediahuis/chameleon-react-legacy';
import isEmpty from 'lodash.isempty';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '~/context';
import { authService } from '~/services';

const AuthCallback = () => {
  const globalState = useGlobalContext();
  const history = useHistory();
  const { setGlobalState, userInfo } = globalState;

  useEffect(() => {
    if (isEmpty(userInfo)) {
      setGlobalState({ isFetchingUser: true });
      authService
        .authenticate(history)
        .then(token => {
          const newUserInfo = {
            accountGuid: token.idToken.sub,
            name: token.idToken.family_name,
            firstName: token.idToken.given_name,
          };
          setGlobalState({
            userInfo: newUserInfo,
            isFetchingUser: false,
          });
          return newUserInfo;
        })
        .catch(e => {
          setGlobalState({ isFetchingUser: false });
          throw e;
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <Loader />;
};

export default AuthCallback;
