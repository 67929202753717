import styled from 'styled-components';
import { Box } from '@mediahuis/chameleon-react-legacy';

const Grid = styled(Box)`
  display: grid;
  grid-gap: 60px 40px;
  ${props =>
    props.gridTemplateColumns &&
    `
    grid-template-columns: repeat(${props.gridTemplateColumns});
  `}
`;

export default Grid;
