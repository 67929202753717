import { config } from '~/services';
import ahApiCall from '~/api/ah/ahApiCall';

const getTemporaryDeliveryChangesOptionsTypes = async ({ subscriptionId }) => {
  const options = await ahApiCall(
    `${config.ahServiceApiUrl}/subscriptions/${subscriptionId}/temporary-delivery-changes/options/types`,
  );

  return options;
};

export default getTemporaryDeliveryChangesOptionsTypes;
