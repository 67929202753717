import { BANNER_TYPES, ERRORS } from '~/constants/messages';
import defaultError from '~/utils/defaultError';

const handleBannerMessage = ({ err, showBanner }) => {
  showBanner({
    message:
      err?.status === 400 && err?.errors?.[0]
        ? ERRORS[err.errors[0]]
        : defaultError,
    type: BANNER_TYPES.ERROR,
  });
};

export default handleBannerMessage;
