/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import { AH_HOLIDAY_SOLUTIONS, HOLIDAY_STEPS } from '~/pages/Holiday/data';
import getEmptySolutionData from '~/pages/Holiday/utils/getEmptySolutionData';
import AhAddressStep from '~/pages/Holiday/components/ah/AhAddressStep';
import getWebformStartDate from './utils/getWebformStartDate';
import AhWebformChoiceStep from './steps/AhWebformChoiceStep';
import getWebformEndDate from './utils/getWebformEndDate';
import AhWebformSummaryStep from './steps/AhWebformSummaryStep';

const AhWebformDialog = ({
  isDialogShown,
  setIsDialogShown,
  currentStep,
  setCurrentStep,
}) => {
  const globalState = useGlobalContext();

  const defaultStartDate = getWebformStartDate();

  const { chosenUserSubscription } = globalState;
  const defaultState = {
    id: '',
    start: defaultStartDate,
    end: getWebformEndDate(defaultStartDate),
    solutionData: getEmptySolutionData(false),
    subscriptionId: chosenUserSubscription.id,
    state: '',
    vacationSolution: AH_HOLIDAY_SOLUTIONS.CONTINUE_DIGITAL,
  };
  const [submissionData, setSubmissionData] = useState(defaultState);
  const resetSubmissionData = () => {
    setSubmissionData(defaultState);
  };
  const [
    selectedDeliveryOptionBySubscription,
    setSelectedDeliveryOptionBySubscription,
  ] = useState(null);

  return (
    <React.Fragment>
      {currentStep === HOLIDAY_STEPS.CHOICE_STEP && (
        <AhWebformChoiceStep
          submissionData={submissionData}
          setSubmissionData={setSubmissionData}
          setCurrentStep={setCurrentStep}
          isDialogShown={isDialogShown}
        />
      )}
      {currentStep === HOLIDAY_STEPS.ADDRESS_STEP && (
        <AhAddressStep
          submissionData={submissionData}
          setSubmissionData={setSubmissionData}
          setCurrentStep={setCurrentStep}
          selectedDeliveryOptionBySubscription={
            selectedDeliveryOptionBySubscription
          }
          setSelectedDeliveryOptionBySubscription={
            setSelectedDeliveryOptionBySubscription
          }
          setIsDialogShown={setIsDialogShown}
        />
      )}
      {currentStep === HOLIDAY_STEPS.SUMMARY_STEP && (
        <AhWebformSummaryStep
          submissionData={submissionData}
          setSubmissionData={setSubmissionData}
          setCurrentStep={setCurrentStep}
          setIsDialogShown={setIsDialogShown}
          selectedDeliveryOptionBySubscription={
            selectedDeliveryOptionBySubscription
          }
          resetSubmissionData={resetSubmissionData}
        />
      )}
    </React.Fragment>
  );
};

export default AhWebformDialog;
