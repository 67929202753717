import styled from 'styled-components';

const FormGroup = styled.div`
  width: ${props => (props.width || 1) * 100}%;
  flex: 0 1 auto;
  margin-bottom: 20px;
  position: relative;
  margin-right: 10px;

  &:last-of-type {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

export default FormGroup;
