import React from 'react';
import { TextField } from '@mediahuis/chameleon-react-legacy';
import language from './language';

const HouseNumberTextField = ({
  validateOnBlur,
  clearError,
  houseNumber,
  errors,
  disabled = false,
  onChange,
  maxLength = 10,
}) => {
  // validateHouseNumber will return a value that matches any numbers + 1 optional alphanumerical character at the end of the input
  const validateHouseNumber = value => {
    const matchedHouseNumber = value.match(/\d+[a-zA-Z]/g);
    return matchedHouseNumber
      ? matchedHouseNumber[0]
      : value.replace(/\D/g, '');
  };

  return (
    <TextField
      label={language.houseNumber}
      data-testid="input-houseNumber"
      id="houseNumber"
      onChange={event => {
        clearError(event);
        const validatedHouseNumber = validateHouseNumber(event.target.value);
        onChange({ houseNumber: validatedHouseNumber });
      }}
      onBlur={validateOnBlur}
      onFocus={clearError}
      value={houseNumber}
      message={errors.houseNumber}
      error={!!errors.houseNumber}
      disabled={disabled}
      autoComplete="new-houseNumber"
      maxlength={maxLength}
    />
  );
};

export default HouseNumberTextField;
