import React, { useEffect, useState } from 'react';
import {
  Banner,
  Box,
  Button,
  DatePicker,
  Flex,
  Select,
} from '@mediahuis/chameleon-react-legacy';
import { ts } from '~/services';
import styled from 'styled-components';
import findIndex from 'lodash.findindex';
import dayjs from 'dayjs';
import { DATE_FORMAT_BE } from '~/constants';
import { APORTAL_PAYER_READER_STATUS } from '~/constants/aportal';

const SearchWrapper = styled.div`
  display: flex;
`;

const SearchButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const ChangeStartDate = ({
  deliveryOptions,
  customerDetails,
  payerReaderStatus,
  handleSubmit,
  startDateOptions = {},
  defaultStartDate,
  isHandlingSubmit,
}) => {
  const [date, setDate] = useState();
  const [deliveryMethod, setDeliveryMethod] = useState('');
  const hasStartDates = Object.values(startDateOptions)?.some(
    startDate => startDate?.selectable,
  );
  useEffect(() => {
    const index = findIndex(deliveryOptions, x => x.isDefault);
    if (index !== -1) setDeliveryMethod(deliveryOptions[index].type.code);
    setDate(new Date(defaultStartDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryOptions]);

  const getStartDateDisabledDays = day => {
    const formattedDay = dayjs(day).format(DATE_FORMAT_BE);
    const isDaySelectable = startDateOptions?.[formattedDay]?.selectable;
    return !isDaySelectable;
  };
  const errorBanner = !hasStartDates && (
    <Banner mb={5} show closeHidden appearance="error">
      {ts.t('Subscription.NoStartDates')}
    </Banner>
  );

  if (!customerDetails) return null;

  if (payerReaderStatus === APORTAL_PAYER_READER_STATUS.IS_PAYER) {
    return (
      <Box mb={7} mt={7}>
        {errorBanner}
        <SearchWrapper>
          <DatePicker
            style={{ flex: 1 }}
            id="datepicker-payer"
            data-testid="datepicker-payer"
            label={ts.t('Subscription.StartDateOfTheChange')}
            onChange={x => setDate(x)}
            value={date}
            disabledDays={day => getStartDateDisabledDays(day)}
            disabled={!hasStartDates}
          />
          <SearchButtonWrapper>
            <Button
              style={{ alignSelf: 'flex-end' }}
              onClick={() => handleSubmit({ startDate: date, deliveryMethod })}
              loading={isHandlingSubmit}
              disabled={isHandlingSubmit || !hasStartDates}
            >
              {ts.t('Subscription.Confirm')}
            </Button>
          </SearchButtonWrapper>
        </SearchWrapper>
      </Box>
    );
  }
  return (
    <>
      {errorBanner}
      <Flex mt={7} mb={7} justifyContent="space-between" style={{ gap: 25 }}>
        <DatePicker
          style={{ flex: 1 }}
          id="datepicker-reader"
          label={ts.t('Subscription.StartDateOfTheChange')}
          onChange={x => setDate(x)}
          value={date}
          disabledDays={day => getStartDateDisabledDays(day)}
          disabled={!hasStartDates}
        />
        <Select
          style={{ flex: 1 }}
          id="select-delivery-method-read"
          value={deliveryMethod}
          onChange={e => setDeliveryMethod(e.target.value)}
          label={ts.t('Subscription.DeliveryMethod')}
        >
          {deliveryOptions.map(x => (
            <option key={x?.type?.code} value={x?.type?.code}>
              {x?.type?.label || x?.type?.code}
            </option>
          ))}
        </Select>
      </Flex>
      <Flex justifyContent="space-between">
        <div></div>
        <Button
          style={{ alignSelf: 'flex-end' }}
          onClick={() => handleSubmit({ startDate: date, deliveryMethod })}
          loading={isHandlingSubmit}
          disabled={isHandlingSubmit || !hasStartDates}
        >
          {ts.t('Subscription.Confirm')}
        </Button>
      </Flex>
    </>
  );
};

export default ChangeStartDate;
