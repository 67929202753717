import React, { useEffect, useState } from 'react';
import { Flex, Box, Icon, Heading } from '@mediahuis/chameleon-react-legacy';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  motionDurationMedium,
  space3,
  colorSecondaryBase,
} from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';
import { Warning } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import { ts } from '~/services';
import MarkdownRender from '~/components/MarkdownRender';
import getServiceMessage from '../../utils/getServiceMessage';

const StyledBox = styled(Box)`
  @keyframes transtitionOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: ${motionDurationMedium} transtitionOpacity;
  border: 2px solid ${colorSecondaryBase};
`;

const MarkDownContainter = styled.div`
  p {
    margin-bottom: ${space3};
    margin-top: 0;
  }
`;
const ServiceMessage = ({ mb = 0, page }) => {
  const { pathname } = useLocation();
  const [serviceMessage, setServiceMessage] = useState(false);

  useEffect(() => {
    getServiceMessage({
      page,
    })
      .then(response => setServiceMessage(response))
      .catch(() => setServiceMessage(false));
  }, [page, pathname]);

  if (!serviceMessage) return null;

  return (
    <StyledBox p={6} mb={mb}>
      <Flex alignItems="center" pb={4}>
        <Box mr={3}>
          <Icon as={Warning} />
        </Box>
        <Heading level={3} fontWeight="bold">
          {ts.t('ServiceMessage.Title')}
        </Heading>
      </Flex>
      <MarkDownContainter>
        <MarkdownRender source={serviceMessage} />
      </MarkDownContainter>
    </StyledBox>
  );
};

export default ServiceMessage;
