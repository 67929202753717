import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  TextField,
} from '@mediahuis/chameleon-react-legacy';
import { colorGrey30 } from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';
import React, { useEffect, useState } from 'react';
import { useBanner, useGlobalContext } from '~/context';
import { getFullNameOfCustomerField } from '~/pages/Subscription/ah/utils/fields';
import { ts } from '~/services';
import 'react-phone-number-input/style.css';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'; //   getCountryCallingCode, //   getCountries,
import styled from 'styled-components';
import updatePhoneNumbers from '~/api/ah/updatePhoneNumbers';
import { BANNER_TYPES } from '~/constants';
import {
  mapPhoneNumbers,
  mapToContactPointsPhone,
} from '~/pages/Subscription/ah/utils/contactDetails';
import nl from 'react-phone-number-input/locale/nl.json';
import en from 'react-phone-number-input/locale/en.json';
import de from 'react-phone-number-input/locale/de.json';
import pt from 'react-phone-number-input/locale/pt.json';

export const StyledFlex = styled(Flex)`
  .PhoneInputCountry {
    position: absolute;
    bottom: ${({ error }) => (error ? '21px' : 0)};
    z-index: 1;
    height: 48px;
    margin-left: 6px;

    &:after {
      content: '';
      border-right: 1px solid ${colorGrey30};
      position: absolute;
      top: 0px;
      bottom: 0px;
      margin: 2px 0;
      right: -15px;
    }
  }
  .PhoneInput {
    width: 100%;
  }
  input {
    padding-left: 64px;
  }

  .PhoneInputCountrySelectArrow {
    border-color: #000000;
    opacity: 1;
  }
`;

export default function PhoneNumbersDialog({
  editPayerPhoneNumbers,
  setDialogShown,
  dialogShown,
}) {
  const { chosenUserSubscription, setGlobalState } = useGlobalContext();
  const { showBanner } = useBanner();

  const customer = editPayerPhoneNumbers
    ? chosenUserSubscription?.payer
    : chosenUserSubscription?.receiver;

  const fullName = getFullNameOfCustomerField(customer);

  const [phoneNumbers, setPhoneNumbers] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const defaultPhoneNumber = {
      countryCallingPrefix: null,
      phone: null,
    };
    const defaultState = {
      fixedPhone1: defaultPhoneNumber,
      fixedPhone2: defaultPhoneNumber,
      mobilePhone1: defaultPhoneNumber,
      mobilePhone2: defaultPhoneNumber,
    };
    if (!customer?.contactPointsPhone?.length) {
      setPhoneNumbers(defaultState);
      return;
    }
    const state = customer?.contactPointsPhone?.reduce((acc, val) => {
      return {
        ...acc,
        [val.purpose]: {
          ...val,
          phone: val.countryCallingPrefix + val.phone || null,
        },
      };
    }, defaultState);
    setPhoneNumbers(state);
    setErrors({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.id, chosenUserSubscription?.id, dialogShown]);

  const onSubmit = async e => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const mappedPhoneNumbers = mapPhoneNumbers(phoneNumbers);
      await updatePhoneNumbers({
        customerId: customer.id,
        body: mappedPhoneNumbers,
      });
      const customerKey = editPayerPhoneNumbers ? 'payer' : 'receiver';
      const updatedChosenUserSubscription = {
        chosenUserSubscription: {
          ...chosenUserSubscription,
          [customerKey]: {
            ...chosenUserSubscription[customerKey],
            contactPointsPhone: mapToContactPointsPhone(mappedPhoneNumbers),
          },
        },
      };
      setGlobalState(updatedChosenUserSubscription);

      showBanner({
        type: BANNER_TYPES.SUCCESS,
        message: <Text>{ts.t('PhoneNumbersDialog.SuccessMessage')}</Text>,
      });
    } catch (err) {
      showBanner({
        message: ts.t('PhoneNumbersDialog.ErrorMessage'),
        type: BANNER_TYPES.ERROR,
      });
    } finally {
      setIsLoading(false);
      setDialogShown(false);
    }
  };
  const buttonIsDisabled = Object.values(errors).filter(Boolean)?.length;
  const locales = { nl, de, pt, en };
  const language = ts.getLanguage().substring(0, 2) || 'nl';

  return (
    <Box>
      <Heading level={4} mb={5} color="primaryBase">
        {ts.t('PhoneNumbersDialog.Title')}
      </Heading>
      <Heading level={5} mb={5}>
        {fullName}
      </Heading>
      <form onSubmit={onSubmit}>
        {Object.entries(phoneNumbers).map(([purpose, phoneNumber]) => {
          const label = purpose.includes('Fixed')
            ? ts.t('PhoneNumbersDialog.Labels.fixed')
            : ts.t('PhoneNumbersDialog.Labels.mobile');
          return (
            <StyledFlex
              key={purpose}
              position="relative"
              mb={3}
              width="100%"
              error={!!errors[purpose]}
            >
              <PhoneInput
                placeholder=" XXX / XX  XX  XX "
                value={phoneNumber?.phone}
                label={label}
                name={purpose}
                labels={locales[language]}
                onChange={value =>
                  setPhoneNumbers(prevState => {
                    return {
                      ...prevState,
                      [purpose]: {
                        ...prevState[purpose],
                        phone: value || null,
                      },
                    };
                  })
                }
                onBlur={() => {
                  setErrors(prevState => {
                    const errorMessage =
                      phoneNumber?.phone &&
                      !isPossiblePhoneNumber(phoneNumber.phone)
                        ? ts.t('PhoneNumbersDialog.InputError')
                        : '';
                    return {
                      ...prevState,
                      [purpose]: errorMessage,
                    };
                  });
                }}
                international
                defaultCountry="LU"
                inputComponent={TextField}
                countryOptionsOrder={['BE', 'FR', 'DE', 'LU', 'NL']}
                id={purpose}
                error={!!errors[purpose]}
                message={errors[purpose]}
                required={false}
              />
            </StyledFlex>
          );
        })}
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            mt={5}
            data-testid="submit-phone-numbers"
            loading={isLoading}
            disabled={buttonIsDisabled}
          >
            {ts.t('Common.Confirm')}
          </Button>
        </Flex>
      </form>
    </Box>
  );
}
