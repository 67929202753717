import React from 'react';
import {
  Box,
  Flex,
  Heading,
  useMediaQuery,
} from '@mediahuis/chameleon-react-legacy';
import { space7 } from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';
import { ICON_FILL } from '~/components/SelfHelp/Icon';
import { config, ts } from '~/services';
import { ENTITIES } from '~/constants';
import { Holiday } from '~/svg/Icons';
import getAhNotAvailabelCopy from '~/pages/Holiday/utils/ah/getAhNotAvailabelCopy';
import getNotAvailableCopy from '../utils/getNotAvailableCopy';

const NotAvailable = ({ chosenUserSubscription = [] }) => {
  const copy =
    config.entity === ENTITIES.MHLU
      ? getAhNotAvailabelCopy({ chosenUserSubscription })
      : getNotAvailableCopy({
          chosenUserSubscription,
        });
  return (
    <Flex
      alignItems="center"
      flexDirection={useMediaQuery({ md: 'row' }, 'column')}
    >
      <Box
        width={useMediaQuery({ sm: '50%', lg: '75%' }, '75%')}
        mr={useMediaQuery({ md: space7 }, 0)}
        mb={useMediaQuery({ md: 0 }, space7)}
        style={{ fill: ICON_FILL[config.brand] }}
      >
        <Holiday />
      </Box>
      <Box width="100%">
        <Heading level={3} fontFamily="primary" mb={3}>
          {ts.t('Holiday.ServiceNotAvailable')}
        </Heading>
        {copy}
      </Box>
    </Flex>
  );
};

export default NotAvailable;
