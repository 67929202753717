import { isMobile } from 'react-device-detect';
import getChatLink from './getChatLink';

const openNormalChat = ({ link, user, openAfterHours }) => {
  const iframeLivechat = document.createElement('iframe');
  iframeLivechat.id = 'iframeLivechat';
  iframeLivechat.style.cssText =
    'position: relative; border: 0 !important; height:600px;';
  iframeLivechat.style.cssText += isMobile ? 'width: 100%;' : 'width: 390px;';
  iframeLivechat.src = getChatLink({ link, user, openAfterHours });
  document.getElementById('livechat').appendChild(iframeLivechat);
};

export default openNormalChat;
