import { environment, config } from '~/services';

export const links = [
  {
    text: 'Prijswinnaars',
    url: `https://${environment.sitePrefix}.${config.siteUrl}/prijswinnaars`,
  },
  {
    text: 'Filippine puzzel',
    url: `https://www.${config.siteUrl}/filippinepuzzel`,
  },
  {
    text: 'Lezerspanel',
    url: 'https://q.crowdtech.com/uHVJ8bUZO0W-IoYIH3zR2Q',
  },
  {
    text: 'Nieuws in de klas',
    url:
      'https://www.nieuwsindeklas.nl/?utm_source=service.limburger&utm_medium=crosspromo',
  },
  {
    text: 'Familieberichten en rubrieksadvertenties',
    url: `https://service.${config.siteUrl}/overige/familieberichten-en-rubrieksadvertenties`,
  },
  {
    text: 'Uitagenda',
    url: 'https://www.uittipslimburg.nl/',
  },
  {
    text: 'Foto nabestellen uit de krant',
    url: `https://${environment.sitePrefix}.${config.siteUrl}/foto-nabestellen`,
  },
];
