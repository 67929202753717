import React, { useEffect } from 'react';
import {
  Box,
  Paragraph,
  Button,
  DatePicker,
  Fieldset,
  Flex,
  Heading,
  useMediaQuery,
  Banner,
  Text,
} from '@mediahuis/chameleon-react-legacy';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import isEmpty from 'lodash.isempty';
import { config, ts } from '~/services';
import EntitySwitch from '~/components/ah/EntitySwitch';
import AhDisabledSubscriptionInfo from '~/components/ah/AhDisabledSubscriptionInfo';
import { UPDATE_POSSIBILITIES } from '~/pages/Holiday/data';
import HolidayResolutions from '../HolidayResolutions';
import DisabledSubscriptionInfo from '../../../../../components/DisabledSubscriptionInfo';

export default function BaseChoiceStep({
  submissionData,
  setSubmissionData,
  isDialogShown,
  onStartDateChange,
  onEndDateChange,
  getStartDateDisabledDays,
  getEndDateDisabledDays,
  errors,
  setErrors,
  isStartDateDisabled,
  isEndDateDisabled,
  isAhWebForm = false,
  onNextStep,
  isLoadingAddresses = false,
}) {
  useEffect(() => {
    // closing dialog resets errors
    if (!isDialogShown) setErrors({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogShown]);

  const isEndDateEmpty = () => !submissionData?.end;

  const isEditting = !!submissionData?.id;
  const startAndEndDateInputs = (
    <Flex
      justifyContent="space-between"
      flexDirection={useMediaQuery(
        {
          md: 'row',
        },
        'column',
      )}
      pb={6}
      width="full"
    >
      <Box
        width={useMediaQuery(
          {
            md: '48%',
          },
          '100%',
        )}
        mb={useMediaQuery(
          {
            md: 0,
          },
          5,
        )}
      >
        <DatePicker
          pickerInputOnly
          id="start-vacation-period"
          data-testid="start-vacation-period"
          label={ts.t('Holiday.ChoiceStep.StartDate')}
          onChange={onStartDateChange}
          error={errors.startDate}
          message={
            errors.startDate
              ? errors.startDate
              : ts.t('Holiday.ChoiceStep.StartDateHelperText')
          }
          value={submissionData.start}
          disabledDays={getStartDateDisabledDays}
          disabled={isStartDateDisabled}
        />
      </Box>
      <Box
        width={useMediaQuery(
          {
            md: '48%',
          },
          '100%',
        )}
      >
        <DatePicker
          pickerInputOnly
          id="end-vacation-period"
          data-testid="end-vacation-period"
          label={ts.t('Holiday.ChoiceStep.EndDate')}
          onChange={onEndDateChange}
          error={errors.endDate}
          message={errors.endDate}
          value={submissionData.end}
          disabledDays={getEndDateDisabledDays}
          disabled={isEndDateDisabled}
          key={submissionData.end}
        />
      </Box>
    </Flex>
  );

  const showPartiallyUpdateNotification =
    submissionData.updatePossibility === UPDATE_POSSIBILITIES.PARTIALLY;

  return (
    <>
      <Heading
        level={3}
        mb={6}
        fontFamily="primary"
        color={config.isAportal ? 'colorSecondaryBase' : 'colorPrimaryBase'}
      >
        {isEditting ? ts.t('Holiday.EditHoliday') : ts.t('Holiday.NewHoliday')}
      </Heading>
      <Box mb={5}>
        <EntitySwitch
          defaultComponent={<DisabledSubscriptionInfo />}
          mhlu={<AhDisabledSubscriptionInfo />}
        />
      </Box>
      {!config.isAportal && startAndEndDateInputs}
      {!isAhWebForm && (
        <Paragraph mb={5}>{ts.t('Holiday.ChoiceStep.Description')}</Paragraph>
      )}
      <Fieldset
        labelProps={{
          size: 'Heading6',
          fontWeight: config.isAportal ? 'medium' : 'regular',
        }}
        label={ts.t('Holiday.ChoiceStep.ResolutionFieldSetLabel')}
      >
        <Box pb={6}>
          <HolidayResolutions
            chosenResolution={submissionData.vacationSolution}
            setSubmissionData={setSubmissionData}
          />
        </Box>
      </Fieldset>
      {config.isAportal && startAndEndDateInputs}
      {showPartiallyUpdateNotification && (
        <Banner appearance="info" show closeHidden mb={7}>
          <Text size="Heading6">
            {ts.t('Holiday.ChoiceStep.PartiallyUpdateNotification')}
          </Text>
        </Banner>
      )}

      <Flex justifyContent="flex-end" mb={5}>
        <Button
          onClick={onNextStep}
          iconRight={ChevronForward}
          disabled={!isEmpty(errors) || isEndDateEmpty()}
          loading={isLoadingAddresses}
        >
          {isEditting ? ts.t('Common.Edit') : ts.t('Common.Next')}
        </Button>
      </Flex>
    </>
  );
}
