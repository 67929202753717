import { ts } from '~/services/translations';
import { Helmet } from 'react-helmet';
import React from 'react';
import { PAGES } from '../pages';

export const METADATA = {
  [PAGES.deliveryComplaint]: (
    <Helmet>
      <title>{ts.t('Metadata.DeliveryComplaint.Title')}</title>
      <meta
        name="description"
        content={ts.t('Metadata.DeliveryComplaint.Content')}
      />
    </Helmet>
  ),
  [PAGES.readDigital]: (
    <Helmet>
      <title>{ts.t('Metadata.ReadDigital.Title')}</title>
      <meta name="description" content={ts.t('Metadata.ReadDigital.Content')} />
    </Helmet>
  ),
  [PAGES.subscription]: (
    <Helmet>
      <title>{ts.t('Metadata.Subscription.Title')}</title>
      <meta
        name="description"
        content={ts.t('Metadata.Subscription.Content')}
      />
    </Helmet>
  ),
  [PAGES.holiday]: (
    <Helmet>
      <title>{ts.t('Metadata.Holiday.Title')}</title>
      <meta name="description" content={ts.t('Metadata.Holiday.Content')} />
    </Helmet>
  ),
  [PAGES.other]: (
    <Helmet>
      <title>{ts.t('Metadata.Home.Title')}</title>
      <meta name="description" content={ts.t('Metadata.Home.Content')} />
    </Helmet>
  ),
  [PAGES.faq]: (
    <Helmet>
      <title>{ts.t('Metadata.Home.Title')}</title>
      <meta name="description" content={ts.t('Metadata.Home.Content')} />
    </Helmet>
  ),
  [PAGES.home]: (
    <Helmet>
      <title>{ts.t('Metadata.Home.Title')}</title>
      <meta name="description" content={ts.t('Metadata.Home.Content')} />
    </Helmet>
  ),
};
