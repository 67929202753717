import dayjs from 'dayjs';
import { BRANDS } from '~/constants';
import { config, ts } from '~/services';

const getNotReceivedMessage = (chosenDate = new Date()) => {
  const now = dayjs();
  const date = dayjs(chosenDate);
  const isToday = date.isSame(now, 'day');

  if (isToday) {
    const day = date.day();
    const weekDays = [1, 2, 3, 4, 5];
    const saturday = 6;

    const hour = now.hour();
    const minute = now.minute();

    const isBefore7h30 = hour < 7 || (hour === 7 && minute < 30);
    const isWeekdayLimit = config.brand === BRANDS.DL ? hour < 7 : isBefore7h30;
    const isSaturdayLimit = config.brand === BRANDS.DL ? hour < 8 : hour < 10;

    if (weekDays.includes(day) && isWeekdayLimit) {
      return ts.t('DeliveryComplaint.Messages.NotReceivedWeekdayLimit');
    }

    if (day === saturday && isSaturdayLimit) {
      return ts.t('DeliveryComplaint.Messages.NotReceivedSaturdayLimit');
    }
  }

  return ts.t('DeliveryComplaint.Messages.DefaultSuccess');
};

export default getNotReceivedMessage;
