import dayjs from 'dayjs';
import { DATE_FORMAT_BE } from '~/constants/date';
import { HOLIDAY_SOLUTIONS } from '../data';

const getHolidaySolutionBody = ({ submissionData }) => {
  const {
    start,
    end,
    vacationSolution,
    data,
    subscriptionId,
    solutionData,
  } = submissionData;

  switch (vacationSolution) {
    case HOLIDAY_SOLUTIONS.CONTINUE_DIGITAL:
    case HOLIDAY_SOLUTIONS.DISCONTINUE_DIGITAL:
    case HOLIDAY_SOLUTIONS.DISCONTINUE_PRINT:
    case HOLIDAY_SOLUTIONS.CHARITY:
      return {
        end: dayjs(end).format(DATE_FORMAT_BE),
        solutionData: {},
        start: dayjs(start).format(DATE_FORMAT_BE),
        subscriptionId,
        vacationSolution,
        id: data?.id,
      };
    case HOLIDAY_SOLUTIONS.FAMILY_ADDRESS:
      return {
        id: data?.id,
        end: dayjs(end).format(DATE_FORMAT_BE),
        solutionData: {
          street: solutionData.street,
          houseNumber: solutionData.houseNumber,
          postalCode: solutionData.postalCode,
          city: solutionData.city,
          countryCode: solutionData.countryCode,
          firstName: solutionData.firstName,
          lastName: solutionData.lastName,
          title: solutionData.title,
          box: solutionData.box,
          extra: solutionData.extra,
        },
        start: dayjs(start).format(DATE_FORMAT_BE),
        subscriptionId,
        vacationSolution,
      };
    case HOLIDAY_SOLUTIONS.HOLIDAY_ADDRESS:
      return {
        id: data?.id,
        end: dayjs(end).format(DATE_FORMAT_BE),
        solutionData: {
          street: solutionData.street,
          houseNumber: solutionData.houseNumber,
          postalCode: solutionData.postalCode,
          city: solutionData.city,
          countryCode: solutionData.countryCode,
          box: solutionData.box,
          extra: solutionData.extra,
        },
        start: dayjs(start).format(DATE_FORMAT_BE),
        subscriptionId,
        vacationSolution,
      };
    default:
      break;
  }
  return submissionData;
};

export default getHolidaySolutionBody;
