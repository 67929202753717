import { Box, Heading } from '@mediahuis/chameleon-react-legacy';
import React from 'react';
import DragAndDrop from '~/components/DragAndDrop';
import { ts } from '~/services';

export default function Attachments({ files, setFiles, maxFiles, accept }) {
  const maxSizeInMb = 15;
  const maxSize = maxSizeInMb * 1024 * 1024;
  const errorLabels = {
    'file-invalid-type': ts.t(
      'SmartWebform.Attachments.Errors.FileInvalidType',
    ),
    'file-too-large': ts.t('SmartWebform.Attachments.Errors.FileTooLarge', {
      values: {
        maxSize: maxSizeInMb,
      },
    }),
    'same-file': ts.t('SmartWebform.Attachments.Errors.SameFile'),
    'too-many-files': ts.t('SmartWebform.Attachments.Errors.TooManyFiles', {
      values: {
        maxFiles,
      },
    }),
  };
  const allowedFileTypes = Object.values(accept)
    .flat()
    .map(fileType => {
      return fileType.replace('.', '').toUpperCase();
    });

  const formatter = new Intl.ListFormat('nl', {
    style: 'long',
    type: 'disjunction',
  });

  return (
    <>
      <Heading level={5} color="colorGrey60" fontWeight="regular" mb={4}>
        {ts.t('SmartWebform.Attachments.IntroText')}
      </Heading>
      <Box>
        <DragAndDrop
          accept={accept}
          maxSize={maxSize}
          maxFiles={maxFiles}
          files={files}
          setFiles={setFiles}
          errorLabels={errorLabels}
          text={ts.t('SmartWebform.Attachments.Text')}
          subText={ts.t('SmartWebform.Attachments.SubText', {
            values: {
              fileTypes: formatter.format(allowedFileTypes),
              maxSize: maxSizeInMb,
            },
          })}
        />
      </Box>
    </>
  );
}
