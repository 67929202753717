import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button, useMediaQuery } from '@mediahuis/chameleon-react-legacy';
import { Add } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import { BANNER_TYPES, DATE_FORMAT_BE } from '~/constants';
import { useBanner, useGlobalContext, useHolidayContext } from '~/context';
import HolidaysList from './HolidaysList';
import cleanHolidayData from '../utils/cleanHolidayData';
import getAllHolidays from '../utils/getAllHolidays';
import sortHolidays from '../utils/sortHolidays';
import { HOLIDAY_STATES } from '../data';
import getLanguage from '../language';

const PlannedHolidays = ({ onAction, setChosenHoliday }) => {
  const holidayContext = useHolidayContext();
  const globalContext = useGlobalContext();

  const { chosenUserSubscription } = globalContext;
  const language = getLanguage(
    chosenUserSubscription?.internalSubscriptionType,
  );
  const { showBanner } = useBanner();
  const {
    setHolidayState,
    holidayArrangementOptions,
    holidays,
  } = holidayContext;

  const [plannedHolidays, setPlannedHolidays] = useState({});
  const [activeHolidays, setActiveHolidays] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchPlannedHolidays = async () => {
    try {
      /* Get planned holiday */
      const plannedResponse = await getAllHolidays({
        subscriptionId: chosenUserSubscription.id,
        /* eslint-disable-next-line babel/camelcase */
        min_end: dayjs(new Date()).format(DATE_FORMAT_BE),
        states: Object.values(HOLIDAY_STATES).join(','),
      });
      setHolidayState({
        holidays: plannedResponse,
      });
    } catch (error) {
      if (error.status !== 404) {
        showBanner({
          message: language.messages.noVacationError,
          type: BANNER_TYPES.ERROR,
        });
      }
      setPlannedHolidays({
        data: null,
        message: language.messages.noVacationPlanned,
      });
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    await fetchPlannedHolidays();
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenUserSubscription]);

  /* Fill overview table every time the holidays change*/
  useEffect(() => {
    if (holidays) {
      const cleanedHolidayData = cleanHolidayData({
        holidayData: holidays,
        holidayOptions: holidayArrangementOptions,
        onAction,
        setChosenHoliday,
      });

      const sortedHolidayData = sortHolidays(cleanedHolidayData, 'desc');

      const today = new Date();
      const currentHolidays = sortedHolidayData?.filter(
        x => x.startDate <= today,
      );
      if (currentHolidays?.length > 0) {
        setActiveHolidays({ data: currentHolidays });
      }
      const futureHolidays = sortedHolidayData?.filter(
        x => x.startDate > today,
      );
      if (futureHolidays?.length > 0) {
        setPlannedHolidays({ data: futureHolidays });
      } else {
        setPlannedHolidays({
          data: null,
          message: language.messages.noVacationPlanned,
        });
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [holidays]);

  return (
    <>
      <HolidaysList
        tableData={plannedHolidays.data}
        tableMessage={plannedHolidays.message}
        isLoading={isLoading}
        heading={language.plannedHolidaysHeading}
        tableAction={
          <Button
            width={useMediaQuery(
              {
                md: 'auto',
              },
              'full',
            )}
            mb={useMediaQuery(
              {
                md: 0,
              },
              7,
            )}
            onClick={() => {
              setChosenHoliday && setChosenHoliday(null);
              onAction && onAction({ type: 'holidayRule' });
            }}
            iconLeft={Add}
            disabled={!chosenUserSubscription}
          >
            {language.newHolidayArrangement}
          </Button>
        }
      />
      {!isLoading && activeHolidays?.data?.length > 0 && (
        <HolidaysList
          tableData={activeHolidays.data}
          isLoading={isLoading}
          heading={language.activeHolidaysHeading}
        />
      )}
    </>
  );
};

export default PlannedHolidays;
