import React from 'react';
import { LinkText, List, ListItem } from '@mediahuis/chameleon-react-legacy';
import { ts } from '~/services';
import { Header, Wrapper } from '~/components';
import { SERVICE_MESSAGE_PAGES } from '~/constants/serviceMessage';
import { links } from './data';
import ServiceMessage from '../../components/ServiceMessage';
import { METADATA, PAGES } from '../../constants';

const Other = () => (
  <React.Fragment>
    {METADATA[PAGES.other]}
    <Header heading={ts.t('Other.Heading')} />
    <Wrapper>
      <ServiceMessage mb={5} page={SERVICE_MESSAGE_PAGES.other} />
      <List mt={6}>
        {links.map(link => (
          <ListItem key={link.text}>
            <LinkText href={link.url} target="_blank">
              {link.text}
            </LinkText>
          </ListItem>
        ))}
      </List>
    </Wrapper>
  </React.Fragment>
);

export default Other;
