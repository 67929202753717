import React, { useState } from 'react';
import isEmpty from 'lodash.isempty';
import { Flex, Button, TextField } from '@mediahuis/chameleon-react-legacy';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import styled from 'styled-components';
import { BANNER_TYPES, ERRORS } from '~/constants/messages';
import { DIRECT_DEBIT } from '~/constants/payment';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import useBanner from '~/context/hooks/useBanner';
import { scrollTopWithTimeout } from '~/utils';
import { ts } from '~/services';
import getDirectDebitErrors from '../../utils/getDirectDebitErrors';
import getRedirectUrl from '../../utils/getRedirectUrl';
import defaultError from '../../../../utils/defaultError';

const TextFieldWithUpperCaseInput = styled(TextField)`
  input {
    text-transform: uppercase;
  }
`;

const DirectDebit = ({ setDialogShown }) => {
  const { userInfo, chosenUserSubscription } = useGlobalContext();
  const { showBanner } = useBanner();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onChange = event => {
    const inputToUpperCase = event.target.value.toUpperCase();
    const id = event.target.id;
    setValues(prevState => ({ ...prevState, [id]: inputToUpperCase }));
  };

  const onFocus = event => {
    const currentErrors = { ...errors };
    delete currentErrors[event.target.id];
    setErrors(currentErrors);
  };

  const onSubmit = () => {
    const directDebitErrors = getDirectDebitErrors(values.cardNumber);
    setErrors(directDebitErrors);
    setIsLoading(true);
    if (isEmpty(directDebitErrors)) {
      getRedirectUrl({
        user: userInfo,
        payment: {
          amount: 0,
          accountNumber: values.cardNumber,
          provider: DIRECT_DEBIT,
        },
        subscription: chosenUserSubscription,
      })
        .then(data => {
          setIsLoading(false);
          setDialogShown(false);
          showBanner({
            message: ts.t(
              'Subscription.PaymentProviders.DirectDebit.SuccessMessage',
            ),
            type: BANNER_TYPES.SUCCESS,
          });
          scrollTopWithTimeout();
          return data;
        })
        .then(() => setDialogShown(false))
        .catch(err => {
          setErrors({
            cardNumber:
              err?.status === 400 && err?.errors?.[0]
                ? ERRORS[err.errors[0]]
                : defaultError,
          });
        });
    }
  };

  return (
    <>
      <TextFieldWithUpperCaseInput
        mb={5}
        data-testid="cardNumber"
        onChange={onChange}
        label="IBAN bankrekeningnummer"
        id="cardNumber"
        value={values.cardNumber}
        message={errors.cardNumber || ts.t('Subscription.CardNumberMessage')}
        error={!!errors.cardNumber}
        onFocus={onFocus}
      />
      <Flex justifyContent="flex-end">
        <Button
          mt={5}
          disabled={!isEmpty(errors)}
          isLoading={isLoading}
          onClick={onSubmit}
          iconRight={ChevronForward}
          data-testid="button-save-payment-info"
        >
          {ts.t('Common.Confirm')}
        </Button>
      </Flex>
    </>
  );
};

export default DirectDebit;
