import isEmpty from 'lodash.isempty';
import getTdcPreviousAddresses from '~/api/ah/getTdcPreviousAddresses';
import getTdcPreviousThirdPartiesAddresses from '~/api/ah/getTdcPreviousThirdPartiesAddresses';
import { AH_HOLIDAY_SOLUTIONS, HOLIDAY_STEPS } from '~/pages/Holiday/data';
import { validateDates } from '~/utils';

const handleNextStep = async ({
  submissionData,
  errors,
  setErrors,
  setCurrentStep,
  setIsLoadingAddresses,
  subscriptionId,
  setHolidayState,
  holidayArrangementOptions,
}) => {
  const fields = [
    { id: 'startDate', value: submissionData.start },
    { id: 'endDate', value: submissionData.end },
  ];

  const datesErrors = validateDates({ errors, fields });
  setErrors(datesErrors);

  if (!isEmpty(datesErrors)) {
    return;
  }

  switch (submissionData.vacationSolution) {
    case AH_HOLIDAY_SOLUTIONS.CONTINUE_DIGITAL:
    case AH_HOLIDAY_SOLUTIONS.DISCONTINUE_DIGITAL:
    case AH_HOLIDAY_SOLUTIONS.CHARITY:
      setCurrentStep(HOLIDAY_STEPS.SUMMARY_STEP);
      break;
    case AH_HOLIDAY_SOLUTIONS.FAMILY_ADDRESS: {
      setIsLoadingAddresses(true);
      const previousTemporaryThirdParties = await getTdcPreviousThirdPartiesAddresses(
        { subscriptionId },
      );
      setIsLoadingAddresses(false);
      setHolidayState({
        holidayArrangementOptions: {
          ...holidayArrangementOptions,
          knownFamilyAddresses: previousTemporaryThirdParties,
        },
      });

      if (previousTemporaryThirdParties?.length > 0) {
        setCurrentStep(HOLIDAY_STEPS.ADDRESS_CHOICE_STEP);
      } else {
        setCurrentStep(HOLIDAY_STEPS.ADDRESS_STEP);
      }
      break;
    }
    case AH_HOLIDAY_SOLUTIONS.HOLIDAY_ADDRESS:
      {
        setIsLoadingAddresses(true);
        const previousAddresses = await getTdcPreviousAddresses({
          subscriptionId,
        });
        setIsLoadingAddresses(false);
        setHolidayState({
          holidayArrangementOptions: {
            ...holidayArrangementOptions,
            knownHolidayAddresses: previousAddresses,
          },
        });
        if (previousAddresses?.length > 0) {
          setCurrentStep(HOLIDAY_STEPS.ADDRESS_CHOICE_STEP);
        } else {
          setCurrentStep(HOLIDAY_STEPS.ADDRESS_STEP);
        }
      }
      break;
    default:
      break;
  }
};

export default handleNextStep;
