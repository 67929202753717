import { AutoComplete } from '@mediahuis/chameleon-react-legacy';
import React, { useEffect, useState } from 'react';
import getCountries from '~/api/getCountries';
import language from '~/components/AddressFields/language';
import { COUNTRIES } from '~/constants';
import { ts } from '~/services';
import { noop } from '~/utils';

const CountryAutoComplete = props => {
  const [countries, setCountries] = useState([]);
  const [currentValue, setCurrentValue] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadCountries = async () => {
    try {
      setLoading(true);
      const loadedCountries = await getCountries();
      const filteredCountries = loadedCountries.filter(country =>
        props.allowedCountries.includes(country.IsoCode),
      );
      const defaultCountry = filteredCountries.find(
        country => country.IsoCode === COUNTRIES.LU,
      );
      const defaultCountryName = defaultCountry.Name.replace('&amp;', '&');

      setCurrentValue(
        props.value
          ? filteredCountries
              .find(x => x.IsoCode === props.value)
              ?.Name.replace('&amp;', '&')
          : defaultCountryName,
      );
      if (!props.value) {
        props.onChange(defaultCountry);
      }

      setCountries(filteredCountries);

      setError(null);
      props.setCountryError(false);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setCountries([]);
      setError(e.message);
    }
  };

  const onChange = value => {
    if (loading) return; // prevent the onChange event from triggering on mounting or loading
    setCurrentValue(value);
    const country = countries.find(
      x => x.Name === value?.replace('&', '&amp;'),
    );
    if (country) {
      props.onChange(country);
      setError(null);
      props.setCountryError(false);
    } else if (!value) {
      props.onChange(null);
      props.setCountryError(true);
      setError(language.countryIsRequired);
    } else if (!country) {
      props.setCountryError(true);
      props.onChange(null);
      setError(language.countryIsNotAllowed);
    }
  };

  useEffect(() => {
    loadCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AutoComplete
      suggestions={countries.map(x => x.Name.replace('&amp;', '&'))}
      onSelect={onChange}
      onChange={noop}
      label={ts.t('AddressFields.Country')}
      value={currentValue}
      loading={loading}
      error={error}
      message={error || language.countryMessage}
    />
  );
};

export default CountryAutoComplete;
