import React, { useState, useCallback } from 'react';

const HolidayContext = React.createContext();

const HolidayContextProvider = ({ children }) => {
  const [holidayState, setState] = useState({
    holidayArrangementOptions: null,
    holidays: null,
  });

  const setHolidayState = useCallback(
    data => setState(state => ({ ...state, ...data })),
    [setState],
  );

  return (
    <HolidayContext.Provider value={{ ...holidayState, setHolidayState }}>
      {children}
    </HolidayContext.Provider>
  );
};

export { HolidayContext, HolidayContextProvider };
