export const cancelSubscriptionYouthConfig = {
  local: {
    baseUrl:
      'http://mp1.test.mediahuis.be/optiext/optiextension.dll?ID=DR9yjkYUcijcIibWkMSM5tDvc66EenIwR0h7PzDZywmh5lA1PACDgtBkx8Y0VD55UYePn8yrzpYhMI',
  },
  dev: {
    baseUrl:
      'http://mp1.test.mediahuis.be/optiext/optiextension.dll?ID=DR9yjkYUcijcIibWkMSM5tDvc66EenIwR0h7PzDZywmh5lA1PACDgtBkx8Y0VD55UYePn8yrzpYhMI',
  },
  test: {
    baseUrl:
      'http://mp1.test.mediahuis.be/optiext/optiextension.dll?ID=DR9yjkYUcijcIibWkMSM5tDvc66EenIwR0h7PzDZywmh5lA1PACDgtBkx8Y0VD55UYePn8yrzpYhMI',
  },
  preview: {
    baseUrl:
      'http://mp1.preview.mediahuis.be/optiext/optiextension.dll?ID=Ji6f6gLGhv6%2BRl1Qetthqgyx2YLXDL9zhChYoOog%2BhmP62F9VEXgvWI_5tPewQ__jqNFdyIZTvEnDh',
  },
  production: {
    baseUrl:
      'http://messagent.vummail.be/optiext/optiextension.dll?ID=Ji6fj_MWkUcxxhzZQAq9JLAxATM9aAi0nreGY%2Bex4WGOkGV7TLG_qU94v1nKCNfckCe6aMbzGBpevI',
  },
};
