import { mapAddress } from '~/api/ah/getTemporaryDeliveryChangesOptions';
import {
  AH_HOLIDAY_SOLUTIONS,
  HOLIDAY_STATES,
  LIFE_CYCLES,
  UPDATE_POSSIBILITIES,
} from '~/pages/Holiday/data';
import {
  cleanDate,
  HOLIDAY_CAPTION_STATES,
  translateButtons,
  translateSolution,
} from '~/pages/Holiday/utils/cleanHolidayData';
import { noop } from '~/utils';

export const mapHolidayToSolutionData = holiday => {
  const { address, namesPerson, demographicsPerson } = holiday || {};
  const { firstName, lastName } = namesPerson || {};
  return {
    ...mapAddress(address, { firstName, lastName }, demographicsPerson),
    title: holiday?.demographicsPerson?.genderCode,
    firstName: holiday?.namesPerson?.firstName,
    lastName: holiday?.namesPerson?.lastName,
  };
};

const formatAddress = ({ solution, address }) => {
  const {
    street,
    houseNumber,
    busNumber,
    postalCode,
    city,
    countryCode,
  } = address;
  switch (solution) {
    case AH_HOLIDAY_SOLUTIONS.HOLIDAY_ADDRESS:
    case AH_HOLIDAY_SOLUTIONS.FAMILY_ADDRESS:
      return `${street} ${houseNumber}${
        busNumber ? `/${busNumber}` : ''
      }, ${postalCode} ${city}, ${countryCode}`;
    default:
      return '';
  }
};
const getAhHolidayState = ({ lifeCycle, updatePossibility }) => {
  if (lifeCycle === LIFE_CYCLES.PAST) {
    return HOLIDAY_CAPTION_STATES.PAST;
  }
  if (updatePossibility === UPDATE_POSSIBILITIES.NOT) {
    return HOLIDAY_CAPTION_STATES.DISABLED;
  }
  return HOLIDAY_STATES.CONFIRMED;
};

const mapHolidayData = ({
  holidayData = [],
  onAction = noop,
  setChosenHoliday,
  isReadOnlyMode,
  subscriptionHasDigitalComponent,
}) =>
  holidayData.map((data, index) => {
    const {
      startDate,
      endDate,
      address,
      type,
      lifeCycle,
      updatePossibility,
    } = data;
    return {
      ...data,
      index,
      start: cleanDate(startDate),
      startDate: new Date(startDate),
      end: cleanDate(endDate),
      endDate: new Date(endDate),
      solutionData:
        address &&
        formatAddress({
          solution: type.code,
          address,
        }),
      originalSolutionData: address,
      vacationSolution: translateSolution(
        type.code,
        subscriptionHasDigitalComponent,
      ),
      cancel: isReadOnlyMode
        ? ' '
        : translateButtons({
            state: getAhHolidayState({
              lifeCycle,
              updatePossibility,
            }),
            index,
            onAction,
            setChosenHoliday,
          }),
    };
  });

export default mapHolidayData;
