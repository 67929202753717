import React from 'react';
import { useHistory } from 'react-router-dom';
import { Icon, Flex, Paper, Heading } from '@mediahuis/chameleon-react-legacy';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import SelfHelpIcon from './Icon';

const Link = ({ name, href, width, external, onClick, children, ...props }) => {
  const history = useHistory();

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    } else if (external) {
      window.location.href = href;
    } else {
      history.push(href);
    }
  };

  return (
    <Paper
      p={4}
      mt={7}
      hoverable
      width={width}
      onClick={handleOnClick}
      style={{ cursor: 'pointer' }}
      display="flex"
      alignItems="center"
      {...props}
    >
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <Flex alignItems="center" verticalAlign="center">
          <SelfHelpIcon name={name} />
          <Heading level={5} fontWeight="regular" ml={4} flex={1}>
            {children}
          </Heading>
        </Flex>
        <Icon as={ChevronForward} size="medium" />
      </Flex>
    </Paper>
  );
};

export default Link;
