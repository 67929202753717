import styled from 'styled-components';
import { colorWhiteBase } from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';

const SuggestionWrapper = styled.ul`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 138px;
  overflow-y: scroll;
  transform: translateY(100%);
  background-color: ${colorWhiteBase};
  z-index: 100;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.31);
`;

export default SuggestionWrapper;
