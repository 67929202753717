/**
 * Contains environment specific configuration.
 * @typedef {Object} Environment
 * @property {string} name - Name of the environment
 * @property {boolean} [isMockEnabled] - Is this a test environment
 * @property {{[string]: string}} prefixes - URL prefixes
 * @property {boolean} isLocal - Is this the local environment
 * @property {boolean} isDev - Is this a test environment
 * @property {boolean} isTest - Is this a test environment
 * @property {boolean} isPreview - Is this a preview environment
 * @property {boolean} isProd - Is this a production environment
 */

/**
 * @class EnvironmentService
 */
class EnvironmentService {
  /** @type {{["LOCAL"|"TEST"|"DEV"|"PREVIEW"|"PROD"]: Environment}} */
  #ENVS = {
    LOCAL: {
      name: 'local',
      isMockEnabled: false,
      prefixes: {
        api: 'test',
        site: 'local',
        mockApi: 'local',
        serviceApi: 'test', // is used as an override, not needed for other environments
      },
      isLocal: true,
      isDev: false,
      isTest: false,
      isPreview: false,
      isProd: false,
    },
    DEV: {
      name: 'dev',
      isMockEnabled: false,
      prefixes: {
        api: 'test',
        site: 'dev',
        serviceApi: 'dev', // is used as an override, not needed for other environments
      },
      isLocal: false,
      isTest: false,
      isDev: true,
      isPreview: false,
      isProd: false,
    },
    TEST: {
      name: 'test',
      isMockEnabled: false,
      prefixes: {
        api: 'test',
        site: 'test',
        mockApi: 'test',
      },
      isLocal: false,
      isDev: false,
      isTest: true,
      isPreview: false,
      isProd: false,
    },
    PREVIEW: {
      name: 'preview',
      isMockEnabled: false,
      prefixes: {
        api: 'preview',
        site: 'preview',
        mockApi: 'preview',
      },
      isLocal: false,
      isDev: false,
      isTest: false,
      isPreview: true,
      isProd: false,
    },
    PROD: {
      name: 'production',
      isMockEnabled: false,
      prefixes: {
        api: '',
        site: 'www',
      },
      isLocal: false,
      isDev: false,
      isTest: false,
      isPreview: false,
      isProd: true,
    },
  };

  /** @type {Environment} */
  #currentEnvironment;

  constructor() {
    if (window.location.hostname.startsWith('local')) {
      this.#currentEnvironment = this.#ENVS.LOCAL;
    } else if (window.location.hostname.startsWith('dev')) {
      this.#currentEnvironment = this.#ENVS.DEV;
    } else if (window.location.hostname.startsWith('test')) {
      this.#currentEnvironment = this.#ENVS.TEST;
    } else if (window.location.hostname.startsWith('preview')) {
      this.#currentEnvironment = this.#ENVS.PREVIEW;
    } else {
      this.#currentEnvironment = this.#ENVS.PROD;
    }
  }

  /** @type {string} */
  get apiPrefix() {
    return this.#currentEnvironment.prefixes.api;
  }

  /** @type {string} */
  get serviceApiPrefix() {
    return this.#currentEnvironment.prefixes.serviceApi || this.apiPrefix;
  }

  /** @type {string} */
  get sitePrefix() {
    return this.#currentEnvironment.prefixes.site;
  }

  /** @type {boolean} */
  get isProd() {
    return this.#currentEnvironment.isProd;
  }

  /** @type {boolean} */
  get isPreview() {
    return this.#currentEnvironment.isPreview;
  }
  /** @type {boolean} */
  get isLocal() {
    return this.#currentEnvironment.isLocal;
  }
  /** @type {boolean} */
  get isDev() {
    return this.#currentEnvironment.isDev;
  }
  /** @type {boolean} */
  get isTest() {
    return this.#currentEnvironment.isTest;
  }

  /** @type {string} */
  get name() {
    return this.#currentEnvironment.name;
  }

  /** @type {string} */
  get mockApiPrefix() {
    return this.#currentEnvironment.prefixes.mockApi;
  }

  get isMockEnabled() {
    return this.#currentEnvironment.isMockEnabled;
  }
}

const instance = new EnvironmentService();
export { instance as environment };
