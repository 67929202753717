import React from 'react';
import { useHistory } from 'react-router-dom';
import { Paragraph, Flex } from '@mediahuis/chameleon-react-legacy';
import Link from './Link';
import { links } from './data';

const MenuItems = () => {
  const history = useHistory();

  return (
    <Flex>
      {links
        .filter(link => !link.selfHelpOnly)
        .map(link => (
          <Link
            key={link.value}
            onClick={() => {
              if (link.external) {
                window.location.href = link.url;
              } else {
                history.push(link.url);
              }
            }}
          >
            <Paragraph>{link.value}</Paragraph>
          </Link>
        ))}
    </Flex>
  );
};

export default MenuItems;
