import React from 'react';
import CancellationConfirmation from '~/pages/Subscription/components/DialogContent/OnlineCancellation/CancellationConfirmation';
import OverviewOfSelectedAlternativeOffer from '~/pages/Subscription/components/DialogContent/OnlineCancellation/OverviewOfSelectedAlternativeOffer';

export default function FourthStep({
  selectedAlternativeOffer,
  handleSubmit,
  isSubmitting,
  error,
  handleClose,
  selectedCancellationReason,
}) {
  return selectedAlternativeOffer ? (
    <OverviewOfSelectedAlternativeOffer
      selectedAlternativeOffer={selectedAlternativeOffer}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      error={error}
      selectedCancellationReason={selectedCancellationReason}
    />
  ) : (
    <CancellationConfirmation
      handleClose={handleClose}
      selectedCancellationReason={selectedCancellationReason}
    />
  );
}
