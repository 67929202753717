import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #f3f4f4;
`;

export const NoResultsBanner = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
`;

export const SearchWrapper = styled.div`
  display: flex;
`;

export const SearchButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
