import React, { useState } from 'react';
import { Text } from '@mediahuis/chameleon-react-legacy';

import { BANNER_TYPES } from '~/constants/messages';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import useHolidayContext from '~/context/hooks/useHolidayContext';
import useBanner from '~/context/hooks/useBanner';
import postTemporaryDeliveryChange from '~/api/ah/postTemporaryDeliveryChange';
import getTemporaryDeliveryChanges from '~/api/ah/getTemporaryDeliveryChanges';
import { ts } from '~/services';
import BaseSummaryStep from '~/pages/Holiday/components/DialogContent/Steps/BaseSummaryStep';
import { HOLIDAY_STEPS } from '~/pages/Holiday/data';
import getAhHolidaySolutionBody from '~/pages/Holiday/utils/ah/getAhHolidaySolutionBody';
import { COULD_NOT_UPDATE_IN_DYNAMICS_ERROR } from '~/constants/aportal';

const AhSummaryStep = ({
  submissionData,
  setCurrentStep,
  setIsDialogShown,
  selectedDeliveryOptionBySubscription,
  setUpdateHolidayOverviewHasFailed,
}) => {
  const globalState = useGlobalContext();
  const { chosenUserSubscription } = globalState;
  const holidayState = useHolidayContext();
  const { showBanner } = useBanner();
  const { setHolidayState } = holidayState;
  const [isLoading, setIsLoading] = useState(false);

  const submitData = async () => {
    try {
      try {
        setIsLoading(true);

        await postTemporaryDeliveryChange({
          subscriptionId: chosenUserSubscription?.id,
          body: getAhHolidaySolutionBody({
            submissionData,
            selectedDeliveryOptionBySubscription,
          }),
          temporaryDeliveryChangeId: submissionData?.id,
        });

        showBanner({
          type: BANNER_TYPES.SUCCESS,
          message: <Text>{ts.t('Holiday.SummaryStep.SuccessMessage')}</Text>,
        });
      } catch (error) {
        if (error.detail === COULD_NOT_UPDATE_IN_DYNAMICS_ERROR) {
          showBanner({
            message: ts.t('Common.UpdateInDynamicsFailed', {
              richContent: true,
            }),
            type: BANNER_TYPES.WARNING,
          });
        } else {
          throw new Error(error.detail || error.message);
        }
      }

      try {
        const allHolidays = await getTemporaryDeliveryChanges({
          subscriptionId: chosenUserSubscription.id,
        });
        setHolidayState({
          holidays: allHolidays,
        });
      } catch {
        setUpdateHolidayOverviewHasFailed(true);
      }
    } catch {
      const isEditting = !!submissionData?.id;
      showBanner({
        message: ts.t(
          isEditting ? 'Holiday.Errors.Update' : 'Holiday.Errors.Create',
        ),
        type: BANNER_TYPES.ERROR,
      });
    } finally {
      setIsLoading(false);
      setIsDialogShown(false);
      setCurrentStep(HOLIDAY_STEPS.CHOICE_STEP);
    }
  };

  return (
    <BaseSummaryStep
      submissionData={submissionData}
      setCurrentStep={setCurrentStep}
      submitData={submitData}
      isLoading={isLoading}
      selectedDeliveryOptionBySubscription={
        selectedDeliveryOptionBySubscription
      }
    />
  );
};

export default AhSummaryStep;
