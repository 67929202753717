import {
  Accordion,
  AccordionItem,
  Banner,
  Box,
  Flex,
  Heading,
  List,
  RichContent,
  Select,
} from '@mediahuis/chameleon-react-legacy';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getCategoryIdByPathname,
  getSelectedArticle,
  getSelectedArticleIndex,
  getSelectedCategory,
  sortByOrder,
} from '~/components/DynamicFaq/utils';
import { ts } from '~/services';
import ListItem from '~/components/DynamicFaq/components/ListItem';
import { useGlobalContext } from '~/context';
import { PAGES } from '~/constants';
import {
  getResponsiveValue,
  isDesktop,
  isMobile,
  useResponsive,
} from '~/utils';
import ContentLoader from '../ContentLoader';
import Wrapper from '../Wrapper';
import getAllFaqItems from './getAllFaqItems';

const DynamicFaq = () => {
  const {
    setGlobalState,
    faqCategories,
    isLoadingFaqCategories,
    faqCategoriesHasFailed,
  } = useGlobalContext();

  const [selectedTopicData, setSelectedTopicData] = useState();
  const [
    internalNumberOfActiveCategory,
    setInternalNumberOfActiveCategory,
  ] = useState();
  const [activeQuestion, setActiveQuestion] = useState(-1);
  const [selectedArticle, setSelectedArticle] = useState(null);

  const { hash, pathname } = useLocation();
  const { replace, location } = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const chosenCategoryId = queryParams.get('categoryId');
  useResponsive();

  const setDefaultCategoryByPage = (allCategories = []) => {
    const categoryId =
      getCategoryIdByPathname(pathname?.split('/')?.[1]) ||
      allCategories[0]?.internalNumber;
    const category =
      allCategories.find(x => x.internalNumber === categoryId) ||
      allCategories[0];
    setInternalNumberOfActiveCategory(categoryId);
    setSelectedTopicData(category);
  };

  const fetchFaqItems = async () => {
    try {
      setGlobalState({
        faqCategoriesHasFailed: false,
      });
      const result = await getAllFaqItems();
      const sortedCategories = sortByOrder(result.categories);
      setGlobalState({
        faqCategories: sortedCategories,
      });

      if (sortedCategories.length > 0 && !chosenCategoryId) {
        setDefaultCategoryByPage(sortedCategories);
      }
    } catch (err) {
      setGlobalState({
        faqCategoriesHasFailed: true,
      });
    } finally {
      setGlobalState({
        isLoadingFaqCategories: false,
      });
    }
  };

  useEffect(() => {
    fetchFaqItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (faqCategories?.length && chosenCategoryId) {
      const category =
        faqCategories.find(x => x.internalNumber === chosenCategoryId) ||
        faqCategories[0];
      setInternalNumberOfActiveCategory(chosenCategoryId);
      setSelectedTopicData(category);
      const timeout = setTimeout(() => {
        document
          .getElementById('FAQWrapper')
          .scrollIntoView({ behavior: 'smooth' });
        clearTimeout(timeout);
      }, 250);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenCategoryId, faqCategories]);

  useEffect(() => {
    if (faqCategories?.length && !chosenCategoryId) {
      setDefaultCategoryByPage(faqCategories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faqCategories, pathname]);

  const handleActiveTopicChange = internalNumber => {
    setInternalNumberOfActiveCategory(internalNumber);
    setActiveQuestion(-1);
    setSelectedArticle(null);
    replace({ ...location, hash: null });

    const index = faqCategories.findIndex(obj => {
      return obj.internalNumber === internalNumber;
    });
    if (index !== -1) {
      setSelectedTopicData(faqCategories[index]);
    }
  };

  useEffect(() => {
    const isLinkToAnotherArticle = hash.startsWith('#') && hash.endsWith('#');
    const articleInternalNumber =
      isLinkToAnotherArticle && hash.substring(1, hash.length - 1);

    const selectedCategory = getSelectedCategory(
      faqCategories,
      articleInternalNumber,
    );
    const article = getSelectedArticle(selectedCategory, articleInternalNumber);

    if (article) {
      setSelectedArticle(article);
      setInternalNumberOfActiveCategory(selectedCategory.internalNumber);
      setSelectedTopicData(selectedCategory);
      setActiveQuestion(article.articleInternalNumber);
    }
  }, [selectedTopicData, hash, faqCategories, replace]);

  useEffect(() => {
    if (selectedArticle) {
      const articleWrapper = document.querySelector(
        `.question-${selectedArticle.articleInternalNumber}`,
      );
      articleWrapper?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  });

  const renderQuestionsList = questions => {
    const sortedKnowledgeArticles = sortByOrder(questions);
    const selectedArticleIndex = getSelectedArticleIndex(
      questions,
      selectedArticle,
    );
    const initialExpandedIndex =
      selectedArticleIndex >= 0 ? selectedArticleIndex : -1;
    return (
      <Accordion
        id="faqAccordion"
        collapsible
        key={`${internalNumberOfActiveCategory}-${activeQuestion}`}
        initialExpandedIndex={initialExpandedIndex}
        mb={6}
      >
        {sortedKnowledgeArticles.map(question => (
          <AccordionItem
            title={question.title}
            key={question.title}
            className={`question-${question.articleInternalNumber}`}
          >
            <RichContent
              as="span"
              fontFamily="system"
              dangerouslySetInnerHTML={{
                __html: question.content,
              }}
            />
          </AccordionItem>
        ))}
      </Accordion>
    );
  };
  const renderSelectedTopic = () => {
    if (selectedTopicData) {
      let knowledgeArticlesWithoutSubcategory = null;
      if (selectedTopicData.knowledgeArticles.length > 0) {
        const sortedKnowledgeArticles = sortByOrder(
          selectedTopicData.knowledgeArticles,
        );
        knowledgeArticlesWithoutSubcategory = renderQuestionsList(
          sortedKnowledgeArticles,
        );
      }
      const sortedSubCategories = sortByOrder(selectedTopicData.subCategories);
      const knowledgeArticlesWithSubcategory = sortedSubCategories.map(item => {
        return (
          <React.Fragment key={item.name}>
            <Heading level={4} mb={3} fontFamily="primary">
              {item.name}
            </Heading>
            {renderQuestionsList(item.knowledgeArticles)}
          </React.Fragment>
        );
      });
      return (
        <>
          {knowledgeArticlesWithoutSubcategory}
          {knowledgeArticlesWithSubcategory}
        </>
      );
    }
    return null;
  };

  const isContactPage = pathname?.split('/')?.[1] === PAGES.contact;
  const isSmartWebformPage = pathname?.split('/')?.[1] === PAGES.smartWebform;

  if (isContactPage || isSmartWebformPage) {
    return null;
  }

  if (faqCategoriesHasFailed) {
    return (
      <Flex justifyContent="center" py={7}>
        <Banner appearance="error" show mx={5} closeHidden>
          {ts.t('Faq.ErrorMessage')}
        </Banner>
      </Flex>
    );
  }

  if (isLoadingFaqCategories) {
    return <ContentLoader height="300px" />;
  }

  return (
    <Box bgColor="colorGrey10" py={8} mt={10} id="faq">
      <Wrapper id="FAQWrapper">
        <Box mb={5}>
          <Heading level={3} fontFamily="primary">
            {ts.t('Faq.Title')}
          </Heading>
        </Box>
        <Flex
          flexDirection={getResponsiveValue({
            desktop: 'row',
            mobile: 'column',
          })}
          justifyContent="space-between"
        >
          {isMobile() && (
            <Select
              mb={6}
              value={internalNumberOfActiveCategory}
              onChange={event => handleActiveTopicChange(event.target.value)}
            >
              {faqCategories.map(category => (
                <option
                  key={category.internalNumber}
                  value={category.internalNumber}
                >
                  {category.name}
                </option>
              ))}
            </Select>
          )}
          {isDesktop() && (
            <List ml={-7} mr={5} width="25%">
              {faqCategories.map(category => (
                <ListItem
                  key={category.internalNumber}
                  id={category.internalNumber}
                  onClick={() =>
                    handleActiveTopicChange(category.internalNumber)
                  }
                  active={
                    category.internalNumber === internalNumberOfActiveCategory
                  }
                >
                  {category.name}
                </ListItem>
              ))}
            </List>
          )}
          <Box
            style={getResponsiveValue({
              desktop: { width: '100%' },
              mobile: { width: 'auto' },
            })}
          >
            {renderSelectedTopic()}
          </Box>
        </Flex>
      </Wrapper>
    </Box>
  );
};

export default DynamicFaq;
