import { config } from '~/services';
import { PHONE, CHAT, STATUSES, STRING_REPLACE } from '../enums';
import getPublicHolidayName from './getPublicHolidayName';
import setBold from './setBold';
import setHours from './setHours';
import getOpeningHours from './getOpeningHours';

const getText = ({
  strType,
  strStatus,
  todaysopeninghours,
  objContactData,
  bSubscriptionpage,
  dToday,
  widgetData,
}) => {
  const { OPEN, OPENSOON, CHATBOT } = STATUSES;
  const { HOLIDAYNAME, PHONENR } = STRING_REPLACE;
  const data = {};

  const strPublicHolidayName = getPublicHolidayName({ dToday, widgetData });
  let strTelnr = objContactData.telnr;
  let strEditTxt = '';
  let strExtraInfoTxt = '';

  if (bSubscriptionpage) {
    strTelnr = objContactData.subscriptiontelnr;
  }

  if (strType === PHONE || strType === CHAT) {
    switch (strStatus) {
      case OPEN:
        strEditTxt = setBold(
          setHours({
            strText: objContactData.opentext,
            arrHours: todaysopeninghours,
          }),
        );
        break;
      case OPENSOON:
        strEditTxt = setBold(
          setHours({
            strText: objContactData.closedtext,
            arrHours: todaysopeninghours,
          }),
        );
        break;
      case CHATBOT:
        strEditTxt = getOpeningHours({
          jsonOpeningHours: objContactData.openinghours.hours,
        });
        break;
      default:
        strEditTxt = getOpeningHours({
          jsonOpeningHours: objContactData.openinghours.hours,
        });
        if (config.isMHLU) {
          strEditTxt = `${objContactData.extrainfo}<br/> ${strEditTxt} `;
        }
        break;
    }

    if (strPublicHolidayName) {
      const strExtraInfoHoliday =
        widgetData.jsonSettings.settings.publicholidays.extrainfo;

      if (strExtraInfoHoliday) {
        strExtraInfoTxt = `<span>${setBold(
          strExtraInfoHoliday.replace(HOLIDAYNAME, strPublicHolidayName),
          false,
        )}</span>`;
      }
    }
  }

  if (objContactData.extrainfo && !strPublicHolidayName && !config.isMHLU) {
    strExtraInfoTxt = `<span>${setBold(objContactData.extrainfo)}</span>`;
  }

  data.maintext = setBold(objContactData.maintext.replace(PHONENR, strTelnr));

  if (strEditTxt) {
    data.edittext = `${strEditTxt}<br />${strExtraInfoTxt}`;
  } else {
    data.edittext = strExtraInfoTxt;
  }

  return data;
};

export default getText;
