export const PAGES = Object.freeze({
  deliveryComplaint: 'zustellung',
  deliveryComplaintHeading: 'Zustellung',
  readDigital: 'digitales-lesen',
  subscription: 'mein-abonnement',
  holiday: 'urlaub',
  other: 'andere',
  faq: 'haeufig-gestellte-fragen',
  home: 'home',
});
