import React, { useState } from 'react';

import dayjs from 'dayjs'; // load on demand
import useHolidayContext from '~/context/hooks/useHolidayContext';
import { validateDates } from '~/utils';
import { useGlobalContext } from '~/context';
import getTemporaryDeliveryChangesOptionsEndDates from '~/api/ah/getTemporaryDeliveryChangesOptionsEndDates';

import { DATE_FORMAT_BE, ERRORS } from '~/constants';
import BaseChoiceStep from '~/pages/Holiday/components/DialogContent/Steps/BaseChoiceStep';
import { UPDATE_POSSIBILITIES } from '~/pages/Holiday/data';

import handleNextStep from '~/pages/Holiday/utils/ah/handleNextStep';

const AhChoiceStep = ({
  submissionData,
  setSubmissionData,
  setCurrentStep,
  isDialogShown,
  chosenHoliday,
  isLoadingUpdateStartAndEndDates,
  errors,
  setErrors,
}) => {
  const globalContext = useGlobalContext();

  const holidayState = useHolidayContext();

  const { holidayArrangementOptions } = holidayState;
  const { chosenUserSubscription } = globalContext;
  const { endDates, startDates, setHolidayState, holidays } = holidayState;

  const [isLoadingEndDates, setIsLoadingEndDates] = useState(false);
  const [isLoadingAddresses, setIsLoadingAddresses] = useState(false);

  const temporaryDeliveryChangeId = submissionData?.id;

  const onStartDateChange = async (value, meta) => {
    const fields = [{ id: 'startDate', value, meta }];

    if (meta.formattedDateIsValid) {
      try {
        setSubmissionData(prevState => ({
          ...prevState,
          start: value,
        }));
        setIsLoadingEndDates(true);
        const response = await getTemporaryDeliveryChangesOptionsEndDates({
          subscriptionId: chosenUserSubscription.id,
          startDate: dayjs(value).format(DATE_FORMAT_BE),
          temporaryDeliveryChangeId,
        });

        const formattedEndDate = dayjs(submissionData.end).format(
          DATE_FORMAT_BE,
        );
        const shouldClearEndDate = !response.dates?.[formattedEndDate]
          ?.selectable;
        const endDate = shouldClearEndDate ? undefined : submissionData.end;

        setHolidayState({
          ...holidayState,
          endDates: response.dates,
        });
        setSubmissionData(prevState => ({
          ...prevState,
          end: endDate,
        }));
      } catch (error) {
        setErrors(prevstate => ({
          ...prevstate,
          endDate: ERRORS.NO_END_DATES,
        }));
        setHolidayState({
          ...holidayState,
          endDates: [],
        });
        return;
      } finally {
        setIsLoadingEndDates(false);
      }
    } else {
      setSubmissionData(prevState => ({
        ...prevState,
        start: value,
      }));
    }
    setErrors(validateDates({ errors: {}, fields }));
  };

  const onEndDateChange = (value, meta) => {
    setSubmissionData(prevState => ({
      ...prevState,
      end: value,
    }));

    const fields = [{ id: 'endDate', value, meta }];
    setErrors(validateDates({ errors, fields }));
  };

  const getStartDateDisabledDays = day => {
    const formattedDay = dayjs(day).format(DATE_FORMAT_BE);
    const isDaySelectable = startDates?.[formattedDay]?.selectable;
    return !isDaySelectable;
  };
  const getEndDateDisabledDays = day => {
    const formattedDay = dayjs(day).format(DATE_FORMAT_BE);
    const isDaySelectable = endDates?.[formattedDay]?.selectable;
    return !isDaySelectable;
  };
  const isStartDateDisabled =
    holidays?.[chosenHoliday]?.updatePossibility ===
      UPDATE_POSSIBILITIES.PARTIALLY || isLoadingUpdateStartAndEndDates;

  const isEndDateDisabled =
    isLoadingEndDates || isLoadingUpdateStartAndEndDates;

  return (
    <BaseChoiceStep
      onEndDateChange={onEndDateChange}
      onStartDateChange={onStartDateChange}
      getEndDateDisabledDays={getEndDateDisabledDays}
      getStartDateDisabledDays={getStartDateDisabledDays}
      isDialogShown={isDialogShown}
      setSubmissionData={setSubmissionData}
      submissionData={submissionData}
      errors={errors}
      setErrors={setErrors}
      isStartDateDisabled={isStartDateDisabled}
      isEndDateDisabled={isEndDateDisabled}
      onNextStep={() =>
        handleNextStep({
          errors,
          holidayArrangementOptions,
          setCurrentStep,
          setErrors,
          setHolidayState,
          setIsLoadingAddresses,
          submissionData,
          subscriptionId: chosenUserSubscription?.id,
        })
      }
      isLoadingAddresses={isLoadingAddresses}
    />
  );
};

export default AhChoiceStep;
