import React, { useState } from 'react';
import {
  Heading,
  Box,
  Flex,
  Fieldset,
  Button,
} from '@mediahuis/chameleon-react-legacy';
import {
  ChevronBack,
  ChevronForward,
} from '@mediahuis/chameleon-theme-wl/legacy/icons';
import isEmpty from 'lodash.isempty';
import { config, ts } from '~/services';

import { HOLIDAY_STEPS } from '~/pages/Holiday/data';
import getEmptySolutionData from '~/pages/Holiday/utils/getEmptySolutionData';
import HolidayAddresses from '~/pages/Holiday/components/DialogContent/HolidayAddresses';
import getStreetFullName from '~/api/getStreetFullName';
import { COUNTRIES } from '~/constants';

const AhAddressChoiceStep = ({
  submissionData,
  setSubmissionData,
  setCurrentStep,
}) => {
  const [errors] = useState({});
  const [isloadingStreetFullName, setIsloadingStreetFullName] = useState(false);

  // eslint-disable-next-line consistent-return
  const onNextStep = async () => {
    const isBelgianAddress =
      submissionData?.solutionData?.countryCode === COUNTRIES.BE;
    if (isBelgianAddress) {
      try {
        setIsloadingStreetFullName(true);
        const { Name } = await getStreetFullName({
          address: submissionData.solutionData,
        });
        setSubmissionData(prevState => ({
          ...prevState,
          solutionData: {
            ...prevState.solutionData,
            street: Name,
            bpostStreetName: prevState.solutionData.street,
          },
        }));
      } catch (error) {
        // When the call to get the full name of street fails, we dont't want to resolve the error,
        // we want to go to the next step and show the bpost street name
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsloadingStreetFullName(false);
      }
    }
    setCurrentStep(HOLIDAY_STEPS.ADDRESS_STEP);
  };
  const handleOnPreviousStep = () => {
    setSubmissionData(prevState => ({
      ...prevState,
      solutionData: getEmptySolutionData(false),
    }));
    setCurrentStep(HOLIDAY_STEPS.CHOICE_STEP);
  };
  const isEditting = !!submissionData?.id;
  const color = config.isAportal ? 'colorSecondaryBase' : 'colorPrimaryBase';

  const heading = (
    <Heading level={3} mb={6} fontFamily="primary" color={color}>
      {isEditting ? ts.t('Holiday.EditHoliday') : ts.t('Holiday.NewHoliday')}
    </Heading>
  );

  return (
    <>
      {heading}
      <Fieldset
        labelProps={{ size: 'Heading6', fontWeight: 'regular' }}
        label={ts.t('Holiday.AddressChoiceStep.FieldSetLabel')}
      >
        <Box pb={6}>
          <HolidayAddresses
            chosenResolution={submissionData.vacationSolution}
            chosenAddress={submissionData.solutionData}
            setSubmissionData={setSubmissionData}
          />
        </Box>
      </Fieldset>
      <Flex justifyContent="flex-end" mb={5}>
        <Button mr={3} onClick={handleOnPreviousStep} iconLeft={ChevronBack}>
          {ts.t('Common.Back')}
        </Button>
        <Button
          onClick={onNextStep}
          iconRight={ChevronForward}
          disabled={!isEmpty(errors)}
          loading={isloadingStreetFullName}
        >
          {isEditting ? ts.t('Common.Edit') : ts.t('Common.Next')}
        </Button>
      </Flex>
    </>
  );
};

export default AhAddressChoiceStep;
