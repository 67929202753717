/* eslint-disable babel/camelcase */
import {
  Divider,
  Heading,
  LinkText,
  List,
  ListItem,
  Paragraph,
  Text,
} from '@mediahuis/chameleon-react-legacy';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { DATE_FORMAT_READABLE } from '~/constants';
import { useGlobalContext } from '~/context';
import {
  DEATH_REASON,
  MEDICAL_REASON,
} from '~/pages/Subscription/constants/onlineCancellation';
import { getCancellationReasonCategory } from '~/pages/Subscription/utils/onlineCancellation';
import { pushTrackingEvent } from '~/utils/tealium';

export default function CancellationConfirmation({
  handleClose,
  selectedCancellationReason,
}) {
  const { userInfo, chosenUserSubscription } = useGlobalContext();

  const subscriptionEndDate = dayjs(chosenUserSubscription?.endDate).format(
    DATE_FORMAT_READABLE,
  );
  const medicalOrDeathReason = [DEATH_REASON, MEDICAL_REASON].includes(
    selectedCancellationReason?.code,
  );

  const deathReason = selectedCancellationReason?.code === DEATH_REASON;

  const listItems = [
    `${deathReason ? 'De' : 'Je'} domiciliëring wordt automatisch stopgezet.`,
    deathReason
      ? `Het abonnement zal stoppen op ${subscriptionEndDate} en kan nog tot die datum gebruikt worden.`
      : `Je abonnement zal stoppen op ${subscriptionEndDate}. Je kan nog lezen tot die datum.`,
  ];

  if (!deathReason) {
    listItems.unshift(
      `Je ontvangt een bevestiging van je opzegging op ${userInfo.emailAddress}.`,
    );
  }

  const defaultTrackingParams = {
    cancellation_step: 4,
    cancellation_reason: selectedCancellationReason?.label,
    cancellation_reason_id: selectedCancellationReason?.code,
    cancellation_reason_category: getCancellationReasonCategory({
      medicalOrDeathReason,
    }),
  };

  useEffect(() => {
    pushTrackingEvent(chosenUserSubscription, {
      ...defaultTrackingParams,
      tealium_event: 'view_cancellation_confirmation',
    });
  }, []);

  return (
    <div>
      <Heading level={3} mb={6} fontFamily="primary">
        Je hebt {deathReason ? 'het' : 'je'} abonnement opgezegd
      </Heading>
      <Heading level={4} mb={4}>
        {deathReason ? 'Het' : 'Jouw'} abonnement stopt op {subscriptionEndDate}
        :
      </Heading>
      <List as="ol" mb={6}>
        {listItems.map(item => {
          return (
            <ListItem key={item}>
              <Text size="Heading5" fontFamily="primary">
                {item}
              </Text>
            </ListItem>
          );
        })}
      </List>
      {!deathReason && (
        <>
          <Divider my={6} />
          <Heading level={4} mb={4} fontFamily="primary">
            Heb je nu al spijt?
          </Heading>
          <Paragraph fontFamily="primary" mb={5}>
            Dat kan gebeuren.{' '}
            <LinkText
              onClick={() => {
                handleClose();
                // ScrollTo and timeout is needed because when the modal is closed
                // the WideListItem is automaticly focused
                pushTrackingEvent(chosenUserSubscription, {
                  ...defaultTrackingParams,
                  tealium_event: 'click_regret',
                });
              }}
              href="#customer-service"
            >
              Neem contact met ons op
            </LinkText>{' '}
            en we draaien de klok terug.
          </Paragraph>
        </>
      )}
    </div>
  );
}
