import React from 'react';
import { Box, Choice } from '@mediahuis/chameleon-react-legacy';
import useHolidayContext from '~/context/hooks/useHolidayContext';
import { config, ts } from '~/services';
import {
  AH_HOLIDAY_SOLUTIONS,
  countries,
  HOLIDAY_SOLUTIONS,
  titles,
} from '~/pages/Holiday/data';
import getEmptySolutionData from '../../utils/getEmptySolutionData';

const HolidayAddresses = ({
  chosenResolution,
  chosenAddress,
  setSubmissionData,
}) => {
  const isFamilyAddresses =
    chosenResolution === HOLIDAY_SOLUTIONS.FAMILY_ADDRESS ||
    chosenResolution === AH_HOLIDAY_SOLUTIONS.FAMILY_ADDRESS;

  const holidayState = useHolidayContext();
  const { holidayArrangementOptions } = holidayState;
  const addresses = isFamilyAddresses
    ? holidayArrangementOptions.knownFamilyAddresses
    : holidayArrangementOptions.knownHolidayAddresses;

  const color = config.isAportal ? 'colorSecondaryBase' : 'colorPrimaryBase';
  return (
    <>
      <Box pb={2}>
        <Choice
          color={color}
          value={null}
          data-testid={null}
          name="new"
          id={null}
          title={ts.t('Holiday.NewAddress')}
          checked={!chosenAddress?.id}
          onChange={() =>
            setSubmissionData(prevState => ({
              ...prevState,
              solutionData: getEmptySolutionData(),
            }))
          }
        />
      </Box>
      {addresses.map(address => {
        const nameString = (
          <>
            {titles[address?.title]} {address?.firstName} {address?.lastName}
          </>
        );
        const addressLine1String = (
          <>
            {address?.street} {address?.houseNumber}
            {address?.box && `/${address.box}`}{' '}
          </>
        );
        const addressLine2String = (
          <>
            {address?.postalCode}{' '}
            {address?.city?.charAt(0) + address?.city?.slice(1)?.toLowerCase()},{' '}
            {config.isMHLU
              ? address.countryCode
              : countries[address?.countryCode]}{' '}
            <br />
            {address?.extraInfo}
          </>
        );

        const addressTitle = isFamilyAddresses
          ? nameString
          : addressLine1String;
        const addressCaption = isFamilyAddresses ? (
          <>
            {addressLine1String}
            <br />
            {addressLine2String}
          </>
        ) : (
          addressLine2String
        );
        return (
          <Box pb={2} key={address.id}>
            <Choice
              color={color}
              value={address.id}
              data-testid={address.id}
              name={address.id}
              id={address.id}
              title={addressTitle}
              message={addressCaption}
              checked={chosenAddress?.id === address.id}
              onChange={() =>
                setSubmissionData(prevState => ({
                  ...prevState,
                  solutionData: { ...address, extra: address.extraInfo },
                }))
              }
            />
          </Box>
        );
      })}
    </>
  );
};

export default HolidayAddresses;
