import { Box, Fieldset, Flex, Radio } from '@mediahuis/chameleon-react-legacy';
import React from 'react';
import { sortBySequence } from '~/pages/SmartWebform/utils';
import { ts } from '~/services';
import { clearValidationOnFocus, getResponsiveValue } from '~/utils';

export default function Level3({
  items = [],
  state,
  setState,
  errors,
  setErrors,
}) {
  const sortedItems = sortBySequence(items);
  return (
    <Fieldset
      label={ts.t('SmartWebform.level3Label')}
      error={!!errors?.level3}
      message={errors?.level3}
    >
      <Flex
        mb={6}
        flexDirection={getResponsiveValue({
          desktop: 'row',
          mobile: 'column',
        })}
      >
        {sortedItems.map(item => {
          return (
            <Box
              key={item.id}
              mr={getResponsiveValue({
                desktop: 3,
                mobile: 0,
              })}
              mb={getResponsiveValue({
                desktop: 0,
                mobile: 3,
              })}
            >
              <Radio
                name={item.label}
                id={item.id}
                value={item.id}
                label={item.label}
                checked={item.id === state.level3}
                onChange={e => {
                  const value = e.target.value;
                  setState(prevState => ({
                    ...prevState,
                    level3: value,
                    level4: '',
                  }));
                }}
                onFocus={() =>
                  clearValidationOnFocus({
                    e: {
                      target: {
                        name: 'level3',
                      },
                    },
                    errors,
                    setErrors,
                  })
                }
              />
            </Box>
          );
        })}
      </Flex>
    </Fieldset>
  );
}
