import React from 'react';

export default function StepFourIcon({ width = 180, height = 163 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 180 163"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M180 73.2187L179.62 73.4717L179.908 72.999L180 73.2187Z"
        fill="#E6E6E6"
      />
      <path
        opacity="0.45"
        d="M90 162.997C139.706 162.997 180 160.541 180 157.511C180 154.482 139.706 152.025 90 152.025C40.2944 152.025 0 154.482 0 157.511C0 160.541 40.2944 162.997 90 162.997Z"
        fill="#E6E6E6"
      />
      <path
        d="M155.075 77.5932C155.48 78.1634 155.746 78.8226 155.853 79.517C155.959 80.2115 155.903 80.9216 155.688 81.5898C155.473 82.2579 155.106 82.8652 154.617 83.3623C154.127 83.8594 153.529 84.2323 152.871 84.4506C149.782 85.5092 144.842 86.2748 138.145 84.4506C125.958 81.1218 121.708 60.5362 121.708 60.5362C121.708 60.5362 119.412 57.0742 121.131 55.5896C122.849 54.1049 124.246 59.5242 124.246 59.5242C124.246 59.5242 126.765 69.3976 135.863 73.006C143.255 75.9354 151.539 72.6399 155.075 77.5932Z"
        fill="#F4A28C"
      />
      <path
        opacity="0.09"
        d="M155.075 77.5932C155.48 78.1634 155.746 78.8226 155.853 79.517C155.959 80.2115 155.903 80.9216 155.688 81.5898C155.473 82.2579 155.106 82.8652 154.617 83.3623C154.127 83.8594 153.529 84.2323 152.871 84.4506C149.782 85.5092 144.842 86.2748 138.145 84.4506C125.958 81.1218 121.708 60.5362 121.708 60.5362C121.708 60.5362 119.412 57.0742 121.131 55.5896C122.849 54.1049 124.246 59.5242 124.246 59.5242C124.246 59.5242 126.765 69.3976 135.863 73.006C143.255 75.9354 151.539 72.6399 155.075 77.5932Z"
        fill="black"
      />
      <path
        d="M158.118 121.4L146.312 153.284L143.99 153.151L148.043 119.822L158.118 121.4Z"
        fill="#F4A28C"
      />
      <path
        d="M166.37 117.925L163.621 131.054L172.667 124.642L174.221 126.706C174.221 126.706 164.894 140.354 158.125 138.337C151.356 136.32 156.865 115.075 156.865 115.075L166.37 117.925Z"
        fill="#F4A28C"
      />
      <path
        d="M154.904 63.0791L157.292 73.9511L151.29 74.8565L151.966 66.581L154.904 63.0791Z"
        fill="#F4A28C"
      />
      <path
        opacity="0.31"
        d="M151.742 68.3852C152.486 68.141 153.142 67.6775 153.625 67.0537C153.625 67.0537 153.664 69.091 151.585 71.2414L151.742 68.3852Z"
        fill="#CE8172"
      />
      <path
        d="M148.745 63.0326C148.81 64.9217 149.123 66.7936 149.676 68.5984C149.756 68.8085 149.876 69.0005 150.029 69.1634C150.182 69.3263 150.365 69.457 150.568 69.5479C150.771 69.6388 150.99 69.6882 151.211 69.6932C151.433 69.6982 151.654 69.6588 151.86 69.5771C152.541 69.3538 153.144 68.9361 153.596 68.374C154.049 67.812 154.332 67.1294 154.412 66.4081L155.068 63.3389C155.134 62.7098 155.006 62.0754 154.702 61.5233C154.397 60.9711 153.932 60.5285 153.369 60.2564C151.329 59.1179 148.581 60.9621 148.745 63.0326Z"
        fill="#F4A28C"
      />
      <path
        d="M152.621 65.5288C152.621 65.5288 152.497 64.1973 153.468 64.1973C154.438 64.1973 154.635 66.0614 153.35 66.3943L152.621 65.5288Z"
        fill="#F4A28C"
      />
      <path
        d="M148.922 65.1689L148.161 66.5804C148.116 66.6608 148.093 66.7513 148.092 66.8435C148.091 66.9357 148.113 67.0266 148.157 67.1077C148.2 67.1888 148.263 67.2574 148.339 67.3073C148.415 67.3571 148.503 67.3865 148.594 67.3926L149.906 67.4792L148.922 65.1689Z"
        fill="#F4A28C"
      />
      <path
        d="M151.29 74.8497C151.29 74.8497 136.689 80.4421 140.657 90.6484C143.53 98.0384 147.368 96.6802 148.332 102.572L149.348 108.371L164.205 103.871C164.205 103.871 162.46 95.382 163.425 89.7496C164.389 84.1172 167.327 70.1094 151.29 74.8497Z"
        fill="#045078"
      />
      <path
        opacity="0.09"
        d="M155.2 78.1318C155.2 78.1318 149.532 86.1943 154.544 94.4298C155.39 95.8213 156.138 97.286 156.859 98.7507L160.008 105.155L164.219 103.877L163.635 100.315L155.2 78.1318Z"
        fill="black"
      />
      <path
        d="M153.468 64.1969C153.468 64.1969 150.884 74.7028 154.347 76.1275C157.81 77.5523 163.445 76.3272 163.596 71.9798C163.747 67.6323 159.509 67.1796 158.637 61.2609C157.765 55.3422 146.614 57.3928 146.981 60.8148C147.348 64.2369 153.468 64.1969 153.468 64.1969Z"
        fill="#EEC274"
      />
      <path
        d="M164.205 103.878L149.348 108.379L146.574 126.115L166.488 125.855C166.488 125.855 171.597 112.54 164.205 103.878Z"
        fill="#24285B"
      />
      <path
        d="M144.16 153.157C144.16 153.157 142.789 154.735 141.156 154.555C139.523 154.376 139.149 156.719 141.812 156.626C142.636 156.602 143.456 156.508 144.265 156.346C145.009 156.215 145.774 156.286 146.482 156.553C146.621 156.592 146.768 156.589 146.905 156.542C147.042 156.496 147.162 156.409 147.25 156.293C148.05 155.441 146.325 153.284 146.325 153.284L144.16 153.157Z"
        fill="#045078"
      />
      <path
        d="M174.011 126.78C174.011 126.78 176.077 126.853 176.989 128.245C177.9 129.636 179.908 128.431 178.15 126.42C177.61 125.788 177.015 125.207 176.372 124.683C175.801 124.18 175.367 123.535 175.113 122.812C175.059 122.681 174.967 122.57 174.85 122.493C174.733 122.416 174.596 122.376 174.457 122.379C173.302 122.293 172.692 124.67 172.692 124.67L174.011 126.78Z"
        fill="#045078"
      />
      <path
        d="M43.8684 153.218C43.8684 153.218 44.9047 154.915 46.5708 154.696C48.2369 154.476 48.9977 156.027 47.6203 156.753C46.2428 157.479 42.0515 156.4 42.0515 156.4L41.5464 153.338L43.8684 153.218Z"
        fill="#045078"
      />
      <path
        d="M53.8717 81.8466C53.8717 81.8466 70.3749 71.1011 76.6128 59.9695L74.5138 57.626C74.5138 57.626 60.3261 70.3688 47.8896 72.5658L53.8717 81.8466Z"
        fill="#F4A28C"
      />
      <path
        d="M76.6122 59.9704C76.645 59.9704 77.9241 58.3393 77.97 58.2261C78.4845 57.5027 78.8054 56.6563 78.9014 55.7694C78.8757 55.2528 78.7103 54.7534 78.4233 54.3259C78.1364 53.8984 77.739 53.5594 77.2747 53.346C77.0739 53.2204 76.8393 53.1619 76.604 53.1786C76.3687 53.1953 76.1445 53.2865 75.9628 53.4392C75.8473 53.5803 75.7603 53.7433 75.707 53.9186C75.5693 54.2954 75.4533 54.68 75.3594 55.0704C75.2928 54.779 75.1961 54.4956 75.0708 54.2249C75.0183 54.1117 74.9462 53.9852 74.8215 53.9652C74.7632 53.9588 74.7043 53.9716 74.6536 54.0015C74.6029 54.0314 74.5629 54.077 74.5395 54.1316C74.4944 54.2413 74.4721 54.3591 74.4739 54.4778C74.4411 55.1436 74.3952 55.8094 74.3755 56.4752C74.3922 56.7146 74.425 56.9526 74.4739 57.1875C74.4739 57.2741 74.4739 57.5604 74.5395 57.6336L76.6122 59.9704Z"
        fill="#F4A28C"
      />
      <path
        d="M31.4979 86.5341C37.4866 88.6047 47.2402 89.6699 57.6629 81.2812L54.8621 69.1709C54.8621 69.1709 38.1031 71.5211 29.9368 78.4917C29.3131 79.0343 28.8426 79.7354 28.5734 80.5232C28.3042 81.311 28.246 82.1572 28.4049 82.9753C28.5637 83.7934 28.9337 84.554 29.4772 85.1793C30.0206 85.8046 30.718 86.2722 31.4979 86.5341Z"
        fill="#045078"
      />
      <path
        opacity="0.46"
        d="M31.4979 86.5341C37.4866 88.6047 47.2402 89.6699 57.6629 81.2812L54.8621 69.1709C54.8621 69.1709 38.1031 71.5211 29.9368 78.4917C29.3131 79.0343 28.8426 79.7354 28.5734 80.5232C28.3042 81.311 28.246 82.1572 28.4049 82.9753C28.5637 83.7934 28.9337 84.554 29.4772 85.1793C30.0206 85.8046 30.718 86.2722 31.4979 86.5341Z"
        fill="white"
      />
      <path
        d="M41.6052 61.3822C42.1386 62.9173 42.4652 64.5185 42.5759 66.1424C42.576 66.5333 42.4234 66.9082 42.1518 67.1852C41.8801 67.4622 41.5114 67.6187 41.1263 67.6204C40.5118 67.6568 39.8999 67.513 39.3638 67.206C38.8276 66.8991 38.3897 66.442 38.1025 65.8894L36.6529 63.6325C36.4056 63.1494 36.3125 62.6001 36.3866 62.0609C36.4606 61.5217 36.6981 61.0192 37.0661 60.6232C38.3321 59.0586 41.1067 59.6711 41.6052 61.3822Z"
        fill="#F4A28C"
      />
      <path
        d="M36.9023 64.0381L37.7944 73.5852L42.9435 72.7064L40.2279 66.1153L36.9023 64.0381Z"
        fill="#F4A28C"
      />
      <path
        d="M40.943 61.6816C40.0794 61.8967 39.203 62.0546 38.3193 62.1543C38.666 62.4751 38.9128 62.8918 39.0293 63.3531C39.1459 63.8144 39.127 64.3002 38.9752 64.7508C38.8283 65.1831 38.5398 65.5511 38.1578 65.7935C37.7758 66.0358 37.3234 66.1378 36.8762 66.0823C36.739 65.661 36.6359 65.229 36.5679 64.7907C36.3974 64.2981 36.135 63.7521 35.5906 63.6256C35.2506 63.6176 34.9122 63.5752 34.5805 63.4991C34.4782 63.4483 34.3878 63.3759 34.3153 63.2868C34.2428 63.1976 34.1899 63.0938 34.1601 62.9823C34.1303 62.8708 34.1243 62.754 34.1425 62.64C34.1607 62.5259 34.2027 62.417 34.2656 62.3207C34.4624 62.0544 34.8363 61.868 34.8232 61.5418C34.81 61.2156 34.5018 61.0891 34.2984 60.876C34.1179 60.6681 34.0008 60.4115 33.9612 60.1373C33.9216 59.8631 33.9613 59.5832 34.0754 59.3314C34.3157 58.8334 34.6658 58.3982 35.0986 58.0598C35.185 58.0034 35.2462 57.915 35.2692 57.8135C35.2692 57.6604 35.1314 57.5605 35.033 57.4473C34.926 57.2997 34.8682 57.1213 34.8682 56.938C34.8682 56.7547 34.926 56.5762 35.033 56.4287C35.2544 56.1287 35.5544 55.898 35.8989 55.7629C36.8125 55.3847 37.7806 55.1598 38.7653 55.0971C39.2043 55.0168 39.6563 55.0535 40.0771 55.2037C40.2793 55.2801 40.4521 55.4202 40.5701 55.6035C40.6881 55.7868 40.7452 56.0037 40.7331 56.2223C40.674 56.6351 40.2805 56.9879 40.3592 57.4007C40.4379 57.8135 40.9627 57.9799 41.389 57.9866C41.8171 57.9179 42.255 58.0154 42.6156 58.2595C43.0551 58.6923 42.7009 59.5378 42.1171 59.7309C42.0015 59.7577 41.8922 59.8075 41.7957 59.8774C41.6251 60.0371 41.6973 60.3367 41.835 60.5431C41.995 60.7206 42.1138 60.9321 42.1827 61.1623C42.2548 61.7082 41.4021 61.5751 41.0938 61.6283L40.943 61.6816Z"
        fill="#EEC274"
      />
      <path
        d="M39.2773 64.584C39.2773 64.584 38.9822 63.4988 38.195 63.7984C37.4079 64.098 37.8277 65.6625 38.9625 65.5227L39.2773 64.584Z"
        fill="#F4A28C"
      />
      <path
        d="M42.1235 63.1523L43.1795 64.0445C43.2387 64.0947 43.2844 64.1591 43.3125 64.232C43.3407 64.3048 43.3503 64.3837 43.3405 64.4613C43.3307 64.5389 43.3018 64.6127 43.2564 64.676C43.2111 64.7393 43.1508 64.79 43.0811 64.8234L42.0645 65.3094L42.1235 63.1523Z"
        fill="#F4A28C"
      />
      <path
        opacity="0.31"
        d="M40.8842 67.526C40.2142 67.5313 39.5573 67.3368 38.9951 66.9668C39.5273 68.244 40.5163 69.2692 41.7631 69.8363L40.8842 67.526Z"
        fill="#CE8172"
      />
      <path
        d="M24.4004 79.0712C25.8747 76.3862 28.1467 74.2422 30.8922 72.9451C33.6378 71.6479 36.7168 71.2639 39.6901 71.8476L41.9662 72.2737C41.9662 72.2737 58.043 74.6638 57.0526 89.6969C56.3102 98.9898 53.0779 107.9 47.7056 115.462L26.0009 112.959C26.0009 112.959 17.7952 91.4944 24.4004 79.0712Z"
        fill="#045078"
      />
      <path
        d="M44.5243 153.41L47.7055 115.461L26.0008 112.958L18.8315 153.77H22.8786L36.4498 127.292C36.6072 125.907 40.7986 153.77 40.7986 153.77L44.5243 153.41Z"
        fill="#24285B"
      />
      <path
        opacity="0.08"
        d="M35.5255 79.0975C35.5255 79.0975 36.9686 90.5954 34.253 101.101C31.5375 111.607 34.253 113.937 34.253 113.937L26.0014 112.985C26.0014 112.985 24.1911 108.325 23.8631 105.961C23.5352 103.598 23.7319 77.7394 35.5255 79.0975Z"
        fill="black"
      />
      <path
        d="M25.1223 85.6814C29.563 90.2552 37.8211 95.6346 50.8348 92.8584L49.1687 78.2581C49.1687 78.2581 37.644 75.2555 27.2803 77.8187C26.4888 78.0216 25.7642 78.4334 25.18 79.0122C24.5957 79.5909 24.1726 80.3162 23.9536 81.1145C23.7345 81.9127 23.7272 82.7555 23.9324 83.5576C24.1377 84.3596 24.5482 85.0923 25.1223 85.6814Z"
        fill="#045078"
      />
      <path
        opacity="0.46"
        d="M25.1223 85.6814C29.563 90.2552 37.8211 95.6346 50.8348 92.8584L49.1687 78.2581C49.1687 78.2581 37.644 75.2555 27.2803 77.8187C26.4888 78.0216 25.7642 78.4334 25.18 79.0122C24.5957 79.5909 24.1726 80.3162 23.9536 81.1145C23.7345 81.9127 23.7272 82.7555 23.9324 83.5576C24.1377 84.3596 24.5482 85.0923 25.1223 85.6814Z"
        fill="white"
      />
      <path
        d="M50.6769 91.4942C50.6769 91.4942 70.1776 89.377 80.6594 82.2533L79.8132 79.2041C79.8132 79.2041 61.5128 84.1574 49.4175 80.4824L50.6769 91.4942Z"
        fill="#F4A28C"
      />
      <path
        d="M80.6594 82.2399C81.5872 81.8312 82.4859 81.3574 83.3487 80.8218C83.9527 80.4215 84.4273 79.8496 84.7131 79.1774C84.8125 78.7462 84.8213 78.2987 84.7388 77.8639C84.6563 77.4291 84.4843 77.0169 84.2342 76.6541C84.1332 76.4858 83.9825 76.3541 83.8035 76.2777C83.6246 76.2012 83.4265 76.1838 83.2372 76.228C83.0337 76.3172 82.8539 76.4541 82.7125 76.6275C82.3583 77.0203 82.0041 77.4131 81.6564 77.8125C81.6557 77.4468 81.6116 77.0824 81.5253 76.7273C81.5253 76.6541 81.4728 76.5609 81.3941 76.5542C81.3463 76.5536 81.2999 76.5701 81.2629 76.6008C80.338 77.2267 79.2361 78.4583 79.5312 79.5169C79.7477 80.3092 80.443 81.4543 80.6594 82.2399Z"
        fill="#F4A28C"
      />
      <path
        d="M19.4548 153.717C19.4548 153.717 18.4119 155.415 16.7524 155.195C15.0929 154.975 14.3189 156.526 15.7029 157.252C17.0869 157.978 21.2717 156.899 21.2717 156.899L21.7768 153.837L19.4548 153.717Z"
        fill="#045078"
      />
      <path
        d="M160.387 76.6876C161.054 76.8781 161.666 77.2252 162.176 77.7009C162.686 78.1765 163.078 78.7673 163.32 79.4254C163.563 80.0834 163.65 80.7903 163.573 81.4887C163.497 82.187 163.259 82.8573 162.88 83.4451C161.115 86.228 157.731 89.9563 151.375 92.7659C139.811 97.8856 123.826 84.4837 123.826 84.4837C123.826 84.4837 119.89 83.2321 120.33 80.9885C120.769 78.7448 125.184 82.087 125.184 82.087C125.184 82.087 133.225 88.2054 142.546 85.2627C150.129 82.8792 154.582 75.0565 160.387 76.6876Z"
        fill="#F4A28C"
      />
      <path
        d="M70.2437 39.8566C70.618 40.5866 71.1331 41.2329 71.7582 41.757C72.3833 42.2811 73.1057 42.6725 73.8827 42.9078C74.6596 43.1432 75.4752 43.2177 76.2811 43.127C77.087 43.0363 77.8667 42.7823 78.574 42.3799C84.8315 38.8114 95.2411 34.2309 98.7504 26.6012C103.303 16.7079 83.4475 3.71875 83.4475 3.71875L80.5811 7.04759C80.5811 7.04759 106.313 23.4921 73.9694 29.9367C69.8501 30.7623 68.2956 36.1017 70.2437 39.8766V39.8566Z"
        fill="#045078"
      />
      <path
        opacity="0.46"
        d="M70.2437 39.8566C70.618 40.5866 71.1331 41.2329 71.7582 41.757C72.3833 42.2811 73.1057 42.6725 73.8827 42.9078C74.6596 43.1432 75.4752 43.2177 76.2811 43.127C77.087 43.0363 77.8667 42.7823 78.574 42.3799C84.8315 38.8114 95.2411 34.2309 98.7504 26.6012C103.303 16.7079 83.4475 3.71875 83.4475 3.71875L80.5811 7.04759C80.5811 7.04759 106.313 23.4921 73.9694 29.9367C69.8501 30.7623 68.2956 36.1017 70.2437 39.8766V39.8566Z"
        fill="white"
      />
      <path
        d="M127.709 44.0848C127.709 44.0848 129.106 43.1927 128.922 41.7746C128.739 40.3565 130.005 39.6841 130.621 40.8625C131.238 42.0409 130.359 45.6294 130.359 45.6294L127.814 46.0755L127.709 44.0848Z"
        fill="#045078"
      />
      <path
        d="M129.743 74.0983C129.743 74.0983 131.284 74.7108 132.301 73.7121C133.318 72.7135 134.597 73.346 134.033 74.5577C133.468 75.7694 130.097 77.1741 130.097 77.1741L128.228 75.3699L129.743 74.0983Z"
        fill="#045078"
      />
      <path
        d="M58.8107 27.5804C60.0949 28.5279 61.2467 29.6477 62.2346 30.9092C62.4435 31.2258 62.5207 31.6133 62.4495 31.9873C62.3782 32.3614 62.1643 32.6918 61.8542 32.9065C61.374 33.2836 60.797 33.5123 60.1922 33.5655C59.5874 33.6186 58.9802 33.4938 58.4434 33.2061L56.0164 32.1875C55.5538 31.9352 55.1793 31.5439 54.9445 31.0671C54.7096 30.5903 54.6258 30.0514 54.7046 29.5244C54.8751 27.5271 57.466 26.4619 58.8107 27.5804Z"
        fill="#F4A28C"
      />
      <path
        d="M56.4297 32.3878L62.3855 39.6646L66.1046 36.0495L60.28 32.208L56.4297 32.3878Z"
        fill="#F4A28C"
      />
      <path
        d="M58.4361 28.1861C57.8826 28.882 57.2508 29.5098 56.5536 30.0569C57.0135 30.1183 57.4454 30.3159 57.7953 30.6249C58.1453 30.9339 58.3978 31.3407 58.5214 31.7945C58.6422 32.2309 58.6132 32.6959 58.4392 33.1133C58.2652 33.5307 57.9564 33.8757 57.5637 34.0914L54.8482 31.6081C54.4476 31.2681 54.1457 30.824 53.9747 30.3232C53.8037 29.8225 53.7701 29.2839 53.8774 28.7653C53.9648 28.3136 54.0831 27.8687 54.2316 27.4337C54.6055 26.3485 56.1535 25.6428 56.2388 24.4444C56.2391 24.3202 56.2736 24.1986 56.3384 24.0932C56.4033 23.9879 56.4959 23.9031 56.6057 23.8483C56.7156 23.7936 56.8384 23.7712 56.9601 23.7836C57.0819 23.796 57.1978 23.8427 57.2948 23.9185C58.2393 24.5377 59.42 25.8559 58.8887 27.4138C58.7811 27.6949 58.6282 27.9559 58.4361 28.1861Z"
        fill="#24285B"
      />
      <path
        d="M58.6462 31.5084C58.6462 31.5084 57.8132 30.7893 57.3343 31.4751C56.8555 32.1608 58.0558 33.1994 58.902 32.4471L58.6462 31.5084Z"
        fill="#F4A28C"
      />
      <path
        d="M60.2012 28.7256L61.513 28.8521C61.5854 28.8611 61.6545 28.8877 61.7146 28.9296C61.7746 28.9715 61.8238 29.0276 61.8579 29.0929C61.8921 29.1583 61.9101 29.2311 61.9106 29.305C61.9111 29.379 61.894 29.452 61.8607 29.5179L61.3031 30.4832L60.2012 28.7256Z"
        fill="#F4A28C"
      />
      <path
        opacity="0.31"
        d="M61.5785 32.9932C61.0354 33.3731 60.3948 33.5837 59.7354 33.599C60.8636 34.3367 62.2247 34.6149 63.5463 34.378L61.5785 32.9932Z"
        fill="#CE8172"
      />
      <path
        d="M62.385 39.664L64.9169 37.2006C65.5336 36.6023 66.1161 35.9688 66.6617 35.3032C68.3999 33.1594 74.0934 27.274 82.5024 30.0702C92.6955 33.4523 98.5202 53.5452 98.5202 53.5452L86.8905 67.0936C86.8905 67.0936 46.4919 59.4373 62.385 39.664Z"
        fill="#045078"
      />
      <path
        d="M81.1189 6.40253C81.1189 6.40253 76.6127 4.35862 76.2585 2.40792C75.9043 0.457218 79.7349 3.43986 79.7349 3.43986C79.7349 3.43986 81.5321 -0.00881934 83.4475 3.69951L81.1189 6.40253Z"
        fill="#F4A28C"
      />
      <path
        d="M80.0757 4.07213C80.0757 4.07213 76.9928 0.743289 77.9832 0.0775205C78.9737 -0.588248 81.3613 3.25323 81.3613 3.25323L80.0757 4.07213Z"
        fill="#F4A28C"
      />
      <path
        d="M86.8911 67.0805C86.8911 67.0805 95.4772 74.3506 100.613 67.6064C105.749 60.8622 106.635 45.1101 127.913 46.5681L127.742 42.993C127.742 42.993 111.036 36.3819 97.6549 50.9289L86.8911 67.0805Z"
        fill="#24285B"
      />
      <path
        d="M93.752 70.0096C93.752 70.0096 110.15 58.4585 126.745 76.5408L129.743 74.0974C129.743 74.0974 119.904 54.3574 94.7227 57.2735L93.752 70.0096Z"
        fill="#24285B"
      />
      <path
        opacity="0.08"
        d="M70.8532 44.2246C70.8532 44.2246 74.7101 46.2219 77.1501 53.5454C79.5902 60.8688 92.184 59.1844 92.184 59.1844L86.8907 67.1204C86.8907 67.1204 73.8311 64.3108 66.7864 59.7237C59.7418 55.1365 70.6695 44.6707 70.6695 44.6707L70.8532 44.2246Z"
        fill="black"
      />
      <path
        d="M62.4445 55.3233C72.0014 59.4311 93.5355 64.977 98.1861 34.3383L93.8963 32.6406C93.8963 32.6406 85.3692 53.1663 68.6561 43.7922C68.0344 43.4345 67.3903 43.1187 66.7277 42.8469C63.7564 41.6485 56.3509 39.3782 56.4427 46.4686C56.4911 48.3759 57.084 50.2279 58.1494 51.7997C59.2148 53.3715 60.7069 54.5956 62.4445 55.3233Z"
        fill="#045078"
      />
      <path
        opacity="0.46"
        d="M62.4445 55.3233C72.0014 59.4311 93.5355 64.977 98.1861 34.3383L93.8963 32.6406C93.8963 32.6406 85.3692 53.1663 68.6561 43.7922C68.0344 43.4345 67.3903 43.1187 66.7277 42.8469C63.7564 41.6485 56.3509 39.3782 56.4427 46.4686C56.4911 48.3759 57.084 50.2279 58.1494 51.7997C59.2148 53.3715 60.7069 54.5956 62.4445 55.3233Z"
        fill="white"
      />
      <path
        d="M94.6572 32.9398C94.9327 31.4262 95.6135 30.0188 96.625 28.872C98.1796 27.3008 99.7669 27.5404 100.646 28.7654C101.525 29.9905 97.6548 32.0211 97.6548 32.0211L97.5761 34.0916L94.6572 32.9398Z"
        fill="#F4A28C"
      />
      <path
        opacity="0.5"
        d="M33.7283 45.0056L24.501 50.5137L25.9541 53.0215L35.1814 47.5135L33.7283 45.0056Z"
        fill="#E6E6E6"
      />
      <path
        opacity="0.5"
        d="M140.387 45.9532L150.741 43.1924L150.012 40.3785L139.659 43.1393L140.387 45.9532Z"
        fill="#E6E6E6"
      />
      <path
        opacity="0.5"
        d="M114.695 25.4209L121.979 27.8115L122.608 25.8351L115.325 23.4445L114.695 25.4209Z"
        fill="#E6E6E6"
      />
      <path
        opacity="0.5"
        d="M144.701 21.6724L141.431 28.6973L143.282 29.5846L146.552 22.5598L144.701 21.6724Z"
        fill="#E6E6E6"
      />
      <path
        opacity="0.5"
        d="M108.111 77.1831L104.841 84.208L106.691 85.0954L109.961 78.0705L108.111 77.1831Z"
        fill="#E6E6E6"
      />
      <path
        opacity="0.5"
        d="M164.27 33.5006L171.853 34.5645L172.133 32.5068L164.55 31.443L164.27 33.5006Z"
        fill="#E6E6E6"
      />
      <path
        opacity="0.5"
        d="M41.7721 35.8859L49.1455 37.9727L49.6952 35.9718L42.3217 33.885L41.7721 35.8859Z"
        fill="#E6E6E6"
      />
      <path
        opacity="0.5"
        d="M66.8437 20.6835L74.3442 19.1318L73.9355 17.0965L66.435 18.6481L66.8437 20.6835Z"
        fill="#E6E6E6"
      />
      <path
        opacity="0.5"
        d="M29.7222 22.7343L37.2227 21.1826L36.8139 19.1473L29.3135 20.6989L29.7222 22.7343Z"
        fill="#E6E6E6"
      />
    </svg>
  );
}
