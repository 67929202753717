const normalizeConsentData = didomi => {
  // Get the required purposes from Didomi
  const purposes = didomi.getRequiredPurposes().map(purpose => purpose.id);
  // Get the current State for each purpose
  const status = purposes.map(
    purpose => !!didomi.getUserConsentStatusForPurpose(purpose),
  );

  const toCamel = s =>
    s.replace(/([-_][a-z])/gi, $1 =>
      $1
        .toUpperCase()
        .replace('-', '')
        .replace('_', ''),
    );

  // Create an object with the matching value for each purpose to store
  return purposes.reduce((accum, purpose, i) => {
    accum[toCamel(purpose)] = status[i];
    return accum;
  }, {});
};

export default normalizeConsentData;
