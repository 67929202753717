import React from 'react';
import { Heading } from '@mediahuis/chameleon-react-legacy';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import { ts } from '~/services';
import PaymentProviders from '../paymentProviders';

const PaymentDialog = ({ setDialogShown }) => {
  const { chosenUserSubscription } = useGlobalContext();

  return (
    <React.Fragment>
      <Heading level={4} mb={5} color="primaryBase">
        {ts.t('Subscription.PaymentDialog.Heading')}
      </Heading>
      <PaymentProviders
        method={chosenUserSubscription.paymentMethod}
        setDialogShown={setDialogShown}
      />
    </React.Fragment>
  );
};

export default PaymentDialog;
