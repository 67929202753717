export * from './pages';
export * from './metadata';
export * from './date';
export * from './messages';
export * from './general';
export * from './language';
export * from './locale';
export * from './payment';
export * from './style';
export * from './config';
export * from './sso';
export * from './targets';

export const SUBSCRIPTION_SCOPES = {
  PLUS_ARTICLES: 'PlusArticles',
  PUBLICATION: 'Publication',
};

export const SUBSCRIPTION_STATUS_CODES = Object.freeze({
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
  WAITING: 'waiting',
  TEMPORARY_SUSPENSION: 'temporarysuspension',
  GRACE_PERIOD: 'graceperiod',
  FUTURE: 'future',
  CANCELLED: 'cancelled',
});

export const AH_SUBSCRIPTION_STATUS_CODES = Object.freeze({
  ACTIVE: 'Active',
  FUTURE: 'Future',
  CANCELLED: 'Cancelled',
  OTHER: 'Other',
});

export const DEEPLINK_KEY = 'deeplink';

export const ENVIRONMENTS = {
  LOCAL: 'local',
  TEST: 'test',
  PREVIEW: 'preview',
  PRODUCTION: 'production',
};
