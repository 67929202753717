import React from 'react';
import AlternativeOffers from '~/pages/Subscription/components/DialogContent/OnlineCancellation/AlternativeOffers';
import MedicalConfirmation from '~/pages/Subscription/components/DialogContent/OnlineCancellation/MedicalConfirmation';

export default function ThirdStep({
  setOnlineCancellationStep,
  medicalOrDeathReason,
  setSelectedAlternativeOffer,
  phoneNumber,
  handleSubmit,
  isSubmitting,
  error,
  setSubmittingHasFailed,
  selectedCancellationReason,
}) {
  return medicalOrDeathReason ? (
    <MedicalConfirmation
      phoneNumber={phoneNumber}
      selectedCancellationReason={selectedCancellationReason}
    />
  ) : (
    <AlternativeOffers
      setOnlineCancellationStep={setOnlineCancellationStep}
      setSelectedAlternativeOffer={setSelectedAlternativeOffer}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      error={error}
      setSubmittingHasFailed={setSubmittingHasFailed}
      selectedCancellationReason={selectedCancellationReason}
    />
  );
}
