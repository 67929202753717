import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  Banner,
  Box,
  Button,
  Choice,
  DatePicker,
  Flex,
  Heading,
  Paragraph,
  Select,
} from '@mediahuis/chameleon-react-legacy';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import isEmpty from 'lodash.isempty';
import styled from 'styled-components';
import {
  useBanner,
  useDeliveryComplaintContext,
  useGlobalContext,
} from '~/context';
import { config, ts } from '~/services';
import { ContentLoader, Dialog, DisabledSubscriptionInfo } from '~/components';
import { handleBannerMessage, mapToDeliveryDate, validateDates } from '~/utils';
import EntitySwitch from '~/components/ah/EntitySwitch';
import AhDisabledSubscriptionInfo from '~/components/ah/AhDisabledSubscriptionInfo';
import {
  BANNER_TYPES,
  DATE_FORMAT_BE,
  PERSONAL_PRONOUN,
} from '../../../constants';
import {
  ERROR_COMMENT_REQUIRED,
  ERROR_NO_SELECTION,
  TOO_LATE,
  WRONG_MAILBOX,
} from '../constants';
import {
  getDeliveryComplaintData,
  getResolutionOptionLabel,
  isDayDisabled,
  postDeliveryComplaint,
  postSuccessMessage,
} from '../utils';
import ChosenComplaintExtra from './ChosenComplaintExtra';

const mapResolution = resolution => {
  switch (resolution) {
    case 'ExtendSubscription':
      return ts.t('DeliveryComplaint.Resolution.ExtendedSubscription');
    case 'CreditNota':
      if (config.brand !== 'dl') {
        return null;
      }
      return ts.t('DeliveryComplaint.Resolution.CreditNota');
    default:
      if (config.brand === 'dl') {
        return null;
      }
      return ts.t('DeliveryComplaint.Resolution.Default');
  }
};

const StyledBox = styled.div`
  &.single-select [class^='Choice_iconContainer'] {
    display: none;
  }

  &.single-select [class^='Choice_header'] {
    padding: 16px;
  }
`;
const DeliveryComplaintDialog = ({
  show,
  onClose,
  setDisabled,
  setIsBtnLoading,
}) => {
  const { chosenUserSubscription } = useGlobalContext();
  const { showBanner } = useBanner();
  const {
    deliveryComplaintState,
    setDeliveryComplaintState,
    deliveryComplaintErrors,
    setDeliveryComplaintErrors,
  } = useDeliveryComplaintContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isHandleSaveLoading, setIsHandleSaveLoading] = useState(false);
  const [messageBannerShown, setMessageBannerShown] = useState(false);

  const handleChosenComplaintChanged = ({ complaint, availableComplaints }) => {
    setDeliveryComplaintErrors({});

    const commentRegExp = new RegExp('[0-9]u[0-9]');
    const commentIsHour = commentRegExp.test(deliveryComplaintState.comment);

    setDeliveryComplaintState({
      comment: commentIsHour ? '' : deliveryComplaintState.comment,
      chosenComplaint: {
        chosenOption: availableComplaints[complaint].resolutions[0],
        complaint,
        ...availableComplaints[complaint],
      },
    });
  };

  const handleDateChanged = (value, meta) => {
    if (value) {
      const newState = {
        deliveryDate: value,
        comment: '',
        chosenComplaint: {},
      };
      if (deliveryComplaintState.chosenComplaint?.complaint) {
        const chosenComplaint =
          deliveryComplaintState?.complaintResolutions?.[
            dayjs(value).format(DATE_FORMAT_BE)
          ]?.[deliveryComplaintState.chosenComplaint.complaint];
        newState.chosenComplaint = chosenComplaint
          ? {
              ...chosenComplaint,
              complaint: deliveryComplaintState.chosenComplaint.complaint,
              chosenOption: chosenComplaint.resolutions[0],
            }
          : {};
      }
      setDeliveryComplaintState(newState);
      setDeliveryComplaintErrors(
        validateDates({
          errors: deliveryComplaintErrors,
          fields: [{ id: 'deliveryDate', value, meta }],
        }),
      );
    }
  };

  const handleSave = () => {
    const complaintValue = deliveryComplaintState?.chosenComplaint?.complaint;
    if (!complaintValue) {
      return setDeliveryComplaintErrors({
        chosenComplaint: ERROR_NO_SELECTION,
      });
    } else if (complaintValue === TOO_LATE && !deliveryComplaintState.comment) {
      return setDeliveryComplaintErrors({ comment: ERROR_NO_SELECTION });
    } else if (
      complaintValue === WRONG_MAILBOX &&
      !deliveryComplaintState.comment
    ) {
      return setDeliveryComplaintErrors({
        comment: ERROR_COMMENT_REQUIRED,
      });
    }
    setIsHandleSaveLoading(true);
    return postDeliveryComplaint({
      deliveryComplaintState,
      chosenUserSubscription,
      chosenOption: deliveryComplaintState.chosenComplaint.chosenOption,
      onSuccess: () => {
        showBanner({
          message: postSuccessMessage(deliveryComplaintState),
          type: BANNER_TYPES.SUCCESS,
        });
        onClose();
        setIsHandleSaveLoading(false);
      },
      onError: err => {
        handleBannerMessage({ err, showBanner });
        onClose();
        setIsHandleSaveLoading(false);
      },
    });
  };

  useEffect(() => {
    chosenUserSubscription &&
      getDeliveryComplaintData({
        subscriptionId: chosenUserSubscription.id,
      })
        .then(data => {
          const { deliveryDates } = data;
          const deliveryDate = mapToDeliveryDate({
            deliveryDates,
            reverse: true,
          });

          setDeliveryComplaintState({
            data,
            deliveryDate,
            complaintResolutions: data.deliveries,
          });
          setIsLoading(false);
          setDisabled(false);
          setIsBtnLoading(false);
          return data;
        })
        .catch(err => {
          if (chosenUserSubscription?.modules?.deliveryComplaint)
            handleBannerMessage({ err, showBanner });
          setDisabled(true);
          setIsBtnLoading(false);
          return err;
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenUserSubscription]);

  // Logic to toggle message banner.
  useEffect(() => {
    if (
      deliveryComplaintState?.chosenComplaint?.message &&
      !messageBannerShown
    ) {
      setMessageBannerShown(true);
    } else if (
      !deliveryComplaintState?.chosenComplaint?.message &&
      messageBannerShown
    ) {
      setMessageBannerShown(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryComplaintState?.chosenComplaint?.message]);

  const availableComplaints =
    deliveryComplaintState?.complaintResolutions &&
    deliveryComplaintState?.deliveryDate &&
    (deliveryComplaintState.complaintResolutions[
      dayjs(deliveryComplaintState?.deliveryDate).format(DATE_FORMAT_BE)
    ] ||
      {});

  const canChooseResolution =
    deliveryComplaintState?.chosenComplaint?.resolutions?.length > 1;

  return (
    <Dialog show={show} onClose={onClose}>
      <Heading level={4} mb={5} color="primaryBase">
        {ts.t('DeliveryComplaint.Complaint')}
      </Heading>
      <Box mb={5}>
        <EntitySwitch
          defaultComponent={<DisabledSubscriptionInfo />}
          mhlu={<AhDisabledSubscriptionInfo />}
        />
      </Box>
      <Banner
        show={messageBannerShown}
        onClose={() => setMessageBannerShown(false)}
        mb={4}
      >
        {deliveryComplaintState.chosenComplaint.message}
      </Banner>
      {isLoading ? (
        <Flex justifyContent="center" mt={8}>
          <ContentLoader />
        </Flex>
      ) : (
        <React.Fragment>
          <Select
            id="chosenComplaint"
            data-testid="value"
            label={ts.t('DeliveryComplaint.Labels.Value')}
            onChange={e =>
              handleChosenComplaintChanged({
                complaint: e.target.value,
                availableComplaints,
              })
            }
            placeholder="Maak een keuze"
            value={deliveryComplaintState?.chosenComplaint?.complaint || ''}
            mb={5}
            error={!!deliveryComplaintErrors.chosenComplaint}
            message={deliveryComplaintErrors.chosenComplaint}
          >
            {Object.keys(availableComplaints).map(option => (
              <option key={option} value={option}>
                {getResolutionOptionLabel(option)}
              </option>
            ))}
          </Select>
          <DatePicker
            pickerInputOnly
            mb={5}
            id="deliveryDate"
            data-testid="deliveryDate"
            label={ts.t('DeliveryComplaint.Labels.Date')}
            onChange={handleDateChanged}
            disabledDays={day =>
              isDayDisabled({
                day,
                deliveryDates: deliveryComplaintState.data.deliveryDates,
              })
            }
            value={deliveryComplaintState.deliveryDate}
            error={deliveryComplaintErrors.deliveryDate}
            message={deliveryComplaintErrors.deliveryDate}
          />
          <ChosenComplaintExtra
            chosenComplaint={deliveryComplaintState.chosenComplaint}
          />
          {deliveryComplaintState.chosenComplaint.resolutions &&
            deliveryComplaintState.chosenComplaint.resolutions[0] !==
              'NoAction' && (
              <React.Fragment>
                <Paragraph mt={5} mb={2}>
                  {canChooseResolution
                    ? `Wat had ${PERSONAL_PRONOUN} graag gewenst als oplossing?`
                    : 'Oplossing:'}
                </Paragraph>
                {deliveryComplaintState.chosenComplaint.resolutions.map(
                  resolution => (
                    <StyledBox
                      key={resolution}
                      className={canChooseResolution ? '' : 'single-select'}
                    >
                      <Box py={2}>
                        <Choice
                          p={2}
                          id={`resolution-${resolution}`}
                          data-testid={`resolution-${resolution}`}
                          value={resolution}
                          name="resolution"
                          title={mapResolution(resolution)}
                          checked={
                            deliveryComplaintState.chosenComplaint
                              .chosenOption === resolution
                          }
                          onChange={({ target }) => {
                            setDeliveryComplaintState({
                              chosenComplaint: {
                                ...deliveryComplaintState.chosenComplaint,
                                chosenOption: target.value,
                              },
                            });
                          }}
                        />
                      </Box>
                    </StyledBox>
                  ),
                )}
              </React.Fragment>
            )}
          <Box align="right" mt={6}>
            <Button
              onClick={handleSave}
              iconRight={ChevronForward}
              loading={isHandleSaveLoading}
              disabled={!isEmpty(deliveryComplaintErrors)}
            >
              Bevestigen
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Dialog>
  );
};

export default DeliveryComplaintDialog;
