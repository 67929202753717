import {
  Box,
  Button,
  Dialog,
  useMediaQuery,
} from '@mediahuis/chameleon-react-legacy';
import { Add } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import React, { useEffect, useState } from 'react';
import { useGlobalContext, useHolidayContext } from '~/context';
import { ts } from '~/services';
import { scrollTopWithTimeout } from '~/utils';
import { AH_HOLIDAY_SOLUTIONS, HOLIDAY_STEPS } from '../../../data';
import getLanguage from '../../../language';
import DialogContent from '../../DialogContent';

const Webform = () => {
  const globalContext = useGlobalContext();
  const { chosenUserSubscription } = globalContext;
  const { setHolidayState } = useHolidayContext();

  const [isDialogShown, setIsDialogShown] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [currentStep, setCurrentStep] = useState(HOLIDAY_STEPS.CHOICE_STEP);

  const language = getLanguage(
    chosenUserSubscription?.internalSubscriptionType,
  );
  const buttonWidth = useMediaQuery(
    {
      md: 'auto',
    },
    'full',
  );
  const buttonMarginButton = useMediaQuery(
    {
      md: 0,
    },
    7,
  );

  useEffect(() => {
    setHolidayState({
      holidayArrangementOptions: {
        resolutions: [
          AH_HOLIDAY_SOLUTIONS.CONTINUE_DIGITAL,
          AH_HOLIDAY_SOLUTIONS.FAMILY_ADDRESS,
          AH_HOLIDAY_SOLUTIONS.HOLIDAY_ADDRESS,
        ],
        allowedCountries: ['LU'],
        allowedPostalCodesByCountry: {
          LU: {
            allowAllPostalCodes: true,
          },
        },
      },
    });
  }, []);

  const handleDialog = ({ type }) => {
    setDialogType(type);
    setIsDialogShown(true);
  };

  return (
    <>
      <Button
        width={buttonWidth}
        mb={buttonMarginButton}
        onClick={() => handleDialog({ type: 'webform' })}
        iconLeft={Add}
        disabled={!chosenUserSubscription}
      >
        {language.newHolidayArrangement}
      </Button>
      <Box mt={5}>
        {ts.t('Holiday.WebformDescription', { richContent: true })}
      </Box>
      <Dialog
        show={isDialogShown}
        onClose={() => {
          setIsDialogShown(false);
          setCurrentStep(HOLIDAY_STEPS.CHOICE_STEP);
          scrollTopWithTimeout();
        }}
        closeOnBackdropClick={false}
      >
        <DialogContent
          dialogType={dialogType}
          isDialogShown={isDialogShown}
          setIsDialogShown={setIsDialogShown}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      </Dialog>
    </>
  );
};

export default Webform;
