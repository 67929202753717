import { salutationSelectOptions } from '~/pages/Holiday/data';

const getEmptySolutionData = (hasDefaultTitle = true) => ({
  title: hasDefaultTitle ? salutationSelectOptions[0].value : '',
  firstName: '',
  lastName: '',
  countryCode: '',
  postalCode: '',
  city: '',
  street: '',
  houseNumber: '',
  box: '',
  extra: '',
});

export default getEmptySolutionData;
