import dayjs from 'dayjs';
import React from 'react';
import { Flex, LinkText, Text } from '@mediahuis/chameleon-react-legacy';
import { capitalize, noop } from '~/utils';
import { ts } from '~/services';
import { DATE_FORMAT_BE } from '~/constants';
import {
  HOLIDAY_STATES,
  HOLIDAY_SOLUTIONS,
  HOLIDAY_SOLUTIONS_TRANSLATED,
  HOLIDAY_SOLUTIONS_TRANSLATED_PRINT_ONLY,
  AH_HOLIDAY_SOLUTIONS,
} from '../data';

export const HOLIDAY_CAPTION_STATES = Object.freeze({
  PAST: 'past',
  DISABLED: 'disabled',
});

const translateSolutionData = ({ solution, solutionData }) => {
  const { street, houseNumber, box, postalCode, city } = solutionData;
  switch (solution) {
    case HOLIDAY_SOLUTIONS.HOLIDAY_ADDRESS:
    case HOLIDAY_SOLUTIONS.FAMILY_ADDRESS:
      return `${street} ${houseNumber}${
        box ? `/${box}` : ''
      }, ${postalCode} ${city}`;
    default:
      return '';
  }
};

export const translateButtons = ({
  state,
  onAction,
  index,
  setChosenHoliday,
}) => {
  switch (state) {
    case HOLIDAY_CAPTION_STATES.PAST:
      return (
        <Text size="Caption1" color="colorGreyBase">
          {ts.t('Holiday.HolidayAlreadyExpired')}
        </Text>
      );
    case HOLIDAY_CAPTION_STATES.DISABLED:
      return (
        <Text size="Caption1" color="colorGreyBase">
          {ts.t('Holiday.ChangesNoLongerAllowed')}
        </Text>
      );
    default:
      return state === HOLIDAY_STATES.CONFIRMED ? (
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center" mr={5}>
            <LinkText
              onClick={() => {
                setChosenHoliday(index);
                onAction({ type: 'cancel' });
              }}
            >
              {ts.t('Common.Cancel')}
            </LinkText>
          </Flex>
          <LinkText
            onClick={() => {
              setChosenHoliday(index);
              onAction({ type: 'holidayRule', editing: true });
            }}
          >
            {ts.t('Common.Edit')}
          </LinkText>
        </Flex>
      ) : (
        <Text size="Caption1" color="colorGreyBase">
          {capitalize(ts.t('Constants.POSSESSIVE_ADJECTIVE'))}{' '}
          {ts.t('Holiday.RequestIsBeingProcessed')}
        </Text>
      );
  }
};

export const translateSolution = (
  solution,
  subscriptionHasDigitalComponent = true,
) => {
  const TRANSLATED_SOLUTION = subscriptionHasDigitalComponent
    ? HOLIDAY_SOLUTIONS_TRANSLATED
    : HOLIDAY_SOLUTIONS_TRANSLATED_PRINT_ONLY;

  switch (solution) {
    case HOLIDAY_SOLUTIONS.CONTINUE_DIGITAL:
    case AH_HOLIDAY_SOLUTIONS.CONTINUE_DIGITAL:
      return TRANSLATED_SOLUTION.INTERRUPT;
    case HOLIDAY_SOLUTIONS.DISCONTINUE_DIGITAL:
    case AH_HOLIDAY_SOLUTIONS.DISCONTINUE_DIGITAL:
      return TRANSLATED_SOLUTION.EXTEND;
    case HOLIDAY_SOLUTIONS.DISCONTINUE_PRINT:
      return TRANSLATED_SOLUTION.INTERRUPT;
    case AH_HOLIDAY_SOLUTIONS.HOLIDAY_ADDRESS:
      return TRANSLATED_SOLUTION.FORWARD_ADDRESS;
    case AH_HOLIDAY_SOLUTIONS.FAMILY_ADDRESS:
      return TRANSLATED_SOLUTION.FORWARD_THIRD_PARTY;
    case HOLIDAY_SOLUTIONS.HOLIDAY_ADDRESS:
    case HOLIDAY_SOLUTIONS.FAMILY_ADDRESS:
      return TRANSLATED_SOLUTION.FORWARD;
    case HOLIDAY_SOLUTIONS.CHARITY:
    case AH_HOLIDAY_SOLUTIONS.CHARITY:
      return TRANSLATED_SOLUTION.CHARITY;
    default:
      return TRANSLATED_SOLUTION.FORWARD;
  }
};

export const cleanDate = date => {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  return new Date(date).toLocaleString('nl-BE', options);
};

const getHolidayState = ({ endDate, minUpdateDate, currentState }) => {
  if (new Date(endDate) < new Date(dayjs(new Date()).format(DATE_FORMAT_BE))) {
    return HOLIDAY_CAPTION_STATES.PAST;
  } else if (new Date(endDate) < new Date(minUpdateDate)) {
    return HOLIDAY_CAPTION_STATES.DISABLED;
  }
  return currentState;
};

const cleanHolidayData = ({
  holidayData = [],
  holidayOptions = {},
  onAction = noop,
  setChosenHoliday,
}) =>
  holidayData?.map((data, index) => {
    const newData = { ...data };
    newData.index = index;
    newData.start = cleanDate(data.start);
    newData.startDate = new Date(data.start);
    newData.end = cleanDate(data.end);
    newData.endDate = new Date(data.end);
    newData.solutionData =
      data.solutionData &&
      translateSolutionData({
        solution: data.vacationSolution,
        solutionData: data.solutionData,
      });
    newData.originalSolutionData = data.solutionData;
    newData.vacationSolution = translateSolution(data.vacationSolution);
    newData.cancel = translateButtons({
      state: getHolidayState({
        endDate: data.end,
        currentState: data.state,
        minUpdateDate: holidayOptions.minimumCreateDate,
      }),
      index,
      onAction,
      setChosenHoliday,
    });

    return newData;
  });

export default cleanHolidayData;
