import {
  Paragraph,
  Button,
  useMediaQuery,
  RichContent,
} from '@mediahuis/chameleon-react-legacy';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import { SUBSCRIPTION_STATUS_CODES } from '~/constants';
import { config, environment, ts } from '~/services';

const NotAvailable = () => (
  <>
    <Paragraph mb={6}>
      {ts.t('DeliveryComplaint.NoActiveSubscription')}
    </Paragraph>
    <Button
      iconRight={ChevronForward}
      width={useMediaQuery({ sm: 'auto' }, 'full')}
      as="a"
      href={`https://${environment.sitePrefix}.${config.siteUrl}/activeer`}
      target="_blank"
    >
      {ts.t('DeliveryComplaint.ActivateDigitalSubscription')}
    </Button>
  </>
);

const getNotAvailableCopy = ({ chosenUserSubscription }) => {
  const {
    WAITING,
    SUSPENDED,
    TEMPORARY_SUSPENSION,
  } = SUBSCRIPTION_STATUS_CODES;
  const subscriptionScopes =
    chosenUserSubscription?.subscriptionScopes?.scopes || [];
  const subscriptionState = chosenUserSubscription?.state;
  const subscriptionType = chosenUserSubscription?.subscriptionType;
  const isDeliveryComplaintModuleActive =
    chosenUserSubscription?.modules?.deliveryComplaint;

  if (!isDeliveryComplaintModuleActive && subscriptionScopes.length > 0) {
    return (
      <RichContent
        as="span"
        fontFamily="system"
        dangerouslySetInnerHTML={{
          __html: ts.t('DeliveryComplaint.NotAvailableModule'),
        }}
      />
    );
  }
  if (subscriptionType?.slice(-2)?.toLowerCase() === 'wk') {
    return (
      <RichContent
        as="span"
        fontFamily="system"
        dangerouslySetInnerHTML={{
          __html: ts.t('DeliveryComplaint.NotAvailableWKSubscription'),
        }}
      />
    );
  }
  if (subscriptionState?.toLowerCase() === WAITING) {
    return (
      <Paragraph mb={5}>
        {ts.t('DeliveryComplaint.NotAvailableWaiting')}
      </Paragraph>
    );
  } else if (subscriptionState?.toLowerCase() === SUSPENDED) {
    return (
      <Paragraph mb={5}>
        {ts.t('DeliveryComplaint.NotAvailableSuspended')}
      </Paragraph>
    );
  } else if (subscriptionState?.toLowerCase() === TEMPORARY_SUSPENSION) {
    return (
      <Paragraph mb={5}>
        {ts.t('DeliveryComplaint.NotAvailableTemporarySuspended')}
      </Paragraph>
    );
  }
  return <NotAvailable />;
};

export default getNotAvailableCopy;
