import dayjs from 'dayjs';

const getFirstUpcomingAddressChange = (addressChanges = []) => {
  const addressChangesInTheFuture = addressChanges?.filter(({ startDate }) =>
    dayjs(startDate).isAfter(dayjs(), 'day'),
  );
  const sortedAddressChanges = addressChangesInTheFuture?.sort(
    (a, b) => new Date(a.startDate) - new Date(b.startDate),
  );

  return sortedAddressChanges?.[0];
};

export default getFirstUpcomingAddressChange;
