import { Checkbox, Table } from '@mediahuis/chameleon-react-legacy';
import React from 'react';
import styled from 'styled-components';
import {
  getAddressField,
  getFullNameOfCustomerField,
} from '~/pages/Subscription/ah/utils/fields';
import { ts } from '~/services';

const ColumnText = styled.p`
  font-size: 12px;
`;

const CustomerDetailsTable = ({ customerDetails }) => {
  if (!customerDetails) return null;

  const getPhoneNumbers = () => {
    if (
      customerDetails?.contactPointsPhone &&
      customerDetails?.contactPointsPhone?.length > 0
    ) {
      const phoneNumbers = customerDetails?.contactPointsPhone;
      return phoneNumbers.map(x => (
        <ColumnText key={x.phone}>
          {x.countryCallingPrefix} (0) {x.phone}
        </ColumnText>
      ));
    }
    return null;
  };

  const getEmailAddresses = () => {
    if (
      customerDetails?.contactPointsEmail &&
      customerDetails?.contactPointsEmail?.length > 0
    ) {
      const emailAddresses = customerDetails?.contactPointsEmail;
      return emailAddresses.map(x => (
        <ColumnText key={x.email}>{x.email}</ColumnText>
      ));
    }
    return null;
  };
  return (
    <Table
      columns={[
        { title: '', field: 'radioButtonField' },
        {
          title: ts.t('Subscription.CustomerTable.CustomerNr'),
          field: 'customerNr',
        },
        { title: ts.t('Subscription.Type'), field: 'type' },
        {
          title: ts.t('Subscription.CustomerTable.NameAndAddress'),
          field: 'nameAndAddress',
        },
        {
          title: ts.t('Subscription.CustomerTable.Phonenumbers'),
          field: 'phoneNumbers',
        },
        {
          title: ts.t('Subscription.CustomerTable.EmailAddresses'),
          field: 'emailAddresses',
        },
      ]}
      data={[
        {
          radioButtonField: <Checkbox checked />,
          customerNr: <ColumnText>{customerDetails?.id}</ColumnText>,
          type: <ColumnText>{customerDetails?.type}</ColumnText>,
          nameAndAddress: (
            <>
              <ColumnText>
                {getFullNameOfCustomerField(customerDetails)}
              </ColumnText>
              <ColumnText>
                {getAddressField(customerDetails?.addresses?.nonStandard?.[0])}
              </ColumnText>
            </>
          ),
          phoneNumbers: <>{getPhoneNumbers()}</>,
          emailAddresses: <>{getEmailAddresses()}</>,
        },
      ]}
      tableLayout="auto"
    />
  );
};

export default CustomerDetailsTable;
