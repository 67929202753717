import React, { useState } from 'react';
import { Banner, Flex, Loader } from '@mediahuis/chameleon-react-legacy';
import {
  Header,
  Wrapper,
  SubscriptionSelect,
  HeaderWithContentLoader,
} from '~/components';
import { PAGES, METADATA } from '~/constants';
import LoginWall from '~/components/LoginWall';
import AhHolidayContent from '~/pages/Holiday/components/ah/AhHolidayContent';
import EntitySwitch from '~/components/ah/EntitySwitch';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import AhSubscriptionSelect from '~/components/ah/AhSubscriptionSelect';
import { SERVICE_MESSAGE_PAGES } from '~/constants/serviceMessage';
import { ts } from '~/services';
import { getSubscriptionRights } from '~/components/ah/AhSubscriptionSelect/util/rights';
import NotAvailable from './components/NotAvailable';
import HolidayContent from './components/HolidayContent';
import ServiceMessage from '../../components/ServiceMessage';
import Webform from './components/ah/Webform';

const Holiday = () => {
  const globalContext = useGlobalContext();
  const {
    chosenUserSubscription,
    userInfo,
    isFetchingUser,
    userModuleRights,
    isLoadingPayerAndReceiver,
  } = globalContext;
  const [
    updateHolidayOverviewHasFailed,
    setUpdateHolidayOverviewHasFailed,
  ] = useState(false);

  const isLoggedIn = userInfo.accountGuid;

  if (isFetchingUser && !isLoggedIn) {
    return <HeaderWithContentLoader title={ts.t('Holiday.Heading')} />;
  }

  const subscriptionRights = getSubscriptionRights({
    subscriptionId: chosenUserSubscription?.id,
    subscriptionsRights: userModuleRights?.subscriptions,
  });

  const hasTemporaryDeliveryChangeWebformRight =
    subscriptionRights?.temporaryDeliveryChangeWebform?.access;
  const hasTemporaryDeliveryChangeRight =
    subscriptionRights?.temporaryDeliveryChange?.access;
  const hasHolidayRight =
    hasTemporaryDeliveryChangeRight ||
    chosenUserSubscription?.modules?.vacation;

  const showHolidayContent =
    chosenUserSubscription &&
    !hasTemporaryDeliveryChangeWebformRight &&
    hasHolidayRight;

  const showNotAvailable =
    chosenUserSubscription !== null &&
    !hasTemporaryDeliveryChangeWebformRight &&
    !hasHolidayRight &&
    !isLoadingPayerAndReceiver;

  // When the TemporaryDeliveryChangeWebform right is true the TDC Webform is shown
  // When there is a chosenUserSubscription and the TemporaryDeliveryChange right or the vacation module (MHBE) is true,
  // and the TemporaryDeliveryChangeWebform right is false the HolidayContent is shown
  // The NotAvaible component is shown when the subscription has no holiday and temporaryDeliveryChangeWebform right

  return (
    <>
      {METADATA[PAGES.holiday]}
      <Header heading={ts.t('Holiday.Heading')} />
      <Wrapper>
        {updateHolidayOverviewHasFailed && (
          <Banner
            appearance="error"
            show
            onClose={() => setUpdateHolidayOverviewHasFailed(false)}
            mb={7}
          >
            {ts.t('Holiday.Errors.UpdateHolidayOverview')}
          </Banner>
        )}
        <ServiceMessage mb={5} page={SERVICE_MESSAGE_PAGES.holiday} />
        {isLoggedIn ? (
          <>
            <EntitySwitch
              defaultComponent={<SubscriptionSelect />}
              mhlu={<AhSubscriptionSelect />}
            />
            {(chosenUserSubscription === null || isLoadingPayerAndReceiver) && (
              <Flex height="500px" justifyContent="center" alignItems="center">
                <Loader />
              </Flex>
            )}
            {showHolidayContent && (
              <EntitySwitch
                defaultComponent={<HolidayContent />}
                mhlu={
                  <AhHolidayContent
                    setUpdateHolidayOverviewHasFailed={
                      setUpdateHolidayOverviewHasFailed
                    }
                  />
                }
              />
            )}

            {hasTemporaryDeliveryChangeWebformRight &&
              !isLoadingPayerAndReceiver && <Webform />}

            {showNotAvailable && (
              <NotAvailable chosenUserSubscription={chosenUserSubscription} />
            )}
          </>
        ) : (
          <LoginWall variant="inline" />
        )}
      </Wrapper>
    </>
  );
};

export default Holiday;
