import { config } from '~/services';
import apiCall from './apiCall';

const getSubscriptionScope = ({ title, brand }) =>
  apiCall(
    `${config.apiSubscriptionScopeUrl}/SubscriptionScopes/${title}?brand=${brand}`,
    {
      method: 'GET',
      headers: { 'x-api-version': '1.0' },
      onSuccess: response => response,
      onError: error => error,
    },
  );
export default getSubscriptionScope;
