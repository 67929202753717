import { config, environment } from '~/services';
import { touchPointIds } from '../data';
import getPaymentIntervalMapping from './getPaymentIntervalMapping';
import getProductMapping from './getProductMapping';
import getUserGenderMapping from './getUserGenderMapping';
import getDeviceMapping from './getDeviceMapping';

/**
 * @interface SurveyRespondent
 * @property {string} firstname
 * @property {string} lastname
 * @property {string} email
 * @property {string} phone
 */

/**
 * @interface SurveyExtraConfig
 * @property {number} score
 * @property {boolean} isPreview
 * @property {SurveyRespondent} respondent
 * @property {Object.<string, string|number|boolean>} metadata
 */

/**
 * @interface SurveyConfig
 * @property {string} baseUrl
 * @property {string} tenantId
 * @property {string} touchPointId
 * @property {string} language
 * @property {SurveyExtraConfig} extra
 */

/**
 * @param chosenUserSubscription
 * @param userInfo
 * @param useCase
 * @returns {SurveyConfig}
 */
const getModalConfig = ({ chosenUserSubscription, userInfo, useCase }) => {
  const date = new Date();
  const chosenPaymentInterval = chosenUserSubscription?.paymentInterval;
  const touchPointId =
    touchPointIds[environment.isProd ? config.brand : 'TEST'][useCase];

  return /** @type {SurveyConfig} */ {
    baseUrl: 'https://mediahuis.hellocustomer.cloud',
    tenantId: '31899494-14c4-41ca-8b2c-c2d0c414ac23',
    touchPointId,
    language: 'NL',
    extra: {
      isPreview: !environment.isProd,
      metadata: {
        aanleverdatum: date?.toISOString(),
        // eslint-disable-next-line babel/camelcase
        agreement_id: chosenUserSubscription?.id,
        betalingswijze: getPaymentIntervalMapping(chosenPaymentInterval),
        // eslint-disable-next-line babel/camelcase
        customer_id: chosenUserSubscription?.payer?.customerId,
        einddatum: chosenUserSubscription?.endDate,
        email: userInfo?.emailAddress,
        geboortejaar: userInfo?.birthDate,
        geslacht: getUserGenderMapping(userInfo?.gender),
        guid: userInfo?.accountGuid,
        package: chosenUserSubscription?.name,
        postcode: userInfo?.zipCode,
        product: getProductMapping(
          chosenUserSubscription?.internalSubscriptionType,
        ),
        source: `${getDeviceMapping()}`,
        startdatum: chosenUserSubscription?.startDate,
        taalcode: 'NL',
        titel: config.brand,
        type: useCase,
        vervaldatum: chosenUserSubscription?.originalAgreementExpires,
        voornaam: userInfo?.firstName,
      },
    },
  };
};

export default getModalConfig;
