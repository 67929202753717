const getPublicHolidayName = ({ dToday, widgetData }) => {
  const strToday = `${dToday.getDate()}-${
    dToday.getMonth() + 1
  }-${dToday.getFullYear()}`;
  const arrPublicHolidays =
    widgetData.jsonSettings.settings.publicholidays.dates;

  const publicHoliday = arrPublicHolidays.find(
    holiday => holiday.date === strToday,
  );

  return publicHoliday ? publicHoliday.name : '';
};

export default getPublicHolidayName;
