import { FLOW_TYPES } from '~/pages/Subscription/constants/onlineCancellation';

export const mapToCancellationBody = ({
  type,
  selectedCancellationReason,
  reasonDetails,
  phoneNumber,
  userInfo,
  selectedAlternativeOffer,
}) => {
  return {
    type,
    reasonCode: selectedCancellationReason?.code,
    reasonDetails,
    email: userInfo?.emailAdress,
    phone: phoneNumber,
    ...(selectedAlternativeOffer &&
      type === FLOW_TYPES.CONVINCED_TO_STAY && {
        chosenAlternative: selectedAlternativeOffer.scenario,
      }),
  };
};
export const filterReasons = (reasons, chosenUserSubscription) => {
  return reasons.filter(
    reason =>
      reason.subscriptionTypes.includes(
        chosenUserSubscription.subscriptionType,
      ) && reason.enabled,
  );
};
export const sortReasons = reasons =>
  reasons.sort((a, b) => a.position - b.position);

export const getCancellationReasonCategory = ({
  medicalOrDeathReason = false,
  otherReason = false,
} = {}) => {
  if (medicalOrDeathReason) {
    return 'medical';
  }
  if (otherReason) {
    return 'other';
  }
  return 'general';
};
