import React from 'react';
import { Flex, Loader } from '@mediahuis/chameleon-react-legacy';
import { DIALOG_MIN_HEIGHT } from '~/constants/style';

const ContentLoader = ({ height = 'auto' }) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: DIALOG_MIN_HEIGHT }}
      height={height}
    >
      <Loader />
    </Flex>
  );
};

export default ContentLoader;
