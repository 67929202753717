import {
  Accordion,
  AccordionItem,
  Box,
  Button,
  Heading,
  RichContent,
} from '@mediahuis/chameleon-react-legacy';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/legacy/icons';
import React from 'react';
import { ts } from '~/services';

export default function FaqArticles({ articles, href, introLabel }) {
  return (
    <Box bgColor="colorPrimary10" p={6} mb={7}>
      <Heading level={4} mb={3}>
        {introLabel || ts.t('Contact.Faq.Title')}
      </Heading>
      <Accordion id="contact-form-accordion" collapsible mb={6}>
        {articles.map(article => (
          <AccordionItem
            title={article.title}
            key={article.articleInternalNumber}
            className={`article-${article.articleInternalNumber}`}
          >
            <RichContent
              as="span"
              fontFamily="system"
              dangerouslySetInnerHTML={{
                __html: article.content,
              }}
            />
          </AccordionItem>
        ))}
      </Accordion>
      <Button
        as="a"
        target="_blank"
        href={href}
        iconRight={ChevronForward}
        size="small"
      >
        {ts.t('Contact.Faq.Button')}
      </Button>
    </Box>
  );
}
